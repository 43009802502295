import { useBooleanHandlers } from '@resnet/client-common/react/hooks/use-boolean-handlers'
import { useLocationStateBoolean } from '@resnet/client-common/react/hooks/use-location-state-boolean'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { IS_OPENED_CREATE_ISSUE_DRAWER_KEY } from '@resnet/client-web/constants/location-state-keys'

export const useCreateIssueDrawer = () => {
  const [opened, setOpened] = useLocationStateBoolean(IS_OPENED_CREATE_ISSUE_DRAWER_KEY)

  const { onDisable: onClose, onEnable: onOpen } = useBooleanHandlers({ setState: setOpened })

  return { onClose, onOpen, opened }
}

export const CreateIssueDrawerContainer = createHookContainer(useCreateIssueDrawer)
