import produce from 'immer'

import { omit } from '@resnet/client-common/common/utils/object/omit'

import {
  useAddRouteAssetsMutation,
  useAddRouteUsersMutation,
  useUpdateRouteMutation,
  useCreateRouteMutation,
  useDeleteRouteMutation,
  useInfiniteListRoutesQuery,
  useJoinRouteMutation,
  useLeaveRouteMutation,
  useRemoveRouteAssetsMutation,
  useRemoveRouteUsersMutation,
  useGetRouteQuery,
  useListRoutesQuery,
  useUpdateRelationsMutation,
  useArchiveRouteMutation,
  useUnarchiveRouteMutation,
  useUpdateEntityTypeMutation,
  EntityTypeIdsT,
  useUploadResourceMutation,
  useDeleteResourceMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const routeQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findRouteQueries = () => {
    return findQueries(queryClient, useGetRouteQuery)
  }

  const findRouteQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetRouteQuery, (data) => data.getRoute, { id })

  const joinRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useJoinRouteMutation, {
      onSuccess: (data, { routeId }) => {
        findRouteQueriesById({ id: routeId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const leaveRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLeaveRouteMutation, {
      onSuccess: (data, { routeId }) => {
        findRouteQueriesById({ id: routeId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRouteMutation, {
      onError: (error, { id }) => {
        findRouteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findRouteQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const route = draft?.getRoute

              if (!route) {
                return
              }

              Object.assign(route, omit(['relations'], data))
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findRouteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const removeRouteUsersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRemoveRouteUsersMutation, {
      onSuccess: (data, { routeId }) => {
        findRouteQueriesById({ id: routeId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const addRouteUsersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useAddRouteUsersMutation, {
      onSuccess: (data, { routeId }) => {
        findRouteQueriesById({ id: routeId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const removeRouteAssetsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRemoveRouteAssetsMutation, {
      onSuccess: (data, { routeId }) => {
        findRouteQueriesById({ id: routeId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const addRouteAssetsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useAddRouteAssetsMutation, {
      onSuccess: (data, { routeId }) => {
        findRouteQueriesById({ id: routeId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteRouteMutation, {
      onSuccess: (data, { id }) => {
        findRouteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findRouteQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveRouteMutation, {
      onError: (error, { id }) => {
        findRouteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findRouteQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const route = draft?.getRoute

              if (!route) {
                return
              }

              Object.assign(route, { archived: { at: new Date().toISOString() } })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findRouteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveRouteMutation, {
      onError: (error, { id }) => {
        findRouteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findRouteQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const route = draft?.getRoute

              if (!route) {
                return
              }

              delete route.archived
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findRouteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.RouteT) {
          return
        }

        findRouteQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const uploadResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUploadResourceMutation, {
      onSuccess: (data, { originId }) => {
        findRouteQueriesById({ id: originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceMutation, {
      onSuccess: (data) => {
        const routeId = data?.deleteResource?.resource?.refId

        if (!routeId) {
          return
        }

        findRouteQueriesById({ id: routeId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    joinRouteHandlers(),
    leaveRouteHandlers(),
    updateRouteHandlers(),
    removeRouteUsersHandlers(),
    addRouteUsersHandlers(),
    removeRouteAssetsHandlers(),
    addRouteAssetsHandlers(),
    deleteRouteHandlers(),
    updateRelationsHandlers(),
    archiveRouteHandlers(),
    unarchiveRouteHandlers(),
    updateEntityTypeHandlers(),
    uploadResourceHandlers(),
    deleteResourceHandlers(),
  ]
}

export const routesQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findRoutesQueries = () => findQueries(queryClient, useListRoutesQuery)

  const createRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateRouteMutation, {
      onSuccess: () => {
        findRoutesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteRouteMutation, {
      onSuccess: () => {
        findRoutesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createRouteHandlers(), deleteRouteHandlers()]
}

export const infiniteRoutesQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteRoutesQueries = () => findInfiniteQueries(queryClient, useInfiniteListRoutesQuery)

  const createRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateRouteMutation, {
      onSuccess: () => {
        findInfiniteRoutesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteRouteMutation, {
      onSuccess: () => {
        findInfiniteRoutesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: () => {
        findInfiniteRoutesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveRouteMutation, {
      onSuccess: () => {
        findInfiniteRoutesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveRouteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveRouteMutation, {
      onSuccess: () => {
        findInfiniteRoutesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.RouteT) {
          return
        }

        findInfiniteRoutesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createRouteHandlers(),
    deleteRouteHandlers(),
    updateRelationsHandlers(),
    archiveRouteHandlers(),
    unarchiveRouteHandlers(),
    updateEntityTypeHandlers(),
  ]
}
