import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable, checkNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { assert } from '@resnet/client-common/typescript/utils/assert'
import { toPartial } from '@resnet/client-common/typescript/utils/to-partial'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type {
  AttachmentSingleCustomFieldValueT,
  AttachmentMultipleCustomFieldValueT,
  AttachmentCustomFieldValueT,
} from '@resnet/client-shared/shared/custom-fields/presets/attachment/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type {
  AttachmentFormCustomFieldValueT,
  AttachmentMultipleFormCustomFieldValueT,
  AttachmentSingleFormCustomFieldValueT,
} from './form-custom-field-value'

export const mapCustomFieldValueToFormCustomFieldValue: CustomFieldT<
  AttachmentCustomFieldValueT,
  AttachmentFormCustomFieldValueT
>['mapCustomFieldValueToFormCustomFieldValue'] = ({ value, field, resources }) => {
  const resourcesById = toPartial(propToKey('id', resources))

  const payload = field.payload

  assert(payload, checkNonNullable)

  const attachmentPayload = payload[CustomFieldTypeT.AttachmentT]

  assert(attachmentPayload, checkNonNullable)

  const { multiple } = attachmentPayload

  if (multiple) {
    const attachmentMultipleFieldValue = value as undefined | AttachmentMultipleCustomFieldValueT

    const attachmentMultipleFormFieldValue: AttachmentMultipleFormCustomFieldValueT = pipeline(
      attachmentMultipleFieldValue,
      (x) => x ?? [],
      (x) => x.map((id) => resourcesById[id]),
      (x) => x.filter(checkNonUndefined),
    )

    return attachmentMultipleFormFieldValue
  }

  const attachmentSingleFieldValue = value as undefined | AttachmentSingleCustomFieldValueT

  const attachmentSingleFormFieldValue: AttachmentSingleFormCustomFieldValueT = pipeline(
    attachmentSingleFieldValue,
    (x) => (!checkNonNullable(x) ? x : resourcesById[x]),
    (x) => x ?? null,
  )

  return attachmentSingleFormFieldValue
}
