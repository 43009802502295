import type { themes } from '@resnet/client-shared/shared/gdl/themes'

export const sketchSx = ({ theme }: { theme: keyof typeof themes }) => {
  switch (theme) {
    case 'dark': {
      return { filter: 'invert(1)' }
    }
    case 'light': {
      return null
    }
  }
}
