import type { MaybeT } from '@resnet/client-common/common/types/common'

import type { InfiniteData, Query, QueryClient } from '@resnet/client-api/vendors/react-query'

import type { InfiniteQueryHookT, QueryHookT } from '../types/query-hook'

import { queryHookMatchesQuery } from './query-hook-matches-query'

export const findExactQuery = <TVariables, TQueryFnData, TError, TData>(
  queryClient: QueryClient,
  queryHook: QueryHookT<TVariables, TQueryFnData, TError, TData>,
  variables: TVariables,
) => {
  return queryClient.getQueryCache().find(queryHook.getKey(variables)) as unknown as
    | Query<TQueryFnData, TError, TQueryFnData, [string, TVariables]>
    | undefined
}

export const findQueries = <TVariables, TQueryFnData, TError, TData>(
  queryClient: QueryClient,
  queryHook: QueryHookT<TVariables, TQueryFnData, TError, TData>,
  predicate?: (query: Query<TQueryFnData, TError, TQueryFnData, [string, TVariables]>) => boolean,
) =>
  queryClient.getQueryCache().findAll({
    predicate: (query) => {
      if (!queryHookMatchesQuery(queryHook as QueryHookT, query) || !query.state.data) {
        return false
      }

      return !predicate
        ? true
        : predicate(query as unknown as Query<TQueryFnData, TError, TQueryFnData, [string, TVariables]>)
    },
  }) as unknown as Query<TQueryFnData, TError, TQueryFnData, [string, TVariables]>[]

export const findGetQueriesById = <TVariables, TQueryFnData, TError, TData>(
  queryClient: QueryClient,
  queryHook: QueryHookT<TVariables, TQueryFnData, TError, TData>,
  selector: (data: TQueryFnData) => MaybeT<{ id: string }>,
  { id }: { id: string },
) =>
  findQueries(queryClient, queryHook, (query) => {
    const entity = selector(query.state.data as unknown as TQueryFnData)

    if (!entity) {
      return false
    }

    return entity.id === id
  })

export const findInfiniteQueries = <TVariables, TQueryFnData, TError, TData>(
  queryClient: QueryClient,
  queryHook: InfiniteQueryHookT<TVariables, TQueryFnData, TError, TData>,
  predicate?: (
    query: Query<InfiniteData<TQueryFnData>, TError, InfiniteData<TQueryFnData>, [string, TVariables]>,
  ) => boolean,
) =>
  queryClient.getQueryCache().findAll({
    predicate: (query) => {
      if (!queryHookMatchesQuery(queryHook as QueryHookT, query) || !query.state.data) {
        return false
      }

      return !predicate
        ? true
        : predicate(
            query as unknown as Query<
              InfiniteData<TQueryFnData>,
              TError,
              InfiniteData<TQueryFnData>,
              [string, TVariables]
            >,
          )
    },
  }) as unknown as Query<InfiniteData<TQueryFnData>, TError, InfiniteData<TQueryFnData>, [string, TVariables]>[]
