import type { Client } from 'graphql-ws'
import { createClient } from 'graphql-ws'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

const getGraphqlWsClientBaseUrl = (tenant: string): string => {
  const baseUrl = process.env.PUBLIC_WS_API_ENDPOINT

  if (baseUrl !== undefined) {
    return baseUrl
  }

  switch (tenant) {
    case 'dev': {
      return 'ws://api.dev.resnet.ai'
    }
    case 'demo':
    case 'test': {
      return 'ws://api.staging.resnet.ai'
    }
    default: {
      return 'ws://api.resnet.ai'
    }
  }
}

let graphQlWsClient: null | Client = null

export const getGraphQlWsClient = (): Client => {
  return assertedNonNullable(graphQlWsClient)
}

export const setGraphQlWsClient = (tenant: string): void => {
  graphQlWsClient = createClient({
    url: getGraphqlWsClientBaseUrl(tenant) + '/graphql',
  })
}
