import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { DiscriminatorCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/discriminator/custom-field-value'

import {
  FieldPreviewLabel,
  FieldPreviewLayout,
  FieldPreviewValueContent,
} from '@resnet/client-web/shared/gdl/components/field-preview'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type DiscriminatorCustomFieldUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | DiscriminatorCustomFieldValueT
}

export const DiscriminatorCustomFieldUserFormPreview = ({
  field,
  value,
}: DiscriminatorCustomFieldUserFormPreviewPropsT) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const discriminatorPayload = payload[CustomFieldTypeT.DiscriminatorT]

  assert(discriminatorPayload, checkNonNullable)

  const options = discriminatorPayload.options

  const option = options.find((option) => option.key === value)

  const renderValue = () => {
    if (!option) {
      return <FieldPreviewValueContent>-</FieldPreviewValueContent>
    }

    return (
      <FieldPreviewValueContent isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</FieldPreviewValueContent>
    )
  }

  return (
    <ObjectDetailsRow>
      <FieldPreviewLayout>
        <FieldPreviewLabel>{field.name}</FieldPreviewLabel>
        {renderValue()}
      </FieldPreviewLayout>
    </ObjectDetailsRow>
  )
}
