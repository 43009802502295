import { cloneElement } from 'react'

import type { ForwardableT } from '../types/forwardable'

export const renderForwardable = <PropsT extends object>(
  forwardable: ForwardableT<PropsT>,
  props: PropsT,
): React.ReactNode => {
  if (typeof forwardable === 'function') {
    return forwardable(props)
  }

  return cloneElement(forwardable, props)
}
