import DOMPurify from 'dompurify'
import escapeHtml from 'escape-html'
import type { Descendant } from 'slate'
import { Text } from 'slate'

import type { MentionElement } from '@resnet/client-web/@types/slate'

import { MENTION_TYPE } from './constants'

const stringifyAttributes = (attributes: Record<string, unknown>): string =>
  Object.entries(attributes)
    .map(([key, value]) => `${key}="${value}"`)
    .join(' ')

const mapNodeToMentionAttributes = (node: MentionElement) => {
  const commonAttributes = {
    'data-mention-id': node.mentionId,
  }

  switch (node.mentionType) {
    case MENTION_TYPE.entity: {
      const commonEntityAttributes = {
        ...commonAttributes,
        'data-mention-type': node.mentionType,
      }

      switch (node.entityTypeId) {
        case 'well': {
          return {
            ...commonEntityAttributes,
            'data-entity-type': node.entityTypeId,
          }
        }
        case 'battery': {
          return {
            ...commonEntityAttributes,
            'data-entity-type': node.entityTypeId,
          }
        }
        case 'site': {
          return {
            ...commonEntityAttributes,
            'data-entity-type': node.entityTypeId,
          }
        }
        case 'route': {
          return {
            ...commonEntityAttributes,
            'data-entity-type': node.entityTypeId,
          }
        }
        default: {
          return null
        }
      }
    }
    case MENTION_TYPE.group: {
      return {
        ...commonAttributes,
        'data-mention-type': node.mentionType,
      }
    }
    case MENTION_TYPE.user: {
      return {
        ...commonAttributes,
        'data-mention-type': node.mentionType,
      }
    }
    default: {
      return null
    }
  }
}

export const serializeNodeToHTML = (node: Descendant): string => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)

    if (node.bold) {
      string = `<strong>${string}</strong>`
    }

    if (node.italic) {
      string = `<em>${string}</em>`
    }

    if (node.underline) {
      string = `<u>${string}</u>`
    }

    return string
  }

  const children = serializeToHTML(node.children)

  switch (node.type) {
    case 'paragraph': {
      return `<p>${children}</p>`
    }
    case 'numbered-list': {
      return `<ol>${children}</ol>`
    }
    case 'bulleted-list': {
      return `<ul>${children}</ul>`
    }
    case 'list-item': {
      return `<li>${children}</li>`
    }
    case 'mention': {
      const mentionAttributes = mapNodeToMentionAttributes(node)

      if (!mentionAttributes) {
        return children
      }

      return `<span class="mention" ${stringifyAttributes(mentionAttributes)}>@${node.character}</span>`
    }
    default: {
      return children
    }
  }
}

export const serializeToHTML = (nodes: Descendant[]): string => nodes.map(serializeNodeToHTML).join('')

export const sanitizeHTML = (value: string): string => DOMPurify.sanitize(value)
