import PlusSolidIcon from '@resnet/client-shared/assets/icons/plus-solid.svg'

import { Button } from '../button'

export const CreateNewButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      color="primary"
      icon={PlusSolidIcon}
      size="sm"
      variant="outlined"
      onClick={onClick}
    >
      Create New
    </Button>
  )
}
