import { transform } from '@resnet/client-common/common/utils/object/transform'
import { createHookElementsContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { useInfiniteSearchUsersQuery } from '@resnet/client-api/api'

import { createUseSelectDropdown } from '../../common/factories/create-use-select-dropdown'

export const useUserSelectDropdown = createUseSelectDropdown({
  mapOptionsQueryDataToOptions: (data) => data.searchUsers.items,
  useOptionsQuery: useInfiniteSearchUsersQuery,
  withSearch: (input, { search }) =>
    transform(input ?? {}, {
      search: () => search,
    }),
})

export const UserSelectDropdownContainer = createHookElementsContainer(useUserSelectDropdown)
