import { useCallback } from 'react'

import { useLocationState } from '@resnet/client-common/react/hooks/use-location-state'

export const useLocationStateBoolean = <KeyT extends string>(key: KeyT) => {
  const model = useLocationState<boolean, undefined | Record<KeyT, undefined | boolean>>(
    useCallback((location) => location.state?.[key] ?? false, [key]),
    useCallback((next, location) => ({ ...location, state: { ...location.state, [key]: next } }), [key]),
  )

  return model
}
