import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

// NOTE copy pasted from old colors, perhaps should be replaced according to new palette

export const gasColors = createConstrainer<Record<string, string>>()({
  alienSkin: '#3ff391',
  blue300: '#383dca',
  blue500: '#2196f3',
  blueA700: '#2962ff',
  blueGray400: '#78909c',
  blueGray50: '#eceff1',
  deepOrange400: '#ff7043',
  greenA700: '#00c853',
  lightBlue: '#01f1e3',
  purpleA700: '#aa00ff',
  yellowA700: '#ffd600',
})
