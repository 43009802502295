import { useImperativeHandle } from 'react'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

export const useResourcesGalleryItem = ({
  index,
  register,
  onOpen,
}: {
  index: number
  register: (index: number, ref: null | { onOpen?: () => void }) => void
  onOpen?: () => void
}) => {
  useImperativeHandle(
    (ref: null | { onOpen?: () => void }) => register(index, ref),
    () => ({ onOpen }),
    [onOpen],
  )
}

export const ResourcesGalleryItemContainer = createHookContainer(useResourcesGalleryItem)
