import { useAsyncEventCallback } from '@resnet/client-common/react/hooks/use-async-event-callback'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { useLikePostMutation, type PostFragmentT, useUnlikePostMutation } from '@resnet/client-api/api'

type UsePostLikeButtonPropsT = { post: PostFragmentT }

export const usePostLikeButton = ({ post }: UsePostLikeButtonPropsT) => {
  const { mutateAsync: likePost } = useLikePostMutation()

  const { mutateAsync: unlikePost } = useUnlikePostMutation()

  const { likedByMe } = post

  const [isLoading, onClick] = useAsyncEventCallback(async () => {
    if (likedByMe) {
      await unlikePost({ postId: post.id })
    } else {
      await likePost({ postId: post.id })
    }
  })

  return { isLoading, onClick }
}

export const PostLikeButtonContainer = createHookContainer(usePostLikeButton)
