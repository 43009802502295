import { createContext, useContext } from 'react'

import type { ThemeT } from '@resnet/client-shared/shared/gdl/themes'
import { themes } from '@resnet/client-shared/shared/gdl/themes'

export const ThemeContext = createContext<ThemeT>('dark')

export const useTheme = () => useContext(ThemeContext)

export const useThemeProps = () => {
  const theme = useTheme()

  const themeProps = themes[theme]

  return themeProps
}
