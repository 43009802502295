import { useCallback, useLayoutEffect, useRef } from 'react'

export const useEventCallback = <ParamsT extends ReadonlyArray<unknown>, ResultT>(
  callback: (...params: ParamsT) => ResultT,
): ((...params: ParamsT) => ResultT) => {
  const callbackRef = useRef(callback)

  useLayoutEffect(() => {
    callbackRef.current = callback
  })

  return useCallback((...params) => {
    return callbackRef.current(...params)
  }, [])
}
