import type { CustomFieldT } from '../../types/custom-field'

import { NumberCustomFieldUserFormField } from './components/number-custom-field-user-form-field'

export const renderUserFormField: CustomFieldT['renderUserFormField'] = ({ field, form, name }) => {
  return (
    <NumberCustomFieldUserFormField
      field={field}
      form={form}
      name={name}
    />
  )
}
