import type { BoxProps, SxProps } from '@mui/material'
import { Box, ButtonBase, Skeleton, Tooltip } from '@mui/material'
import { useCallback } from 'react'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useIntersectionObserver } from '@resnet/client-common/react/hooks/use-intersection-observer'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import CheckmarkSolidIcon from '@resnet/client-shared/assets/icons/checkmark-solid.svg'
import SearchSolidIcon from '@resnet/client-shared/assets/icons/search-solid.svg'

import { Wrap } from '@resnet/client-web/components/wrap/wrap'
import { Divider } from '@resnet/client-web/shared/gdl/components/divider'
import { Spinner } from '@resnet/client-web/shared/gdl/components/spinner'
import { TextOverflow } from '@resnet/client-web/shared/gdl/components/text-overflow'
import { VirtualizedList } from '@resnet/client-web/shared/gdl/components/virtualized-list'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { Avatar } from '@resnet/client-web/shared/gdl/legacy-components/avatar'
import { stretchRowItemStyles } from '@resnet/client-web/styles/sx-presets'

import type { AbstractOptionT } from '../../types/abstract-option'

export type MediaT =
  | undefined
  | { type?: 'avatar'; src?: string; children?: string }
  | { type: 'icon'; Icon: SVGComponentT }

const SELECT_DROPDOWN_CONFIG = {
  listPadding: 8,
  optionHeight: 36,
  optionIconOffset: 6,
  optionIconSize: 16,
  optionPadding: 8,
}

const SELECT_DROPDOWN_MAX_VISIBLE_OPTIONS = 6

enum SELECT_CUSTOM_PROPERTY {
  DROPDOWN_OPTION_IS_HOVERED = '--select-dropdown-option-is-hovered',
}

type SelectDropdownSearchInputPropsT = {
  placeholder?: string
  value: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const SelectDropdownSearchInput = ({
  placeholder = 'Type to search...',
  value,
  onChange,
}: SelectDropdownSearchInputPropsT): React.ReactElement => {
  const autofocusRef = useCallback((node: null | HTMLInputElement) => {
    node?.focus()
  }, [])

  return (
    <Box
      sx={{
        borderColor: themeColors.borderDefault,
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: themeColors.overBackgroundDefault,
        display: 'flex',
        fontSize: '14px',
        lineHeight: '16px',
        padding: '8px',
      }}
    >
      <SearchSolidIcon
        fill={themeColors.overBackgroundDefault}
        height={16}
        width={16}
      />
      <Box sx={{ width: '6px' }} />
      <Box
        component="input"
        placeholder={placeholder}
        ref={autofocusRef}
        sx={[
          {
            all: 'unset',
          },
          {
            font: 'inherit',
          },
          stretchRowItemStyles,
        ].flat()}
        value={value}
        onChange={onChange}
      />
    </Box>
  )
}

const SelectDropdownOptionsGroupSkeleton = (): React.ReactElement => {
  return (
    <Box
      sx={{
        padding: '10px',
      }}
    >
      <Skeleton
        sx={{ height: '14px' }}
        variant="rectangular"
      />
    </Box>
  )
}

type SelectDropdownOptionActionPropsT = {
  id: string
  title?: string
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  onClick?: () => void
  'data-testid'?: string
}

const SelectDropdownOptionAction = ({
  Icon,
  onClick,
  title,
  'data-testid': dataTestId,
}: SelectDropdownOptionActionPropsT): React.ReactElement => {
  return (
    <Wrap
      with={!title ? null : (tooltipChildren: React.ReactElement) => <Tooltip title={title}>{tooltipChildren}</Tooltip>}
    >
      <Box
        component="button"
        data-testid={dataTestId}
        sx={[
          {
            all: 'unset',
          },
          {
            '&:hover': {
              color: themeColors.basePrimary,
            },
            alignItems: 'center',
            color: themeColors.overBackgroundDefault,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            opacity: `var(${SELECT_CUSTOM_PROPERTY.DROPDOWN_OPTION_IS_HOVERED})`,
            position: 'relative',
            transition: 'color 200ms ease, opacity 200ms ease',
          },
        ]}
        type="button"
        onClick={onClick}
      >
        <Icon
          style={{
            height: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px`,
            width: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px`,
          }}
        />
      </Box>
    </Wrap>
  )
}

type SelectDropdownOptionPropsT = {
  sx?: SxProps
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  media?: MediaT
  actions?: React.ComponentProps<typeof SelectDropdownOptionAction>[]
  onClick?: () => void
  children: React.ReactNode
  pending?: boolean
}

const SelectDropdownOption = ({
  sx = [],
  Icon,
  media,
  actions,
  onClick,
  children,
  pending = false,
}: SelectDropdownOptionPropsT): React.ReactElement => {
  const renderMedia = (): React.ReactNode => {
    if (!media) {
      return null
    }

    switch (media.type) {
      case 'icon': {
        return (
          <media.Icon
            style={{
              fill: themeColors.basePrimary,
              height: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px`,
              width: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px`,
            }}
          />
        )
      }
      default: {
        return (
          <Avatar
            size={SELECT_DROPDOWN_CONFIG.optionIconSize}
            {...media}
          />
        )
      }
    }
  }

  const renderContent = (): React.ReactNode => {
    const renderIcon = (): React.ReactElement => {
      if (pending) {
        return (
          <Spinner
            color="primary"
            size={SELECT_DROPDOWN_CONFIG.optionIconSize}
          />
        )
      }

      if (!Icon) {
        return <Box sx={{ width: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px` }} />
      }

      return (
        <Icon
          style={{
            fill: themeColors.basePrimary,
            height: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px`,
            width: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px`,
          }}
        />
      )
    }

    return (
      <Box
        sx={[
          stretchRowItemStyles,
          { alignItems: 'center', display: 'flex', gap: `${SELECT_DROPDOWN_CONFIG.optionIconOffset}px` },
        ].flat()}
      >
        {renderIcon()}
        {renderMedia()}
        <TextOverflow sx={stretchRowItemStyles}>{children}</TextOverflow>
      </Box>
    )
  }

  const renderActions = (): React.ReactNode => {
    if (!actions) {
      return null
    }

    return actions.map((action) => {
      return (
        <SelectDropdownOptionAction
          key={action.id}
          {...action}
        />
      )
    })
  }

  return (
    <Box
      sx={[
        {
          [SELECT_CUSTOM_PROPERTY.DROPDOWN_OPTION_IS_HOVERED]: 0,
          '&:focus-within': {
            [SELECT_CUSTOM_PROPERTY.DROPDOWN_OPTION_IS_HOVERED]: 1,
            backgroundColor: themeColors.surfaceNeutralDefault,
          },
          '&:hover': {
            [SELECT_CUSTOM_PROPERTY.DROPDOWN_OPTION_IS_HOVERED]: 1,
            backgroundColor: themeColors.surfaceNeutralDefault,
          },
          alignItems: 'center',
          color: themeColors.overBackgroundDefault,
          display: 'flex',
          fontSize: '14px',
          gap: '8px',
          height: `${SELECT_DROPDOWN_CONFIG.optionHeight}px`,
          lineHeight: '120%',
          position: 'relative',
          px: `${SELECT_DROPDOWN_CONFIG.optionPadding}px`,
          textAlign: 'start',
          transition: 'background-color 200ms ease',
        },
        sx,
      ].flat()}
    >
      <Wrap
        with={
          !children
            ? null
            : (tooltipChildren: React.ReactElement) => (
              <Tooltip
                disableInteractive
                enterDelay={500}
                enterNextDelay={500}
                title={children}
              >
                {tooltipChildren}
              </Tooltip>
              )
        }
      >
        <ButtonBase
          sx={{
            inset: 0,
            position: 'absolute',
            width: '100%',
          }}
          onClick={onClick}
        />
      </Wrap>
      {renderContent()}
      {renderActions()}
    </Box>
  )
}

const SelectDropdownOptionSkeleton = (): React.ReactElement => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: `${SELECT_DROPDOWN_CONFIG.optionHeight}px`,
        px: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px`,
      }}
    >
      <Skeleton
        sx={{
          height: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px`,
          width: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px`,
        }}
        variant="circular"
      />
      <Box sx={{ width: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px` }} />
      <Skeleton
        sx={[stretchRowItemStyles, { height: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px` }].flat()}
        variant="rectangular"
      />
    </Box>
  )
}

type SelectDropdownOptionsBottomBoundaryPropsT = {
  onObserve?: () => void
}

const SelectDropdownOptionsBottomBoundary = ({
  onObserve,
}: SelectDropdownOptionsBottomBoundaryPropsT): React.ReactElement => {
  const ref = useIntersectionObserver(
    useEventCallback((entry) => {
      if (!entry.isIntersecting) {
        return
      }

      onObserve?.()
    }),
  )

  return (
    <Box
      ref={ref}
      sx={{
        transform: `translateY(${-SELECT_DROPDOWN_CONFIG.optionHeight * 2}px)`,
      }}
    />
  )
}

type SelectDropdownMessagePanelPropsT = { children: React.ReactNode }

const SelectDropdownMessagePanel = ({ children }: SelectDropdownMessagePanelPropsT): React.ReactElement => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        color: themeColors.overBackgroundDefault,
        display: 'flex',
        fontSize: '14px',
        height: `${SELECT_DROPDOWN_CONFIG.optionHeight}px`,
        lineHeight: '110%',
        px: `${SELECT_DROPDOWN_CONFIG.optionPadding}px`,
        textAlign: 'start',
      }}
    >
      <Box sx={{ width: `${SELECT_DROPDOWN_CONFIG.optionIconSize}px` }} />
      <Box sx={{ width: `${SELECT_DROPDOWN_CONFIG.optionIconOffset}px` }} />
      {children}
    </Box>
  )
}

type SelectDropdownBasePropsT = {
  search?: React.ComponentProps<typeof SelectDropdownSearchInput>
  action?: {
    label: React.ReactNode
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    onClick?: () => void
    pending?: boolean
  }
  sx?: SxProps
  children: React.ReactNode
}

type SelectDropdownBaseRefT = React.ForwardedRef<HTMLElement>

const SelectDropdownBase = forwardFunctionalComponentRef(
  ({ search, action, sx, children }: SelectDropdownBasePropsT, ref: SelectDropdownBaseRefT): React.ReactElement => {
    const renderSearch = (): React.ReactNode => {
      if (!search) {
        return null
      }

      return (
        <>
          <Box sx={{ height: '10px' }} />
          <Box sx={{ px: '8px' }}>
            <SelectDropdownSearchInput {...search} />
          </Box>
          <Box sx={{ height: '10px' }} />
          <Divider />
        </>
      )
    }

    const renderAction = (): React.ReactNode => {
      if (!action) {
        return null
      }

      return (
        <>
          <Divider />
          <Box sx={{ height: '8px' }} />
          <SelectDropdownOption
            Icon={action.Icon}
            pending={action.pending}
            sx={{
              color: themeColors.basePrimary,
            }}
            onClick={action.onClick}
          >
            {action.label}
          </SelectDropdownOption>
          <Box sx={{ height: '8px' }} />
        </>
      )
    }

    return (
      <Box
        ref={ref}
        sx={[
          {
            backgroundColor: themeColors.surfaceNeutralDefault,
            border: themeColors.borderDefault,
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            width: '228px',
          },
          sx ?? null,
        ].flat()}
      >
        {renderSearch()}
        {children}
        {renderAction()}
      </Box>
    )
  },
)

export type SelectDropdownCommonPropsT<OptionT extends AbstractOptionT> = Omit<SelectDropdownBasePropsT, 'children'> & {
  options: undefined | null | readonly OptionT[]
  getOptionMedia?: (option: OptionT) => undefined | MediaT
  getOptionLabel?: (option: OptionT) => React.ReactNode
  getOptionActions?: (option: OptionT) => SelectDropdownOptionActionPropsT[]
  notFoundMessage?: string
  bottomBoundary?: SelectDropdownOptionsBottomBoundaryPropsT
  isLoading?: boolean
  error?: string
}

export const defaultGetOptionLabel: NonNullable<SelectDropdownCommonPropsT<{ id: string }>['getOptionLabel']> = (
  option,
): string => option.id

export type SelectDropdownPropsT<OptionT extends AbstractOptionT> = SelectDropdownCommonPropsT<OptionT> & {
  value: undefined | null | OptionT
  onChange?: (value: null | OptionT) => void
}

export const SelectDropdown = forwardFunctionalComponentRef(
  <OptionT extends AbstractOptionT>(
    {
      search,
      action,
      options,
      getOptionMedia,
      getOptionLabel = defaultGetOptionLabel,
      getOptionActions,
      notFoundMessage = 'No options found',
      bottomBoundary,
      isLoading = false,
      value,
      onChange,
      error,
      sx,
    }: SelectDropdownPropsT<OptionT>,
    ref: SelectDropdownBaseRefT,
  ): React.ReactElement => {
    const renderOuterElement = useCallback(
      ({ children, ...props }: BoxProps, ref: React.ForwardedRef<HTMLDivElement>) => (
        <Box
          {...props}
          ref={ref}
        >
          {children}
          {!bottomBoundary ? null : <SelectDropdownOptionsBottomBoundary {...bottomBoundary} />}
        </Box>
      ),
      [bottomBoundary],
    )

    const renderOptions = (): React.ReactNode => {
      if (isLoading) {
        return Array.from({ length: SELECT_DROPDOWN_MAX_VISIBLE_OPTIONS }, (item, index) => {
          return <SelectDropdownOptionSkeleton key={index} />
        })
      }

      if (error) {
        return (
          <Box sx={{ py: `${SELECT_DROPDOWN_CONFIG.listPadding}px` }}>
            <SelectDropdownMessagePanel>{error}</SelectDropdownMessagePanel>
          </Box>
        )
      }

      if (!options) {
        throw new Error('should be non nullable')
      }

      if (options.length === 0) {
        return (
          <Box sx={{ py: `${SELECT_DROPDOWN_CONFIG.listPadding}px` }}>
            <SelectDropdownMessagePanel>{notFoundMessage}</SelectDropdownMessagePanel>
          </Box>
        )
      }

      return (
        <VirtualizedList
          data={options}
          getItemSize={() => SELECT_DROPDOWN_CONFIG.optionHeight}
          maxHeight={SELECT_DROPDOWN_CONFIG.optionHeight * SELECT_DROPDOWN_MAX_VISIBLE_OPTIONS}
          paddingBottom={SELECT_DROPDOWN_CONFIG.listPadding}
          paddingTop={SELECT_DROPDOWN_CONFIG.listPadding}
          renderOuterElement={renderOuterElement}
        >
          {({ item: option, style }) => {
            const isSelected = value && option.id === value.id

            return (
              <SelectDropdownOption
                Icon={!isSelected ? undefined : CheckmarkSolidIcon}
                actions={getOptionActions?.(option)}
                key={option.id}
                media={getOptionMedia?.(option)}
                sx={style}
                onClick={() => {
                  onChange?.(isSelected ? null : option)
                }}
              >
                {getOptionLabel(option)}
              </SelectDropdownOption>
            )
          }}
        </VirtualizedList>
      )
    }

    return (
      <SelectDropdownBase
        action={action}
        ref={ref}
        search={search}
        sx={sx}
      >
        {renderOptions()}
      </SelectDropdownBase>
    )
  },
)

export type SelectMultipleDropdownPropsT<OptionT extends AbstractOptionT> = SelectDropdownCommonPropsT<OptionT> & {
  value: undefined | null | readonly OptionT[]
  onChange?: (value: OptionT[]) => void
}

export const SelectMultipleDropdown = forwardFunctionalComponentRef(
  <OptionT extends AbstractOptionT>(
    {
      search,
      action,
      options,
      getOptionMedia,
      getOptionLabel = defaultGetOptionLabel,
      getOptionActions,
      notFoundMessage = 'No options found',
      bottomBoundary,
      isLoading = false,
      value,
      onChange,
      error,
    }: SelectMultipleDropdownPropsT<OptionT>,
    ref: SelectDropdownBaseRefT,
  ): React.ReactElement => {
    const renderOuterElement = useCallback(
      ({ children, ...props }: BoxProps, ref: React.ForwardedRef<HTMLDivElement>) => (
        <Box
          {...props}
          ref={ref}
        >
          {children}
          {!bottomBoundary ? null : <SelectDropdownOptionsBottomBoundary {...bottomBoundary} />}
        </Box>
      ),
      [bottomBoundary],
    )

    const renderOptions = (): React.ReactNode => {
      if (isLoading) {
        return (
          <>
            <SelectDropdownOptionsGroupSkeleton />
            {Array.from({ length: 3 }, (_item, index) => {
              return <SelectDropdownOptionSkeleton key={index} />
            })}
          </>
        )
      }

      if (error) {
        return (
          <Box sx={{ py: `${SELECT_DROPDOWN_CONFIG.listPadding}px` }}>
            <SelectDropdownMessagePanel>{error}</SelectDropdownMessagePanel>
          </Box>
        )
      }

      if (!options || !value) {
        throw new Error('should be non nullable')
      }

      if (options.length === 0) {
        return (
          <Box sx={{ py: `${SELECT_DROPDOWN_CONFIG.listPadding}px` }}>
            <SelectDropdownMessagePanel>{notFoundMessage}</SelectDropdownMessagePanel>
          </Box>
        )
      }

      return (
        <VirtualizedList
          data={options}
          getItemSize={() => SELECT_DROPDOWN_CONFIG.optionHeight}
          maxHeight={SELECT_DROPDOWN_CONFIG.optionHeight * SELECT_DROPDOWN_MAX_VISIBLE_OPTIONS}
          paddingBottom={SELECT_DROPDOWN_CONFIG.listPadding}
          paddingTop={SELECT_DROPDOWN_CONFIG.listPadding}
          renderOuterElement={renderOuterElement}
        >
          {({ item: option, style }) => {
            const isSelected = value.some((selectedOption) => selectedOption.id === option.id)

            return (
              <SelectDropdownOption
                Icon={isSelected ? CheckmarkSolidIcon : undefined}
                actions={getOptionActions?.(option)}
                key={option.id}
                media={getOptionMedia?.(option)}
                sx={style}
                onClick={() => {
                  if (isSelected) {
                    onChange?.(value.filter((selectedOption) => selectedOption.id !== option.id))
                  } else {
                    onChange?.(value.concat([option]))
                  }
                }}
              >
                {getOptionLabel(option)}
              </SelectDropdownOption>
            )
          }}
        </VirtualizedList>
      )
    }

    return (
      <SelectDropdownBase
        action={action}
        ref={ref}
        search={search}
      >
        {renderOptions()}
      </SelectDropdownBase>
    )
  },
)
