import { useMemo } from 'react'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { useMaybeProfileTokenPayload } from './use-profile-token-payload'

export const useMaybeProfileId = () => {
  const { profileTokenPayload } = useMaybeProfileTokenPayload()

  const profileId = useMemo(() => {
    if (!profileTokenPayload) {
      return null
    }

    return profileTokenPayload.sub
  }, [profileTokenPayload])

  return { profileId }
}

export const useProfileId = () => {
  const { profileId } = useMaybeProfileId()

  assert(profileId, checkNonNullable, '"profileId" should be non nullable')

  return { profileId }
}
