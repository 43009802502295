import type { ComparatorT } from '@resnet/client-common/react/hooks/use-same-ref'
import { useSameRef } from '@resnet/client-common/react/hooks/use-same-ref'

export const MemoProvider = <ValueT extends unknown>({
  Context,
  value,
  comparator,
  children,
}: {
  Context: React.Context<ValueT>
  value: ValueT
  comparator?: ComparatorT<ValueT>
  children: React.ReactNode
}): JSX.Element => {
  const memoizedValue = useSameRef(value, comparator)

  return <Context.Provider value={memoizedValue}>{children}</Context.Provider>
}
