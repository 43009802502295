import { useCallback, useState } from 'react'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { useDerivedState } from './use-derived-state'

export type ToggleT = {
  value: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
  setState: (nextValue: boolean) => void
}

export const useToggleState = ([value, setValue]: [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
]): ToggleT => {
  const onOpen = useCallback(() => {
    setValue(true)
  }, [setValue])

  const onClose = useCallback(() => {
    setValue(false)
  }, [setValue])

  const onToggle = useCallback(() => {
    setValue((current) => !current)
  }, [setValue])

  return { onClose, onOpen, onToggle, setState: setValue, value }
}

export const useToggle = (initialValue: ToggleT['value'] = false): ToggleT => useToggleState(useState(initialValue))

export const ToggleContainer = createHookContainer(
  ({ initialValue }: { initialValue?: Parameters<typeof useToggle>[0] }) => useToggle(initialValue),
)

export const useDerivedToggle = (value: ToggleT['value'] = false): ToggleT =>
  useToggleState(useDerivedState(() => value, [value]))
