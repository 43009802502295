import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import ErrorSolidIcon from '@resnet/client-shared/assets/icons/error-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { TextOverflow } from '@resnet/client-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'

export type FieldPropsT = BoxProps<'div'>

export const Field = ({ sx = null, children, ...props }: FieldPropsT) => {
  return (
    <Box
      {...props}
      sx={[{ display: 'flex', flexDirection: 'column', gap: toPx(8) }, sx].flat()}
    >
      {children}
    </Box>
  )
}

export type FieldLabelPropsT = BoxProps<'div'>

export const FieldLabel = ({ sx = null, children, ...props }: FieldLabelPropsT) => {
  return (
    <Box
      {...props}
      sx={[mapTypographyPresetToSx(typographyPresets.labelLarge), { color: themeColors.overBackgroundBold }, sx].flat()}
    >
      {children}
    </Box>
  )
}

const fieldInputSx = () => ({
  alignItems: 'center',
  backgroundColor: themeColors.surfaceNeutralDefault,
  borderColor: themeColors.borderFaded,
  borderRadius: toPx(8),
  borderStyle: 'solid',
  borderWidth: toPx(1),
  display: 'flex',
  position: 'relative',
  px: toPx(16 - 1),
  py: toPx(8 - 1),
})

export type FieldReadonlyInputPropsT = MergeAllT<
  [
    BoxProps<'div'>,
    {
      value: string
    },
  ]
>

export type FieldReadonlyInputRefT = React.Ref<HTMLDivElement>

export const FieldReadonlyInput = forwardFunctionalComponentRef(
  ({ value, sx = null, ...props }: FieldReadonlyInputPropsT, ref: FieldReadonlyInputRefT) => {
    const renderLabel = () => {
      return (
        <Box
          sx={[
            mapTypographyPresetToSx(typographyPresets.bodyMedium),
            {
              color: themeColors.overBackgroundFaded,
              display: 'flex',
              flexGrow: 1,
              flexShrink: 999,
              minWidth: 0,
            },
          ]}
        >
          <TextOverflow>{value}</TextOverflow>
        </Box>
      )
    }

    return (
      <Box
        {...props}
        ref={ref}
        sx={[fieldInputSx(), sx].flat()}
      >
        {renderLabel()}
      </Box>
    )
  },
)

export type FieldButtonInputPropsT = MergeAllT<
  [
    BoxProps<'button'>,
    {
      value: string
      endIcon?: ForwardableT<React.SVGProps<SVGSVGElement>>
    },
  ]
>

export type FieldButtonInputRefT = React.Ref<HTMLButtonElement>

export const FieldButtonInput = forwardFunctionalComponentRef(
  ({ value, endIcon, sx = null, ...props }: FieldButtonInputPropsT, ref: FieldButtonInputRefT) => {
    const renderLabel = () => {
      return (
        <Box
          sx={[
            mapTypographyPresetToSx(typographyPresets.bodyMedium),
            {
              color: themeColors.overBackgroundFaded,
              display: 'flex',
              flexGrow: 1,
              flexShrink: 999,
              minWidth: 0,
            },
          ]}
        >
          <TextOverflow>{value}</TextOverflow>
        </Box>
      )
    }

    const renderEndIcon = () => {
      if (!endIcon) {
        return null
      }

      return renderForwardable(endIcon, { fill: themeColors.overBackgroundFaded, height: 20, width: 20 })
    }

    return (
      <Box
        {...props}
        component="button"
        ref={ref}
        sx={[
          { all: 'unset' },
          fieldInputSx(),
          { cursor: 'pointer', transition: 'background-color 0.2s ease' },
          { '&:hover': { backgroundColor: themeColors.surfaceNeutralHover } },
          { '&:active': { backgroundColor: themeColors.surfaceNeutralPressed } },
          sx,
        ].flat()}
      >
        {renderLabel()}
        {renderEndIcon()}
      </Box>
    )
  },
)

export type FieldHelperTextPropsT = MergeAllT<
  [
    BoxProps<'div'>,
    {
      icon?: ForwardableT<{ width: number; height: number }>
    },
  ]
>

export const FieldHelperText = ({ sx = null, icon, children, ...props }: FieldHelperTextPropsT) => {
  const renderIcon = (): React.ReactNode => {
    if (!icon) {
      return null
    }

    const iconSize = 16

    return renderForwardable(icon, { height: iconSize, width: iconSize })
  }

  const renderContent = (): React.ReactNode => {
    return <Box sx={{ flexGrow: 1, width: 0 }}>{children}</Box>
  }

  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.captionRegular),
        { alignItems: 'center', color: themeColors.overBackgroundMuted, display: 'flex', gap: toPx(8) },
        sx,
      ].flat()}
    >
      {renderIcon()}
      {renderContent()}
    </Box>
  )
}

export type FieldErrorTextPropsT = FieldHelperTextPropsT

export const FieldErrorText = ({ sx = null, children, ...props }: FieldErrorTextPropsT) => {
  const renderIcon = ({ width, height }: { width: number; height: number }): React.ReactNode => {
    return (
      <ErrorSolidIcon
        height={height}
        width={width}
      />
    )
  }

  return (
    <FieldHelperText
      {...props}
      icon={renderIcon}
      sx={[{ color: themeColors.feedbackCritical }, sx].flat()}
    >
      {children}
    </FieldHelperText>
  )
}
