import { useEffect } from 'react'

import { createHookContainer } from '../utils/create-hook-container'

import { useEventCallback } from './use-event-callback'

type UseErrorBoundaryFallbackEffectT = {
  onError?: () => void
  onRecover?: () => void
}

export const useErrorBoundaryFallbackEffect = ({ onError, onRecover }: UseErrorBoundaryFallbackEffectT) => {
  const onMount = useEventCallback(() => {
    onError?.()
    onRecover?.()
  })

  useEffect(() => {
    onMount()
  }, [onMount])
}

export const ErrorBoundaryFallbackEffectContainer = createHookContainer(useErrorBoundaryFallbackEffect)
