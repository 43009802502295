import type { QueryClient } from '@tanstack/react-query'

import { useFollowMutation, useInfiniteEntitiesQuery, useUnfollowMutation } from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findInfiniteQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'

export const infiniteEntitiesQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteEntitiesQueries = () => findInfiniteQueries(queryClient, useInfiniteEntitiesQuery)

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: () => {
        findInfiniteEntitiesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: () => {
        findInfiniteEntitiesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [followHandlers(), unfollowHandlers()]
}
