import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

export type ValueT = undefined | null | string | number | AnyTypeT[] | Record<string, AnyTypeT>

export const checkIsEmptyDeep = <SpecificValueT extends ValueT>(value: SpecificValueT): boolean => {
  if (value === undefined || value === null || value === '') {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(checkIsEmptyDeep)
  }

  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).every((key) => checkIsEmptyDeep(value[key]))
  }

  return false
}

export const checkIsNotEmptyDeep = <SpecificValueT extends ValueT>(value: SpecificValueT): boolean => {
  return !checkIsEmptyDeep(value)
}
