export const propToKey = <
  PropNameT extends PropertyKey,
  ItemT extends Record<PropertyKey, unknown> & Record<PropNameT, PropertyKey>,
>(
  propName: PropNameT,
  list: readonly ItemT[],
) =>
  Object.fromEntries(list.map((value) => [value[propName], value])) as unknown as {
    [K in ItemT[PropNameT]]: Extract<ItemT, Record<PropNameT, K>>
  }
