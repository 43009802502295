import type { SxProps } from '@mui/material'
import { Box, ButtonBase } from '@mui/material'
import { useCallback } from 'react'

import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import AngleDownSolidIcon from '@resnet/client-shared/assets/icons/angle-down-solid.svg'
import AngleUpSolidIcon from '@resnet/client-shared/assets/icons/angle-up-solid.svg'

import { TextOverflow } from '@resnet/client-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { usePopover } from '@resnet/client-web/shared/gdl/legacy-components/popover'

import { ClickableOpacity } from '../../components/clickable-opacity'
import type { AbstractOptionT } from '../../types/abstract-option'
import { Avatar } from '../avatar'
import type { SelectDropdownPropsT, SelectMultipleDropdownPropsT } from '../select-dropdown'
import { SelectDropdown, SelectMultipleDropdown, defaultGetOptionLabel, type MediaT } from '../select-dropdown'

type SelectButtonPropsT = {
  sx?: SxProps
  media?: MediaT
  disabled?: boolean
  onClick?: () => void
  children?: React.ReactNode
  isSelected?: boolean
}

type SelectButtonRefT = React.ForwardedRef<HTMLButtonElement>

const SelectButton = forwardFunctionalComponentRef(
  (
    { sx = [], media, disabled, isSelected, onClick, children }: SelectButtonPropsT,
    ref: SelectButtonRefT,
  ): React.ReactElement => {
    const Icon = isSelected ? AngleUpSolidIcon : AngleDownSolidIcon

    return (
      <ButtonBase
        disabled={disabled}
        ref={ref}
        sx={[
          {
            '&:focus': {
              borderColor: themeColors.borderBold,
            },
            '&:hover': {
              borderColor: themeColors.borderBold,
            },
            backgroundColor: themeColors.surfaceNeutralDefault,
            borderColor: themeColors.borderDefault,
            borderRadius: '4px',
            borderStyle: 'solid',
            borderWidth: '1px',
            color: themeColors.overBackgroundDefault,
            display: 'flex',
            fontSize: '14px',
            gap: '8px',
            height: '36px',
            justifyContent: 'flex-start',
            lineHeight: '120%',
            maxWidth: '100%',
            px: '8px',
            textAlign: 'left',
          },
          !disabled ? [] : [{ opacity: 0.6 }],
          sx,
        ].flat()}
        onClick={onClick}
      >
        {!media ? null : (
          <Avatar
            size={16}
            {...media}
          />
        )}
        <TextOverflow sx={{ flexGrow: 1 }}>{children}</TextOverflow>
        <Icon style={{ flexShrink: 0, height: '18px', width: '18px' }} />
      </ButtonBase>
    )
  },
)

export type SelectPropsT<OptionT extends AbstractOptionT> = Pick<
  SelectDropdownPropsT<OptionT>,
  'getOptionMedia' | 'getOptionLabel' | 'value' | 'onChange'
> &
  Pick<SelectButtonPropsT, 'sx' | 'disabled'> & {
    placeholder?: string
    dropdownSx?: SxProps
    children: (
      props: Pick<SelectDropdownPropsT<OptionT>, 'getOptionMedia' | 'getOptionLabel' | 'value' | 'onChange'> & {
        children: (props: SelectDropdownPropsT<OptionT>) => React.ReactElement
      },
    ) => React.ReactElement
  }

export const Select = <OptionT extends AbstractOptionT>({
  sx,
  dropdownSx,
  getOptionMedia,
  getOptionLabel = defaultGetOptionLabel,
  value,
  onChange: onChangeProp,
  placeholder = 'Not Selected',
  disabled = false,
  children,
}: SelectPropsT<OptionT>): React.ReactElement => {
  const renderButtonLabel = (): React.ReactNode => {
    if (!value) {
      return (
        <Box
          component="span"
          sx={{ color: themeColors.overBackgroundDefault }}
        >
          {placeholder}
        </Box>
      )
    }

    return getOptionLabel(value)
  }

  const [renderSelectDropdown, { setAnchorEl, onOpen, onClose, isOpened }] = usePopover(() =>
    children({
      children: (props) => (
        <SelectDropdown
          sx={dropdownSx}
          {...props}
        />
      ),
      getOptionLabel,
      getOptionMedia,
      onChange,
      value,
    }),
  )

  const onChange = useCallback(
    (nextValue: null | OptionT) => {
      onChangeProp?.(nextValue)
      onClose()
    },
    [onChangeProp, onClose],
  )

  return (
    <>
      <SelectButton
        disabled={disabled}
        isSelected={isOpened}
        media={!value ? undefined : getOptionMedia?.(value)}
        ref={setAnchorEl}
        sx={sx}
        onClick={onOpen}
      >
        {renderButtonLabel()}
      </SelectButton>
      {renderSelectDropdown()}
    </>
  )
}

export type TextSelectPropsT<OptionT extends AbstractOptionT> = Pick<
  SelectDropdownPropsT<OptionT>,
  'getOptionMedia' | 'getOptionLabel' | 'value' | 'onChange'
> & {
  sx?: SxProps
  disabled?: boolean
  label: string
  placeholder?: string
  children: (
    props: Pick<SelectDropdownPropsT<OptionT>, 'getOptionMedia' | 'getOptionLabel' | 'value' | 'onChange'> & {
      children: (props: SelectDropdownPropsT<OptionT>) => React.ReactElement
    },
  ) => React.ReactElement
}

export const TextSelect = <OptionT extends AbstractOptionT>({
  sx = [],
  getOptionMedia,
  getOptionLabel = defaultGetOptionLabel,
  value,
  onChange: onChangeProp,
  label,
  placeholder = 'Not Selected',
  disabled = false,
  children,
}: TextSelectPropsT<OptionT>): React.ReactElement => {
  const [renderSelectDropdown, { setAnchorEl, onOpen, onClose }] = usePopover(() =>
    children({
      children: (props) => <SelectDropdown {...props} />,
      getOptionLabel,
      getOptionMedia,
      onChange,
      value,
    }),
  )

  const onChange = useCallback(
    (nextValue: null | OptionT) => {
      onChangeProp?.(nextValue)
      onClose()
    },
    [onChangeProp, onClose],
  )

  const renderTextSelectButton = (): React.ReactNode => {
    return (
      <Box sx={[{ display: 'flex', fontSize: '14px', lineHeight: '20px' }, sx].flat()}>
        <Box
          component="span"
          sx={{ color: themeColors.overBackgroundFaded }}
        >
          {label}:
        </Box>
        &nbsp;
        <ClickableOpacity
          disabled={disabled}
          ref={setAnchorEl}
          sx={{ color: themeColors.basePrimary }}
          onClick={onOpen}
        >
          {!value ? placeholder : getOptionLabel(value)}
        </ClickableOpacity>
      </Box>
    )
  }

  return (
    <>
      {renderTextSelectButton()}
      {renderSelectDropdown()}
    </>
  )
}

export type SelectMultiplePropsT<OptionT extends AbstractOptionT> = Pick<
  SelectMultipleDropdownPropsT<OptionT>,
  'getOptionMedia' | 'getOptionLabel' | 'value' | 'onChange'
> &
  Pick<SelectButtonPropsT, 'sx' | 'disabled'> & {
    placeholder?: string
    children: (
      props: Pick<SelectMultipleDropdownPropsT<OptionT>, 'getOptionMedia' | 'getOptionLabel' | 'value' | 'onChange'> & {
        children: (props: SelectMultipleDropdownPropsT<OptionT>) => React.ReactElement
      },
    ) => React.ReactElement
    isLoading?: boolean
  }

export const SelectMultiple = <OptionT extends AbstractOptionT>({
  sx,
  placeholder = 'Not Selected',
  disabled = false,
  getOptionMedia,
  getOptionLabel = defaultGetOptionLabel,
  value,
  onChange: onChangeProp,
  isLoading = false,
  children,
}: SelectMultiplePropsT<OptionT>): React.ReactElement => {
  const renderButtonLabel = (): React.ReactNode => {
    if (isLoading) {
      return 'Loading...'
    }

    if (!value || value.length === 0) {
      return (
        <Box
          component="span"
          sx={{ color: themeColors.overBackgroundDefault }}
        >
          {placeholder}
        </Box>
      )
    }

    if (value.length === 1) {
      return getOptionLabel(value[0])
    }

    return `${value.length} Selected`
  }

  const [renderSelectDropdown, { setAnchorEl, onOpen }] = usePopover(() =>
    children({
      children: (props) => <SelectMultipleDropdown {...props} />,
      getOptionLabel,
      getOptionMedia,
      onChange,
      value,
    }),
  )

  const onChange = useCallback(
    (nextValue: OptionT[]) => {
      onChangeProp?.(nextValue)
    },
    [onChangeProp],
  )

  return (
    <>
      <SelectButton
        disabled={disabled}
        media={!value || value.length > 1 ? undefined : getOptionMedia?.(value[0])}
        ref={setAnchorEl}
        sx={sx}
        onClick={onOpen}
      >
        {renderButtonLabel()}
      </SelectButton>
      {renderSelectDropdown()}
    </>
  )
}
