import { transform } from '@resnet/client-common/common/utils/object/transform'

import { FilterModeT } from '@resnet/client-api/api'

import { UserMultipleFilter } from '@resnet/client-web/shared/filters/components/user-multiple-filter'
import { FilterRuleContainer } from '@resnet/client-web/shared/presets/hooks/use-filter-rule'
import { mapFilterModeToKey } from '@resnet/client-web/shared/presets/utils/map-filter-mode-to-key'

import type { CustomFieldT } from '../../types/custom-field'
import { mapCustomFieldToFullKey, mapCustomFieldToKey } from '../../utils/map-custom-field-to-key'

export const createFilterOption: CustomFieldT['createFilterOption'] = ({
  field,
  fieldValuesKey,
  filterObjectType,
  updateFieldsFilter,
}) => {
  return {
    apply: (input, rule) => {
      const { id: payload } = rule

      if (!payload) {
        return input
      }

      const { mode, items } = payload

      if (mode === FilterModeT.IncludeT && items.length === 0) {
        return input
      }

      return updateFieldsFilter(input, (fieldsFilter) =>
        transform(fieldsFilter ?? {}, {
          [mapCustomFieldToKey(field)]: () => ({ [mapFilterModeToKey(mode)]: items }),
        }),
      )
    },
    id: mapCustomFieldToFullKey(field, fieldValuesKey),
    name: field.name,
    objectType: filterObjectType,
    renderContent: ({ filterPresetRuleModel, popperRef }) => {
      return (
        <FilterRuleContainer
          filterPresetRuleModel={filterPresetRuleModel}
          filterType="id"
        >
          {({ filterPresetItemRulePayloadModel: [value, onChange] }) => {
            return (
              <UserMultipleFilter
                popperRef={popperRef}
                value={value}
                onChange={onChange}
              />
            )
          }}
        </FilterRuleContainer>
      )
    },
  }
}
