import produce from 'immer'

import { omit } from '@resnet/client-common/common/utils/object/omit'

import type { InputUpdateInsightT, ListInsightsQueryT } from '@resnet/client-api/api'
import {
  useCreateInsightMutation,
  useDeleteInsightMutation,
  useFollowMutation,
  useInfiniteListInsightsQuery,
  useGetInsightQuery,
  useListInsightsQuery,
  useUnfollowMutation,
  useUpdateFollowersMutation,
  useUpdateInsightMutation,
  useUpdateRelationsMutation,
  useArchiveInsightMutation,
  useUnarchiveInsightMutation,
  useUpdateEntityTypeMutation,
  EntityTypeIdsT,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const insightQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findInsightQueries = () => {
    return findQueries(queryClient, useGetInsightQuery)
  }

  const findInsightQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetInsightQuery, (data) => data.getInsight, { id })

  const updateInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateInsightMutation, {
      onError: (error, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findInsightQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const insight = draft?.getInsight

              if (!insight) {
                return
              }

              Object.assign(insight, omit(['relations'], data))
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFollowersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFollowersMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteInsightMutation, {
      onSuccess: (data, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveInsightMutation, {
      onError: (error, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInsightQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const insight = draft?.getInsight

              if (!insight) {
                return
              }

              Object.assign(insight, { archived: { at: new Date().toISOString() } })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveInsightMutation, {
      onError: (error, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInsightQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const insight = draft?.getInsight

              if (!insight) {
                return
              }

              delete insight.archived
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.InsightT) {
          return
        }

        findInsightQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    updateInsightHandlers(),
    followHandlers(),
    unfollowHandlers(),
    updateFollowersHandlers(),
    deleteInsightHandlers(),
    updateRelationsHandlers(),
    archiveInsightHandlers(),
    unarchiveInsightHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const insightsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findInsightsQueries = () => findQueries(queryClient, useListInsightsQuery)

  const findInfiniteInsightsQueries = () => findInfiniteQueries(queryClient, useInfiniteListInsightsQuery)

  const findInsightsQueriesById = ({ id }: { id: string }) =>
    findQueries(queryClient, useListInsightsQuery, (query) => {
      const data = query.state.data

      if (!data) {
        return false
      }

      const items = data.listInsights.items

      return items.some((item) => item.id === id)
    })

  const findInfiniteInsightsQueriesById = ({ id }: { id: string }) =>
    findInfiniteQueries(queryClient, useInfiniteListInsightsQuery, (query) => {
      const infiniteData = query.state.data

      if (!infiniteData) {
        return false
      }

      return infiniteData.pages.some((data) => {
        const items = data.listInsights.items

        return items.some((item) => item.id === id)
      })
    })

  const updateInsight = (data: ListInsightsQueryT, { id, diff }: { id: string; diff: InputUpdateInsightT }) => {
    const items = data.listInsights.items

    const item = items.find((item) => item.id === id)

    if (!item) {
      return
    }

    Object.assign(item, omit(['relations'], diff))
  }

  const deleteInsight = (data: ListInsightsQueryT, { id }: { id: string }) => {
    const items = data.listInsights.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateInsightMutation, {
      onSuccess: () => {
        findInsightsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteInsightMutation, {
      onError: (error, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInsightsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(produce((data) => (!data ? data : deleteInsight(data, { id }))))
        })

        findInfiniteInsightsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((infiniteData) =>
              !infiniteData ? infiniteData : infiniteData.pages.forEach((data) => deleteInsight(data, { id })),
            ),
          )
        })
      },
      onSuccess: (error, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateInsightMutation, {
      onError: (error, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        if (!diff) {
          return
        }

        findInsightsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(produce((data) => (!data ? data : updateInsight(data, { diff, id }))))
        })

        findInfiniteInsightsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((infiniteData) =>
              !infiniteData ? infiniteData : infiniteData.pages.forEach((data) => updateInsight(data, { diff, id })),
            ),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId: id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId: id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightsQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveInsightMutation, {
      onSuccess: (data, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveInsightMutation, {
      onSuccess: (data, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.InsightT) {
          return
        }

        findInfiniteInsightsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createInsightHandlers(),
    deleteInsightHandlers(),
    updateInsightHandlers(),
    followHandlers(),
    unfollowHandlers(),
    updateRelationsHandlers(),
    archiveInsightHandlers(),
    unarchiveInsightHandlers(),
    updateEntityTypeHandlers(),
  ]
}
