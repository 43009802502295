import type { RequestOptions } from 'graphql-request'
import { useContext } from 'react'

import type { MaybeT } from '@resnet/client-common/common/types/common'
import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import { getGraphQlClient } from '@resnet/client-api/services/graphql-client'

import { AuthContext } from '../auth-api/auth-context'

import { useRequest } from './use-request'

export const useGraphQLQuery = <TData, TVariables>(query: string) => {
  const { requestHeaders } = assertedNonNullable(useContext(AuthContext))

  return useRequest(
    (token: MaybeT<string>, variables: undefined | TVariables, signal?: AbortSignal): Promise<TData> =>
      getGraphQlClient().request<TData, TVariables>({
        document: query,
        requestHeaders: {
          authorization: !token ? '' : `Bearer ${token}`,
          ...requestHeaders,
        },
        signal,
        variables,
      } as RequestOptions<TVariables>),
  )
}
