import type { CustomFieldT } from '../../types/custom-field'

import { UserPickerCustomFieldUserFormField } from './components/user-picker-custom-field-user-form-field'

export const renderUserFormField: CustomFieldT['renderUserFormField'] = ({ field, form, name }) => {
  return (
    <UserPickerCustomFieldUserFormField
      field={field}
      form={form}
      name={name}
    />
  )
}
