import type { ValuesT } from '@resnet/client-common/typescript/types/values'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { GenericThemePropsT } from '../types/generic-theme-props'

import { theme as dark } from './dark'
import { theme as light } from './light'

export const themes = createConstrainer<Record<string, GenericThemePropsT>>()({
  dark,
  light,
})

export type ThemeT = keyof typeof themes

export type ThemePropsT = ValuesT<typeof themes>

export type ThemePropsColorT = keyof ThemePropsT['colors']
