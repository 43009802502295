import { Box } from '@mui/material'
import { memo } from 'react'
import type { FieldPathByValue, FieldValues, PathValue, UseControllerProps, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { Field, FieldErrorText } from '@resnet/client-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-web/shared/gdl/components/field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { UserDropdown } from '@resnet/client-web/shared/users/components/user-dropdown'

type UserSelectValueT = undefined | null | string

type UserSelectFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, UserSelectValueT>,
> = {
  defaultValue?: PathValue<TFieldValues, TPath>
  form: UseFormReturn<TFieldValues>
  headerRight?: React.ReactNode
  name: TPath
  label: string
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
}

export const UserSelectField = memo(
  <TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, UserSelectValueT>>({
    form,
    name,
    label,
    rules,
    defaultValue,
    headerRight,
  }: UserSelectFieldPropsT<TFieldValues, TPath>) => {
    const {
      field,
      fieldState: { error, invalid },
    } = useController({ control: form.control, defaultValue, name, rules })

    const renderHeader = () => {
      return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
          <FieldLabel>{label}</FieldLabel>
          {headerRight}
        </Box>
      )
    }

    const renderInput = () => {
      return (
        <UserDropdown
          hasError={invalid}
          {...field}
          onChange={(value: PathValue<TFieldValues, TPath>) => {
            field.onChange(value)
          }}
        />
      )
    }

    const renderFooter = () => {
      if (!error) {
        return null
      }

      return <FieldErrorText>{error.message as string}</FieldErrorText>
    }

    return (
      <Field sx={{ flexGrow: 1, width: 0 }}>
        {renderHeader()}
        {renderInput()}
        {renderFooter()}
      </Field>
    )
  },
)
