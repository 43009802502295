export const update = <InputT extends Record<string, unknown>, PropNameT extends keyof InputT>(
  input: InputT,
  propName: PropNameT,
  updater: (inputProp: InputT[PropNameT]) => InputT[PropNameT],
): InputT => {
  const inputProp = input[propName]

  const outputProp = updater(inputProp)

  if (inputProp === outputProp) {
    return input
  }

  const output = Object.assign({}, input)

  output[propName] = outputProp

  return output
}
