export const pullAt = <ItemT>(input: ItemT[], index: number): ItemT[] => {
  if (index < 0 || index >= input.length) {
    return input
  }

  const output = input.slice()

  output.splice(index, 1)

  return output
}
