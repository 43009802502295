import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'

import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { ThemePropsColorT } from '@resnet/client-shared/shared/gdl/themes'

import { themeColors } from '../../constants/theme-colors'

const colors = createConstrainer<{ id: ThemePropsColorT }[]>()([
  { id: 'basePrimary' as const },
  { id: 'borderDefault' as const },
  { id: 'borderBold' as const },
  { id: 'borderFaded' as const },
  { id: 'borderFocus' as const },
  { id: 'feedbackWarning' as const },
  { id: 'feedbackCritical' as const },
  { id: 'feedbackSuccess' as const },
  { id: 'feedbackInfo' as const },
  { id: 'overSurfaceGamificationBronze' as const },
  { id: 'overSurfaceGamificationSilver' as const },
  { id: 'overSurfaceGamificationGold' as const },
])

export type OutlinePropsT = {
  color?: (typeof colors)[number]['id']
  sx?: SxProps
}

export const Outline = ({ color = 'borderDefault', sx }: OutlinePropsT): React.ReactElement => {
  return (
    <Box
      sx={[
        {
          borderColor: themeColors[color],
          borderStyle: 'solid',
          borderWidth: 1,
          inset: 0,
          pointerEvents: 'none',
          position: 'absolute',
        },
        sx ?? null,
      ].flat()}
    />
  )
}
