import { Box } from '@mui/material'
import type { Value as ClassValue } from 'classnames'
import { isValidElement, Suspense, useMemo } from 'react'
import { generatePath } from 'react-router-dom'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { mergeTextTokens, tokenize } from '@resnet/client-common/common/utils/string/parse'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import { mapHTMLToReactNodes } from '@resnet/client-common/react/utils/map-html-to-react-nodes'

import { rootScreenRoutes } from '@resnet/client-shared/shared/app-routes/routes/root-screen-routes'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import { mapQueryParamsObjectToSearch } from '@resnet/client-shared/shared/url/utils/map-query-params-object-to-search'

import { Link } from '@resnet/client-web/shared/gdl/components/link'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'

import { Mention } from '../mention'
import { sanitizeHTML } from '../utils/serializer'

const patterns = [
  { pattern: '\\w+:\\/\\/\\S+', type: 'link' },
  { pattern: '##[\\w-]+', type: 'tag' },
  { pattern: '[\\S\\s]', type: 'text' },
]

export const SlateContent = forwardFunctionalComponentRef(
  (
    {
      children,
    }: {
      children: string
    },
    ref: React.Ref<HTMLDivElement>,
  ): React.ReactElement => {
    const mappedNodes = useMemo(() => {
      const mapNode = (reactNode: React.ReactNode, node: Node): React.ReactNode => {
        if (
          node instanceof HTMLElement &&
          node.dataset.mentionType != null &&
          node.dataset.mentionId != null &&
          isValidElement(reactNode)
        ) {
          return (
            <Suspense fallback={reactNode}>
              <Mention
                mentionId={node.dataset.mentionId}
                mentionType={node.dataset.mentionType}
              >
                {reactNode}
              </Mention>
            </Suspense>
          )
        }

        if (typeof reactNode === 'string') {
          return pipeline(
            reactNode,
            (x) => tokenize(x, patterns),
            mergeTextTokens,
            (x) =>
              x.map((token): React.ReactNode => {
                switch (token.type) {
                  case 'link': {
                    return (
                      <Link
                        href={token.value}
                        type="anchor"
                      >
                        {token.value}
                      </Link>
                    )
                  }
                  case 'tag': {
                    return (
                      <Link
                        to={{
                          pathname: generatePath(rootScreenRoutes.search),
                          search: mapQueryParamsObjectToSearch({ query: token.value }),
                        }}
                      >
                        {token.value}
                      </Link>
                    )
                  }
                  case 'text': {
                    return token.value
                  }
                  default: {
                    return null
                  }
                }
              }),
          )
        }

        return reactNode
      }

      return mapHTMLToReactNodes(sanitizeHTML(children), mapNode)
    }, [children])

    return (
      <Box
        ref={ref}
        sx={[mapTypographyPresetToSx(typographyPresets.bodySmall), { color: themeColors.overBackgroundDefault }]}
      >
        {mappedNodes}
      </Box>
    )
  },
)
