export const userDetailsScreenRoutes = {
  activity: '/activity',
  attachments: '/attachments',
  awards: '/awards',
  credentials: '/credentials',
  issues: '/issues',
  rewards: '/rewards',
  scheduling: '/scheduling',
  whatIFollow: '/what-i-follow',
}
