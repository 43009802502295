import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { filterValues } from '@resnet/client-common/common/utils/object/filter-values'

import { useCustomBadgeQuery } from '@resnet/client-api/api'
import { useCreateCustomBadgeMutation } from '@resnet/client-api/api'
import { useInfiniteAwardsQuery } from '@resnet/client-api/api'
import { useUnarchiveCustomBadgeMutation } from '@resnet/client-api/api'
import { useArchiveCustomBadgeMutation } from '@resnet/client-api/api'
import { useUpdateCustomBadgeMutation } from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findExactQuery, findInfiniteQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const awardsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findInfiniteAwardsQueries = () => findInfiniteQueries(queryClient, useInfiniteAwardsQuery)

  const invalidateQueries = () => {
    findInfiniteAwardsQueries()
      .filter(checkNonNullable)
      .forEach((query) => {
        queryClient.invalidateQueries(query.queryKey, { exact: true })
      })
  }

  const updateCustomBadgeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCustomBadgeMutation, {
      onSuccess: () => {
        invalidateQueries()
      },
    })
  }

  const createCustomBadgeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCustomBadgeMutation, {
      onSuccess: () => {
        invalidateQueries()
      },
    })
  }

  return [updateCustomBadgeHandlers(), createCustomBadgeHandlers()]
}

export const badgeQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findCustomBadgeQuery = (id: string) => findExactQuery(queryClient, useCustomBadgeQuery, { id })

  const updateCustomBadgeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCustomBadgeMutation, {
      onError: (error, { id }) => {
        ;[findCustomBadgeQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: updateData }) => {
        ;[findCustomBadgeQuery(id)].filter(checkNonNullable).forEach((query) => {
          setQueryData(queryClient)(query)((data) => {
            const getCustomBadge = data?.getCustomBadge

            if (!getCustomBadge) {
              return data
            }

            return {
              ...data,
              getCustomBadge: Object.assign(
                {},
                getCustomBadge,
                filterValues(checkNonNullable, updateData) as typeof getCustomBadge,
              ),
            }
          })
        })
      },
      onSuccess: (data, { id }) => {
        ;[findCustomBadgeQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveCustomBadgeHandlers = () => {
    const updateQueryData = (id: string) => {
      const query = findCustomBadgeQuery(id)

      if (!query) {
        return
      }

      setQueryData(queryClient)(query)((data) => {
        const getCustomBadge = data?.getCustomBadge

        if (!getCustomBadge) {
          return data
        }

        return {
          ...data,
          getCustomBadge: {
            ...getCustomBadge,
            archived: {
              at: new Date().toISOString(),
              by: '',
            },
          },
        }
      })
    }

    return createMutationHookMutationCacheHandlers(useArchiveCustomBadgeMutation, {
      onError: (_, { id }) => {
        ;[findCustomBadgeQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        updateQueryData(id)
      },
      onSuccess: (_, { id }) => {
        ;[findCustomBadgeQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveCustomBadgeHandlers = () => {
    const updateQueryData = (id: string) => {
      const query = findCustomBadgeQuery(id)

      if (!query) {
        return
      }

      setQueryData(queryClient)(query)((data) => {
        const getCustomBadge = data?.getCustomBadge

        if (!getCustomBadge) {
          return data
        }

        return {
          ...data,
          getCustomBadge: {
            ...getCustomBadge,
            archived: null,
          },
        }
      })
    }

    return createMutationHookMutationCacheHandlers(useUnarchiveCustomBadgeMutation, {
      onError: (_, { id }) => {
        ;[findCustomBadgeQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        updateQueryData(id)
      },
      onSuccess: (_, { id }) => {
        ;[findCustomBadgeQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateCustomBadgeHandlers(), archiveCustomBadgeHandlers(), unarchiveCustomBadgeHandlers()]
}
