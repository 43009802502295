import { Box, ButtonBase } from '@mui/material'

import { useToggle } from '@resnet/client-common/react/hooks/use-toggle'

import AngleDownSolidIcon from '@resnet/client-shared/assets/icons/angle-down-solid.svg'

import { Divider } from '@resnet/client-web/shared/gdl/components/divider'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

import { TextOverflow } from '../../components/text-overflow'

type AccordionPropsT = {
  initialIsOpened?: boolean
  feedbackLevel?: 'feedbackInfo' | 'feedbackCritical' | 'feedbackWarning' | 'feedbackSuccess'
  children: {
    header: {
      title: React.ReactNode
    }
    content: () => React.ReactNode
  }
}

export const Accordion = ({
  initialIsOpened = false,
  feedbackLevel,
  children,
}: AccordionPropsT): React.ReactElement => {
  const borderColor = feedbackLevel ? themeColors[feedbackLevel] : themeColors.borderDefault

  const isOpenedToggle = useToggle(initialIsOpened)

  const renderHeader = (): React.ReactNode => {
    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', position: 'relative' }}>
        <ButtonBase
          sx={{ inset: '-8px', position: 'absolute' }}
          onClick={isOpenedToggle.onToggle}
        />
        <TextOverflow
          sx={{
            color: themeColors.overBackgroundDefault,
            flexGrow: 1,
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            width: 0,
          }}
        >
          {children.header.title}
        </TextOverflow>
        <Box
          component={AngleDownSolidIcon}
          sx={[
            { color: themeColors.overBackgroundDefault, height: '18px', pointerEvents: 'none', width: '18px' },
            !isOpenedToggle.value ? {} : { transform: 'rotate(180deg)' },
          ]}
        />
      </Box>
    )
  }

  const renderContent = (): React.ReactNode => {
    if (!isOpenedToggle.value) {
      return null
    }

    return (
      <>
        <Divider sx={{ my: '8px' }} />
        {children.content()}
      </>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: themeColors.surfaceNeutralDefault,
        border: '1px solid',
        borderColor: borderColor,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: '8px',
      }}
    >
      {renderHeader()}
      {renderContent()}
    </Box>
  )
}
