import { VALIDATION_ERROR_CODE } from '../constants/error-codes'
import { ApiError } from '../errors/api-error'

export type ValidationErrorT = {
  code: typeof VALIDATION_ERROR_CODE
  name: typeof VALIDATION_ERROR_CODE
  statusCode: number
  data: {
    instancePath: string
    message: string
  }[]
}

export const checkIsValidationError = (error: unknown): error is ValidationErrorT => {
  return error instanceof ApiError && error.code === VALIDATION_ERROR_CODE
}
