import { updateEach } from '@resnet/client-common/common/utils/array/update-each'
import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { transform } from '@resnet/client-common/common/utils/object/transform'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { DiscriminatorCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/discriminator/custom-field-value'

import { undraft } from '@resnet/client-web/shared/draftable/utils/undraft'

import type { CustomFieldT } from '../../types/custom-field'
import { removeRedundantMarkedAsDeletedOptions } from '../../utils/remove-redundant-marked-as-deleted-options'

import type { DiscriminatorFormCustomFieldValueT } from './form-custom-field-value'

export const mapCustomFieldToMutationVariable: CustomFieldT<
  DiscriminatorCustomFieldValueT,
  DiscriminatorFormCustomFieldValueT
>['mapCustomFieldToMutationVariable'] = (field) => {
  return transform(field, {
    payload: (payload) =>
      transform(assertedNonNullable(payload), {
        [CustomFieldTypeT.DiscriminatorT]: (discriminator) =>
          transform(assertedNonNullable(discriminator), {
            options: (options) =>
              pipeline(options, (x) => updateEach(x, undraft), removeRedundantMarkedAsDeletedOptions),
          }),
      }),
  })
}
