import { updateAt } from '@resnet/client-common/common/utils/array/update-at'
import { update } from '@resnet/client-common/common/utils/object/update'

export const setStateReducer = <StateT>(state: StateT, action: React.SetStateAction<StateT>): StateT => {
  if (typeof action === 'function') {
    const fn = action as (state: StateT) => StateT

    return fn(state)
  }

  return action
}

export const setPropStateReducer = <StateT extends Record<string, unknown>, PropNameT extends keyof StateT>(
  state: StateT,
  propName: PropNameT,
  action: React.SetStateAction<StateT[PropNameT]>,
): StateT => {
  return update(state, propName, (propValue) => setStateReducer(propValue, action))
}

export const setItemStateReducer = <StateT extends unknown[], IndexT extends number>(
  state: StateT,
  index: IndexT,
  action: React.SetStateAction<StateT[IndexT]>,
): StateT => {
  return updateAt(state, index, (item) => setStateReducer(item, action))
}
