import produce from 'immer'
import { omit } from 'ramda'

import {
  useBlockUserMutation,
  useCancelInviteMutation,
  useProfileQuery,
  useRedeemCouponMutation,
  useUnblockUserMutation,
  useUpdateProfileMutation,
  useUpdateUserMutation,
  useUserQuery,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

import type { RedeemCouponMutationMetaT } from '../gamification/usercoupons.meta'

export const profileQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findProfileQueries = () => findQueries(queryClient, useProfileQuery)

  const findProfileQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useProfileQuery, (data) => data.getMyProfile, { id })

  const updateProfileHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateProfileMutation, {
      onError: () => {
        findProfileQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ data }) => {
        findProfileQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const profile = draft?.getMyProfile

              if (!profile) {
                return
              }

              Object.assign(profile, omit(['avatar'], data))
            }),
          )
        })
      },
      onSuccess: () => {
        findProfileQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const redeemCouponHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRedeemCouponMutation, {
      onSuccess: (data, variables, context, mutation) => {
        const meta = mutation.options.meta as RedeemCouponMutationMetaT

        if (!meta) {
          return
        }

        const { userId } = meta

        findProfileQueriesById({ id: userId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateProfileHandlers(), redeemCouponHandlers()]
}

export const userQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findUserQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useUserQuery, (data) => data.getUser, { id })

  const findUserQueriesByEmail = ({ email }: { email: string }) =>
    findQueries(queryClient, useUserQuery, (query) => {
      const entity = query.state.data?.getUser

      if (!entity) {
        return false
      }

      return entity.email === email
    })

  const blockUserHandlers = () => {
    return createMutationHookMutationCacheHandlers(useBlockUserMutation, {
      onSuccess: (data, { id }) => {
        findUserQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unblockUserHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnblockUserMutation, {
      onSuccess: (data, { id }) => {
        findUserQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateUserHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateUserMutation, {
      onSuccess: (data, { id }) => {
        findUserQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const cancelInviteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCancelInviteMutation, {
      onSuccess: (data, { email }) => {
        findUserQueriesByEmail({ email }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const redeemCouponHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRedeemCouponMutation, {
      onSuccess: (data, variables, context, mutation) => {
        const meta = mutation.options.meta as RedeemCouponMutationMetaT

        if (!meta) {
          return
        }

        const { userId } = meta

        findUserQueriesById({ id: userId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    blockUserHandlers(),
    unblockUserHandlers(),
    updateUserHandlers(),
    cancelInviteHandlers(),
    redeemCouponHandlers(),
  ]
}
