import type { PromiseRefT } from '@resnet/client-common/common/utils/function/memo-promise'
import { memoPromise } from '@resnet/client-common/common/utils/function/memo-promise'
import { ApiError } from '@resnet/client-common/network/errors/api-error'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import { useAuth, useSignOut } from './auth-context'

import { useRefreshTokensMutation } from './'

const refreshTokensPromiseRef: PromiseRefT<void> = { current: null }

export const useRefreshAccessToken = () => {
  const { tenant, setTokens, getLatestTokens } = useAuth()

  const signOut = useSignOut()

  const { mutateAsync: refreshTokens } = useRefreshTokensMutation()

  const refreshAccessToken = useEventCallback(
    memoPromise(refreshTokensPromiseRef, async () => {
      const initialTokens = getLatestTokens()

      const initialTenant = tenant

      if (!initialTokens || !initialTenant) {
        return
      }

      try {
        const { token, refreshToken } = await refreshTokens({
          refreshToken: initialTokens.refreshToken,
          tenant: initialTenant,
        })

        setTokens({ accessToken: token, refreshToken })
      } catch (error) {
        if (error instanceof ApiError) {
          switch (error.code) {
            case 'RefreshError': {
              const currentTokens = getLatestTokens()

              // NOTE
              // It means that user has already signed out.
              if (!currentTokens) {
                return
              }

              // NOTE
              // This covers corner case when user has multiple tabs opened which calls refreshAccessToken in parallel
              // so if one of them succeeds, it will update tokens in local storage and thus we can ignore RefreshError
              // from other tabs.
              // If token is not changed it means that it was actually expired.
              // It doesn't cover race condition when failed request comes before successful one.
              // It can be refactored to worker proxy mechanism to ensure only one request is sent at a time.
              const isRefreshTokenExpired = currentTokens.refreshToken === initialTokens.refreshToken

              if (isRefreshTokenExpired) {
                signOut()
              }

              return
            }
          }
        }

        throw error
      }
    }),
  )

  return refreshAccessToken
}
