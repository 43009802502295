import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

type TokenT = { type: string; value: string }

export const tokenize = (text: string, patterns: { type: string; pattern: string }[]): TokenT[] => {
  const regex = new RegExp(patterns.map(({ pattern }) => `(${pattern})`).join('|'), 'g')

  const tokens = Array.from(text.matchAll(regex))
    .map((match: RegExpMatchArray) => {
      const index = match.slice(1).findIndex(checkNonNullable)

      if (index === -1) {
        return null
      }

      const { type } = patterns[index]

      const value = match[index + 1]

      return {
        type,
        value,
      }
    })
    .filter(checkNonNullable)

  return tokens
}

export const mergeTextTokens = (list: TokenT[]): TokenT[] =>
  list.reduce<TokenT[]>((acc, current) => {
    if (current.type === 'text' && acc[acc.length - 1] && acc[acc.length - 1].type === 'text') {
      acc[acc.length - 1].value += current.value
    } else {
      acc.push(current)
    }

    return acc
  }, [])
