import { useMemo } from 'react'
import { useWatch, type FieldPathByValue, type FieldValues, type UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import { Field, FieldLabel } from '@resnet/client-web/shared/gdl/components/field'
import { FieldPreviewValueContent } from '@resnet/client-web/shared/gdl/components/field-preview'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

import type { FormCustomFieldValuesT } from '../../../../types/form-custom-field-values'
import { mapFormulaToValue } from '../../utils/map-formula-to-value'

export type FormulaCustomFieldUserFormFieldPropsT<TFieldValues extends FieldValues> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  fieldValuesName: FieldPathByValue<TFieldValues, undefined | FormCustomFieldValuesT>
}

export const FormulaCustomFieldUserFormField = <TFieldValues extends FieldValues>({
  field,
  form,
  fieldValuesName,
}: FormulaCustomFieldUserFormFieldPropsT<TFieldValues>) => {
  const { control } = form

  const payload = field.payload

  assert(payload, checkNonNullable)

  const formulaPayload = payload[CustomFieldTypeT.FormulaT]

  assert(formulaPayload, checkNonNullable)

  const { value: formula } = formulaPayload

  const fieldValues = useWatch({ control, name: fieldValuesName })

  const value = useMemo(() => {
    return mapFormulaToValue(formula, fieldValues)
  }, [fieldValues, formula])

  const renderValue = () => {
    if (value === null) {
      return (
        <FieldPreviewValueContent sx={{ color: themeColors.feedbackCritical }}>
          Computation error
        </FieldPreviewValueContent>
      )
    }

    return <FieldPreviewValueContent>{value}</FieldPreviewValueContent>
  }

  return (
    <ObjectDetailsRow>
      <Field>
        <FieldLabel>{field.name}</FieldLabel>
        {renderValue()}
      </Field>
    </ObjectDetailsRow>
  )
}
