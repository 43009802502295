import { mapToKeys } from './map-to-keys'
import { update } from './update'

export const transform = <InputT extends Record<string, unknown>>(
  input: InputT,
  spec: Partial<{ [K in keyof InputT]: (input: InputT[K]) => InputT[K] }>,
): InputT => {
  let output = input

  for (const key of mapToKeys(spec)) {
    const updater = spec[key]

    if (!updater) {
      continue
    }

    output = update(output, key, updater)
  }

  return output
}
