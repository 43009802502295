import { Box } from '@mui/material'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import CircleSolidIcon from '@resnet/client-shared/assets/icons/circle-solid.svg'
import { mapPickerColorPresetToColor } from '@resnet/client-shared/shared/common/utils/map-picker-color-preset-to-color'
import type { DropdownCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/dropdown/custom-field-value'

import {
  FieldPreviewLabel,
  FieldPreviewLayout,
  FieldPreviewValueContent,
} from '@resnet/client-web/shared/gdl/components/field-preview'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type DropdownFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | DropdownCustomFieldValueT
}

export const DropdownFormUserFormPreview = ({ field, value }: DropdownFormUserFormPreviewPropsT) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const dropdownPayload = payload[CustomFieldTypeT.DropdownT]

  assert(dropdownPayload, checkNonNullable)

  const options = dropdownPayload.options

  const option = options.find((option) => option.key === value)

  const renderValue = () => {
    if (!option) {
      return <FieldPreviewValueContent>-</FieldPreviewValueContent>
    }

    const renderText = () => {
      return (
        <FieldPreviewValueContent isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</FieldPreviewValueContent>
      )
    }

    const renderColor = () => {
      const color = option?.color

      return (
        <CircleSolidIcon
          color={mapPickerColorPresetToColor(color)}
          height={20}
          width={20}
        />
      )
    }

    if (!dropdownPayload.hasColor) {
      return renderText()
    }

    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
        {renderColor()}
        {renderText()}
      </Box>
    )
  }

  return (
    <ObjectDetailsRow>
      <FieldPreviewLayout>
        <FieldPreviewLabel>{field.name}</FieldPreviewLabel>
        {renderValue()}
      </FieldPreviewLayout>
    </ObjectDetailsRow>
  )
}
