export const uniqBy = <ItemT, KeyT>(input: ItemT[], mapItemToKey: (item: ItemT) => KeyT): ItemT[] => {
  const map = new Map<KeyT, ItemT>()

  for (const item of input) {
    const key = mapItemToKey(item)

    if (map.has(key)) {
      continue
    }

    map.set(key, item)
  }

  if (map.size === input.length) {
    return input
  }

  const output = Array.from(map.values())

  return output
}
