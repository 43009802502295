import { checkIsEntity } from '../../entities/utils/check-is-entity'
import type { EntityWithFeedPathnameT } from '../../entities/utils/map-entity-to-feed-pathname'
import { mapEntityToFeedPathname } from '../../entities/utils/map-entity-to-feed-pathname'
import { checkIsGroup } from '../../groups/utils/check-is-group'
import type { GroupWithFeedPathnameT } from '../../groups/utils/map-group-to-feed-pathname'
import { mapGroupToFeedPathname } from '../../groups/utils/map-group-to-feed-pathname'

export type OriginWithFeedPathnameT = EntityWithFeedPathnameT | GroupWithFeedPathnameT

export const mapOriginToFeedPathname = <ValueT extends OriginWithFeedPathnameT>(value: ValueT): string => {
  if (checkIsGroup(value)) {
    return mapGroupToFeedPathname(value)
  }

  if (checkIsEntity(value)) {
    return mapEntityToFeedPathname(value)
  }

  throw new Error(`Unknown origin type: ${value.__typename}`)
}
