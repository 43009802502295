import { useCallback } from 'react'
import type { CustomTypes } from 'slate'

import type { CustomFormatsT } from '../types'
import { toggleMark } from '../utils/common'

const HOT_KEYS: { [key: string]: CustomFormatsT } = {
  b: 'bold',
  i: 'italic',
  u: 'underline',
}

export const useRichTextHotKeys = (editor: CustomTypes['Editor']): React.KeyboardEventHandler<HTMLDivElement> => {
  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        return
      }

      const mark = HOT_KEYS[event.key]

      if (mark) {
        event.preventDefault()

        toggleMark(editor, mark)
      }
    },
    [editor],
  )

  return onKeyDown
}
