import { useMemo, useState } from 'react'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { fold } from '@resnet/client-common/common/utils/object/fold'
import { useDebounced } from '@resnet/client-common/react/hooks/use-debounced'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { UserFragmentT } from '@resnet/client-api/api'
import { useInfiniteSearchUsersQuery } from '@resnet/client-api/api'
import { defaultLimit } from '@resnet/client-api/constants/variables'

import { mapUserToMedia } from '@resnet/client-shared/shared/users/utils/map-user-to-media-boston'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { useSearchHandlers } from '@resnet/client-web/shared/gdl/components/search'

export const getUsersSelectOptionLabel = (option: UserFragmentT) => mapUserToTitle(option)

export const getUsersSelectOptionMedia = (option: UserFragmentT) => mapUserToMedia(option)

export const useUsersSelectDropdown = ({
  excludeIds,
}: {
  excludeIds?: string[]
} = {}) => {
  const [searchActual, setSearch] = useState('')

  const search = useDebounced(searchActual)

  const usersQuery = useInfiniteSearchUsersQuery(
    {
      limit: defaultLimit,
      search,
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        fold({
          offset: lastPage.searchUsers.items.length === 0 ? undefined : allPages.length * defaultLimit,
        }),
      keepPreviousData: true,
    },
  )

  const usersQueryFetchNextPage = usersQuery.fetchNextPage

  const users = useMemo(
    () =>
      pipeline(
        usersQuery.data,
        (data) => (!data ? [] : data.pages),
        (pages) => pages.flatMap((page) => page.searchUsers.items),
      ),
    [usersQuery.data],
  )

  const options = useMemo(
    () => pipeline(users, (users) => (!excludeIds ? users : users.filter((item) => !excludeIds.includes(item.id)))),
    [excludeIds, users],
  )

  const { onChange: onSearchChange, onClear: onSearchClear } = useSearchHandlers({ setSearch })

  const bottomBoundary = useMemo(() => {
    if (usersQuery.isFetchingNextPage || !usersQuery.hasNextPage) {
      return
    }

    return { onObserve: usersQueryFetchNextPage }
  }, [usersQuery.hasNextPage, usersQuery.isFetchingNextPage, usersQueryFetchNextPage])

  const dropdownProps = {
    bottomBoundary,
    getOptionLabel: getUsersSelectOptionLabel,
    getOptionMedia: getUsersSelectOptionMedia,
    isLoading: usersQuery.isFetching,
    optionHeight: 48,
    options,
    skeletonProps: { media: { type: 'avatar' as const } },
  }

  const searchProps = {
    onChange: onSearchChange,
    onClear: onSearchClear,
    value: searchActual,
  }

  return {
    dropdownProps,
    searchProps,
  }
}

export const UsersSelectDropdownContainer = createHookContainer(useUsersSelectDropdown)
