import { pull } from './pull'
import { push } from './push'

export const toggle = <ItemT>(input: ItemT[], toggleItem: ItemT): ItemT[] => {
  if (!input.includes(toggleItem)) {
    return push(input, toggleItem)
  }

  return pull(input, toggleItem)
}
