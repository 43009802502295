import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { EntityPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/entity-picker/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type {
  EntityPickerFormCustomFieldValueT,
  EntityPickerMultipleFormCustomFieldValueT,
  EntityPickerSingleFormCustomFieldValueT,
} from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  EntityPickerCustomFieldValueT,
  EntityPickerFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const entityPickerPayload = payload[CustomFieldTypeT.EntityPickerT]

  assert(entityPickerPayload, checkNonNullable)

  const { multiple } = entityPickerPayload

  if (multiple) {
    const defaultValue: EntityPickerMultipleFormCustomFieldValueT = []

    return defaultValue
  }

  const defaultValue: EntityPickerSingleFormCustomFieldValueT = null

  return defaultValue
}
