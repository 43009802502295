import { Box } from '@mui/material'
import { useMemo } from 'react'

import { ChildrenRepeater } from '@resnet/client-common/react/components/children-transformer'

import type { ResourceFragmentT } from '@resnet/client-api/api'

import { checkIsMediaType } from '@resnet/client-shared/shared/resources/utils/check-is-media-type'
import { mapMimetypeToType } from '@resnet/client-shared/shared/resources/utils/map-mimetype-to-type'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { toColumnWidth } from '@resnet/client-web/shared/gdl/utils/to-column-width'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { AttachmentPreviewModalContainer } from '@resnet/client-web/shared/resources/components/attachment-preview-modal'
import {
  MediaResourceThumbnail,
  MediaResourceThumbnailSkeleton,
} from '@resnet/client-web/shared/resources/components/media-resource-thumbnail'
import {
  ResourceThumbnail,
  ResourceThumbnailSkeleton,
} from '@resnet/client-web/shared/resources/components/resource-thumbnail'
import { useResourcesGallery } from '@resnet/client-web/shared/resources/hooks/use-resources-gallery'
import { ResourcesGalleryItemContainer } from '@resnet/client-web/shared/resources/hooks/use-resources-gallery-item'

export type ResourcesGalleryPropsT = {
  resources: ResourceFragmentT[]
}

export const ResourcesGallery = ({ resources }: ResourcesGalleryPropsT) => {
  const gallerySize = resources.length

  const { openPrev, openNext, register } = useResourcesGallery()

  const mediaResources = useMemo(
    () => resources.filter((resource) => checkIsMediaType(mapMimetypeToType(resource.mimetype))),
    [resources],
  )

  const regularResources = useMemo(
    () => resources.filter((resource) => !checkIsMediaType(mapMimetypeToType(resource.mimetype))),
    [resources],
  )

  const renderMediaResources = () => {
    if (mediaResources.length === 0) {
      return null
    }

    return (
      <Box
        sx={{
          backgroundColor: themeColors.surfaceVariantDefault,
          borderRadius: toPx(8),
          display: 'flex',
          flexWrap: 'wrap',
          gap: toPx(4),
          maxWidth: toPx(624),
          padding: toPx(8),
        }}
      >
        {mediaResources.map((resource, index) => {
          const galleryIndex = index

          return (
            <Box
              key={resource.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: toColumnWidth(3, 4),
              }}
            >
              <AttachmentPreviewModalContainer>
                {({ onOpen: onOpenProp, onClose }) => {
                  const onOpen = () => {
                    onOpenProp({
                      attachment: resource,
                      hasNext: galleryIndex < gallerySize - 1,
                      hasPrev: galleryIndex > 0,
                      onOpenNext: () => {
                        onClose()
                        openNext(galleryIndex)
                      },
                      onOpenPrev: () => {
                        onClose()
                        openPrev(galleryIndex)
                      },
                    })
                  }

                  return (
                    <ResourcesGalleryItemContainer
                      index={galleryIndex}
                      register={register}
                      onOpen={onOpen}
                    >
                      {() => (
                        <MediaResourceThumbnail
                          resource={resource}
                          onClick={onOpen}
                        />
                      )}
                    </ResourcesGalleryItemContainer>
                  )
                }}
              </AttachmentPreviewModalContainer>
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderRegularResources = () => {
    if (regularResources.length === 0) {
      return null
    }

    return (
      <Box
        sx={{
          backgroundColor: themeColors.surfaceVariantDefault,
          borderRadius: toPx(8),
          display: 'flex',
          flexDirection: 'column',
          gap: toPx(8),
          padding: toPx(8),
        }}
      >
        {regularResources.map((resource, index) => {
          const galleryIndex = mediaResources.length + index

          return (
            <Box
              key={resource.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <AttachmentPreviewModalContainer>
                {({ onOpen: onOpenProp, onClose }) => {
                  const onOpen = () => {
                    onOpenProp({
                      attachment: resource,
                      hasNext: galleryIndex < gallerySize - 1,
                      hasPrev: galleryIndex > 0,
                      onOpenNext: () => {
                        onClose()
                        openNext(galleryIndex)
                      },
                      onOpenPrev: () => {
                        onClose()
                        openPrev(galleryIndex)
                      },
                    })
                  }

                  return (
                    <ResourcesGalleryItemContainer
                      index={galleryIndex}
                      register={register}
                      onOpen={onOpen}
                    >
                      {() => (
                        <ResourceThumbnail
                          resource={resource}
                          onClick={onOpen}
                        />
                      )}
                    </ResourcesGalleryItemContainer>
                  )
                }}
              </AttachmentPreviewModalContainer>
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
      {renderMediaResources()}
      {renderRegularResources()}
    </Box>
  )
}

export const ResourcesGallerySkeleton = () => {
  const renderMediaResources = () => {
    return (
      <Box
        sx={{
          backgroundColor: themeColors.surfaceVariantDefault,
          borderRadius: toPx(8),
          display: 'flex',
          flexWrap: 'wrap',
          gap: toPx(4),
          maxWidth: toPx(624),
          padding: toPx(8),
        }}
      >
        <ChildrenRepeater count={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: toColumnWidth(3, 4),
            }}
          >
            <MediaResourceThumbnailSkeleton />
          </Box>
        </ChildrenRepeater>
      </Box>
    )
  }

  const renderRegularResources = () => {
    return (
      <Box
        sx={{
          backgroundColor: themeColors.surfaceVariantDefault,
          borderRadius: toPx(8),
          display: 'flex',
          flexDirection: 'column',
          gap: toPx(8),
          padding: toPx(8),
        }}
      >
        <ChildrenRepeater count={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ResourceThumbnailSkeleton />
          </Box>
        </ChildrenRepeater>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
      {renderMediaResources()}
      {renderRegularResources()}
    </Box>
  )
}
