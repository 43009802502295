import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { IssueStatusT } from '@resnet/client-api/api'

import { gasColors } from '../../gdl/constants/gas-colors'

export const issueStatusOptions = [
  {
    buttonColor: 'primary' as const,
    fill: gasColors.blueGray400,
    id: IssueStatusT.BacklogT as const,
    name: 'Backlog',
  },
  {
    buttonColor: 'primary' as const,
    fill: gasColors.blueA700,
    id: IssueStatusT.TodoT as const,
    name: 'To Do',
  },
  {
    buttonColor: 'primary' as const,
    fill: gasColors.yellowA700,
    id: IssueStatusT.InProgressT as const,
    name: 'In Progress',
  },
  {
    buttonColor: 'primary' as const,
    fill: gasColors.purpleA700,
    id: IssueStatusT.InReviewT as const,
    name: 'In Review',
  },
  {
    buttonColor: 'primary' as const,
    fill: gasColors.greenA700,
    id: IssueStatusT.DoneT as const,
    name: 'Done',
  },
]

export const issueStatusOptionById = propToKey('id', issueStatusOptions)
