type TargetT<EventT> = {
  addEventListener: (
    type: string,
    listener: (event: EventT) => void,
    options?: boolean | AddEventListenerOptions,
  ) => void
  removeEventListener: (
    type: string,
    listener: (event: EventT) => void,
    options?: boolean | AddEventListenerOptions,
  ) => void
}

export const subscribeEvent = <EventT>(
  target: TargetT<EventT>,
  type: string,
  listener: (event: EventT) => void,
  options?: boolean | AddEventListenerOptions,
): (() => void) => {
  target.addEventListener(type, listener, options)

  return () => {
    target.removeEventListener(type, listener, options)
  }
}
