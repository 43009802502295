import { flattenRoutes } from '../utils/flatten-routes'

export const awardDetailsScreenNestedRoutes = {
  analytics: '/analytics',
  history: '/history',
}

export const awardDetailsScreenRoutes = flattenRoutes({
  achievement: ['/achievement/:awardId', awardDetailsScreenNestedRoutes],
  badge: ['/badge/:awardId', awardDetailsScreenNestedRoutes],
  customBadge: ['/custom-badge/:awardId', awardDetailsScreenNestedRoutes],
  specialMedal: ['/special-medal/:awardId', awardDetailsScreenNestedRoutes],
})
