import type { SectionHeaderCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/section-header/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { SectionHeaderFormCustomFieldValueT } from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  SectionHeaderCustomFieldValueT,
  SectionHeaderFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field: _field }) => {
  return undefined
}
