import { uniq } from '@resnet/client-common/common/utils/array/uniq'
import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { fromEntries } from '@resnet/client-common/common/utils/object/from-entries'
import { mapToKeys } from '@resnet/client-common/common/utils/object/map-to-keys'
import { mapToValues } from '@resnet/client-common/common/utils/object/map-to-values'
import { toKebabCase } from '@resnet/client-common/common/utils/string/to-kebab-case'

import type { ThemePropsT } from '@resnet/client-shared/shared/gdl/themes'
import { themes } from '@resnet/client-shared/shared/gdl/themes'

const mapThemePropsToColors = (themeProps: ThemePropsT) => pipeline(themeProps, (x) => x.colors, mapToKeys)

export const themeColorNames = pipeline(themes, mapToValues, (x) => x.flatMap(mapThemePropsToColors), uniq)

export const mapThemePropsToThemeColorsCustomProperties = (themeProps: ThemePropsT) =>
  pipeline(
    themeColorNames,
    (x): [string, string][] =>
      x.map((colorName) => [`--theme-colors-${toKebabCase(colorName)}`, themeProps.colors[colorName]]),
    fromEntries,
  )

export const themeColors: { [K in (typeof themeColorNames)[number]]: string } = pipeline(
  themeColorNames,
  (x): [(typeof themeColorNames)[number], string][] =>
    x.map((colorName) => [colorName, `var(--theme-colors-${toKebabCase(colorName)})`]),
  fromEntries,
)
