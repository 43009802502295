import type { QueryClient } from '@tanstack/react-query'
import produce from 'immer'

import { omit } from '@resnet/client-common/common/utils/object/omit'

import {
  useArchiveCampaignMutation,
  useGetCampaignQuery,
  useCreateCampaignMutation,
  useCreateIssuesMutation,
  useDeleteCampaignMutation,
  useDeleteIssueMutation,
  useInfiniteListCampaignsQuery,
  useUnarchiveCampaignMutation,
  useUpdateCampaignMutation,
  useUpdateIssueMutation,
  useUpdateRelationsMutation,
  EntityTypeIdsT,
  useUpdateEntityTypeMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

import type { UpdateIssueMutationMetaT } from '../issue/types/meta'

export const campaignQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findCampaignQueries = () => {
    return findQueries(queryClient, useGetCampaignQuery)
  }

  const findCampaignQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetCampaignQuery, (data) => data.getCampaign, { id })

  const updateCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCampaignMutation, {
      onError: (error, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findCampaignQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const campaign = draft?.getCampaign

              if (!campaign) {
                return
              }

              Object.assign(campaign, omit(['relations'], data))
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCampaignMutation, {
      onSuccess: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createIssuesHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateIssuesMutation, {
      onSuccess: (data, { issue }) => {
        const campaignId = issue?.campaignId

        if (!campaignId) {
          return
        }

        findCampaignQueriesById({ id: campaignId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateIssueMutation, {
      onSuccess: (data, variables, context, mutation) => {
        const meta = mutation.meta as UpdateIssueMutationMetaT

        const campaignId = data.updateIssue?.campaignId ?? meta?.issue?.campaignId

        if (!campaignId) {
          return
        }

        findCampaignQueriesById({ id: campaignId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteIssueMutation, {
      onSuccess: (data) => {
        const campaignId = data.deleteIssue?.campaignId

        if (!campaignId) {
          return
        }

        findCampaignQueriesById({ id: campaignId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveCampaignMutation, {
      onError: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findCampaignQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const campaign = draft?.getCampaign

              if (!campaign) {
                return
              }

              Object.assign(campaign, { archived: { at: new Date().toISOString() } })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveCampaignMutation, {
      onError: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findCampaignQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const campaign = draft?.getCampaign

              if (!campaign) {
                return
              }

              delete campaign.archived
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findCampaignQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.CampaignT) {
          return
        }

        findCampaignQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    updateCampaignHandlers(),
    deleteCampaignHandlers(),
    deleteIssueHandlers(),
    createIssuesHandlers(),
    updateIssueHandlers(),
    deleteIssueHandlers(),
    archiveCampaignHandlers(),
    unarchiveCampaignHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const infiniteCampaignsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteCampaignsQueries = () => findInfiniteQueries(queryClient, useInfiniteListCampaignsQuery)

  const createCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCampaignMutation, {
      onSuccess: () => {
        findInfiniteCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCampaignMutation, {
      onSuccess: () => {
        findInfiniteCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveCampaignMutation, {
      onSuccess: () => {
        findInfiniteCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveCampaignMutation, {
      onSuccess: () => {
        findInfiniteCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: () => {
        findInfiniteCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.CampaignT) {
          return
        }

        findInfiniteCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createCampaignHandlers(),
    deleteCampaignHandlers(),
    archiveCampaignHandlers(),
    unarchiveCampaignHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}
