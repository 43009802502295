import { transform } from '@resnet/client-common/common/utils/object/transform'
import { createHookElementsContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { useInfiniteResourceObjectsQuery } from '@resnet/client-api/api'

import { createUseSelectDropdown } from '../../common/factories/create-use-select-dropdown'

export const useResourceObjectSelectDropdown = createUseSelectDropdown({
  mapOptionsQueryDataToOptions: (data) => data.listResourceObjects.items,
  useOptionsQuery: useInfiniteResourceObjectsQuery,
  withSearch: (input, { search }) =>
    transform(input ?? {}, { filter: (filter) => transform(filter ?? {}, { name: () => ({ includes: search }) }) }),
})

export const ResourceObjectSelectDropdownContainer = createHookElementsContainer(useResourceObjectSelectDropdown)
