import type { SidesT } from '../types/sides'

import { mapNumberToSides } from './map-number-to-sides'
import { toPx } from './to-px'

export const mapBorderWidthToValue = ({ borderWidth: borderWidthActual }: { borderWidth: number | SidesT }) => {
  const borderWidth =
    typeof borderWidthActual === 'number' ? mapNumberToSides({ number: borderWidthActual }) : borderWidthActual

  const { bottom: borderBottom = 0, left: borderLeft = 0, right: borderRight = 0, top: borderTop = 0 } = borderWidth

  if (borderTop === borderBottom && borderTop === borderLeft && borderTop === borderRight) {
    return toPx(borderTop)
  }

  if (borderTop === borderBottom && borderLeft === borderRight) {
    return `${toPx(borderTop)} ${toPx(borderLeft)}`
  }

  if (borderLeft === borderRight) {
    return `${toPx(borderTop)} ${toPx(borderLeft)} ${toPx(borderBottom)}`
  }

  return `${toPx(borderTop)} ${toPx(borderRight)} ${toPx(borderBottom)} ${toPx(borderLeft)}`
}
