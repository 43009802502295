import type { FieldPathByValue, FieldValues, PathValue, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { DateCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/date/custom-field-value'
import { validateRequired } from '@resnet/client-shared/shared/forms/validators/any-required'
import { composeValidators } from '@resnet/client-shared/shared/forms/validators/compose-validators'

import { DateField } from '@resnet/client-web/shared/form-dalaran/components/common/date-field'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type DateCustomFieldUserFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | DateCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const DateCustomFieldUserFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | DateCustomFieldValueT>,
>({
  field,
  form,
  name,
}: DateCustomFieldUserFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const datePayload = payload[CustomFieldTypeT.DateT]

  assert(datePayload, checkNonNullable)

  const { defaultValue, required, addTimeEnabled } = datePayload

  return (
    <ObjectDetailsRow>
      <DateField
        defaultValue={defaultValue as PathValue<TFieldValues, TPath>}
        form={form}
        label={field.name}
        name={name}
        rules={{ validate: composeValidators(...(!required ? [] : [validateRequired])) }}
        showTime={addTimeEnabled}
      />
    </ObjectDetailsRow>
  )
}
