import { Box } from '@mui/material'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { ErrorBoundaryFallbackEffectContainer } from '@resnet/client-common/react/hooks/use-error-boundary-fallback-effect'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import type { AbstractOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'

import type { OptionContainerT } from '../../types/option-container'
import {
  DropdownInputMedia,
  DropdownInputPlaceholder,
  DropdownInputText,
  DropdownInputTextSkeleton,
} from '../dropdown-input'

export type DropdownTextPropsT<
  OptionT extends AbstractOptionT,
  ValueT extends undefined | null | OptionT['id'] = OptionT['id'],
> = {
  value: ValueT
  getOptionLabel?: (option: OptionT) => string
  getOptionMedia?: (option: OptionT) => MediaT
  OptionContainer?: OptionContainerT<OptionT>
  onCurrentValueQueryError?: () => void
}

export const DropdownText = <
  OptionT extends AbstractOptionT,
  ValueT extends undefined | null | OptionT['id'] = OptionT['id'],
>({
  value,
  getOptionLabel,
  getOptionMedia,
  OptionContainer,
  onCurrentValueQueryError,
}: DropdownTextPropsT<OptionT, ValueT>) => {
  if (!value) {
    return <DropdownInputPlaceholder>Not Selected</DropdownInputPlaceholder>
  }

  if (!OptionContainer || !getOptionLabel) {
    return <DropdownInputText>{value}</DropdownInputText>
  }

  return (
    <ErrorBoundary
      fallback={({ onRecover, recoveredCount }) => (
        <ErrorBoundaryFallbackEffectContainer
          onError={onCurrentValueQueryError}
          onRecover={recoveredCount > 0 ? undefined : onRecover}
        >
          {() => <DropdownInputPlaceholder>Not Found</DropdownInputPlaceholder>}
        </ErrorBoundaryFallbackEffectContainer>
      )}
    >
      <OptionContainer id={value}>
        {({ optionQuery }) => {
          if (!optionQuery.isSuccess) {
            return <DropdownInputTextSkeleton />
          }

          const option = optionQuery.data

          const media = getOptionMedia?.(option)

          if (media) {
            return (
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                <DropdownInputMedia
                  media={media}
                  sx={{ display: 'flex' }}
                />
                <DropdownInputText>{getOptionLabel(option)}</DropdownInputText>
              </Box>
            )
          }

          return <DropdownInputText>{getOptionLabel(option)}</DropdownInputText>
        }}
      </OptionContainer>
    </ErrorBoundary>
  )
}
