import type { UseQueryOptions } from '@tanstack/react-query'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { GetEntityTypeQueryT, EntityTypeFragmentT } from '@resnet/client-api/api'
import { useGetEntityTypeQuery } from '@resnet/client-api/api'

export type UseEntityTypeOptionPropsT = {
  id: string
  options?: UseQueryOptions<GetEntityTypeQueryT, unknown, EntityTypeFragmentT>
}

export const useEntityTypeOption = ({ id, options }: UseEntityTypeOptionPropsT) => {
  const optionQuery = useGetEntityTypeQuery({ id }, { ...options, select: (data) => data.getEntityType })

  return { optionQuery }
}

export const EntityTypeOptionContainer = createHookContainer(useEntityTypeOption)
