import { Editor, Element as SlateElement, Transforms, Text } from 'slate'
import type { CustomTypes, Descendant } from 'slate'

import type { CustomFormatsT, CustomTypesT } from '../types'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

export const toggleMark = (editor: CustomTypes['Editor'], format: CustomFormatsT): void => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isBlockActive = (editor: CustomTypes['Editor'], type: CustomTypesT): boolean => {
  // @ts-ignore issue with generators
  const [match] = Editor.nodes(editor, {
    match: (node) => !Editor.isEditor(node) && SlateElement.isElement(node) && node.type === type,
  })

  return !!match
}

export const isMarkActive = (editor: CustomTypes['Editor'], format: CustomFormatsT): boolean => {
  const marks = Editor.marks(editor)

  return marks ? marks[format] === true : false
}

export const toggleBlock = (editor: CustomTypes['Editor'], type: CustomTypesT): void => {
  const isActive = isBlockActive(editor, type)
  const isList = LIST_TYPES.includes(type)

  Transforms.unwrapNodes(editor, {
    match: (node) => LIST_TYPES.includes(!Editor.isEditor(node) && SlateElement.isElement(node) ? node.type : ''),
    split: true,
  })

  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : type,
  }

  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { children: [], type }

    Transforms.wrapNodes(editor, block as CustomTypes['Element'])
  }
}

export const insertMention = (
  editor: CustomTypes['Editor'],
  {
    name,
    mentionId,
    ...rest
  }: {
    name: string
    mentionId: string
  } & (
    | { mentionType: 'entity'; entityTypeId: 'well' | 'battery' | 'site' | 'route' }
    | { mentionType: 'group' }
    | { mentionType: 'user' }
  ),
): void => {
  const mention: CustomTypes['MentionElement'] = {
    character: name,
    children: [{ text: '' }],
    mentionId,
    type: 'mention',
    ...rest,
  }

  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
  Transforms.insertNodes(editor, { text: ' ' })
  Transforms.move(editor)
}

export const checkIsEmptyText = (value: Descendant[]): boolean =>
  value.every((node) => {
    if (Text.isText(node)) {
      return node.text.trim() === ''
    }

    switch (node.type) {
      case 'mention': {
        return false
      }
      case 'numbered-list': {
        return false
      }
      case 'bulleted-list': {
        return false
      }
      default: {
        return checkIsEmptyText(node.children)
      }
    }
  })
