import { Box } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import { useIssuesQuery } from "@resnet/client-api/api";
import { EmptyPanel } from "@resnet/client-web/shared/common/components/empty-panel";
import {
  getIssueCardSize,
  IssueCard,
  IssueCardSkeleton,
} from "@resnet/client-web/screens/field-dispatch-console-screen/modules/cards/components/issue-card";
import {
  useLoadableList,
  checkIsSkeletonItem,
} from "@resnet/client-web/shared/async/hooks/use-loadable-list";
import { VirtualizedList } from "@resnet/client-web/shared/gdl/components/virtualized-list";
import { useHistory } from "react-router-dom";
import { themeColors } from "@resnet/client-web/shared/gdl/constants/theme-colors";
import { useLocation } from "react-router-dom";
import { useTeamsData } from "../context/teams-context";
import {
  Search,
  useSearchHandlers,
} from "@resnet/client-web/shared/gdl/components/search";

const emptyArray: never[] = [];

const listConfig = {
  gap: 8,
  maxVisibleOptions: 3,
  optionHeight: getIssueCardSize(),
} as const;

const Issues = (): React.ReactElement => {
  const { updateMessage, updateSubject, updateTeamsUrl, updateAttachments } =
    useTeamsData();
  const location = useLocation();

  const [searchActual, setSearch] = React.useState("");

  const { onChange: onSearchChange, onClear: onSearchClear } =
    useSearchHandlers({ setSearch });

  const decodeURLParams = (url: string): Record<string, string> => {
    const params = new URLSearchParams(url);
    const decodedParams: Record<string, string> = {};
    for (const [key, value] of params) {
      decodedParams[key] = decodeURIComponent(value);
    }
    return decodedParams;
  };

  const handleURL = (url: string | undefined | null): void => {
    if (url === "" || url === undefined || url === null) {
      return;
    } else {
      const decodedParams = decodeURLParams(url);
      updateMessage(decodedParams.message);
      updateSubject(decodedParams.subject || "");
      updateTeamsUrl(decodedParams.teamsUrl || "");
      const attachmentsArray = JSON.parse(decodedParams.attachments || "[]");
      updateAttachments(attachmentsArray);
    }
  };

  // Call handleURL whenever location changes
  React.useEffect(() => {
    handleURL(location.search);
  }, [location.search]);

  const queryVariables = {
    filter: { name: { includes: searchActual } },
  };

  const issuesQuery = useIssuesQuery(queryVariables, {
    select: (data) => data.listIssues?.items,
  });

  const dataActual = issuesQuery.data ?? emptyArray;

  const isFetching = issuesQuery.isFetching;

  const { data } = useLoadableList({ data: dataActual, isFetching });

  const navigate = useHistory();

  const renderSearch = (): React.ReactNode => {
    return (
      <Search
        placeholder="Search Issues"
        sx={{ flexGrow: 1 }}
        value={searchActual}
        onChange={onSearchChange}
        onClear={onSearchClear}
      />
    );
  };

  const renderIssues = () => {
    if (!data.length && issuesQuery.isSuccess) {
      return (
        <EmptyPanel>
          {{
            title: <>No issues found</>,
          }}
        </EmptyPanel>
      );
    }

    return (
      <VirtualizedList
        data={data}
        gap={listConfig.gap}
        getItemSize={() => listConfig.optionHeight}
      >
        {({ item, style }) => {
          return (
            <Box
              key={item.id}
              style={style}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              {checkIsSkeletonItem(item) ? (
                <IssueCardSkeleton />
              ) : (
                <IssueCard
                  onClick={() => navigate.push("/issue/" + item.id)}
                  issue={item}
                />
              )}
            </Box>
          );
        }}
      </VirtualizedList>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          zIndex: 9,
          width: "100%",
          background: themeColors.background,
        }}
      >
        <Header />

        <Box sx={{ padding: "8px 16px"}}>{renderSearch()}</Box>
      </Box>
      <Box sx={{ padding: "8px 16px", paddingTop: 15 }}>
        {renderIssues()}
      </Box>
    </Box>
  );
};

export default Issues;
