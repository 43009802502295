import type { CustomTypes, Descendant } from 'slate'

export const EMPTY_STATE: Descendant[] = [
  {
    children: [
      {
        text: '',
      },
    ],
    type: 'paragraph',
  },
]

export const withMentions = (editor: CustomTypes['Editor']): CustomTypes['Editor'] => {
  const { isInline, isVoid } = editor

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  return editor
}
