import { Box, Popper, Tooltip } from '@mui/material'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'

import OilWellSolidIcon from '@resnet/client-shared/assets/icons/oil-well-solid.svg'
import RouteSolidIcon from '@resnet/client-shared/assets/icons/route-solid.svg'
import UserGroupSolidIcon from '@resnet/client-shared/assets/icons/user-group-solid.svg'
import { mapBatteryToTitle } from '@resnet/client-shared/shared/batteries/utils/map-battery-to-title'
import { mapGroupToTitle } from '@resnet/client-shared/shared/groups/utils/map-group-to-title'
import { mapRouteToTitle } from '@resnet/client-shared/shared/routes/utils/map-route-to-title'
import { mapSiteToTitle } from '@resnet/client-shared/shared/sites/utils/map-site-to-title'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'
import { mapWellToTitle } from '@resnet/client-shared/shared/wells/utils/map-well-to-title'

import { IconAvatar } from '@resnet/client-web/shared/gdl/components/avatar'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { UserAvatar } from '@resnet/client-web/shared/users/components/user-avatar'

import type { GroupT } from '../types'

import * as styles from './styles.module.scss'

type PropsT = {
  anchorEl: HTMLElement | null
  groups: GroupT[]
  currentIndex: number
  currentGroupIndex: number
  show?: boolean
  onItemClick: () => void
  setIndex: (value: number) => void
  setGroupIndex: (value: number) => void
}

const ITEM_HEIGHT = 60
const GROUP_HEADER_HEIGHT = 28

export const MentionsMenu = ({
  anchorEl,
  groups,
  currentIndex,
  currentGroupIndex,
  show = false,
  onItemClick,
  setIndex,
  setGroupIndex,
}: PropsT): JSX.Element | null => {
  const activeItemRef = useRef<null | HTMLDivElement>(null)
  const menuRef = useRef<null | HTMLDivElement>(null)
  const wasJustScrolledRef = useRef<boolean>(false)

  useEffect(() => {
    if (!activeItemRef.current || !menuRef.current) {
      return
    }

    const {
      current: { offsetHeight: menuHeight, scrollHeight: menuScrollHeight, scrollTop },
    } = menuRef

    const {
      current: { offsetTop: itemTop },
    } = activeItemRef

    if (menuHeight === menuScrollHeight) {
      return
    }

    if (menuHeight - itemTop + scrollTop < ITEM_HEIGHT) {
      // prevent onMouseEnter event to fire after scroll
      wasJustScrolledRef.current = true
      menuRef.current.scrollTo(0, itemTop - menuHeight + ITEM_HEIGHT)

      return
    }

    if (scrollTop > itemTop) {
      // prevent onMouseEnter event to fire after scroll
      wasJustScrolledRef.current = true
      menuRef.current.scrollTo(0, itemTop - (currentIndex === 0 ? GROUP_HEADER_HEIGHT : 0))
    }
  }, [currentIndex, currentGroupIndex])

  return (
    <Popper
      disablePortal
      anchorEl={anchorEl}
      className={styles.popper}
      modifiers={[
        {
          enabled: true,
          name: 'flip',
          options: {
            altBoundary: false,
          },
        },
      ]}
      open={Boolean(show && anchorEl)}
      placement="bottom-start"
    >
      <div
        className={styles.menu}
        ref={menuRef}
      >
        {groups.map((group, groupIndex) => (
          <Box
            className={styles.group}
            key={group.id}
            mx="10px"
          >
            <Box className={styles['group-title']}>
              {group.label} ({group.items.length})
            </Box>

            {group.items.map((item, index) => {
              const isActive = index === currentIndex && groupIndex === currentGroupIndex

              const renderItemContent = (): React.ReactNode => {
                switch (item.__typename) {
                  case 'Well': {
                    const title = mapWellToTitle(item)

                    return (
                      <>
                        <IconAvatar
                          icon={<OilWellSolidIcon />}
                          size="lg"
                        />
                        <Tooltip title={title}>
                          <Box>{title}</Box>
                        </Tooltip>
                      </>
                    )
                  }
                  case 'Battery': {
                    const title = mapBatteryToTitle(item)

                    return (
                      <>
                        <IconAvatar
                          icon={<OilWellSolidIcon />}
                          size="lg"
                        />
                        <Tooltip title={title}>
                          <Box className={styles.title}>{title}</Box>
                        </Tooltip>
                      </>
                    )
                  }
                  case 'Site': {
                    const title = mapSiteToTitle(item)

                    return (
                      <>
                        <IconAvatar
                          icon={<OilWellSolidIcon />}
                          size="lg"
                        />
                        <Tooltip title={title}>
                          <Box className={styles.title}>{title}</Box>
                        </Tooltip>
                      </>
                    )
                  }
                  case 'Route': {
                    const title = mapRouteToTitle(item)

                    return (
                      <>
                        <IconAvatar
                          icon={<RouteSolidIcon />}
                          size="lg"
                        />
                        <Tooltip title={title}>
                          <Box className={styles.title}>{title}</Box>
                        </Tooltip>
                      </>
                    )
                  }
                  case 'User': {
                    const title = mapUserToTitle(item)

                    return (
                      <>
                        <UserAvatar
                          size="lg"
                          user={item}
                        />
                        <Tooltip title={title}>
                          <Box className={styles.title}>{title}</Box>
                        </Tooltip>
                      </>
                    )
                  }
                  case 'Group': {
                    const title = mapGroupToTitle(item)

                    return (
                      <>
                        <IconAvatar
                          icon={<UserGroupSolidIcon />}
                          size="lg"
                        />
                        <Tooltip title={title}>
                          <Box className={styles.title}>{title}</Box>
                        </Tooltip>
                      </>
                    )
                  }
                  default: {
                    return null
                  }
                }
              }

              return (
                <Box
                  className={classNames(styles['menu-item'], isActive && styles.active)}
                  key={item.id}
                  ref={isActive ? activeItemRef : null}
                  sx={{ gap: toPx(8) }}
                  onMouseDown={onItemClick}
                  onMouseEnter={() => {
                    if (wasJustScrolledRef.current) {
                      wasJustScrolledRef.current = false

                      return
                    }
                    setGroupIndex(groupIndex)
                    setIndex(index)
                  }}
                >
                  {renderItemContent()}
                </Box>
              )
            })}
          </Box>
        ))}
      </div>
    </Popper>
  )
}
