import { useState } from 'react'

import { useEventCallback } from './use-event-callback'

export const useAsyncEventCallback = <ParamsT extends ReadonlyArray<unknown>, ResultT>(
  callbackActual: (...params: ParamsT) => Promise<ResultT>,
) => {
  const [isLoading, setIsLoading] = useState(false)

  const callback = useEventCallback(async (...params: ParamsT) => {
    if (isLoading) {
      throw new Error('Already pending')
    }

    try {
      setIsLoading(true)

      const result = await callbackActual(...params)

      return result
    } finally {
      setIsLoading(false)
    }
  })

  return [isLoading, callback] as const
}
