import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CheckboxCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/checkbox/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { CheckboxFormCustomFieldValueT } from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  CheckboxCustomFieldValueT,
  CheckboxFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const checkboxPayload = payload[CustomFieldTypeT.CheckboxT]

  assert(checkboxPayload, checkNonNullable)

  const { defaultValue } = checkboxPayload

  return defaultValue
}
