import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { useResourceObjectTypeQuery } from '@resnet/client-api/api'

export type UseResourceObjectTypeOptionPropsT = { id: string }

export const useResourceObjectTypeOption = ({ id }: UseResourceObjectTypeOptionPropsT) => {
  const optionQuery = useResourceObjectTypeQuery({ id }, { select: (data) => data.getResourceObjectType })

  return { optionQuery }
}

export const ResourceObjectTypeOptionContainer = createHookContainer(useResourceObjectTypeOption)
