import { checkIsEntity } from '../../entities/utils/check-is-entity'
import type { EntityWithReadableIdT } from '../../entities/utils/map-entity-to-readable-id'
import { mapEntityToReadableId } from '../../entities/utils/map-entity-to-readable-id'
import { checkIsGroup } from '../../groups/utils/check-is-group'
import type { GroupWithReadableIdT } from '../../groups/utils/map-group-to-readable-id'
import { mapGroupToReadableId } from '../../groups/utils/map-group-to-readable-id'

export type OriginWithReadableIdT = EntityWithReadableIdT | GroupWithReadableIdT

export const mapOriginToReadableId = <ValueT extends OriginWithReadableIdT>(value: ValueT): string => {
  if (checkIsGroup(value)) {
    return mapGroupToReadableId(value)
  }

  if (checkIsEntity(value)) {
    return mapEntityToReadableId(value)
  }

  throw new Error(`Unknown origin type: ${value.__typename}`)
}
