import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

type ComponentT<PropsT> = (props: PropsT) => null | React.ReactElement

type WrapperT<WrapperPropsT, NestedPropsT> = (Nested: ComponentT<NestedPropsT>) => ComponentT<WrapperPropsT>

type WithNestedT = {
  <A, B>(a: WrapperT<A, B>, b: ComponentT<B>): ComponentT<A>
  <A, B, C>(a: WrapperT<A, B>, b: WrapperT<B, C>, c: ComponentT<C>): ComponentT<A>
  <A, B, C, D>(a: WrapperT<A, B>, b: WrapperT<B, C>, c: WrapperT<C, D>, d: ComponentT<D>): ComponentT<A>
}

export const withNested: WithNestedT = (...args: AnyTypeT[]) =>
  args.reduceRight(
    (
      NestedComponent: ComponentT<Record<string, unknown>>,
      wrapper: WrapperT<Record<string, unknown>, Record<string, unknown>>,
    ): ComponentT<Record<string, unknown>> => wrapper(NestedComponent),
  )
