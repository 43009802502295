import { checkKeyIn } from '../object/check-key-in'

const exclusions = {
  person: 'people',
  was: 'were',
}

const yExclusions = ['day']

const wordEndConsonantExclusions = ['s', 'x', 'z', 'ch', 'sh']

const keepFirstLetterCase = (left: string, right: string) => {
  if (left[0] === left[0].toUpperCase()) {
    return right[0].toUpperCase() + right.slice(1)
  }

  return right
}

const handleWordIsExclusion = (word: string) => {
  const key = word.toLowerCase()

  if (!checkKeyIn(key, exclusions)) {
    return undefined
  }

  return keepFirstLetterCase(word, exclusions[key])
}

const handleYIsLastLetter = (word: string) => {
  if (word.at(-1) !== 'y' || yExclusions.includes(word.toLowerCase())) {
    return undefined
  }

  // eslint-disable-next-line spellcheck/spell-checker
  return `${word.slice(0, -1)}ies`
}

const handleWordEndIsConsonantExclusion = (word: string) => {
  if (!wordEndConsonantExclusions.some((exclusion) => word.endsWith(exclusion))) {
    return undefined
  }

  return `${word}es`
}

const handleDefault = (word: string) => `${word}s`

export const pluralize = (count: number, sentence: string): string => {
  if (count === 1) {
    return sentence
  }

  return sentence
    .split(' ')
    .map(
      (word) =>
        handleWordIsExclusion(word) ??
        handleYIsLastLetter(word) ??
        handleWordEndIsConsonantExclusion(word) ??
        handleDefault(word),
    )
    .join(' ')
}
