import { useCallback, useRef } from 'react'
import type { DependencyList } from 'react'

type CleanupCallbackT = () => void

type EffectCallbackT<ElementT> = (element: null | ElementT) => void | CleanupCallbackT

type CallbackRefEffectT<ElementT> = (element: null | ElementT) => void

/*
 * Just usual useCallback except it allows to return cleanup function in useEffect manner
 * that will run before next ref updates.
 */

export const useCallbackRefEffect = <ElementT>(
  effectCallback: EffectCallbackT<ElementT>,
  deps: DependencyList,
): CallbackRefEffectT<ElementT> => {
  const cleanupCallbackRef = useRef<void | CleanupCallbackT>()

  const callbackRefEffect = useCallback((element: null | ElementT) => {
    cleanupCallbackRef.current?.()

    const cleanupCallback = effectCallback(element)

    cleanupCallbackRef.current = cleanupCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return callbackRefEffect
}
