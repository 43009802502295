import type { PopperTransitionProps } from '@mui/base'
import { Box, Grow, Popper as MUIPopper, useEventCallback } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import { createContext, useImperativeHandle, useState } from 'react'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { MemoProvider } from '@resnet/client-common/react/components/memo-provider'
import type { StateModelT } from '@resnet/client-common/react/types/state-model'

export type PopperRefValueT = {
  close: () => void
  open: () => void
  setAnchorEl: (element: null | HTMLElement) => void
}

export const PopperContext = createContext<null | {
  anchorEl: null | HTMLElement
  close: () => void
  isOpened: boolean
  open: () => void
  setAnchorEl: (element: null | HTMLElement) => void
}>(null)

export const PopperTransitionContext = createContext<undefined | PopperTransitionProps>(undefined)

export type PopperPropsT = {
  children: {
    content: () => React.ReactElement
    anchor?: () => React.ReactElement
  }
  disablePortal?: boolean
  isOpenedStateModel?: StateModelT<boolean>
  onClose?: () => void
  placement?: React.ComponentProps<typeof MUIPopper>['placement']
  popperRef?: React.Ref<PopperRefValueT>
}

export const Popper = ({
  children,
  disablePortal = false,
  isOpenedStateModel: isOpenedStateModelProp,
  onClose,
  placement = 'bottom-start',
  popperRef,
}: PopperPropsT) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isOpenedStateModel = useState(false)

  const [isOpened, setIsOpened] = isOpenedStateModelProp ?? isOpenedStateModel

  const open = useEventCallback(() => {
    setIsOpened(true)
  })

  const close = useEventCallback(() => {
    setIsOpened(false)
    onClose?.()
  })

  useImperativeHandle(popperRef, () => ({ close, open, setAnchorEl }), [close, open])

  const renderAnchor = (): React.ReactNode => {
    return children.anchor?.() ?? null
  }

  const renderContent = (): React.ReactNode => {
    return (
      <MUIPopper
        transition
        anchorEl={anchorEl}
        disablePortal={disablePortal}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-start', 'auto'],
            },
          },
        ]}
        open={isOpened}
        placement={placement}
        sx={{ zIndex: zIndex.modal }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: '0 0 0' }}
            timeout={300}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <ErrorBoundary>
                <MemoProvider
                  Context={PopperTransitionContext}
                  value={TransitionProps}
                >
                  {children.content()}
                </MemoProvider>
              </ErrorBoundary>
            </Box>
          </Grow>
        )}
      </MUIPopper>
    )
  }

  return (
    <MemoProvider
      Context={PopperContext}
      value={{ anchorEl, close, isOpened, open, setAnchorEl }}
    >
      {renderAnchor()}
      {renderContent()}
    </MemoProvider>
  )
}
