import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { transform } from '@resnet/client-common/common/utils/object/transform'

import { NumberRangeFilter } from '@resnet/client-web/shared/filters/components/number-range-filter'
import { FilterRuleContainer } from '@resnet/client-web/shared/presets/hooks/use-filter-rule'

import type { CustomFieldT } from '../../types/custom-field'
import { mapCustomFieldToFullKey, mapCustomFieldToKey } from '../../utils/map-custom-field-to-key'

export const createFilterOption: CustomFieldT['createFilterOption'] = ({
  field,
  fieldValuesKey,
  filterObjectType,
  updateFieldsFilter,
}) => {
  return {
    apply: (input, rule) => {
      type InputT = typeof input

      const { numberRange: payload } = rule

      if (!payload) {
        return input
      }

      const withFrom = (input: InputT) => {
        const { from } = payload

        if (from === undefined || from === null) {
          return input
        }

        return updateFieldsFilter(input, (fieldsFilter) =>
          transform(fieldsFilter ?? {}, {
            [mapCustomFieldToKey(field)]: (fieldFilter) =>
              transform(fieldFilter ?? {}, {
                gte: () => from,
              }),
          }),
        )
      }

      const withTo = (input: InputT) => {
        const { to } = payload

        if (to === undefined || to === null) {
          return input
        }

        return updateFieldsFilter(input, (fieldsFilter) =>
          transform(fieldsFilter ?? {}, {
            [mapCustomFieldToKey(field)]: (fieldFilter) =>
              transform(fieldFilter ?? {}, {
                lte: () => to,
              }),
          }),
        )
      }

      return pipeline(input, withFrom, withTo)
    },
    id: mapCustomFieldToFullKey(field, fieldValuesKey),
    name: field.name,
    objectType: filterObjectType,
    renderContent: ({ filterPresetRuleModel, popperRef }) => {
      return (
        <FilterRuleContainer
          filterPresetRuleModel={filterPresetRuleModel}
          filterType="numberRange"
        >
          {({ filterPresetItemRulePayloadModel: [value, onChange] }) => {
            return (
              <NumberRangeFilter
                isInteger
                min={0}
                popperRef={popperRef}
                value={value}
                onChange={onChange}
              />
            )
          }}
        </FilterRuleContainer>
      )
    },
  }
}
