import { generatePath } from 'react-router-dom'

import type { BatteryFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type BatteryWithFeedPathnameT = Pick<BatteryFragmentT, '__typename' | 'readableId'>

export const mapBatteryToFeedPathname = <ValueT extends BatteryWithFeedPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.batteriesDetailsFeed, { batteryId: value.readableId })
}
