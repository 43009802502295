import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { uniqId } from '@resnet/client-common/common/utils/object/uniq-id'

import { useUpdateIssueMutation } from '@resnet/client-api/api'
import { useRejectIssueMutation, useResetIssueApprovalMutation } from '@resnet/client-api/api'
import { useApproveIssueMutation, useGetMyIssueInfoAsApproverQuery } from '@resnet/client-api/api'
import { ApproveStatusT } from '@resnet/client-api/api'
import { useListIssueApprovalLevelsQuery, useSetIssueApprovalLevelsMutation } from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findExactQuery } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const issueApprovalMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findGetMyIssueInfoAsApproverQuery = (id: string) =>
    findExactQuery(queryClient, useGetMyIssueInfoAsApproverQuery, { id })

  const rejectIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRejectIssueMutation, {
      onError: (_, { id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach((query) => {
          setQueryData(queryClient)(query)((data) => {
            const getMyIssueInfoAsApprover = data?.getMyIssueInfoAsApprover

            if (!getMyIssueInfoAsApprover) {
              return data
            }

            return {
              ...data,
              getMyIssueInfoAsApprover: {
                ...getMyIssueInfoAsApprover,
                status: ApproveStatusT.RejectedT,
              },
            }
          })
        })
      },
      onSuccess: (_, { id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const resetIssueApprovalHandlers = () => {
    return createMutationHookMutationCacheHandlers(useResetIssueApprovalMutation, {
      onError: (_, { id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach((query) => {
          setQueryData(queryClient)(query)((data) => {
            const getMyIssueInfoAsApprover = data?.getMyIssueInfoAsApprover

            if (!getMyIssueInfoAsApprover) {
              return data
            }

            return {
              ...data,
              getMyIssueInfoAsApprover: {
                ...getMyIssueInfoAsApprover,
                status: ApproveStatusT.PendingT,
              },
            }
          })
        })
      },
      onSuccess: (_, { id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const approveIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useApproveIssueMutation, {
      onError: (_, { id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach((query) => {
          setQueryData(queryClient)(query)((data) => {
            const getMyIssueInfoAsApprover = data?.getMyIssueInfoAsApprover

            if (!getMyIssueInfoAsApprover) {
              return data
            }

            return {
              ...data,
              getMyIssueInfoAsApprover: {
                ...getMyIssueInfoAsApprover,
                status: ApproveStatusT.ApprovedT,
              },
            }
          })
        })
      },
      onSuccess: (_, { id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateIssueMutation, {
      onSuccess: (_, { id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const setIssueApprovalLevelsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSetIssueApprovalLevelsMutation, {
      onSuccess: (_, { id }) => {
        ;[findGetMyIssueInfoAsApproverQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    approveIssueHandlers(),
    rejectIssueHandlers(),
    resetIssueApprovalHandlers(),
    updateIssueHandlers(),
    setIssueApprovalLevelsHandlers(),
  ]
}

export const issueApprovalLevelsMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListIssueApprovalLevelsQuery = (id: string) =>
    findExactQuery(queryClient, useListIssueApprovalLevelsQuery, { id })

  const setIssueApprovalLevelsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSetIssueApprovalLevelsMutation, {
      onError: (_, { id }) => {
        ;[findListIssueApprovalLevelsQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, levels }) => {
        ;[findListIssueApprovalLevelsQuery(id)].filter(checkNonNullable).forEach((query) => {
          setQueryData(queryClient)(query)((data) => {
            const approverLevels = data?.listIssueApprovalLevels

            if (!approverLevels) {
              return data
            }

            const nextLevels = [levels].flat().map((level) => {
              const existingLevel = approverLevels.items.find((item) => item.id === level.id)

              const approvers =
                level.approvers?.map((approverId) => {
                  const draftApprover = existingLevel?.approvers?.find((item) => item.id === approverId)

                  return {
                    id: approverId,
                    status: draftApprover?.status ?? ApproveStatusT.PendingT,
                    updatedAt: draftApprover?.updatedAt ?? new Date().toISOString(),
                  }
                }) ?? []

              return {
                ...level,
                approvers,
                id: existingLevel?.id ?? uniqId(),
                status: existingLevel?.status ?? ApproveStatusT.PendingT,
              }
            })

            return {
              ...data,
              listIssueApprovalLevels: {
                ...data.listIssueApprovalLevels,
                items: nextLevels,
              },
            }
          })
        })
      },
      onSuccess: (_, { id }) => {
        ;[findListIssueApprovalLevelsQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const rejectIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRejectIssueMutation, {
      onSuccess: (_, { id }) => {
        ;[findListIssueApprovalLevelsQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const approveIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useResetIssueApprovalMutation, {
      onSuccess: (_, { id }) => {
        ;[findListIssueApprovalLevelsQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const resetIssueApprovalHandlers = () => {
    return createMutationHookMutationCacheHandlers(useApproveIssueMutation, {
      onSuccess: (_, { id }) => {
        ;[findListIssueApprovalLevelsQuery(id)].filter(checkNonNullable).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [setIssueApprovalLevelsHandlers(), approveIssueHandlers(), rejectIssueHandlers(), resetIssueApprovalHandlers()]
}
