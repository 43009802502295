import { uniq } from 'ramda'
import { useMemo, useState } from 'react'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useDebounced } from '@resnet/client-common/react/hooks/use-debounced'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { EntityTypeIdsT } from '@resnet/client-api/api'
import { useLabelsQuery } from '@resnet/client-api/api'

import { useSearchHandlers } from '@resnet/client-web/shared/gdl/components/search'
import type { AbstractSimpleOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'

export const getLabelsSelectOptionLabel = (option: AbstractSimpleOptionT) => option.name

const emptyArray: string[] = []

export const useEntityLabelsSelectDropdown = ({
  entityTypeId,
  value: valueProp,
}: {
  entityTypeId: EntityTypeIdsT
  value?: null | string[]
}) => {
  const [searchActual, setSearch] = useState('')

  const search = useDebounced(searchActual)

  const value = valueProp ?? emptyArray

  const labelsQuery = useLabelsQuery(
    { entityTypeId },
    {
      keepPreviousData: true,
      select: (data) => assertedNonNullable(data.listLabels),
    },
  )

  const labels = useMemo(() => pipeline(labelsQuery.data, (data) => (!data ? [] : data)), [labelsQuery.data])

  const labelsSet = useMemo(() => new Set(labels), [labels])

  const options = useMemo(
    () =>
      pipeline(
        value,
        (x) => x.filter((item) => !labelsSet.has(item)),
        (x) => uniq([...x, ...labels]),
        (x) => x.filter((option) => option.toLowerCase().startsWith(search.toLowerCase())),
        (x) => x.map((id) => ({ id, name: id })),
      ),
    [labels, labelsSet, search, value],
  )

  const { onChange: onSearchChange, onClear: onSearchClear } = useSearchHandlers({ setSearch })

  const dropdownProps = {
    getOptionLabel: getLabelsSelectOptionLabel,
    isLoading: labelsQuery.isFetching,
    options,
  }

  const searchProps = {
    onChange: onSearchChange,
    onClear: onSearchClear,
    value: searchActual,
  }

  return {
    dropdownProps,
    searchProps,
  }
}

export const EntityLabelsSelectDropdownContainer = createHookContainer(useEntityLabelsSelectDropdown)
