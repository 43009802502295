import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { TextCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/text/custom-field-value'

import { FieldPreview } from '@resnet/client-web/shared/gdl/components/field-preview'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type TextFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | TextCustomFieldValueT
}

export const TextFormUserFormPreview = ({ field, value }: TextFormUserFormPreviewPropsT) => {
  return (
    <ObjectDetailsRow>
      <FieldPreview
        label={field.name}
        multiline={field.payload?.[CustomFieldTypeT.TextT]?.multiline}
        value={value}
      />
    </ObjectDetailsRow>
  )
}
