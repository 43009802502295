import { useMemo, useState } from 'react'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { fold } from '@resnet/client-common/common/utils/object/fold'
import { transform } from '@resnet/client-common/common/utils/object/transform'
import { useDebounced } from '@resnet/client-common/react/hooks/use-debounced'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { SortDirectionT, useInfiniteSearchEntitiesQuery } from '@resnet/client-api/api'
import type {
  MinimalEntityFragmentT,
  SearchEntitiesQueryVariablesT,
  SearchEntityQueryFilterT,
} from '@resnet/client-api/api'
import { defaultLimit } from '@resnet/client-api/constants/variables'

import {
  mapEntityToEntityTypeId,
  mapEntityToEntityTypeIdTitle,
} from '@resnet/client-shared/shared/entities/utils/map-entity-to-entity-type-id'
import { mapEntityToMedia } from '@resnet/client-shared/shared/entities/utils/map-entity-to-media'
import { mapEntityToTitle } from '@resnet/client-shared/shared/entities/utils/map-entity-to-title'

import { useSearchHandlers } from '@resnet/client-web/shared/gdl/components/search'

type UseEntitiesSelectDropdownPropsT = {
  filter?: SearchEntityQueryFilterT
  exclude?: string[]
  withQueryVariables?: (input: SearchEntitiesQueryVariablesT) => SearchEntitiesQueryVariablesT
}

export const getEntitySelectOptionLabel = <ValueT extends MinimalEntityFragmentT>(value: ValueT) =>
  mapEntityToTitle(value)

export const getEntitySelectOptionMedia = <ValueT extends MinimalEntityFragmentT>(value: ValueT) =>
  mapEntityToMedia(value)

export const getEntitySelectOptionDescription = <ValueT extends MinimalEntityFragmentT>(value: ValueT) =>
  mapEntityToEntityTypeIdTitle(mapEntityToEntityTypeId(value))

export const useEntitiesSelectDropdown = <OptionT extends MinimalEntityFragmentT>({
  filter,
  withQueryVariables: withQueryVariablesActual,
  exclude,
}: UseEntitiesSelectDropdownPropsT) => {
  const [searchActual, setSearch] = useState('')

  const search = useDebounced(searchActual)

  const withSearch = (input: SearchEntitiesQueryVariablesT) => {
    if (search.length === 0) {
      return input
    }

    return transform(input ?? {}, {
      search: () => search,
    })
  }

  const withLimit = (input: SearchEntitiesQueryVariablesT) => {
    return transform(input ?? {}, {
      limit: () => defaultLimit,
    })
  }

  const withFilter = (input: SearchEntitiesQueryVariablesT) => {
    return transform(input ?? {}, {
      filter: () => (!filter ? input.filter : { ...input.filter, ...filter }),
    })
  }

  const withSort = (input: SearchEntitiesQueryVariablesT) => {
    return transform(input ?? {}, {
      sort: () => ({ name: SortDirectionT.AscT }),
    })
  }

  const withQueryVariables = (input: SearchEntitiesQueryVariablesT) => {
    return !withQueryVariablesActual ? input : withQueryVariablesActual(input)
  }

  const entitiesQuery = useInfiniteSearchEntitiesQuery(
    pipeline({}, withQueryVariables, withFilter, withSort, withSearch, withLimit),
    {
      getNextPageParam: ({ searchEntities }, allPages) =>
        fold({
          offset: searchEntities?.items?.length ? allPages.length * defaultLimit : undefined,
        }),
      keepPreviousData: true,
      useErrorBoundary: false,
    },
  )

  const entitiesQueryFetchNextPage = entitiesQuery.fetchNextPage

  const entities = useMemo(
    () => entitiesQuery.data?.pages.flatMap((page) => assertedNonNullable(page.searchEntities?.items)) ?? [],
    [entitiesQuery.data],
  )

  const options = useMemo(
    () => (!exclude ? entities : entities?.filter((item) => !exclude.includes(item.id))),
    [exclude, entities],
  ) as OptionT[]

  const { onChange: onSearchChange, onClear: onSearchClear } = useSearchHandlers({ setSearch })

  const bottomBoundary = useMemo(() => {
    if (entitiesQuery.isFetchingNextPage || !entitiesQuery.hasNextPage) {
      return
    }

    return { onObserve: entitiesQueryFetchNextPage }
  }, [entitiesQuery.hasNextPage, entitiesQuery.isFetchingNextPage, entitiesQueryFetchNextPage])

  const dropdownProps = {
    bottomBoundary,
    getOptionDescription: getEntitySelectOptionDescription,
    getOptionLabel: getEntitySelectOptionLabel,
    getOptionMedia: getEntitySelectOptionMedia,
    isLoading: entitiesQuery.isFetching,
    optionHeight: 60,
    options,
  }

  const searchProps = {
    onChange: onSearchChange,
    onClear: onSearchClear,
    value: searchActual,
  }

  return {
    dropdownProps,
    searchProps,
  }
}

export const EntitiesSelectDropdownContainer = createHookContainer(useEntitiesSelectDropdown)
