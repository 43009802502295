import { Box } from '@mui/material'
import { useContext } from 'react'
import type { UseFormHandleSubmit } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { assertedNonNull } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import { Form } from '@resnet/client-web/shared/form-dalaran/components/common/form'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { Divider } from '@resnet/client-web/shared/gdl/components/divider'
import { Field } from '@resnet/client-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-web/shared/gdl/components/field'
import {
  ModalContent,
  ModalContext,
  createModalContainer,
  createModalHook,
} from '@resnet/client-web/shared/gdl/components/modal'
import { TextInput } from '@resnet/client-web/shared/gdl/components/text-input'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export const SimpleTextInputModal = ({
  content,
  label,
  isLoading,
  onSubmit,
  isDestructive = false,
  cancelActionLabel = 'Cancel',
  confirmActionLabel = 'Confirm',
  onCancel,
  title,
  placeholder,
  value: valueProp,
}: {
  value?: string
  cancelActionLabel?: string
  confirmActionLabel?: string
  isDestructive?: boolean
  isLoading?: boolean
  label: string
  title: string
  placeholder?: string
  content?: () => React.ReactNode
  onSubmit: Parameters<UseFormHandleSubmit<{ value: 'string' }>>[0]
  onCancel?: () => void
}): React.ReactElement => {
  const form = useForm({
    defaultValues: {
      value: valueProp ?? '',
    },
  })

  const { onClose } = pipeline(useContext(ModalContext), assertedNonNull)

  const renderHeaderTitle = (): React.ReactNode => {
    return title
  }

  const renderFields = (): React.ReactNode => {
    return (
      <Box sx={{ display: 'flex', gap: toPx(16) }}>
        <Field sx={{ flexGrow: 1, width: 0 }}>
          <FieldLabel>{label}</FieldLabel>
          <Controller
            control={form.control}
            name="value"
            render={({ field, fieldState }) => (
              <TextInput
                hasError={fieldState.invalid}
                placeholder={placeholder}
                {...field}
              />
            )}
            rules={{ required: true }}
          />
        </Field>
      </Box>
    )
  }

  const renderSaveAsNewButton = (): React.ReactNode => {
    return (
      <Button
        color="primary"
        disabled={form.formState.isSubmitting}
        isLoading={isLoading || form.formState.isSubmitting}
        size="md"
        type="submit"
        variant="contained"
      >
        {confirmActionLabel}
      </Button>
    )
  }

  const renderCloseButton = (): React.ReactNode => {
    return (
      <Button
        color={!isDestructive ? 'primary' : 'danger'}
        size="md"
        variant="outlined"
        onClick={() => {
          onCancel?.()
          onClose?.()
        }}
      >
        {cancelActionLabel}
      </Button>
    )
  }

  const renderControls = (): React.ReactNode => {
    return (
      <Box sx={{ display: 'flex', gap: toPx(16) }}>
        {renderCloseButton()}
        <Box sx={{ flexGrow: 1, ml: toPx(-16) }} />
        {renderSaveAsNewButton()}
      </Box>
    )
  }

  const renderContent = (): React.ReactNode => {
    return (
      <Form
        form={form}
        sx={{ gap: toPx(16) }}
        onSubmit={onSubmit}
      >
        {content?.() ?? null}
        <ChildrenDivider dividerNode={<Divider />}>
          {renderFields()}
          {renderControls()}
        </ChildrenDivider>
      </Form>
    )
  }

  return (
    <ModalContent sx={{ maxWidth: toPx(512), width: '100%' }}>
      {{
        content: renderContent(),
        header: { title: renderHeaderTitle() },
      }}
    </ModalContent>
  )
}

export const useSimpleTextInputModal = createModalHook((params: React.ComponentProps<typeof SimpleTextInputModal>) => (
  <SimpleTextInputModal {...params} />
))

export const SimpleTextInputModalContainer = createModalContainer(useSimpleTextInputModal)
