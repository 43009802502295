import { Box, type BoxProps } from '@mui/material'

import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'
import type { ValuesT } from '@resnet/client-common/typescript/types/values'

import type { BatteryFragmentT, ResourceObjectFragmentT, SiteFragmentT, WellFragmentT } from '@resnet/client-api/api'
import { useResourcesQuery, ResourceTypesT } from '@resnet/client-api/api'

import { useThemeProps } from '@resnet/client-shared/shared/gdl/contexts/theme-context'
import type { themes } from '@resnet/client-shared/shared/gdl/themes'
import { mapOriginToAbbr } from '@resnet/client-shared/shared/origins/utils/map-origin-to-abbr'

import type { AvatarPropsT } from '@resnet/client-web/shared/gdl/components/avatar'
import { Avatar, AvatarSkeleton } from '@resnet/client-web/shared/gdl/components/avatar'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { mapBorderWidthToValue } from '@resnet/client-web/shared/gdl/utils/map-border-width-to-value'
import { mapPaddingToValue } from '@resnet/client-web/shared/gdl/utils/map-padding-to-value'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export type AnyCardPropsT = MergeAllT<[BoxProps, { isActive?: boolean; onClick?: () => void }]>

export const AnyCard = ({ children, isActive, sx = null, onClick, ...props }: AnyCardPropsT) => {
  const borderRadius = 8

  const renderButton = () => {
    if (!onClick) {
      return null
    }

    return (
      <Box
        component="button"
        sx={[
          { all: 'unset' },
          { borderRadius: toPx(borderRadius), cursor: 'pointer', inset: toPx(0), position: 'absolute', width: '100%' },
        ]}
        onClick={onClick}
      />
    )
  }

  const renderUnderlay = () => {
    const borderWidth = 1

    return (
      <Box
        sx={[
          {
            backgroundColor: themeColors.surfaceVariantDefault,
            borderColor: themeColors.borderDefault,
            borderRadius: toPx(borderRadius),
            borderStyle: 'solid',
            borderWidth: mapBorderWidthToValue({ borderWidth }),
            inset: toPx(0),
            pointerEvents: 'none',
            position: 'absolute',
            zIndex: -1,
          },
          {
            'button:hover + &': {
              backgroundColor: themeColors.surfaceVariantHover,
            },
          },
          {
            'button:active + &': {
              backgroundColor: themeColors.surfaceVariantPressed,
            },
          },
          {
            'button:focus-visible + &': {
              borderColor: themeColors.borderFocus,
            },
          },
          !isActive
            ? null
            : {
                borderColor: themeColors.basePrimary,
              },
        ]}
      />
    )
  }

  const renderRoot = () => {
    const padding = 16

    return (
      <Box
        {...props}
        sx={[
          {
            borderRadius: toPx(borderRadius),
            display: 'flex',
            flexDirection: 'column',
            padding: mapPaddingToValue({ padding }),
            position: 'relative',
            zIndex: 0,
          },
          sx,
        ].flat()}
      >
        {renderButton()}
        {renderUnderlay()}
        {children}
      </Box>
    )
  }

  return renderRoot()
}

export const EntityCardAvatar = <
  EntityT extends BatteryFragmentT | ResourceObjectFragmentT | SiteFragmentT | WellFragmentT,
>({
  entity,
  size,
  mapEntityToDefaultAvatar,
}: {
  entity: EntityT
  size: AvatarPropsT['size']
  mapEntityToDefaultAvatar?: (props: {
    entity: EntityT
    themeProps: ValuesT<typeof themes>
  }) => AvatarPropsT['resource']
}) => {
  const themeProps = useThemeProps()

  const avatarId = entity.avatarId

  const avatarQueryEnabled = Boolean(avatarId)

  const avatarQuery = useResourcesQuery(
    {
      filter: { id: { eq: avatarId }, resourceType: { eq: ResourceTypesT.AvatarT } },
    },
    { enabled: avatarQueryEnabled, select: (data) => data.listResources.items[0] },
  )

  if (avatarQueryEnabled && !avatarQuery.isSuccess) {
    return <AvatarSkeleton size={size} />
  }

  const defaultAvatar = mapEntityToDefaultAvatar?.({ entity, themeProps })

  const avatar = avatarQuery.data ?? defaultAvatar

  return (
    <Avatar
      name={mapOriginToAbbr(entity)}
      resource={avatar}
      size={size}
    />
  )
}
