import type { CustomFieldT } from '../../types/custom-field'

import { SectionHeaderCustomFieldUserFormField } from './components/section-header-custom-field-user-form-field'

export const renderUserFormField: CustomFieldT['renderUserFormField'] = ({ index, field }) => {
  return (
    <SectionHeaderCustomFieldUserFormField
      field={field}
      index={index}
    />
  )
}
