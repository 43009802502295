import type { BoxProps, SxProps } from '@mui/material'
import { Box } from '@mui/material'

import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import type { TypographyPresetT } from '@resnet/client-shared/shared/gdl/types/typography-preset'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

import { Skeleton } from '../skeleton'

type TextSkeletonPropsT = MergeT<
  Omit<BoxProps, 'children'>,
  {
    typographyPreset: TypographyPresetT
    contentSx?: SxProps
  }
>

export const TextSkeleton = ({ typographyPreset, sx, contentSx, ...props }: TextSkeletonPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          py: toPx((typographyPreset.lineHeight - typographyPreset.fontSize) / 2),
        },
        sx ?? null,
      ].flat()}
    >
      <Skeleton
        sx={[
          {
            backgroundColor: themeColors.overBackgroundDefault,
            borderRadius: toPx(2),
            height: toPx(typographyPreset.fontSize),
          },
          contentSx ?? null,
        ].flat()}
      />
    </Box>
  )
}
