import { checkNonNullable } from '../nullable/non-nullable'

export const parse = <ReturnT>(value: undefined | null | string): null | ReturnT => {
  if (!checkNonNullable(value)) {
    return null
  }

  try {
    return JSON.parse(value)
  } catch {
    return null
  }
}
