import type { FieldPathByValue, FieldValues, PathValue } from 'react-hook-form'
import { useWatch, type UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { DiscriminatorCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/discriminator/custom-field-value'

import type { FormCustomFieldValuesT } from '../types/form-custom-field-values'
import { mapCustomFieldToKey } from '../utils/map-custom-field-to-key'

import { useCheckUserFormItemIsNotHiddenByDiscriminatorCallback } from './use-check-user-form-item-is-not-hidden-by-discriminator-callback'

export const useCheckUserFormFieldIsNotHiddenByDiscriminatorCallback = <TFieldValues extends FieldValues>({
  fields,
  form,
  name,
}: {
  fields: CustomFieldFragmentT[]
  form: UseFormReturn<TFieldValues>
  name: FieldPathByValue<TFieldValues, undefined | FormCustomFieldValuesT>
}) => {
  const { control } = form

  const discriminatorField = fields.find((field) => field.type === CustomFieldTypeT.DiscriminatorT)

  const discriminatorValue = (() => {
    if (!discriminatorField) {
      return null
    }

    const payload = discriminatorField.payload

    assert(payload, checkNonNullable)

    const discriminatorPayload = payload[CustomFieldTypeT.DiscriminatorT]

    assert(discriminatorPayload, checkNonNullable)

    const { defaultValue: defaultValueActual } = discriminatorPayload

    const defaultValue = defaultValueActual as PathValue<
      TFieldValues,
      FieldPathByValue<TFieldValues, DiscriminatorCustomFieldValueT>
    >

    type DiscriminatorFieldPathT = FieldPathByValue<TFieldValues, DiscriminatorCustomFieldValueT>

    const discriminatorFieldName = `${name}.${mapCustomFieldToKey(discriminatorField)}` as DiscriminatorFieldPathT

    // NOTE: it is ok to call hook conditionally cause form structure cannot be changed during component lifetime and thus this hook will be either always called or never called
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const value = useWatch({
      control,
      defaultValue,
      name: discriminatorFieldName,
    }) as DiscriminatorCustomFieldValueT

    // NOTE for.getValue() is used for getting first value, for some reason watch() fails to do so in some cases
    return value ?? form.getValues(discriminatorFieldName)
  })()

  const { checkUserFormItemIsNotHiddenByDiscriminator: checkUserFormFieldIsNotHiddenByDiscriminator } =
    useCheckUserFormItemIsNotHiddenByDiscriminatorCallback({
      discriminatorValue,
      fields,
    })

  return { checkUserFormFieldIsNotHiddenByDiscriminator }
}
