import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToMedia } from '@resnet/client-shared/shared/users/utils/map-user-to-media-boston'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { createDropdownComponent } from '@resnet/client-web/shared/common/factories/create-dropdown-component'

import { UserSelectDropdownContainer } from '../../hooks/use-user-select-dropdown'

export const UserDropdown = createDropdownComponent({
  OptionContainer: UserOptionContainer,
  SelectDropdownContainer: UserSelectDropdownContainer,
  getOptionLabel: mapUserToTitle,
  getOptionMedia: mapUserToMedia,
  optionHeight: 48,
  skeletonProps: { media: { type: 'avatar' as const } },
})
