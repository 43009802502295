import { generatePath } from 'react-router-dom'

import type { GroupFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type GroupWithPathnameT = Pick<GroupFragmentT, '__typename' | 'id'>

export const mapGroupToPathname = <ValueT extends GroupWithPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.groupsDetails, { groupId: value.id })
}
