import { isValidElement, Children } from 'react'

export const foldElement = (element: React.ReactNode): React.ReactNode => {
  if (!isValidElement(element)) {
    return null
  }

  if (Children.toArray(element.props.children).filter(Boolean).length === 0) {
    return null
  }

  return element
}
