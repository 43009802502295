import type { UserFragmentT } from '@resnet/client-api/api'

import type { AvatarMediaT } from '../../gdl/types/media'

import { mapUserToTitle } from './map-user-to-title'

export const mapUserToMedia = (user: UserFragmentT): AvatarMediaT => ({
  name: mapUserToTitle(user),
  resourceId: user.avatarId,
  type: 'avatar',
})
