import { generatePath } from 'react-router-dom'

import type { IssueFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type IssueWithPathnameT = Pick<IssueFragmentT, '__typename' | 'readableId'>

export const mapIssueToPathname = <ValueT extends IssueWithPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.issuesDetails, { issueId: value.readableId })
}
