import { Box } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import { toPx } from "@resnet/client-web/shared/gdl/utils/to-px";
import { useSnackbar } from "notistack";
import {
  SegmentedControl,
  SegmentedControlItem,
} from "@resnet/client-web/shared/gdl/components/segmented-control";
import { themeColors } from "@resnet/client-web/shared/gdl/constants/theme-colors";
import { useParams } from "react-router-dom";
import { Feed } from "../components/Feed";
import { TextInput } from "@resnet/client-web/shared/gdl/components/text-input";
import { Button } from "@resnet/client-web/shared/gdl/components/button";
import {
  PostOriginTypeT,
  ResourceTypesT,
  useCreatePostMutation,
  useUploadResourceMutation,
} from "@resnet/client-api/api";
import { Attachments } from "./Issue/components/Attachments";
import { mapErrorToMessage } from "@resnet/client-common/network/utils/map-error-to-message";
import { useTeamsData } from "../context/teams-context";
import { stripHtml } from "../../utils/strip-html";

const Issue = (): React.ReactElement => {
  const [selectedAttachments, setSelectedAttachments] = React.useState<[]>([]);
  const [tab, setTab] = React.useState<string>("Attach");
  const { id } = useParams<{ id: string }>();

  const { message, teamsUrl } = useTeamsData();

  const { mutateAsync: createPost } = useCreatePostMutation();
  const { mutateAsync: uploadResource } = useUploadResourceMutation();

  const { enqueueSnackbar } = useSnackbar();

  const submit = async (message: string) => {
    try {
      let dynamicMessage = message;

      if (selectedAttachments.length > 0) {
        dynamicMessage += "</p>Attachments:<p>";
      }

      selectedAttachments.forEach((attachment) => {
        dynamicMessage +=
          "<p><a href='" + attachment.contentUrl + "'>" + attachment.name + "</a></p>";
      });

      const messageWithAttachmentUrl = dynamicMessage;

      const createPostResponse = await createPost({
        originId: id,
        originType: PostOriginTypeT.EntityT,
        post: {
          message: messageWithAttachmentUrl,
        },
      });

      // for (const attachment of selectedAttachments) {
      //   try {
      //     //https://netorgft6641677.sharepoint.com/sites/resnet.ai/Shared Documents/log-sep/e6c05d28003d6a7f42d1ced3d8f33e0ecf35a64b.jpg

      //     const blob = await fetch(attachment.contentUrl, {
      //       method: "GET",
      //       credentials: "include",
      //     })
      //       .then((res) => {
      //         // Get the content type from the response headers
      //         console.log("fetch res", res);
      //         const contentType = res.headers.get("content-type");
      //         console.log(contentType);
      //         return res.blob();
      //       })
      //       .then((blob) => {
      //         // Now you have the blob data and the content type
      //         console.log("Blob:", blob);
      //         console.log("Content Type:", contentType);
      //         // Use the blob as needed
      //       })
      //       .catch((error) => {
      //         console.error("Error fetching data:", error);
      //       });

      //     const file = new File([blob], attachment.name);

      //     await uploadResource({
      //       file: file,
      //       originId: createPostResponse.createPost,
      //       originType: "POST",
      //       type: ResourceTypesT.AttachmentT,
      //     });
      //   } catch (error) {
      //     enqueueSnackbar(mapErrorToMessage(error), {
      //       variant: "error",
      //     });
      //   }
      // }

      setTab("Feed");

      enqueueSnackbar("Your post has been successfully created.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("An error occurred while creating the post.", {
        variant: "error",
      });
    }
  };

  const AttachEmail = (): React.ReactElement => {
    const [value, setValue] = React.useState<string>(
      stripHtml(message + " - " + teamsUrl)
    );
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: toPx(16) }}>
        <TextInput
          sx={{ height: toPx(200) }}
          placeholder="Enter your message here"
          component={"textarea"}
          defaultValue={stripHtml(message + " " + teamsUrl)}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Attachments
          selectedAttachments={selectedAttachments}
          setSelectedAttachments={setSelectedAttachments}
        />
        <Button
          color="primary"
          size="md"
          variant="contained"
          onClick={() => submit(value)}
        >
          Attach
        </Button>
      </Box>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          zIndex: 9,
          width: "100%",
          background: themeColors.background,
        }}
      >
        <Header />
        <Box sx={{ padding: "8px 16px" }}>
          <SegmentedControl>
            <SegmentedControlItem
              value="Attach"
              onChange={() => setTab("Attach")}
              checked={tab == "Attach" ? true : false}
              label="Attach to Issue"
            />
            <SegmentedControlItem
              value="Feed"
              onChange={() => setTab("Feed")}
              checked={tab == "Feed" ? true : false}
              label="Feed"
            />
          </SegmentedControl>
        </Box>
      </Box>
      <Box sx={{ padding: toPx(16), paddingTop: 15 }}>
        {tab === "Attach" && <AttachEmail />}
        {tab === "Feed" && <Feed origin={id} />}
      </Box>
    </Box>
  );
};

export default Issue;
