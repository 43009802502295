import { generatePath } from 'react-router-dom'

import type { InsightFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type InsightWithFeedPathnameT = Pick<InsightFragmentT, '__typename' | 'readableId'>

export const mapInsightToFeedPathname = <ValueT extends InsightWithFeedPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.insightsDetailsFeed, { insightId: value.readableId })
}
