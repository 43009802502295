import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import { formulaCustomField as formulaCustomFieldShared } from '@resnet/client-shared/shared/custom-fields/presets/formula/custom-field'

import type { CustomFieldT } from '../../types/custom-field'

import { createColumn } from './create-column'
import { createFilterOption } from './create-filter-option'
import { mapCustomFieldToDefaultValue } from './map-custom-field-to-default-value'
import { mapFormCustomFieldValueToCustomFieldValue } from './map-form-custom-field-value-to-custom-field-value'
import { renderPayload } from './render-payload'
import { renderUserFormField } from './render-user-form-field'
import { renderUserFormPreview } from './render-user-form-preview'

export const formulaCustomField = createConstrainer<CustomFieldT>()({
  ...formulaCustomFieldShared,
  createColumn,
  createFilterOption,
  mapCustomFieldToDefaultValue,
  mapFormCustomFieldValueToCustomFieldValue,
  renderPayload,
  renderUserFormField,
  renderUserFormPreview,
})
