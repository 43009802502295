import type { ErrorInfo } from 'react'
import { Component } from 'react'

export type ErrorBoundaryFallbackPropsT = {
  error: Error
  onRecover: () => void
  recoveredCount: number
}

export type ErrorBoundaryPropsT = {
  children?: React.ReactNode
  fallback?: (props: ErrorBoundaryFallbackPropsT) => React.ReactNode
  onComponentDidCatch?: (error: Error, errorInfo: ErrorInfo) => void
}

type ErrorBoundaryStateT = {
  error: null | Error
  recoveredCount: number
}

const defaultFallback: NonNullable<ErrorBoundaryPropsT['fallback']> = () => null

export class ErrorBoundary extends Component<ErrorBoundaryPropsT, ErrorBoundaryStateT> {
  constructor(props: Record<string, unknown>) {
    super(props)

    this.state = {
      error: null,
      recoveredCount: 0,
    }

    this.onRecover = this.onRecover.bind(this)
  }

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.props.onComponentDidCatch?.(error, errorInfo)
  }

  onRecover(): void {
    this.setState({
      error: null,
      recoveredCount: this.state.recoveredCount + 1,
    })
  }

  render() {
    const { fallback = defaultFallback } = this.props

    const { error, recoveredCount } = this.state

    if (error) {
      return fallback({
        error,
        onRecover: this.onRecover,
        recoveredCount,
      })
    }

    return this.props.children
  }
}
