import { Box, useEventCallback } from '@mui/material'
import type { FieldPathByValue, FieldValues, PathValue, UseControllerProps } from 'react-hook-form'
import { useController, type UseFormReturn } from 'react-hook-form'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import { ResourceTypesT } from '@resnet/client-api/api'

import MinusSolidIcon from '@resnet/client-shared/assets/icons/minus-solid.svg'

import type { ResourceT } from '@resnet/client-web/shared/files/types/resource'
import { mapFileToDraftResource } from '@resnet/client-web/shared/files/utils/map-file-to-draft-resource'
import { Accordion } from '@resnet/client-web/shared/gdl/components/accordion'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { Divider } from '@resnet/client-web/shared/gdl/components/divider'
import { Field, FieldErrorText } from '@resnet/client-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-web/shared/gdl/components/field'
import { DropFileArea } from '@resnet/client-web/shared/gdl/legacy-components/drop-file-area'
import { AttachmentPreviewModalContainer } from '@resnet/client-web/shared/resources/components/attachment-preview-modal'
import { ResourceThumbnail } from '@resnet/client-web/shared/resources/components/resource-thumbnail'

export type AttachmentFieldValueT = undefined | null | ResourceT

export type AttachmentFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, AttachmentFieldValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
  label: string
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
  defaultValue?: PathValue<TFieldValues, TPath>
}

export const AttachmentField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, AttachmentFieldValueT>,
>({
  form,
  rules,
  label,
  name,
  defaultValue,
}: AttachmentFieldPropsT<TFieldValues, TPath>): React.ReactElement => {
  const {
    field: { value, onChange },
    fieldState: { error, invalid },
  } = useController({ control: form.control, defaultValue, name, rules })

  const resource = value ?? (null as Exclude<AttachmentFieldValueT, undefined>)

  const onDeleteAttachment = useEventCallback(() => {
    onChange(null as PathValue<TFieldValues, TPath>)
  })

  const onDropFileAreaInput = useEventCallback(async ({ files }: { files: File[] }) => {
    const [file] = files

    const resource = await mapFileToDraftResource(file, ResourceTypesT.AttachmentT)

    onChange(resource as PathValue<TFieldValues, TPath>)
  })

  const renderHeader = () => {
    return <FieldLabel>{label}</FieldLabel>
  }

  const renderInput = () => {
    const renderHeaderTitle = () => {
      return 'Attachment'
    }

    const renderAttachment = () => {
      if (!resource) {
        return null
      }

      const renderThumbnail = () => {
        return (
          <AttachmentPreviewModalContainer>
            {({ onOpen }) => (
              <ResourceThumbnail
                resource={resource}
                sx={{
                  flexGrow: 1,
                  width: 0,
                }}
                onClick={() => {
                  onOpen({ attachment: resource })
                }}
              />
            )}
          </AttachmentPreviewModalContainer>
        )
      }

      const renderDeleteAttachmentButton = () => {
        return (
          <Button
            color="primary"
            icon={<MinusSolidIcon />}
            size="md"
            sx={{ ml: 'auto' }}
            variant="text"
            onClick={onDeleteAttachment}
          />
        )
      }

      return (
        <Box sx={{ alignItems: 'center', display: 'flex', fontSize: '14px', gap: '8px', lineHeight: '20px' }}>
          {renderThumbnail()}
          {renderDeleteAttachmentButton()}
        </Box>
      )
    }

    const renderDropFileArea = () => {
      if (resource) {
        return null
      }

      return (
        <DropFileArea
          multiple={false}
          onInput={onDropFileAreaInput}
        />
      )
    }

    const renderContent = () => {
      return (
        <ChildrenDivider dividerNode={<Divider sx={{ my: '8px' }} />}>
          {renderAttachment()}
          {renderDropFileArea()}
        </ChildrenDivider>
      )
    }

    return (
      <Accordion
        defaultIsOpened
        hasError={invalid}
      >
        {{
          content: () => renderContent(),
          header: { title: renderHeaderTitle() },
        }}
      </Accordion>
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderFooter()}
    </Field>
  )
}
