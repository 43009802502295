import { useCallback } from 'react'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useLocationState } from '@resnet/client-common/react/hooks/use-location-state'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

type StateT = { opened: boolean; targetId?: string }

const stateKey = 'notificationsDrawer'

export const useNotificationsDrawer = () => {
  const [notificationsDrawerState, setNotificationsDrawerState] = useLocationState<
    StateT,
    undefined | Record<typeof stateKey, undefined | StateT>
  >(
    useCallback((location) => location.state?.[stateKey] ?? { opened: false }, []),
    useCallback((next, location) => ({ ...location, state: { ...location.state, [stateKey]: next } }), []),
  )

  const onClose = useEventCallback(() => {
    setNotificationsDrawerState({ opened: false })
  })

  const onOpen = useEventCallback(({ targetId }: { targetId?: string } = {}) => {
    setNotificationsDrawerState({ opened: true, targetId })
  })

  const { opened, targetId } = notificationsDrawerState

  return { onClose, onOpen, opened, targetId }
}

export const NotificationsDrawerContainer = createHookContainer(useNotificationsDrawer)
