import { Box } from '@mui/material'
import { useId } from 'react'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { TextOverflow } from '@resnet/client-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { visuallyHiddenStyles } from '@resnet/client-web/styles/sx-presets'

import { focusOutlineSx } from '../../sx-presets/focus-outline'
import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'

export const SegmentedControl = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return (
    <Box
      component="fieldset"
      role="radiogroup"
      sx={[
        { all: 'unset' },
        {
          '& > *': { flexGrow: 1, width: 0 },
          backgroundColor: themeColors.surfaceNeutralDefault,
          borderRadius: toPx(8),
          display: 'flex',
          gap: toPx(2),
          padding: toPx(4),
        },
      ]}
    >
      {children}
    </Box>
  )
}

export const SegmentedControlItem = <ValueT extends unknown>({
  value,
  checked,
  label,
  onChange,
}: {
  value: ValueT
  checked: boolean
  label: React.ReactNode
  onChange: (value: ValueT) => void
}): React.ReactElement => {
  const id = useId()

  return (
    <Box>
      <Box
        checked={checked}
        component="input"
        id={id}
        sx={[visuallyHiddenStyles, { '&:focus-visible + label': focusOutlineSx }].flat()}
        tabIndex={checked ? 0 : -1}
        type="radio"
        onChange={() => onChange(value)}
      />
      <Box
        component="label"
        htmlFor={id}
        sx={[
          { all: 'unset' },
          {
            alignItems: 'center',
            backgroundColor: checked ? themeColors.actionsPrimaryDefault : 'transparent',
            borderRadius: toPx(4),
            color: checked ? themeColors.actionsOverPrimaryDefault : themeColors.overBackgroundBold,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            paddingX: toPx(8),
            paddingY: toPx(4),
            whiteSpace: 'nowrap',
          },
          checked ? null : { '&:hover': { backgroundColor: themeColors.surfaceVariantHover } },
          checked ? null : { '&:active': { backgroundColor: themeColors.surfaceVariantPressed } },
          mapTypographyPresetToSx(typographyPresets.labelSmall),
        ].flat()}
      >
        <TextOverflow>{label}</TextOverflow>
      </Box>
    </Box>
  )
}
