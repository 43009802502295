import { pipeline } from '../function/pipeline'

type PromiseAllPropsT = <ObjectT extends Record<string, unknown>>(
  object: ObjectT,
) => Promise<{ [K in keyof ObjectT]: Awaited<ObjectT[K]> }>

export const promiseAllProps = ((object: Record<string, unknown>) =>
  pipeline(
    object,
    (x) => Object.entries(x),
    (x) => x.map(async (entry): Promise<[string, unknown]> => [entry[0], await entry[1]]),
    (x) => Promise.all(x),
    (x) => x.then(Object.fromEntries),
  )) as PromiseAllPropsT
