import { pipeline } from '../function/pipeline'

export const move = <ItemT>(array: ItemT[], fromIndex: number, toIndex: number) => {
  fromIndex = pipeline(
    fromIndex,
    (x) => Math.max(0, x),
    (x) => Math.min(array.length - 1, x),
  )

  toIndex = pipeline(
    toIndex,
    (x) => Math.max(0, x),
    (x) => Math.min(array.length - 1, x),
  )

  if (fromIndex === toIndex) {
    return array
  }

  const nextArray = array.slice()

  const [item] = nextArray.splice(fromIndex, 1)

  nextArray.splice(toIndex, 0, item)

  return nextArray
}
