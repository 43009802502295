import { Box, type BoxProps } from '@mui/material'

import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import type { ResourceFragmentT } from '@resnet/client-api/api'

export type VideoResourcePropsT = MergeT<BoxProps<'video'>, { resource: Pick<ResourceFragmentT, 'url'> }>

export const VideoResource = ({ resource, ...props }: VideoResourcePropsT) => {
  const src = resource.url

  return (
    <Box
      {...props}
      component="video"
      src={src}
    />
  )
}
