import type { SystemStyleObject } from '@mui/system'

import { themeColors } from '../constants/theme-colors'
import { toPx } from '../utils/to-px'

export const focusOutlineSx: SystemStyleObject = {
  outlineColor: themeColors.borderFocus,
  outlineOffset: toPx(-2),
  outlineStyle: 'solid',
  outlineWidth: toPx(2),
}
