import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import type { CustomFieldPayloadFormulaT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { validateTextRequired } from '@resnet/client-shared/shared/forms/validators/text-required'

import { TextField } from '@resnet/client-web/shared/form-dalaran/components/common/text-field'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type FormulaCustomFieldPayloadValueT = CustomFieldFragmentT

export type FormulaCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, FormulaCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const FormulaCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, FormulaCustomFieldPayloadValueT>,
>({
  form,
  name,
}: FormulaCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type FormulaPathT = FieldPathByValue<TFieldValues, CustomFieldPayloadFormulaT['value']>

  const formulaName = `${name}.payload.${CustomFieldTypeT.FormulaT}.value` as FormulaPathT

  const renderFormulaField = () => {
    return (
      <ObjectDetailsRow>
        <TextField
          form={form}
          label="Formula"
          name={formulaName}
          placeholder="Enter Formula"
          rules={{ validate: validateTextRequired }}
        />
      </ObjectDetailsRow>
    )
  }

  return <>{renderFormulaField()}</>
}
