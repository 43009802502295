const toWords = (string: string): string[] =>
  Array.from(
    string.matchAll(
      new RegExp(
        [
          // lowercase words
          '([a-z]+)',
          // capitalized words
          '([A-Z][a-z]+)',
          // uppercase words
          '(([A-Z](?![a-z]))+)',
          // digits
          '([\\d]+)',
        ].join('|'),
        'g',
      ),
    ),
    (match) => match[0],
  )

export const toKebabCase = (string: string): string => toWords(string).join('-').toLowerCase()
