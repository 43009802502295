import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import { systemColors } from '../constants/system-colors'
import type { GenericThemePropsT } from '../types/generic-theme-props'

export const theme = createConstrainer<GenericThemePropsT>()({
  colors: {
    actionsAccentDefault: systemColors.radiation70,
    actionsAccentHover: systemColors.radiation80,
    actionsAccentPressed: systemColors.radiation60,
    actionsDangerDefault: systemColors.critical60,
    actionsDangerHover: systemColors.critical70,
    actionsDangerPressed: systemColors.critical40,
    actionsDisabled: systemColors.blackA05,
    actionsLinkDefault: systemColors.moonBase40,
    actionsLinkHover: systemColors.moonBase50,
    actionsLinkPressed: systemColors.moonBase20,
    actionsOverAccentDefault: systemColors.whiteA90,
    actionsOverDangerDefault: systemColors.whiteA90,
    actionsOverDisabled: systemColors.blackA20,
    actionsOverPrimaryDefault: systemColors.white,
    actionsPrimaryDefault: systemColors.moonBase40,
    actionsPrimaryHover: systemColors.moonBase50,
    actionsPrimaryPressed: systemColors.moonBase20,
    background: systemColors.white,
    baseAccent: systemColors.radiation70,
    basePrimary: systemColors.moonBase40,
    borderBold: systemColors.neutral40,
    borderDefault: systemColors.neutral70,
    borderFaded: systemColors.neutral90,
    borderFeedbackCritical: systemColors.critical80,
    borderFeedbackInfo: systemColors.info70,
    borderFeedbackWarning: systemColors.warning60,
    borderFocus: systemColors.radiation70,
    feedbackCritical: systemColors.critical60,
    feedbackInfo: systemColors.info60,
    feedbackSuccess: systemColors.alienSkin20,
    feedbackWarning: systemColors.warning50,
    linearProgress: systemColors.moonBase40,
    linearProgressBar: systemColors.moonBase60,
    logoBackground: systemColors.moonBase40,
    logoOverBackground: systemColors.whiteA90,
    overBackgroundBold: systemColors.black,
    overBackgroundDefault: systemColors.blackA60,
    overBackgroundFaded: systemColors.blackA40,
    overBackgroundMuted: systemColors.blackA50,
    overSurfaceContrast: systemColors.neutral05,
    overSurfaceGamificationBronze: systemColors.warning70,
    overSurfaceGamificationGold: systemColors.radiation70,
    overSurfaceGamificationSilver: systemColors.neutral50,
    progressBarBackground: systemColors.moonBase40,
    progressBarOverBackground: systemColors.white,
    surfaceContrastDefault: systemColors.white,
    surfaceContrastHover: systemColors.neutral90,
    surfaceContrastPressed: systemColors.neutral80,
    surfaceFeedbackCriticalDefault: systemColors.critical99,
    surfaceFeedbackCriticalHover: systemColors.critical95,
    surfaceFeedbackCriticalPressed: systemColors.critical90,
    surfaceFeedbackInfoDefault: systemColors.info99,
    surfaceFeedbackInfoHover: systemColors.info95,
    surfaceFeedbackInfoPressed: systemColors.info90,
    surfaceFeedbackSuccessDefault: systemColors.alienSkin90,
    surfaceFeedbackSuccessHover: systemColors.alienSkin80,
    surfaceFeedbackSuccessPressed: systemColors.alienSkin60,
    surfaceFeedbackWarningDefault: systemColors.warning99,
    surfaceFeedbackWarningHover: systemColors.warning95,
    surfaceFeedbackWarningPressed: systemColors.warning90,
    surfaceGamificationBronze: systemColors.warning90,
    surfaceGamificationBronzeHover: systemColors.warning80,
    surfaceGamificationBronzePressed: systemColors.warning70,
    surfaceGamificationGold: systemColors.radiation90,
    surfaceGamificationGoldHover: systemColors.radiation80,
    surfaceGamificationGoldPressed: systemColors.radiation70,
    surfaceGamificationSilver: systemColors.neutral90,
    surfaceGamificationSilverHover: systemColors.neutral80,
    surfaceGamificationSilverPressed: systemColors.neutral70,
    surfaceNeutralDefault: systemColors.neutral95,
    surfaceNeutralHover: systemColors.neutral90,
    surfaceNeutralPressed: systemColors.neutral80,
    surfaceOverlayDefault: systemColors.blackA50,
    surfacePrimaryDefault: systemColors.moonBase99,
    surfacePrimaryHover: systemColors.moonBase95,
    surfacePrimaryPressed: systemColors.moonBase90,
    surfaceVariantDefault: systemColors.quantumA05,
    surfaceVariantHover: systemColors.quantumA10,
    surfaceVariantPressed: systemColors.quantumA20,
  },
})
