import { pickerColorPresets } from '@resnet/client-shared/shared/gdl/constants/picker-color-presets'

export const mapIdToColor = (id: string) => {
  const count = pickerColorPresets.length

  const index = id.split('').reduce((acc, char) => {
    return acc + char.charCodeAt(0)
  }, 0)

  return pickerColorPresets[index % count].value
}
