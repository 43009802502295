import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { foldElement } from '@resnet/client-common/react/utils/fold-element'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import ErrorSolidIcon from '@resnet/client-shared/assets/icons/error-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { feedbackUrl } from '@resnet/client-web/screens/root-screen/components/root-screen-layout-header/components/nav-bar-feedback-button'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export const ErrorPanelIcon = ({
  children,
}: {
  children: ForwardableT<React.SVGProps<SVGSVGElement>>
}): React.ReactElement => {
  const iconSize = 48

  return (
    <>
      {renderForwardable(children, {
        fill: themeColors.feedbackCritical,
        height: iconSize,
        width: iconSize,
      })}
    </>
  )
}

export const ErrorPanelTitle = ({ sx, children, ...props }: BoxProps): React.ReactElement => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.titleLarge),
        { color: themeColors.overBackgroundBold, textAlign: 'center' },
        sx ?? null,
      ].flat()}
    >
      {children}
    </Box>
  )
}

export const ErrorPanelDescription = ({ sx, children, ...props }: BoxProps): React.ReactElement => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.bodyMedium),
        { color: themeColors.overBackgroundDefault, textAlign: 'center' },
        sx ?? null,
      ].flat()}
    >
      {children}
    </Box>
  )
}

export const ErrorPanelLayout = ({
  sx,
  children,
  ...props
}: MergeT<
  BoxProps,
  {
    children?: {
      icon?: React.ReactNode
      title?: React.ReactNode
      description?: React.ReactNode
      button?: React.ReactNode
    }
  }
>): React.ReactElement => {
  const borderWidth = 1

  const renderIcon = (): React.ReactNode => {
    return foldElement(
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>{children?.icon}</Box>,
    )
  }

  const renderTitleAndDescription = () => {
    return foldElement(
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {children?.title}
        {children?.description}
      </Box>,
    )
  }

  const renderButton = () => {
    return foldElement(
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>{children?.button}</Box>,
    )
  }

  return (
    <Box
      {...props}
      sx={[
        {
          backgroundColor: themeColors.surfaceNeutralDefault,
          borderColor: themeColors.borderDefault,
          borderRadius: toPx(16),
          borderStyle: 'solid',
          borderWidth: toPx(borderWidth),
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: toPx(16 - borderWidth),
        },
        sx ?? null,
      ].flat()}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(32), my: 'auto' }}>
        {renderIcon()}
        {renderTitleAndDescription()}
        {renderButton()}
      </Box>
    </Box>
  )
}

export const ErrorPanel = (props: Omit<React.ComponentProps<typeof ErrorPanelLayout>, 'children'>) => {
  return (
    <ErrorPanelLayout {...props}>
      {{
        button: (
          <Button
            color="primary"
            href={feedbackUrl}
            size="sm"
            target="_blank"
            type="anchor"
            variant="contained"
          >
            Report
          </Button>
        ),
        description: <ErrorPanelDescription>Please, try again or report the problem</ErrorPanelDescription>,
        icon: (
          <ErrorPanelIcon>
            <ErrorSolidIcon />
          </ErrorPanelIcon>
        ),
        title: <ErrorPanelTitle>Something Went Wrong</ErrorPanelTitle>,
      }}
    </ErrorPanelLayout>
  )
}
