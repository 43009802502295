import type { Query, QueryClient, QueryKey } from '@resnet/client-api/vendors/react-query'

export const setQueryData = (queryClient: QueryClient) => {
  return <TQueryFnData, TError, TData, TQueryKey extends QueryKey>(
    query: Query<TQueryFnData, TError, TData, TQueryKey>,
  ) => {
    return (updater: (data: undefined | TQueryFnData) => undefined | TQueryFnData) => {
      queryClient.setQueryData(query.queryKey, updater)
    }
  }
}
