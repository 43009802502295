export const createGetNextPageParam =
  <DataT>(limit: number, getItemsCount: (data: DataT) => number) =>
  (lastPage: DataT, allPages: DataT[]) => {
    if (getItemsCount(lastPage) < limit) {
      return undefined
    }

    return {
      offset: allPages.length * limit,
    }
  }
