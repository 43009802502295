import type { BoxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'

import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import CircleSolidIcon from '@resnet/client-shared/assets/icons/circle-solid.svg'
import { mapPickerColorPresetToColor } from '@resnet/client-shared/shared/common/utils/map-picker-color-preset-to-color'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import { TextSkeleton } from '@resnet/client-web/shared/async/components/text-skeleton'
import { TextOverflow } from '@resnet/client-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'
import { Avatar, IconAvatar } from '../avatar'
import { DropdownIcon } from '../dropdown-icon'

export type DropdownInputPropsT = MergeAllT<
  [
    Omit<BoxProps<'button'>, 'placeholder' | 'value'>,
    {
      disabledTooltipTitle?: string
      hasError?: boolean
      icon?: ForwardableT<React.SVGProps<SVGSVGElement>>
      isOpened?: boolean
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    },
  ]
>

export const DropdownInput = forwardFunctionalComponentRef(
  (
    {
      children,
      disabled,
      disabledTooltipTitle,
      hasError,
      icon,
      isOpened,
      onClick,
      sx = null,
      ...props
    }: DropdownInputPropsT,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const borderRadius = 8

    const borderWidth = 1

    const renderIcon = () => {
      if (!icon) {
        return null
      }

      const iconSize = 20

      return renderForwardable(icon, { fill: themeColors.overBackgroundFaded, height: iconSize, width: iconSize })
    }

    const renderContent = () => {
      return <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 999, minWidth: 0 }}>{children}</Box>
    }

    const renderDropdownIcon = () => {
      const iconSize = 20

      return (
        <DropdownIcon
          fill={themeColors.overBackgroundFaded}
          height={iconSize}
          isOpened={isOpened}
          width={iconSize}
        />
      )
    }

    return (
      <Tooltip title={disabled ? disabledTooltipTitle : ''}>
        <Box
          {...props}
          component="button"
          disabled={disabled}
          ref={ref}
          sx={[
            { all: 'unset' },
            {
              alignItems: 'center',
              backgroundColor: themeColors.surfaceNeutralDefault,
              borderColor: themeColors.borderFaded,
              borderRadius: toPx(borderRadius),
              borderStyle: 'solid',
              borderWidth: toPx(borderWidth),
              cursor: 'pointer',
              display: 'flex',
              gap: toPx(8),
              position: 'relative',
              px: toPx(16 - borderWidth),
              py: toPx(8 - borderWidth),
              transition: 'background-color 200ms ease',
            },
            !disabled
              ? null
              : {
                  cursor: 'not-allowed',
                  userSelect: 'none',
                },
            !disabled
              ? null
              : {
                  opacity: 0.64,
                },
            disabled
              ? null
              : {
                  '&:hover': {
                    backgroundColor: themeColors.surfaceNeutralHover,
                  },
                },

            disabled
              ? null
              : {
                  '&:active': {
                    backgroundColor: themeColors.surfaceNeutralPressed,
                  },
                },
            {
              '&:focus-visible': {
                outlineColor: themeColors.borderFocus,
                outlineStyle: 'solid',
                outlineWidth: toPx(2),
              },
            },
            !hasError ? {} : { borderColor: themeColors.feedbackCritical },
            sx,
          ].flat()}
          type="button"
          onClick={onClick}
        >
          {renderIcon()}
          {renderContent()}
          {renderDropdownIcon()}
        </Box>
      </Tooltip>
    )
  },
)

export const DropdownInputMedia = ({ sx, media, ...props }: React.ComponentProps<typeof Box> & { media: MediaT }) => {
  const renderMedia = () => {
    if (!media || !media.type) {
      return null
    }

    switch (media.type) {
      case 'icon': {
        const iconSize = 20

        return renderForwardable(media.icon, {
          fill: themeColors.overBackgroundDefault,
          height: iconSize,
          width: iconSize,
        })
      }
      case 'iconAvatar': {
        return (
          <IconAvatar
            size="md"
            {...media}
          />
        )
      }
      case 'avatar': {
        return (
          <Avatar
            size="sm"
            {...media}
          />
        )
      }
      case 'color': {
        const colorSize = 20

        return (
          <CircleSolidIcon
            fill={mapPickerColorPresetToColor(media.color)}
            height={colorSize}
            width={colorSize}
          />
        )
      }
    }
  }

  return (
    <Box
      {...props}
      sx={sx}
    >
      {renderMedia()}
    </Box>
  )
}

export const DropdownInputText = ({ sx, children, ...props }: React.ComponentProps<typeof Box>) => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.bodyMedium),
        {
          color: themeColors.overBackgroundDefault,
          display: 'flex',
          minWidth: 0,
        },
        sx ?? null,
      ].flat()}
    >
      <TextOverflow>{children}</TextOverflow>
    </Box>
  )
}

export const DropdownInputTextSkeleton = (
  props: Omit<React.ComponentProps<typeof TextSkeleton>, 'typographyPreset'>,
) => {
  return (
    <TextSkeleton
      {...props}
      contentSx={{ width: '70%' }}
      sx={{ flexGrow: 1 }}
      typographyPreset={typographyPresets.bodyMedium}
    />
  )
}

export const DropdownInputPlaceholder = ({
  sx,
  children,
  ...props
}: React.ComponentProps<typeof DropdownInputText>) => {
  return (
    <DropdownInputText
      {...props}
      sx={[
        {
          color: themeColors.overBackgroundFaded,
        },
        sx ?? null,
      ].flat()}
    >
      {children}
    </DropdownInputText>
  )
}
