import { asserted } from '@resnet/client-common/typescript/utils/assert'

export const checkNonUndefined = <ValueT extends undefined | unknown>(
  value: ValueT,
): value is Exclude<ValueT, undefined> => value !== undefined

export const assertedNonUndefined = <ValueT>(value: ValueT) =>
  asserted(value, checkNonUndefined, 'should be non undefined')

export const toNonUndefined = <ValueT extends undefined | unknown>(value: ValueT) => {
  return value as Exclude<ValueT, undefined>
}

export const checkNonNull = <ValueT extends null | unknown>(value: ValueT): value is Exclude<ValueT, null> =>
  value !== null

export const assertedNonNull = <ValueT>(value: ValueT) => asserted(value, checkNonNull, 'should be non null')

export const toNonNull = <ValueT extends null | unknown>(value: ValueT) => {
  return value as Exclude<ValueT, null>
}

export const checkNonNullable = <ValueT extends undefined | null | unknown>(
  value: ValueT,
): value is Exclude<ValueT, undefined | null> => checkNonUndefined(value) && checkNonNull(value)

export const assertedNonNullable = <ValueT>(value: ValueT) =>
  asserted(value, checkNonNullable, 'should be non nullable')

export const toNonNullable = <ValueT extends undefined | null | unknown>(value: ValueT) => {
  return value as Exclude<ValueT, undefined | null>
}

export const checkIsNullable = <ValueT extends undefined | null | unknown>(
  value: ValueT,
): value is Extract<ValueT, undefined | null> => !checkNonNullable(value)
