export const TIME_COEFICIENT = 60

export const DAYS_IN_YEAR = 365

export const MONTH_IN_YEAR = 12

export const DAYS_IN_MONTH = 30

export const HOURS_IN_DAY = 24

export const DAYS_IN_WEEK = 7

export const MINUTES_IN_HOUR = TIME_COEFICIENT

export const SECONDS_IN_MINUTE = TIME_COEFICIENT

export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR

export const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY

export const SECONDS_IN_MONTH = SECONDS_IN_DAY * DAYS_IN_MONTH

export const SECONDS_IN_YEAR = SECONDS_IN_DAY * DAYS_IN_YEAR

export const MILLISECONDS_IN_SECOND = 1000

export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE

export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * MINUTES_IN_HOUR

export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * HOURS_IN_DAY

export const MILLISECONDS_IN_WEEK = MILLISECONDS_IN_DAY * DAYS_IN_WEEK

export const MILLISECONDS_IN_MONTH = MILLISECONDS_IN_DAY * DAYS_IN_MONTH

export const MILLISECONDS_IN_YEAR = DAYS_IN_YEAR * MILLISECONDS_IN_DAY

export const MONTH_NAME_BY_INDEX = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
