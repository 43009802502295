import { useMemo } from 'react'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import {
  useSearchUsersQuery,
  useSearchEntitiesQuery,
  useSearchGroupsQuery,
  SortDirectionT,
} from '@resnet/client-api/api'

import type { GroupT } from '../types'

export const useMentionGroups = ({ search, enabled }: { search: string; enabled: boolean }): typeof result => {
  const usersQuery = useSearchUsersQuery(
    { search },
    { enabled, keepPreviousData: enabled, select: (data) => assertedNonNullable(data.searchUsers?.items) },
  )

  const usersGroup = useMemo<GroupT>(() => {
    return {
      id: 'users',
      items: !usersQuery.isSuccess ? [] : usersQuery.data,
      label: 'Users',
    }
  }, [usersQuery.isSuccess, usersQuery.data])

  const groupsQuery = useSearchGroupsQuery(
    { search },
    {
      enabled,
      keepPreviousData: enabled,
      select: (data) => assertedNonNullable(data.searchGroups?.items),
    },
  )

  const groupsGroup = useMemo<GroupT>(() => {
    return {
      id: 'groups',
      items: !groupsQuery.isSuccess ? [] : groupsQuery.data,
      label: 'Groups',
    }
  }, [groupsQuery.data, groupsQuery.isSuccess])

  const routesQuery = useSearchEntitiesQuery(
    { filter: { entityTypeId: { eq: 'Route' } }, search, sort: { name: SortDirectionT.AscT } },
    { enabled, keepPreviousData: enabled, select: (data) => assertedNonNullable(data.searchEntities?.items) },
  )

  const routesGroup = useMemo<GroupT>(() => {
    return {
      id: 'routes',
      items: !routesQuery.isSuccess ? [] : routesQuery.data,
      label: 'Routes',
    }
  }, [routesQuery.isSuccess, routesQuery.data])

  const wellsQuery = useSearchEntitiesQuery(
    { filter: { entityTypeId: { eq: 'Well' } }, search, sort: { name: SortDirectionT.AscT } },
    { enabled, keepPreviousData: enabled, select: (data) => assertedNonNullable(data.searchEntities?.items) },
  )

  const wellsGroup = useMemo<GroupT>(() => {
    return {
      id: 'wells',
      items: !wellsQuery.isSuccess ? [] : wellsQuery.data,
      label: 'Wells',
    }
  }, [wellsQuery.isSuccess, wellsQuery.data])

  const batteriesQuery = useSearchEntitiesQuery(
    { filter: { entityTypeId: { eq: 'Battery' } }, search, sort: { name: SortDirectionT.AscT } },
    { enabled, keepPreviousData: enabled, select: (data) => assertedNonNullable(data.searchEntities?.items) },
  )

  const batteriesGroup = useMemo<GroupT>(() => {
    return {
      id: 'batteries',
      items: !batteriesQuery.isSuccess ? [] : batteriesQuery.data,
      label: 'Batteries',
    }
  }, [batteriesQuery.isSuccess, batteriesQuery.data])

  const sitesQuery = useSearchEntitiesQuery(
    { filter: { entityTypeId: { eq: 'Site' } }, search, sort: { name: SortDirectionT.AscT } },
    { enabled, keepPreviousData: enabled, select: (data) => assertedNonNullable(data.searchEntities?.items) },
  )

  const sitesGroup = useMemo<GroupT>(() => {
    return {
      id: 'sites',
      items: !sitesQuery.isSuccess ? [] : sitesQuery.data,
      label: 'Sites',
    }
  }, [sitesQuery.isSuccess, sitesQuery.data])

  const mentionGroups = useMemo(
    () => [usersGroup, groupsGroup, routesGroup, wellsGroup, batteriesGroup, sitesGroup],
    [usersGroup, groupsGroup, routesGroup, wellsGroup, batteriesGroup, sitesGroup],
  )

  const result = {
    mentionGroups,
  }

  return result
}
