import type { EmitterT } from '@resnet/client-common/common/utils/emitter/create-emitter'
import { createEmitter } from '@resnet/client-common/common/utils/emitter/create-emitter'
import { assertedNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useCallbackRefEffect } from '@resnet/client-common/react/hooks/use-callback-ref-effect'

import type { StateModelT } from '../types/state-model'

const emitters = new WeakMap<IntersectionObserver, EmitterT<IntersectionObserverEntry>>()

const getEmitter = (observer: IntersectionObserver) => {
  if (emitters.has(observer)) {
    return assertedNonUndefined(emitters.get(observer))
  }

  const emitter = createEmitter<IntersectionObserverEntry>()

  emitters.set(observer, emitter)

  return emitter
}

const observers = new WeakMap<IntersectionObserverInit, IntersectionObserver>()

const observerCallback: IntersectionObserverCallback = (entries, observer) => {
  const emitter = getEmitter(observer)

  for (const entry of entries) {
    emitter.dispatch(entry)
  }
}

const getObserver = (options: IntersectionObserverInit) => {
  if (observers.has(options)) {
    return assertedNonUndefined(observers.get(options))
  }

  const observer = new IntersectionObserver(observerCallback, options)

  observers.set(options, observer)

  return observer
}

type IntersectionObserverListenerT = (entry: IntersectionObserverEntry, observer: IntersectionObserver) => void

export const subscribeIntersectionObserver = (
  element: HTMLElement,
  listener: IntersectionObserverListenerT,
  options: IntersectionObserverInit,
) => {
  const observer = getObserver(options)

  const emitter = getEmitter(observer)

  const unsubscribeEmitter = emitter.subscribe((entry) => {
    if (entry.target !== element) {
      return
    }

    listener(entry, observer)
  })

  const unsubscribeObserver = (() => {
    observer.observe(element)

    return () => {
      observer.unobserve(element)
    }
  })()

  return () => {
    unsubscribeEmitter()
    unsubscribeObserver()
  }
}

const defaultOptions: IntersectionObserverInit = {}

export const useIntersectionObserver = (
  listener: IntersectionObserverListenerT,
  options: IntersectionObserverInit = defaultOptions,
) => {
  const elementRef = useCallbackRefEffect<HTMLElement>(
    (element) => {
      if (!element) {
        return
      }

      return subscribeIntersectionObserver(element, listener, options)
    },
    [listener, options],
  )

  return elementRef
}

export const useSubscribeIsIntersecting = (
  listener: (isIntersecting: boolean) => void,
  options?: IntersectionObserverInit,
) => {
  const elementRef = useIntersectionObserver((entry) => listener(entry.isIntersecting), options)

  return elementRef
}

export const useIsIntersecting = (
  [isIntersecting, setIsIntersecting]: StateModelT<boolean>,
  options?: IntersectionObserverInit,
) => {
  const elementRef = useSubscribeIsIntersecting(setIsIntersecting, options)

  return [elementRef, isIntersecting] as const
}
