import { format } from 'date-fns'

export const fullDateFormatter = (() => {
  if (window.Intl === undefined) {
    return {
      format: (date: Date) => format(date, 'MM/dd/YYYY'),
    }
  }

  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
})()
