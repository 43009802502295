import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import type { TypographyPresetT } from '@resnet/client-shared/shared/gdl/types/typography-preset'

import { themeColors } from '../../constants/theme-colors'
import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'

export const sizeOptions = [{ gap: 16, id: 'md' as const, typographyPreset: typographyPresets.bodyMedium }]

export const sizeOptionsById = propToKey('id', sizeOptions)

const getLabelDefaultSx = ({ gap }: { gap: number }) => {
  return { alignItems: 'center', display: 'flex', gap: toPx(gap) }
}

const getLabelClickableSx = () => {
  return { cursor: 'pointer', position: 'relative' }
}

const getLabelClickableDisabledSx = ({ disabled }: { disabled?: boolean }) => {
  if (!disabled) {
    return null
  }

  return { cursor: 'not-allowed' }
}

const getLabelDescriptionSx = ({ typographyPreset }: { typographyPreset: TypographyPresetT }) => [
  mapTypographyPresetToSx(typographyPreset),
  { color: themeColors.overBackgroundDefault, flexGrow: 1, flexShrink: 999 },
]

const getLabelDescriptionMarkedAsDeletedSx = ({
  isMarkedAsDeleted,
}: {
  isMarkedAsDeleted?: undefined | null | boolean
}) => {
  if (isMarkedAsDeleted) {
    return {
      textDecoration: 'line-through',
    }
  }

  return null
}

export type LabelPropsT = MergeAllT<
  [
    BoxProps,
    {
      description?: React.ReactNode
      descriptionPosition?: 'start' | 'end'
      disabled?: boolean
      size: (typeof sizeOptions)[number]['id']
    },
  ]
>

export const Label = ({
  children,
  description,
  descriptionPosition = 'start',
  disabled,
  size,
  sx = null,
  ...props
}: LabelPropsT) => {
  const { gap, typographyPreset } = sizeOptionsById[size]

  const renderDescription = ({ position }: { position: 'start' | 'end' }) => {
    if (!description || descriptionPosition !== position) {
      return null
    }

    return <Box sx={getLabelDescriptionSx({ typographyPreset })}>{description}</Box>
  }

  return (
    <Box
      {...props}
      component="label"
      sx={[getLabelDefaultSx({ gap }), getLabelClickableSx(), getLabelClickableDisabledSx({ disabled }), sx].flat()}
    >
      {renderDescription({ position: 'start' })}
      {children}
      {renderDescription({ position: 'end' })}
    </Box>
  )
}

export type LabelPreviewPropsT = MergeAllT<
  [
    BoxProps,
    {
      description?: React.ReactNode
      descriptionPosition?: 'start' | 'end'
      isMarkedAsDeleted?: undefined | null | boolean
      size: (typeof sizeOptions)[number]['id']
    },
  ]
>

export const LabelPreview = ({
  children,
  description,
  descriptionPosition = 'start',
  isMarkedAsDeleted,
  size,
  sx = null,
  ...props
}: LabelPreviewPropsT) => {
  const { gap, typographyPreset } = sizeOptionsById[size]

  const renderDescription = ({ position }: { position: 'start' | 'end' }) => {
    if (!description || descriptionPosition !== position) {
      return null
    }

    return (
      <Box
        sx={[
          getLabelDescriptionSx({ typographyPreset }),
          getLabelDescriptionMarkedAsDeletedSx({ isMarkedAsDeleted }),
        ].flat()}
      >
        {description}
      </Box>
    )
  }

  return (
    <Box
      {...props}
      sx={[getLabelDefaultSx({ gap }), sx].flat()}
    >
      {renderDescription({ position: 'start' })}
      {children}
      {renderDescription({ position: 'end' })}
    </Box>
  )
}
