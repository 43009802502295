import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { DiscriminatorCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/discriminator/custom-field-value'

import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'

import type { CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createColumn: CustomFieldT['createColumn'] = ({ field, fieldValuesKey }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const discriminatorPayload = payload[CustomFieldTypeT.DiscriminatorT]

  assert(discriminatorPayload, checkNonNullable)

  const getValue = (row: Record<string, unknown>) =>
    getFieldValue<DiscriminatorCustomFieldValueT>(field, fieldValuesKey, row)

  return {
    field: mapCustomFieldToFullKey(field, fieldValuesKey),
    flex: 1,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      const option = discriminatorPayload.options.find((item) => item.key === groupingKey)

      if (option === undefined) {
        return <RegularCell>-</RegularCell>
      }

      return <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
    },
    groupable: true,
    groupingValueGetter: (params) => {
      const value = getValue(params.row)

      if (value === undefined) {
        return undefined
      }

      return value
    },
    headerName: field.name,
    minWidth: 150,
    renderCell: (params) => {
      const value = params.value as ReturnType<typeof getValue>

      if (value === undefined) {
        return <RegularCell>-</RegularCell>
      }

      const option = discriminatorPayload.options.find((item) => item.key === value)

      if (option === undefined) {
        return <RegularCell>-</RegularCell>
      }

      return <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
    },
    sortable: true,
    valueGetter: (params) => {
      const value = getValue(params.row)

      return value
    },
  }
}
