import { Box } from '@mui/material'
import { useState } from 'react'

import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { ModalContent } from '@resnet/client-web/shared/gdl/components/modal'
import { Field } from '@resnet/client-web/shared/gdl/legacy-components/field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export const DeleteModalContent = ({
  targetName,
  onCancel,
  onDelete,
  children,
  isLoading,
}: {
  targetName: string
  onCancel?: () => void
  onDelete?: () => void
  isLoading?: boolean
  children: {
    header: {
      title: React.ReactNode
    }
    description?: React.ReactNode
    cancelButtonLabel?: React.ReactNode
    deleteButtonLabel?: React.ReactNode
  }
}): React.ReactElement => {
  const [value, setValue] = useState('')

  const isDeleteDisabled = value.toLowerCase() !== targetName.toLowerCase()

  return (
    <ModalContent sx={{ maxWidth: toPx(512), width: '100%' }}>
      {{
        content: (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {children.description}
            <Field
              value={value}
              onChange={(event) => {
                setValue(event.currentTarget.value)
              }}
            />
          </Box>
        ),
        footer: (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Button
              color="primary"
              size="md"
              variant="contained"
              onClick={onCancel}
            >
              {children.cancelButtonLabel ?? 'Cancel'}
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="danger"
              disabled={isDeleteDisabled || isLoading}
              isLoading={isLoading}
              size="md"
              variant="text"
              onClick={onDelete}
            >
              {children.deleteButtonLabel ?? 'Delete'}
            </Button>
          </Box>
        ),
        header: {
          title: children.header.title,
        },
      }}
    </ModalContent>
  )
}
