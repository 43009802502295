import { useMemo } from 'react'
import type { PathValue } from 'react-hook-form'
import { type FieldPathByValue, type FieldValues, type UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { DropdownCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/dropdown/custom-field-value'
import { validateRequired } from '@resnet/client-shared/shared/forms/validators/any-required'

import { SimpleStaticOptionsDropdownField } from '@resnet/client-web/shared/form-dalaran/components/common/simple-static-options-dropdown-field'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

import { mapOptionToOptionIdFromKey } from '../../utils/map-option-to-option-id-from-key'

export type DropdownCustomFieldUserFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | DropdownCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const DropdownCustomFieldUserFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | DropdownCustomFieldValueT>,
>({
  field,
  form,
  name,
}: DropdownCustomFieldUserFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const dropdownPayload = payload[CustomFieldTypeT.DropdownT]

  assert(dropdownPayload, checkNonNullable)

  const { required, hasColor, options, defaultValue } = dropdownPayload

  const rules = useMemo(() => {
    return {
      validate: !required ? undefined : validateRequired,
    }
  }, [required])

  const value = form.watch(name, defaultValue as PathValue<TFieldValues, TPath>)

  const availableOptions = useMemo(
    () => options.filter((option) => !option.isMarkedAsDeleted || option.key === value),
    [options, value],
  )

  const optionsWithIdFromKey = mapOptionToOptionIdFromKey({ hasColor, options: availableOptions })

  return (
    <ObjectDetailsRow>
      <SimpleStaticOptionsDropdownField
        defaultValue={defaultValue as PathValue<TFieldValues, TPath>}
        form={form}
        label={field.name}
        name={name}
        options={optionsWithIdFromKey}
        rules={rules}
      />
    </ObjectDetailsRow>
  )
}
