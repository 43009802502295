import type { OmitDistributiveT } from '@resnet/client-common/common/types/common'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import SaveSolidIcon from '@resnet/client-shared/assets/icons/save-solid.svg'

import type { ButtonRefT } from '@resnet/client-web/shared/gdl/components/button'
import { Button, type ButtonPropsT } from '@resnet/client-web/shared/gdl/components/button'

export type SaveButtonPropsT = OmitDistributiveT<ButtonPropsT, 'color' | 'variant' | 'icon' | 'endIcon' | 'children'>

export const SaveButton = forwardFunctionalComponentRef((props: SaveButtonPropsT, ref: ButtonRefT) => {
  return (
    <Button
      {...props}
      color="primary"
      icon={<SaveSolidIcon />}
      ref={ref}
      variant="contained"
    >
      Save
    </Button>
  )
})
