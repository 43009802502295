import { checkSetHas } from '@resnet/client-common/common/utils/set/check-set-has'

import type { mapMimetypeToType } from './map-mimetype-to-type'

export const mediaTypes = ['image' as const, 'video' as const]

export const mediaTypesSet = new Set(mediaTypes)

export const checkIsMediaType = (type: ReturnType<typeof mapMimetypeToType>): type is (typeof mediaTypes)[number] =>
  checkSetHas(mediaTypesSet, type)
