import { Box } from '@mui/material'
import { useState } from 'react'

import { useHeightModel } from '@resnet/client-common/react/hooks/use-rect'
import type { ToggleT } from '@resnet/client-common/react/hooks/use-toggle'
import { useDerivedToggle } from '@resnet/client-common/react/hooks/use-toggle'
import type { StateModelT } from '@resnet/client-common/react/types/state-model'

export const Collapse = ({
  children: { content, controls },
  height,
  contentHeightModel,
  sx,
}: Pick<React.ComponentProps<typeof Box>, 'sx'> & {
  height: number
  contentHeightModel?: StateModelT<null | number>
  children: {
    content: (props: { contentRef: React.RefCallback<HTMLElement> }) => React.ReactNode
    controls: (props: { isFitted: boolean; isCollapsed: boolean; onToggle: ToggleT['onToggle'] }) => React.ReactNode
  }
}): React.ReactElement => {
  const contentHeightFallbackModel = useState(null)

  const [contentRef, contentHeight] = useHeightModel(contentHeightModel ?? contentHeightFallbackModel)

  const isFitted = contentHeight ? contentHeight <= height : true

  const isCollapsedToggle = useDerivedToggle(!isFitted)

  return (
    <Box sx={sx}>
      <Box
        sx={{
          height: isCollapsedToggle.value ? `${height}px` : 'auto',
          maxHeight: !isFitted ? 'none' : `${height}px`,
          overflow: 'hidden',
        }}
      >
        {content({ contentRef })}
      </Box>
      {controls({ isCollapsed: isCollapsedToggle.value, isFitted, onToggle: isCollapsedToggle.onToggle })}
    </Box>
  )
}
