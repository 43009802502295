import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { IssueTypeT } from '@resnet/client-api/api'

import { issueTypesById } from '@resnet/client-shared/shared/issues/constants/issue-type-props'

export type IssueTypeIconPropsT = MergeAllT<
  [
    React.SVGProps<SVGSVGElement>,
    {
      type: IssueTypeT
    },
  ]
>

export const IssueTypeIcon = ({ type, ...props }: IssueTypeIconPropsT) => {
  const { icon, iconColor } = issueTypesById[type]

  return renderForwardable(icon, { ...props, fill: iconColor })
}
