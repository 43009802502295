import produce from 'immer'

import type { GetCredentialQueryT, InputUpdateCredentialT, ListCredentialsQueryT } from '@resnet/client-api/api'
import {
  EntityTypeIdsT,
  useArchiveCredentialMutation,
  useCreateCredentialMutation,
  useDeleteCredentialMutation,
  useGetCredentialQuery,
  useInfiniteListCredentialsQuery,
  useListCredentialsQuery,
  useUnarchiveCredentialMutation,
  useUpdateCredentialMutation,
  useUpdateEntityTypeMutation,
  useUpdateRelationsMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const getCredentialQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findCredentialQueries = () => {
    return findQueries(queryClient, useGetCredentialQuery)
  }

  const findCredentialQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetCredentialQuery, (data) => data.getCredential, { id })

  const updateCredential = (data: undefined | GetCredentialQueryT, { diff }: { diff: InputUpdateCredentialT }) => {
    if (!data) {
      return
    }

    const item = data.getCredential

    Object.assign(item, diff)
  }

  const deleteCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCredentialMutation, {
      onSuccess: (data, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCredentialMutation, {
      onError: (error, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findCredentialQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateCredential(data, { diff }))),
        )
      },
      onSuccess: (data, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveCredentialMutation, {
      onSuccess: (data, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveCredentialMutation, {
      onSuccess: (data, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findCredentialQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.CredentialT) {
          return
        }

        findCredentialQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    deleteCredentialHandlers(),
    updateCredentialHandlers(),
    archiveCredentialHandlers(),
    unarchiveCredentialHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const listCredentialsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findCredentialsQueries = () => findQueries(queryClient, useListCredentialsQuery)

  const findInfiniteCredentialsQueries = () => findInfiniteQueries(queryClient, useInfiniteListCredentialsQuery)

  const updateCredential = (
    data: undefined | ListCredentialsQueryT,
    { id, diff }: { id: string; diff: InputUpdateCredentialT },
  ) => {
    if (!data) {
      return
    }

    const items = data.listCredentials.items

    const item = items.find((item) => item.id === id)

    if (!item) {
      return
    }

    Object.assign(item, diff)
  }

  const deleteCredential = (data: undefined | ListCredentialsQueryT, { id }: { id: string }) => {
    if (!data) {
      return
    }

    const items = data.listCredentials.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCredentialMutation, {
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCredentialMutation, {
      onError: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => deleteCredential(data, { id }))),
        )
        findInfiniteCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => deleteCredential(page, { id }))),
          ),
        )
      },
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCredentialMutation, {
      onError: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateCredential(data, { diff, id }))),
        )
        findInfiniteCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => updateCredential(page, { diff, id }))),
          ),
        )
      },
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveCredentialMutation, {
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveCredentialMutation, {
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.CredentialT) {
          return
        }

        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createCredentialHandlers(),
    deleteCredentialHandlers(),
    updateCredentialHandlers(),
    archiveCredentialHandlers(),
    unarchiveCredentialHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}
