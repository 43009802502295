export const updateAt = <InputT extends unknown[], IndexT extends number>(
  input: InputT,
  index: IndexT,
  updater: (inputItem: InputT[IndexT]) => InputT[IndexT],
): InputT => {
  if (index < 0 || index >= input.length) {
    return input
  }

  const inputItem = input[index]

  const outputItem = updater(inputItem)

  if (inputItem === outputItem) {
    return input
  }

  const output = input.slice() as InputT

  output[index] = outputItem

  return output
}
