import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { OptionGroupCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/option-group/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type {
  OptionGroupFormCustomFieldValueT,
  OptionGroupMultipleFormCustomFieldValueT,
  OptionGroupSingleFormCustomFieldValueT,
} from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  OptionGroupCustomFieldValueT,
  OptionGroupFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const optionGroupPayload = payload[CustomFieldTypeT.OptionGroupT]

  assert(optionGroupPayload, checkNonNullable)

  const { multiple } = optionGroupPayload

  if (multiple) {
    const defaultValue: OptionGroupMultipleFormCustomFieldValueT = []

    return defaultValue
  }

  const defaultValue: OptionGroupSingleFormCustomFieldValueT = null

  return defaultValue
}
