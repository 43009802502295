import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { IssueResolutionT } from '@resnet/client-api/api'

export const issueResolutionOptions = [
  {
    feedbackLevel: 'feedbackWarning' as const,
    id: IssueResolutionT.PendingT as const,
    name: 'Needs Approval',
  },
  {
    feedbackLevel: 'feedbackSuccess' as const,
    id: IssueResolutionT.ApprovedT as const,
    name: 'Approved',
  },
  {
    feedbackLevel: 'feedbackCritical' as const,
    id: IssueResolutionT.RejectedT as const,
    name: 'Rejected',
  },
]

export const issueResolutionOptionsById = propToKey('id', issueResolutionOptions)
