import type { BoxProps, SxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { focusOutlineSx } from '@resnet/client-web/shared/gdl/sx-presets/focus-outline'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

const clickableOpacitySx = (): SxProps => {
  return [
    {
      all: 'unset',
    },
    {
      borderRadius: toPx(4),
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      transition: 'opacity 200ms ease',
    },
    {
      '&:hover': {
        opacity: 0.8,
      },
    },
    {
      '&:active': {
        opacity: 0.6,
      },
    },
    {
      '&:focus-visible': focusOutlineSx,
    },
  ]
}

export type ClickableOpacityLinkPropsT = MergeAllT<
  [
    Omit<BoxProps<'a'>, 'ref'>,
    {
      to: LinkProps['to']
    },
  ]
>

export type ClickableOpacityLinkRefT = React.ForwardedRef<HTMLAnchorElement>

export const ClickableOpacityLink = forwardFunctionalComponentRef(
  ({ sx = null, ...props }: ClickableOpacityLinkPropsT, ref: ClickableOpacityLinkRefT) => {
    return (
      <Box
        {...props}
        component={Link}
        ref={ref}
        sx={[clickableOpacitySx(), sx].flat()}
      />
    )
  },
)

export type ClickableOpacityButtonPropsT = MergeAllT<
  [
    Omit<BoxProps<'button'>, 'ref'>,
    {
      disabled?: boolean
      onClick?: (event: React.MouseEvent) => void
    },
  ]
>

export type ClickableOpacityButtonRefT = React.ForwardedRef<HTMLButtonElement>

export const ClickableOpacityButton = forwardFunctionalComponentRef(
  ({ type = 'button', sx = null, ...props }: ClickableOpacityButtonPropsT, ref: ClickableOpacityButtonRefT) => {
    return (
      <Box
        {...props}
        component="button"
        ref={ref}
        sx={[clickableOpacitySx(), sx].flat()}
        type={type}
      />
    )
  },
)

export type ClickableOpacityPropsT =
  | MergeAllT<[ClickableOpacityLinkPropsT, { type: 'link' }]>
  | MergeAllT<[ClickableOpacityButtonPropsT, { type?: 'button' }]>

export type ClickableOpacityRefT = ClickableOpacityLinkRefT | ClickableOpacityButtonRefT

export const ClickableOpacity = forwardFunctionalComponentRef(
  (props: ClickableOpacityPropsT, ref: ClickableOpacityRefT) => {
    switch (props.type) {
      case 'link': {
        const { type: _type, ...linkProps } = props

        return (
          <ClickableOpacityLink
            {...linkProps}
            ref={ref as ClickableOpacityLinkRefT}
          />
        )
      }
      default: {
        const buttonProps = props

        return (
          <ClickableOpacityButton
            {...buttonProps}
            ref={ref as ClickableOpacityButtonRefT}
          />
        )
      }
    }
  },
)
