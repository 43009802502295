import { Box, useEventCallback } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import type { Descendant } from 'slate'
import { Editable, Slate } from 'slate-react'

import { pullById } from '@resnet/client-common/common/utils/array/pull-by-id'

import type { ResourceFragmentT } from '@resnet/client-api/api'

import BoldSolidIcon from '@resnet/client-shared/assets/icons/bold-solid.svg'
import CircleXmarkSolidIcon from '@resnet/client-shared/assets/icons/circle-xmark-solid.svg'
import DeleteSolidIcon from '@resnet/client-shared/assets/icons/delete-solid.svg'
import ItalicSolidIcon from '@resnet/client-shared/assets/icons/italic-solid.svg'
import ListOlSolidIcon from '@resnet/client-shared/assets/icons/list-ol-solid.svg'
import ListUlSolidIcon from '@resnet/client-shared/assets/icons/list-ul-solid.svg'
import PaperClipSolidIcon from '@resnet/client-shared/assets/icons/paper-clip-solid.svg'
import PaperPlaneSolidIcon from '@resnet/client-shared/assets/icons/paper-plane-solid.svg'
import UnderlineSolidIcon from '@resnet/client-shared/assets/icons/underline-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { Element, Leaf } from '@resnet/client-web/components/slate/blocks'
import { FormatBlockButtonContainer } from '@resnet/client-web/components/slate/format-block-button-container'
import { FormatButtonContainer } from '@resnet/client-web/components/slate/format-button-container'
import { useEditor } from '@resnet/client-web/components/slate/hooks/use-editor'
import { useMentions } from '@resnet/client-web/components/slate/hooks/use-mentions'
import { useRichTextHotKeys } from '@resnet/client-web/components/slate/hooks/use-rich-text-hotkeys'
import { useSendHotkeyEventHandler } from '@resnet/client-web/components/slate/hooks/use-send-hotkey-event-handler'
import { checkIsEmptyText } from '@resnet/client-web/components/slate/utils/common'
import { deserialize } from '@resnet/client-web/components/slate/utils/deserializer'
import { serializeToHTML } from '@resnet/client-web/components/slate/utils/serializer'
import { FileInput } from '@resnet/client-web/shared/files/components/file-input'
import { useFileInputChangeHandler } from '@resnet/client-web/shared/files/hooks/use-file-input-change-handler'
import type { DraftResourceT } from '@resnet/client-web/shared/files/types/draft-resource'
import type { ResourceT } from '@resnet/client-web/shared/files/types/resource'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { focusOutlineSx } from '@resnet/client-web/shared/gdl/sx-presets/focus-outline'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { AttachmentPreviewModalContainer } from '@resnet/client-web/shared/resources/components/attachment-preview-modal'
import { ResourceThumbnail } from '@resnet/client-web/shared/resources/components/resource-thumbnail'

const emptyArray: never[] = []

const editorNestedElementsSx = {
  '& ol': {
    listStyle: 'revert',
    margin: 'revert',
    padding: 'revert',
  },
  '& p': {
    margin: '0',
  },
  '& ul': {
    listStyle: 'revert',
    margin: 'revert',
    padding: 'revert',
  },
}

const renderElement = (props: React.ComponentProps<typeof Element>): React.ReactElement => <Element {...props} />

const renderLeaf = (props: React.ComponentProps<typeof Leaf>): React.ReactElement => <Leaf {...props} />

export const MessageForm = ({
  placeholder = '',
  defaultValue: defaultValueProp,
  defaultAttachments = emptyArray,
  isEdit = false,
  isLoading = false,
  onCancel,
  onSubmit: onSubmitProp,
  autoFocus,
}: {
  placeholder?: string
  defaultValue?: string
  defaultAttachments?: ResourceFragmentT[]
  isEdit?: boolean
  isLoading?: boolean
  autoFocus?: boolean
  onCancel?: () => void
  onSubmit: (data: {
    message: string
    deleteAttachments: ResourceFragmentT[]
    uploadAttachments: DraftResourceT[]
  }) => void
}): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()

  const defaultValue = useMemo(() => {
    if (!defaultValueProp) {
      return undefined
    }

    return deserialize(new DOMParser().parseFromString(defaultValueProp, 'text/html').body) as Descendant[]
  }, [defaultValueProp])

  const { editor, value, setValue, onResetEditor } = useEditor(defaultValue)

  const onKeyDownWithRichText = useRichTextHotKeys(editor)

  const [attachments, setAttachments] = useState<ResourceT[]>(defaultAttachments)

  const { onFileInputChange } = useFileInputChangeHandler({ setResources: setAttachments })

  const { onChangeWithMention, onKeyDownWithMention, mentionsMenuElement } = useMentions(editor)

  const isEmptyMessage = checkIsEmptyText(value)

  const isEmptyAttachments = attachments.length === 0

  const onSubmit = useEventCallback((): void => {
    if (isLoading) {
      return
    }

    if (isEmptyMessage && isEmptyAttachments) {
      enqueueSnackbar('Message cannot be empty', { variant: 'warning' })

      return
    }

    onResetEditor()

    const serializedText = checkIsEmptyText(value) ? '' : serializeToHTML(value)

    const deleteAttachments = defaultAttachments.filter(
      (defaultAttachment) => !attachments.some((attachment) => defaultAttachment.id === attachment.id),
    )

    const uploadAttachments = attachments.filter(
      (attachment): attachment is DraftResourceT => attachment.__typename === '@DraftResource',
    )

    onSubmitProp({
      deleteAttachments,
      message: serializedText,
      uploadAttachments,
    })
  })

  const onKeyDownWithSendEvent = useSendHotkeyEventHandler(onSubmit)

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useEventCallback((event) => {
    onKeyDownWithMention(event)
    onKeyDownWithRichText(event)
    onKeyDownWithSendEvent(event)
  })

  const onChange = useEventCallback((value: Descendant[]) => {
    setValue(value)

    onChangeWithMention()
  })

  const renderEditor = () => {
    const padding = 8

    const typographyPreset = typographyPresets.bodySmall

    const maxLinesCount = 4

    const maxHeight = padding + typographyPreset.lineHeight * maxLinesCount + padding

    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPreset),
          {
            borderRadius: toPx(4),
            color: themeColors.overBackgroundDefault,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: toPx(maxHeight),
            overflow: 'auto',
            padding: toPx(padding),
          },
          { '&:focus-within': focusOutlineSx },
        ]}
      >
        <Box
          autoFocus={autoFocus}
          component={Editable}
          placeholder={placeholder}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          sx={[
            {
              flexShrink: 0,
              outline: 'none',
              overflow: 'hidden',
              resize: 'none',
            },
            editorNestedElementsSx,
          ]}
          onKeyDown={onKeyDown}
        />
      </Box>
    )
  }

  const renderFiles = () => {
    if (attachments.length === 0) {
      return null
    }

    return (
      <Box
        sx={{
          backgroundColor: themeColors.surfaceVariantDefault,
          borderRadius: toPx(8),
          display: 'flex',
          gap: toPx(8),
          overflow: 'auto',
          padding: toPx(8),
        }}
      >
        {attachments.map((attachment) => {
          return (
            <Box
              key={attachment.id}
              sx={{ alignItems: 'center', display: 'flex', gap: toPx(8), maxWidth: toPx(200) }}
            >
              <AttachmentPreviewModalContainer>
                {({ onOpen }) => (
                  <ResourceThumbnail
                    resource={attachment}
                    sx={{ flexGrow: 1, minWidth: 0 }}
                    onClick={() => {
                      onOpen({ attachment })
                    }}
                  />
                )}
              </AttachmentPreviewModalContainer>
              <Button
                color="default"
                data-testid="delete-attachment-button"
                icon={<DeleteSolidIcon />}
                size="sm"
                sx={{ flexShrink: 0 }}
                variant="contained"
                onClick={() => {
                  setAttachments((attachments) => pullById(attachments, attachment.id))
                }}
              />
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderCancelButton = () => {
    if (!isEdit) {
      return null
    }

    return (
      <Button
        color="default"
        icon={<CircleXmarkSolidIcon />}
        size="sm"
        variant="contained"
        onClick={onCancel}
      />
    )
  }

  const renderBoldButton = () => {
    return (
      <FormatButtonContainer format="bold">
        {({ isActive, onClick }) => (
          <Button
            color={isActive ? 'primary' : 'default'}
            data-testid="format-bold-button"
            icon={<BoldSolidIcon />}
            size="sm"
            variant="contained"
            onClick={onClick}
          />
        )}
      </FormatButtonContainer>
    )
  }

  const renderItalicButton = () => {
    return (
      <FormatButtonContainer format="italic">
        {({ isActive, onClick }) => (
          <Button
            color={isActive ? 'primary' : 'default'}
            data-testid="format-italic-button"
            icon={<ItalicSolidIcon />}
            size="sm"
            variant="contained"
            onClick={onClick}
          />
        )}
      </FormatButtonContainer>
    )
  }

  const renderUnderlineButton = () => {
    return (
      <FormatButtonContainer format="underline">
        {({ isActive, onClick }) => (
          <Button
            color={isActive ? 'primary' : 'default'}
            data-testid="format-underline-button"
            icon={<UnderlineSolidIcon />}
            size="sm"
            variant="contained"
            onClick={onClick}
          />
        )}
      </FormatButtonContainer>
    )
  }

  const renderNumberedListButton = () => {
    return (
      <FormatBlockButtonContainer format="numbered-list">
        {({ isActive, onClick }) => (
          <Button
            color={isActive ? 'primary' : 'default'}
            data-testid="format-numbered-list-button"
            icon={<ListOlSolidIcon />}
            size="sm"
            variant="contained"
            onClick={onClick}
          />
        )}
      </FormatBlockButtonContainer>
    )
  }

  const renderBulletedListButton = () => {
    return (
      <FormatBlockButtonContainer format="bulleted-list">
        {({ isActive, onClick }) => (
          <Button
            color={isActive ? 'primary' : 'default'}
            data-testid="format-bulleted-list-button"
            icon={<ListUlSolidIcon />}
            size="sm"
            variant="contained"
            onClick={onClick}
          />
        )}
      </FormatBlockButtonContainer>
    )
  }

  const renderAddAttachments = () => {
    return (
      <Box
        component="label"
        sx={[{ all: 'unset' }, { cursor: 'pointer', display: 'flex', flexDirection: 'column' }]}
      >
        <FileInput
          multiple
          onChange={onFileInputChange}
        />
        <Button
          color="default"
          data-testid="add-attachments-button"
          icon={<PaperClipSolidIcon />}
          size="sm"
          type="placeholder"
          variant="contained"
        />
      </Box>
    )
  }

  const renderPostButton = () => {
    return (
      <Button
        color="primary"
        data-testid="post-button"
        disabled={(isEmptyMessage && isEmptyAttachments) || isLoading}
        icon={<PaperPlaneSolidIcon />}
        isLoading={isLoading}
        size="sm"
        variant="contained"
        onClick={onSubmit}
      >
        Post
      </Button>
    )
  }

  const renderControls = () => {
    const gap = 4

    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(gap) }}>
        <Box sx={{ alignItems: 'center', display: 'flex', flexGrow: 1, gap: toPx(gap) }}>
          {renderCancelButton()}
          {renderBoldButton()}
          {renderItalicButton()}
          {renderUnderlineButton()}
          {renderNumberedListButton()}
          {renderBulletedListButton()}
          {renderAddAttachments()}
        </Box>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(gap), ml: 'auto' }}>{renderPostButton()}</Box>
      </Box>
    )
  }

  const renderRoot = () => {
    return (
      <Box
        sx={{
          backgroundColor: themeColors.surfaceNeutralDefault,
          borderColor: themeColors.borderFaded,
          borderRadius: toPx(8),
          borderStyle: 'solid',
          borderWidth: toPx(1),
          display: 'flex',
          flexDirection: 'column',
          gap: toPx(8),
          px: toPx(16 - 1),
          py: toPx(8 - 1),
        }}
      >
        <Slate
          editor={editor}
          initialValue={value}
          onChange={onChange}
        >
          {renderEditor()}
          {renderFiles()}
          {renderControls()}
        </Slate>
      </Box>
    )
  }

  return (
    <>
      {renderRoot()}
      {mentionsMenuElement}
    </>
  )
}
