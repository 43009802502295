import { Box } from '@mui/material'
import { memo, useCallback } from 'react'
import type { FieldPathByValue, FieldValues, PathValue, UseControllerProps, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import type { SearchEntitiesQueryVariablesT } from '@resnet/client-api/api'

import { EntityDropdown } from '@resnet/client-web/shared/entities/components/entity-dropdown'
import { Field, FieldErrorText } from '@resnet/client-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-web/shared/gdl/components/field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

type EntitySelectValueT = undefined | null | string

type EntitySelectFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, EntitySelectValueT>,
> = {
  defaultValue?: PathValue<TFieldValues, TPath>
  form: UseFormReturn<TFieldValues>
  headerRight?: React.ReactNode
  name: TPath
  label: string
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
  entityTypeIds?: string[]
}

export const EntitySelectField = memo(
  <TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, EntitySelectValueT>>({
    form,
    name,
    label,
    rules,
    defaultValue,
    entityTypeIds,
    headerRight,
  }: EntitySelectFieldPropsT<TFieldValues, TPath>) => {
    const {
      field,
      fieldState: { error, invalid },
    } = useController({ control: form.control, defaultValue, name, rules })

    const withQueryVariables = useCallback(
      (input: SearchEntitiesQueryVariablesT): SearchEntitiesQueryVariablesT => {
        if (!entityTypeIds) {
          return input
        }

        return {
          ...input,
          filter: {
            entityTypeId: {
              in: entityTypeIds,
            },
          },
        }
      },
      [entityTypeIds],
    )

    const renderHeader = () => {
      return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
          <FieldLabel>{label}</FieldLabel>
          {headerRight}
        </Box>
      )
    }

    const renderInput = () => {
      return (
        <EntityDropdown
          hasError={invalid}
          withQueryVariables={withQueryVariables}
          {...field}
          onChange={(value: PathValue<TFieldValues, TPath>) => {
            field.onChange(value)
          }}
        />
      )
    }

    const renderFooter = () => {
      if (!error) {
        return null
      }

      return <FieldErrorText>{error.message as string}</FieldErrorText>
    }

    return (
      <Field sx={{ flexGrow: 1, width: 0 }}>
        {renderHeader()}
        {renderInput()}
        {renderFooter()}
      </Field>
    )
  },
)
