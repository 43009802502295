import { useContext } from 'react'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { createHookContainer } from '../utils/create-hook-container'

export const useNonNullableContext = <ValueT>(Context: React.Context<ValueT>) => {
  const value = useContext(Context)

  assert(
    value,
    checkNonNullable,
    'context value is expected to be defined, it might be consumer was rendered outside of provider',
  )

  return value
}

export const NonNullableContextContainer = createHookContainer(
  <ValueT>({ Context }: { Context: React.Context<ValueT> }) => useNonNullableContext(Context),
)
