import { generatePath } from 'react-router-dom'

import type { SiteFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type SiteWithFeedPathnameT = Pick<SiteFragmentT, '__typename' | 'readableId'>

export const mapSiteToFeedPathname = <ValueT extends SiteWithFeedPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.sitesDetailsFeed, { siteId: value.readableId })
}
