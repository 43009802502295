import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import { systemColors } from '../constants/system-colors'
import type { GenericThemePropsT } from '../types/generic-theme-props'

export const theme = createConstrainer<GenericThemePropsT>()({
  colors: {
    actionsAccentDefault: systemColors.radiation70,
    actionsAccentHover: systemColors.radiation80,
    actionsAccentPressed: systemColors.radiation60,
    actionsDangerDefault: systemColors.critical50,
    actionsDangerHover: systemColors.critical60,
    actionsDangerPressed: systemColors.critical30,
    actionsDisabled: systemColors.whiteA10,
    actionsLinkDefault: systemColors.alienSkin40,
    actionsLinkHover: systemColors.alienSkin50,
    actionsLinkPressed: systemColors.alienSkin20,
    actionsOverAccentDefault: systemColors.whiteA90,
    actionsOverDangerDefault: systemColors.whiteA90,
    actionsOverDisabled: systemColors.whiteA20,
    actionsOverPrimaryDefault: systemColors.blackA90,
    actionsPrimaryDefault: systemColors.alienSkin40,
    actionsPrimaryHover: systemColors.alienSkin50,
    actionsPrimaryPressed: systemColors.alienSkin20,
    background: systemColors.black,
    baseAccent: systemColors.radiation70,
    basePrimary: systemColors.alienSkin40,
    borderBold: systemColors.whiteA30,
    borderDefault: systemColors.whiteA10,
    borderFaded: systemColors.whiteA05,
    borderFeedbackCritical: systemColors.critical40,
    borderFeedbackInfo: systemColors.info30,
    borderFeedbackWarning: systemColors.radiation30,
    borderFocus: systemColors.radiation70,
    feedbackCritical: systemColors.critical60,
    feedbackInfo: systemColors.info60,
    feedbackSuccess: systemColors.alienSkin20,
    feedbackWarning: systemColors.warning50,
    linearProgress: systemColors.alienSkin40,
    linearProgressBar: systemColors.alienSkin50,
    logoBackground: systemColors.alienSkin40,
    logoOverBackground: systemColors.blackA90,
    overBackgroundBold: systemColors.white,
    overBackgroundDefault: systemColors.whiteA80,
    overBackgroundFaded: systemColors.whiteA50,
    overBackgroundMuted: systemColors.whiteA70,
    overSurfaceContrast: systemColors.neutral05,
    overSurfaceGamificationBronze: systemColors.warning70,
    overSurfaceGamificationGold: systemColors.radiation70,
    overSurfaceGamificationSilver: systemColors.neutral30,
    progressBarBackground: systemColors.alienSkin40,
    progressBarOverBackground: systemColors.black,
    surfaceContrastDefault: systemColors.white,
    surfaceContrastHover: systemColors.neutral90,
    surfaceContrastPressed: systemColors.neutral80,
    surfaceFeedbackCriticalDefault: systemColors.critical30,
    surfaceFeedbackCriticalHover: systemColors.critical40,
    surfaceFeedbackCriticalPressed: systemColors.critical20,
    surfaceFeedbackInfoDefault: systemColors.info20,
    surfaceFeedbackInfoHover: systemColors.info30,
    surfaceFeedbackInfoPressed: systemColors.info15,
    surfaceFeedbackSuccessDefault: systemColors.alienSkin15,
    surfaceFeedbackSuccessHover: systemColors.alienSkin20,
    surfaceFeedbackSuccessPressed: systemColors.alienSkin10,
    surfaceFeedbackWarningDefault: systemColors.warning15,
    surfaceFeedbackWarningHover: systemColors.warning20,
    surfaceFeedbackWarningPressed: systemColors.warning10,
    surfaceGamificationBronze: systemColors.warning10,
    surfaceGamificationBronzeHover: systemColors.warning15,
    surfaceGamificationBronzePressed: systemColors.warning05,
    surfaceGamificationGold: systemColors.radiation10,
    surfaceGamificationGoldHover: systemColors.radiation15,
    surfaceGamificationGoldPressed: systemColors.radiation05,
    surfaceGamificationSilver: systemColors.neutral10,
    surfaceGamificationSilverHover: systemColors.neutral15,
    surfaceGamificationSilverPressed: systemColors.neutral05,
    surfaceNeutralDefault: systemColors.neutral05,
    surfaceNeutralHover: systemColors.neutral15,
    surfaceNeutralPressed: systemColors.neutral10,
    surfaceOverlayDefault: systemColors.blackA50,
    surfacePrimaryDefault: systemColors.alienSkin05,
    surfacePrimaryHover: systemColors.alienSkin10,
    surfacePrimaryPressed: systemColors.alienSkin15,
    surfaceVariantDefault: systemColors.whiteA05,
    surfaceVariantHover: systemColors.whiteA10,
    surfaceVariantPressed: systemColors.whiteA20,
  },
})
