import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

export type HookContainerChildrenT<HookResultT> = (props: HookResultT) => React.ReactNode

export type HookContainerT<HookPropsT, HookResultT> = (
  props: MergeAllT<[HookPropsT, { children: HookContainerChildrenT<HookResultT> }]>,
) => null | React.ReactElement

export type UseHookT<HookPropsT extends Record<string, unknown>, HookResultT> = (props: HookPropsT) => HookResultT

export const createHookContainer = <HookPropsT extends Record<string, unknown>, HookResult>(
  useHook: UseHookT<HookPropsT, HookResult>,
) => {
  const HookContainer = ({
    children,
    ...props
  }: MergeAllT<
    [
      HookPropsT,
      {
        children: HookContainerChildrenT<HookResult>
      },
    ]
  >) => {
    const result = useHook(props as unknown as HookPropsT)

    return <>{children(result)}</>
  }

  return HookContainer
}

export type UseElementsHookT<HookPropsT extends Record<string, unknown>, HookResultT> = (
  props: HookPropsT,
) => [HookResultT, React.ReactNode]

export const createHookElementsContainer = <HookPropsT extends Record<string, unknown>, HookResult>(
  useHook: UseElementsHookT<HookPropsT, HookResult>,
) => {
  const HookElementsContainer = ({
    children,
    ...props
  }: MergeAllT<
    [
      HookPropsT,
      {
        children: HookContainerChildrenT<HookResult>
      },
    ]
  >) => {
    const [result, elements] = useHook(props as unknown as HookPropsT)

    return (
      <>
        {children(result)}
        {elements}
      </>
    )
  }

  return HookElementsContainer
}
