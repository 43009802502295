import type { CustomFieldT } from '../../types/custom-field'

import { DateCustomFieldUserFormField } from './components/date-custom-field-user-form-field'

export const renderUserFormField: CustomFieldT['renderUserFormField'] = ({ field, form, name }) => {
  return (
    <DateCustomFieldUserFormField
      field={field}
      form={form}
      name={name}
    />
  )
}
