import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldT } from '../../types/custom-field'

export const checkboxCustomField = createConstrainer<CustomFieldT>()({
  id: CustomFieldTypeT.CheckboxT as const,
  name: 'Checkbox (True/False)',
  payload: createConstrainer<CustomFieldPayloadT>()({
    Checkbox: {
      defaultValue: false,
    },
  }) as CustomFieldPayloadT,
})
