import { transform } from '@resnet/client-common/common/utils/object/transform'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { FilterModeT } from '@resnet/client-api/api'

import type { IncludeExcludeFilterT } from '../types/filters'

export const useIncludeExcludeFilterSelectMultipleDropdown = <ItemT extends null | string>({
  value: valueProp,
  onChange: onChangeProp,
}: {
  value: IncludeExcludeFilterT<ItemT>
  onChange: (value: IncludeExcludeFilterT<ItemT>) => void
}) => {
  const mode = valueProp.mode

  const value = valueProp.items

  const onChange = useEventCallback((items: ItemT[]) => {
    onChangeProp(
      transform(valueProp, {
        items: () => items,
      }),
    )
  })

  const onSelectAll = useEventCallback(() => {
    onChangeProp({ items: [], mode: FilterModeT.ExcludeT })
  })

  const onClear = useEventCallback(() => {
    onChangeProp({ items: [], mode: FilterModeT.IncludeT })
  })

  return {
    mode,
    onChange,
    onClear,
    onSelectAll,
    value,
  }
}

export const IncludeExcludeFilterSelectMultipleDropdownContainer = createHookContainer(
  useIncludeExcludeFilterSelectMultipleDropdown,
)
