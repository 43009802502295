import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { UserPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/user-picker/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type {
  UserPickerFormCustomFieldValueT,
  UserPickerMultipleFormCustomFieldValueT,
  UserPickerSingleFormCustomFieldValueT,
} from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  UserPickerCustomFieldValueT,
  UserPickerFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const userPickerPayload = payload[CustomFieldTypeT.UserPickerT]

  assert(userPickerPayload, checkNonNullable)

  const { multiple } = userPickerPayload

  if (multiple) {
    const defaultValue: UserPickerMultipleFormCustomFieldValueT = []

    return defaultValue
  }

  const defaultValue: UserPickerSingleFormCustomFieldValueT = null

  return defaultValue
}
