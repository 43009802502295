import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

const animationDuration = 2000

const opacityStart = 0.15

const opacityEnd = 0.3

export type SkeletonPropsT = BoxProps

export const Skeleton = ({ sx = null, ...props }: SkeletonPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          '@keyframes pulse': {
            /* eslint-disable sort-keys-fix/sort-keys-fix */
            '0%': {
              opacity: opacityStart,
            },
            '50%': {
              opacity: opacityEnd,
            },
            '100%': {
              opacity: opacityStart,
            },
            /* eslint-enable sort-keys-fix/sort-keys-fix */
          },
          animation: `pulse ${animationDuration}ms ease infinite`,
        },
        sx,
      ].flat()}
    />
  )
}
