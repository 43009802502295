import type { UserFragmentT } from '@resnet/client-api/api'

import { getFirstLastName } from './get-first-last-name'
import { getUsername } from './get-username'

export const mapUserToTitle = <UserT extends Pick<UserFragmentT, 'firstName' | 'lastName' | 'nickname' | 'email'>>(
  user: UserT,
) => {
  return getFirstLastName(user) ?? getUsername(user)
}
