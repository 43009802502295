import type { UseQueryOptions } from '@tanstack/react-query'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { GetEntityQueryT, MinimalEntityFragmentT } from '@resnet/client-api/api'
import { useGetEntityQuery } from '@resnet/client-api/api'

export type UseEntityOptionPropsT<EntityT extends MinimalEntityFragmentT = MinimalEntityFragmentT> = {
  id: string
  options?: UseQueryOptions<GetEntityQueryT, unknown, EntityT>
}

export const useEntityOption = <EntityT extends MinimalEntityFragmentT = MinimalEntityFragmentT>({
  id,
  options,
}: UseEntityOptionPropsT<EntityT>) => {
  const optionQuery = useGetEntityQuery({ id }, { ...options, select: (data) => data.getEntity as EntityT })

  return { optionQuery }
}

export const EntityOptionContainer = createHookContainer(useEntityOption)
