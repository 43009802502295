import { feedRoutes } from '@resnet/client-shared/shared/origins/routes/feed-routes'

export const siteDetailsScreenRoutes = {
  assets: '/assets',
  attachments: '/attachments',
  feed: feedRoutes,
  issues: '/issues',
  relations: '/relations',
  scheduling: '/scheduling',
}
