import { checkIsQuerySuccess } from '@resnet/client-common/react-query/utils/check-is-query-success'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { useProfileQuery } from '@resnet/client-api/api'

export const useProfile = () => {
  const profileQuery = useProfileQuery(undefined, { select: (data) => data.getMyProfile, suspense: true })

  assert(profileQuery, checkIsQuerySuccess, '"profileQuery" should be loaded')

  const profile = profileQuery.data

  return { profile }
}
