import { MILLISECONDS_IN_DAY } from '@resnet/client-common/common/constants/time'
import { checkIsNotFoundError } from '@resnet/client-common/network/utils/check-is-not-found-error'
import { checkIsUnsupportedAppVersionError } from '@resnet/client-common/network/utils/check-is-unsupported-app-version-error'

import { checkIsAnyClientUnauthorizedError } from '@resnet/client-api/utils/check-is-any-client-error'
import { QueryClient } from '@resnet/client-api/vendors/react-query'

import * as mutationCacheHandlersCreators from './mutation-cache-handlers'
import type { MutationCacheHandlersT } from './types/mutation-cache-handlers'
import { combineMutationCacheHandlers } from './utils/combine-mutation-cache-handlers'

type ContextT = {
  queryClient: QueryClient
}

type MutationCacheHandlersCreatorT = (context: ContextT) => MutationCacheHandlersT[]

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: (failureCount, error) => {
        // need to retry at least once if unauthorized error because of refresh token mechanism
        if (checkIsAnyClientUnauthorizedError(error)) {
          return failureCount < 1
        }

        return false
      },
    },
    queries: {
      cacheTime: MILLISECONDS_IN_DAY,
      retry: (failureCount, error) => {
        // no need to retry if the error is not found or unsupported app version
        if (checkIsNotFoundError(error) || checkIsUnsupportedAppVersionError(error)) {
          return false
        }

        return failureCount < 3
      },
      useErrorBoundary: true,
    },
  },
})

// https://github.com/TanStack/query/discussions/3450

const context: ContextT = { queryClient }

Object.assign(
  queryClient.getMutationCache().config,
  combineMutationCacheHandlers(
    Object.values(mutationCacheHandlersCreators).flatMap((creator: MutationCacheHandlersCreatorT) => creator(context)),
  ),
)
