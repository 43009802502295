import { checkIsAsset } from '../../assets/utils/check-is-asset'
import type { AssetWithReadableIdT } from '../../assets/utils/map-asset-to-readable-id'
import { mapAssetToReadableId } from '../../assets/utils/map-asset-to-readable-id'
import { checkIsCampaign } from '../../campaigns/utils/check-is-campaign'
import {
  mapCampaignToReadableId,
  type CampaignWithReadableIdT,
} from '../../campaigns/utils/map-campaign-to-readable-id'
import { checkIsCredential } from '../../credentials/utils/check-is-credential'
import {
  mapCredentialToReadableId,
  type CredentialWithReadableIdT,
} from '../../credentials/utils/map-credential-to-readable-id'
import { checkIsFormObject } from '../../form-objects/utils/check-is-form-object'
import type { FormObjectWithReadableIdT } from '../../form-objects/utils/map-form-object-to-readable-id'
import { mapFormObjectToReadableId } from '../../form-objects/utils/map-form-object-to-readable-id'
import { checkIsInsight } from '../../insights/utils/check-is-insight'
import type { InsightWithReadableIdT } from '../../insights/utils/map-insight-to-readable-id'
import { mapInsightToReadableId } from '../../insights/utils/map-insight-to-readable-id'
import { checkIsIssue } from '../../issues/utils/check-is-issue'
import type { IssueWithReadableIdT } from '../../issues/utils/map-issue-to-readable-id'
import { mapIssueToReadableId } from '../../issues/utils/map-issue-to-readable-id'
import { checkIsResourceObject } from '../../resource-objects/utils/check-is-resource-object'
import {
  mapResourceObjectToReadableId,
  type ResourceObjectWithReadableIdT,
} from '../../resource-objects/utils/map-resource-object-to-readable-id'
import { checkIsRoute } from '../../routes/utils/check-is-route'
import type { RouteWithReadableIdT } from '../../routes/utils/map-route-to-readable-id'
import { mapRouteToReadableId } from '../../routes/utils/map-route-to-readable-id'

export type EntityWithReadableIdT =
  | AssetWithReadableIdT
  | CampaignWithReadableIdT
  | CredentialWithReadableIdT
  | FormObjectWithReadableIdT
  | IssueWithReadableIdT
  | InsightWithReadableIdT
  | ResourceObjectWithReadableIdT
  | RouteWithReadableIdT

export const mapEntityToReadableId = <ValueT extends EntityWithReadableIdT>(value: ValueT) => {
  if (checkIsAsset(value)) {
    return mapAssetToReadableId(value)
  }

  if (checkIsCampaign(value)) {
    return mapCampaignToReadableId(value)
  }

  if (checkIsCredential(value)) {
    return mapCredentialToReadableId(value)
  }

  if (checkIsFormObject(value)) {
    return mapFormObjectToReadableId(value)
  }

  if (checkIsIssue(value)) {
    return mapIssueToReadableId(value)
  }

  if (checkIsInsight(value)) {
    return mapInsightToReadableId(value)
  }

  if (checkIsResourceObject(value)) {
    return mapResourceObjectToReadableId(value)
  }

  if (checkIsRoute(value)) {
    return mapRouteToReadableId(value)
  }

  throw new Error(`Unknown entity type: ${value.__typename}`)
}
