type ValidatorT<ValueT> = (value: ValueT) => string | boolean

export const composeValidators = <ValueT>(...validators: ValidatorT<ValueT>[]) => {
  return (value: ValueT) => {
    let result: string | boolean = true

    for (const validator of validators) {
      result = validator(value)

      if (result !== true) {
        break
      }
    }

    return result
  }
}
