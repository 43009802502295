import type { CustomFieldT } from '../../types/custom-field'

import { UserPickerCustomFieldPayload } from './components/user-picker-custom-field-payload'

export const renderPayload: CustomFieldT['renderPayload'] = ({ form, name, isDraft }) => {
  return (
    <UserPickerCustomFieldPayload
      form={form}
      isDraft={isDraft}
      name={name}
    />
  )
}
