import { useMemo } from 'react'

const isSkeletonSymbol = Symbol('is-skeleton')

export type SkeletonItemT = { id: string; [isSkeletonSymbol]: boolean }

export type LoadableItemT<ItemT> = SkeletonItemT | ItemT

export type UseLoadableListPropsT<ItemT> = {
  data: ItemT[]
  isFetching?: boolean
  skeletonsCount?: number
}

export const useLoadableList = <ItemT>({
  data: dataActual,
  isFetching = false,
  skeletonsCount = 5,
}: UseLoadableListPropsT<ItemT>) => {
  const skeletonItems = useMemo<SkeletonItemT[]>(() => {
    if (!isFetching) {
      return []
    }

    return Array.from({ length: skeletonsCount }, (_, index) => ({
      id: `skeleton-${index}`,
      [isSkeletonSymbol]: true,
    }))
  }, [isFetching, skeletonsCount])

  const data = useMemo(() => [...dataActual, ...skeletonItems], [dataActual, skeletonItems])

  return { data }
}

export const checkIsSkeletonItem = (item: LoadableItemT<unknown>): item is SkeletonItemT => {
  return (
    typeof item === 'object' &&
    item !== null &&
    isSkeletonSymbol in item &&
    typeof item[isSkeletonSymbol] === 'boolean' &&
    item[isSkeletonSymbol]
  )
}

// we can use this preset in most of the cases assuming item has id prop
export const getLoadableItemKey = <ItemT extends { id: string }>(index: number, data: LoadableItemT<ItemT>[]) => {
  const item = data[index]

  if (!item) {
    return `an-unknown-item-${index}`
  }

  return item.id
}
