import { ResourceObjectOptionContainer } from '@resnet/client-shared/shared/resource-objects/hooks/use-resource-object-option'
import { mapResourceObjectToTitle } from '@resnet/client-shared/shared/resource-objects/utils/map-resource-object-to-title'

import { createDropdownComponent } from '@resnet/client-web/shared/common/factories/create-dropdown-component'

import { ResourceObjectSelectDropdownContainer } from '../../hooks/use-resource-object-select-dropdown'

export const ResourceObjectDropdown = createDropdownComponent({
  OptionContainer: ResourceObjectOptionContainer,
  SelectDropdownContainer: ResourceObjectSelectDropdownContainer,
  getOptionLabel: mapResourceObjectToTitle,
})
