import type { Query } from '@resnet/client-api/vendors/react-query'

import type { InfiniteQueryHookT, QueryHookT } from '../types/query-hook'

export const queryHookMatchesQuery = (queryHook: QueryHookT | InfiniteQueryHookT, query: Query): boolean => {
  const queryKey = query.queryKey

  // queryHook.getKey might need variables arg, but we need to check only first key item
  const getKey = queryHook.getKey as () => string[]

  const queryHookKey = getKey()

  if (queryHookKey[0] !== queryKey[0]) {
    return false
  }

  return true
}
