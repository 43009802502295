import { useRef } from 'react'
import type { MutableRefObject } from 'react'

export const useRefInitializer = <ValueT>(fn: () => ValueT): MutableRefObject<ValueT> => {
  const initializedRef = useRef<boolean>(false)
  const ref = useRef<undefined | ValueT>()

  if (!initializedRef.current) {
    ref.current = fn()
    initializedRef.current = true
  }

  return ref as MutableRefObject<ValueT>
}
