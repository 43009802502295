import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { fromEntries } from '@resnet/client-common/common/utils/object/from-entries'
import { getObjectProp } from '@resnet/client-common/common/utils/object/get-object-prop'
import { mapValues } from '@resnet/client-common/common/utils/object/map-values'
import { assert } from '@resnet/client-common/typescript/utils/assert'
import { toTuple } from '@resnet/client-common/typescript/utils/to-tuple'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/types/custom-field-value'
import type { CustomFieldValuesT } from '@resnet/client-shared/shared/custom-fields/types/custom-field-values'

import { customFieldsById } from '../constants/custom-fields'
import type { CustomFieldT } from '../types/custom-field'
import type { FormCustomFieldValuesT } from '../types/form-custom-field-values'

import { mapCustomFieldToKey } from './map-custom-field-to-key'

export const mapFormCustomFieldValuesToCustomFieldValues = ({
  fields,
  fieldValues: fieldValuesActual,
}: {
  fields: CustomFieldFragmentT[]
  fieldValues: FormCustomFieldValuesT
}) => {
  const allDiscriminatorFields = fields.filter((field) => field.type === CustomFieldTypeT.DiscriminatorT)

  const allFieldsToNullifyByKey = new Set(
    allDiscriminatorFields.flatMap((field) => {
      const { payload, key: discriminatorFieldKey } = field

      assert(payload, checkNonNullable)

      const discriminatorPayload = payload[CustomFieldTypeT.DiscriminatorT]

      assert(discriminatorPayload, checkNonNullable)

      const discriminatorFieldValue = fieldValuesActual?.[discriminatorFieldKey] ?? discriminatorPayload.defaultValue

      const restOptions = discriminatorPayload.options.filter((option) => {
        return option.key !== discriminatorFieldValue
      })

      if (!restOptions.length) {
        return
      }

      return restOptions.map((option) => option.fields).flat()
    }),
  )

  const fieldsByKey = pipeline(
    fields,
    (fields) => fields.map((field) => toTuple([mapCustomFieldToKey(field), field])),
    fromEntries,
  )

  const fieldValues: CustomFieldValuesT = pipeline(
    fieldsByKey,
    (x) => mapValues((field, key) => fieldValuesActual?.[key], x),
    (x) =>
      mapValues((value, key, fieldValues) => {
        const field = fieldsByKey[key]

        const customFieldTypeProps = customFieldsById[field.type] as CustomFieldT

        const mapFormCustomFieldValueToCustomFieldValue = getObjectProp(
          customFieldTypeProps,
          'mapFormCustomFieldValueToCustomFieldValue',
        )

        if (!mapFormCustomFieldValueToCustomFieldValue) {
          return value as CustomFieldValueT
        }

        return mapFormCustomFieldValueToCustomFieldValue({ field, fieldValues, value })
      }, x),
    (x) => mapValues((value, key) => (allFieldsToNullifyByKey.has(key) ? undefined : value), x),
  )

  return { fieldValues }
}
