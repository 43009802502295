import { CombineRefsContainer } from '@resnet/client-common/react/hooks/use-combine-refs'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { DateInputInputRefT } from '@resnet/client-web/shared/gdl/components/date-input'
import { DateInputInput } from '@resnet/client-web/shared/gdl/components/date-input'
import { DateInputText } from '@resnet/client-web/shared/gdl/components/date-input-text'
import { PopperContext } from '@resnet/client-web/shared/gdl/components/popper'

import type { CalendarPopperPropsT } from '../calendar-popper'
import { CalendarPopper } from '../calendar-popper'

type DateSelectDropDownPropsT = MergeAllT<
  [
    Omit<CalendarPopperPropsT, 'children'>,
    {
      hasError?: boolean
      showTime?: boolean
      value?: null | Date
    },
  ]
>

export const DateSelectDropDown = forwardFunctionalComponentRef(
  (
    { disabled, hasError, showTime, value, ...calendarPopperProps }: DateSelectDropDownPropsT,
    ref: DateInputInputRefT,
  ) => {
    const renderAnchor = (): React.ReactElement => {
      return (
        <NonNullableContextContainer Context={PopperContext}>
          {({ setAnchorEl, open }) => (
            <CombineRefsContainer refs={[ref, setAnchorEl]}>
              {(ref) => (
                <DateInputInput
                  disabled={disabled}
                  hasError={hasError}
                  ref={ref}
                  onClick={disabled ? undefined : open}
                >
                  <DateInputText
                    showTime={showTime}
                    value={value}
                  />
                </DateInputInput>
              )}
            </CombineRefsContainer>
          )}
        </NonNullableContextContainer>
      )
    }

    return (
      <CalendarPopper
        showTime={showTime}
        value={value}
        {...calendarPopperProps}
      >
        {{ anchor: renderAnchor }}
      </CalendarPopper>
    )
  },
)
