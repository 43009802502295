import { checkIsBattery } from '../../batteries/utils/check-is-battery'
import type { BatteryWithTitleT } from '../../batteries/utils/map-battery-to-title'
import { mapBatteryToTitle } from '../../batteries/utils/map-battery-to-title'
import { checkIsSite } from '../../sites/utils/check-is-site'
import type { SiteWithTitleT } from '../../sites/utils/map-site-to-title'
import { mapSiteToTitle } from '../../sites/utils/map-site-to-title'
import { checkIsWell } from '../../wells/utils/check-is-well'
import type { WellWithTitleT } from '../../wells/utils/map-well-to-title'
import { mapWellToTitle } from '../../wells/utils/map-well-to-title'

export type AssetWithTitleT = BatteryWithTitleT | SiteWithTitleT | WellWithTitleT

export const mapAssetToTitle = <ValueT extends AssetWithTitleT>(value: ValueT): string => {
  if (checkIsBattery(value)) {
    return mapBatteryToTitle(value)
  }

  if (checkIsSite(value)) {
    return mapSiteToTitle(value)
  }

  if (checkIsWell(value)) {
    return mapWellToTitle(value)
  }

  throw new Error(`Unknown asset type: ${value.__typename}`)
}
