import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'

import type { MinimalEntityFragmentT } from '@resnet/client-api/api'

import CampaignSolidIcon from '@resnet/client-shared/assets/icons/campaign-solid.svg'
import CertificateSolidIcon from '@resnet/client-shared/assets/icons/certificate-solid.svg'
import InsightSolidIcon from '@resnet/client-shared/assets/icons/insight-solid.svg'
import RouteSolidIcon from '@resnet/client-shared/assets/icons/route-solid.svg'
import ScrewdriverWrenchSolidIcon from '@resnet/client-shared/assets/icons/screwdriver-wrench-solid.svg'
import SignatureSolidIcon from '@resnet/client-shared/assets/icons/signature-solid.svg'
import TruckRigSolidIcon from '@resnet/client-shared/assets/icons/truck-rig-solid.svg'

import { checkIsAsset } from '../../assets/utils/check-is-asset'
import { mapAssetToIcon } from '../../assets/utils/map-asset-to-icon'
import { checkIsCampaign } from '../../campaigns/utils/check-is-campaign'
import { checkIsCredential } from '../../credentials/utils/check-is-credential'
import { checkIsFormObject } from '../../form-objects/utils/check-is-form-object'
import { checkIsInsight } from '../../insights/utils/check-is-insight'
import { checkIsIssue } from '../../issues/utils/check-is-issue'
import { checkIsResourceObject } from '../../resource-objects/utils/check-is-resource-object'
import { checkIsRoute } from '../../routes/utils/check-is-route'

export const mapEntityToIcon = <ValueT extends Pick<MinimalEntityFragmentT, '__typename'>>(value: ValueT) => {
  if (checkIsAsset(value)) {
    return mapAssetToIcon(value) as ForwardableT<React.SVGProps<SVGSVGElement>>
  }

  if (checkIsCampaign(value)) {
    return CampaignSolidIcon as ForwardableT<React.SVGProps<SVGSVGElement>>
  }

  if (checkIsInsight(value)) {
    return InsightSolidIcon as ForwardableT<React.SVGProps<SVGSVGElement>>
  }

  if (checkIsIssue(value)) {
    return ScrewdriverWrenchSolidIcon as ForwardableT<React.SVGProps<SVGSVGElement>>
  }

  if (checkIsRoute(value)) {
    return RouteSolidIcon as ForwardableT<React.SVGProps<SVGSVGElement>>
  }

  if (checkIsResourceObject(value)) {
    return TruckRigSolidIcon as ForwardableT<React.SVGProps<SVGSVGElement>>
  }

  if (checkIsCredential(value)) {
    return CertificateSolidIcon as ForwardableT<React.SVGProps<SVGSVGElement>>
  }

  if (checkIsFormObject(value)) {
    return SignatureSolidIcon as ForwardableT<React.SVGProps<SVGSVGElement>>
  }

  throw new Error(`Unknown entity type: ${value.__typename}`)
}
