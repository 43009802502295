import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { UserPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/user-picker/custom-field-value'
import { validateRequired } from '@resnet/client-shared/shared/forms/validators/any-required'
import { validateListRequired } from '@resnet/client-shared/shared/forms/validators/list-required'

import { UserSelectField } from '@resnet/client-web/shared/form-dalaran/components/users/user-select-field'
import { UserSelectMultipleField } from '@resnet/client-web/shared/form-dalaran/components/users/user-select-multiple-field'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type UserPickerCustomFieldUserFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | UserPickerCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const UserPickerCustomFieldUserFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | UserPickerCustomFieldValueT>,
>({
  field,
  form,
  name,
}: UserPickerCustomFieldUserFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const userPickerPayload = payload[CustomFieldTypeT.UserPickerT]

  assert(userPickerPayload, checkNonNullable)

  const { multiple, required } = userPickerPayload

  const renderField = () => {
    if (!multiple) {
      return (
        <UserSelectField
          // NOTE value can be string or array of strings
          form={form as UseFormReturn<FieldValues>}
          label={field.name}
          name={name}
          rules={!required ? undefined : { validate: validateRequired }}
        />
      )
    }

    return (
      <UserSelectMultipleField
        defaultValue={[]}
        form={form as UseFormReturn<FieldValues>}
        label={field.name}
        name={name}
        nullOptionEnabled={false}
        rules={!required ? undefined : { validate: validateListRequired }}
      />
    )
  }

  return <ObjectDetailsRow>{renderField()}</ObjectDetailsRow>
}
