import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { CustomFieldPayloadNumberT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { NumberField } from '@resnet/client-web/shared/form-dalaran/components/common/number-field'
import { SwitchField } from '@resnet/client-web/shared/form-dalaran/components/common/switch-field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export type NumberCustomFieldPayloadValueT = CustomFieldFragmentT

export type NumberCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, NumberCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const NumberCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, NumberCustomFieldPayloadValueT>,
>({
  form,
  name,
}: NumberCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type RequiredPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadNumberT['required']>

  const requiredName = `${name}.payload.${CustomFieldTypeT.NumberT}.required` as RequiredPathT

  type IsIntegerEnabledPathT = FieldPathByValue<
    TFieldValues,
    undefined | null | CustomFieldPayloadNumberT['isIntegerEnabled']
  >

  const isIntegerEnabledName = `${name}.payload.${CustomFieldTypeT.NumberT}.isIntegerEnabled` as IsIntegerEnabledPathT

  type MinValuePathT = FieldPathByValue<TFieldValues, CustomFieldPayloadNumberT['minValue']>

  const minValueName = `${name}.payload.${CustomFieldTypeT.NumberT}.minValue` as MinValuePathT

  type MaxValuePathT = FieldPathByValue<TFieldValues, CustomFieldPayloadNumberT['maxValue']>

  const maxValueName = `${name}.payload.${CustomFieldTypeT.NumberT}.maxValue` as MaxValuePathT

  type DefaultValuePathT = FieldPathByValue<TFieldValues, CustomFieldPayloadNumberT['defaultValue']>

  const defaultValueName = `${name}.payload.${CustomFieldTypeT.NumberT}.defaultValue` as DefaultValuePathT

  const renderRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName}
      />
    )
  }

  const renderIsIntegerEnabledField = () => {
    return (
      <SwitchField
        form={form}
        label="Integer"
        name={isIntegerEnabledName}
      />
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderRequiredField()}
        {renderIsIntegerEnabledField()}
      </Box>
    )
  }

  const renderMinValueField = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <NumberField
          form={form}
          label="Minimum Value"
          name={minValueName}
          placeholder="0"
          rules={{
            validate: (value) => {
              if (!checkNonNullable(value)) {
                return true
              }

              const maxValue = form.getValues(maxValueName)

              if (checkNonNullable(maxValue) && value > maxValue) {
                return `Minimum value must be less than ${maxValue}`
              }

              const isIntegerEnabled = form.getValues(isIntegerEnabledName)

              if (isIntegerEnabled && !Number.isInteger(value)) {
                return 'Minimum value must be an integer'
              }

              return true
            },
          }}
        />
      </Box>
    )
  }

  const renderMaxValueField = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <NumberField
          form={form}
          label="Maximum Value"
          name={maxValueName}
          placeholder="0"
          rules={{
            validate: (value) => {
              if (!checkNonNullable(value)) {
                return true
              }

              const minValue = form.getValues(minValueName)

              if (checkNonNullable(minValue) && value < minValue) {
                return `Maximum value must be greater than ${minValue}`
              }

              const isIntegerEnabled = form.getValues(isIntegerEnabledName)

              if (isIntegerEnabled && !Number.isInteger(value)) {
                return 'Maximum value must be an integer'
              }

              return true
            },
          }}
        />
      </Box>
    )
  }

  const renderDefaultValueField = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <NumberField
          form={form}
          label="Default Value"
          name={defaultValueName}
          placeholder="0"
          rules={{
            validate: (value) => {
              if (!checkNonNullable(value)) {
                return true
              }

              const minValue = form.getValues(minValueName)

              if (checkNonNullable(minValue) && value < minValue) {
                return `Default value must be greater than ${minValue}`
              }

              const maxValue = form.getValues(maxValueName)

              if (checkNonNullable(maxValue) && value > maxValue) {
                return `Default value must be less than ${maxValue}`
              }

              const isIntegerEnabled = form.getValues(isIntegerEnabledName)

              if (isIntegerEnabled && !Number.isInteger(value)) {
                return 'Default value must be an integer'
              }

              return true
            },
          }}
        />
      </Box>
    )
  }

  return (
    <>
      {renderSwitchFields()}
      {renderMinValueField()}
      {renderMaxValueField()}
      {renderDefaultValueField()}
    </>
  )
}
