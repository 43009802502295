import type { IssueFragmentT } from '@resnet/client-api/api'

import { issueResolutionOptionsById } from '../constants/issue-resolution-options'

export const mapIssueToResolutionLabel = <V extends Pick<IssueFragmentT, 'resolution'>>(value: V) => {
  const resolution = value.resolution

  if (!resolution) {
    return null
  }

  return issueResolutionOptionsById[resolution]
}
