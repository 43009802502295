import type { CustomFieldFragmentT } from '@resnet/client-api/api'

export const mapCustomFieldToKey = (field: CustomFieldFragmentT) => {
  if (!field.scope) {
    return field.key
  }

  return `@${field.scope.field}/${field.scope.value}/${field.key}`
}

export const mapCustomFieldToFullKey = (field: CustomFieldFragmentT, fieldValuesKey: string) => {
  return `${fieldValuesKey}.${mapCustomFieldToKey(field)}`
}
