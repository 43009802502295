import { useDerivedState } from './use-derived-state'
import { useEventCallback } from './use-event-callback'

type FieldModelT<ValueT> = [ValueT, (value: ValueT) => void]

// this is similar to useMapStateModel, but adapted for event handler rather then setState action
export const useMapFieldModel = <InputValueT, OutputValueT>(
  inputFieldModel: FieldModelT<InputValueT>,
  mapInputValueToOutputValue: (inputValue: InputValueT) => OutputValueT,
  mapOutputValueToInputValue: (outputValue: OutputValueT, inputValue: InputValueT) => InputValueT,
): FieldModelT<OutputValueT> => {
  const [inputValue, onInputChange] = inputFieldModel

  const [outputValue] = useDerivedState(() => mapInputValueToOutputValue(inputValue), [inputValue])

  const onOutputChange = useEventCallback((nextOutputValue: OutputValueT): void => {
    const nextInputValue = mapOutputValueToInputValue(nextOutputValue, inputValue)

    onInputChange(nextInputValue)
  })

  return [outputValue, onOutputChange]
}
