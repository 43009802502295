import produce from 'immer'

import type {
  InputResourceObjectTypeT,
  ResourceObjectTypeQueryT,
  ResourceObjectTypesQueryT,
} from '@resnet/client-api/api'
import {
  useCreateResourceObjectTypeMutation,
  useDeleteResourceObjectTypeMutation,
  useInfiniteResourceObjectTypesQuery,
  useResourceObjectTypeQuery,
  useResourceObjectTypesQuery,
  useUpdateResourceObjectTypeMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const resourceObjectTypeQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findResourceObjectTypeQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useResourceObjectTypeQuery, (data) => data.getResourceObjectType, { id })

  const updateResourceObjectType = (
    data: undefined | ResourceObjectTypeQueryT,
    { diff }: { diff: InputResourceObjectTypeT },
  ) => {
    if (!data) {
      return
    }

    const item = data.getResourceObjectType

    Object.assign(item, diff)
  }

  const deleteResourceObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceObjectTypeMutation, {
      onSuccess: (data, { id }) => {
        findResourceObjectTypeQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateResourceObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateResourceObjectTypeMutation, {
      onError: (error, { id }) => {
        findResourceObjectTypeQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findResourceObjectTypeQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateResourceObjectType(data, { diff }))),
        )
      },
      onSuccess: (data, { id }) => {
        findResourceObjectTypeQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [deleteResourceObjectTypeHandlers(), updateResourceObjectTypeHandlers()]
}

export const resourceObjectTypesQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findResourceObjectTypesQueries = () => findQueries(queryClient, useResourceObjectTypesQuery)

  const findInfiniteResourceObjectTypesQueries = () =>
    findInfiniteQueries(queryClient, useInfiniteResourceObjectTypesQuery)

  const updateResourceObjectType = (
    data: undefined | ResourceObjectTypesQueryT,
    { id, diff }: { id: string; diff: InputResourceObjectTypeT },
  ) => {
    if (!data) {
      return
    }

    const items = data.listResourceObjectTypes.items

    const item = items.find((item) => item.id === id)

    if (!item) {
      return
    }

    Object.assign(item, diff)
  }

  const deleteResourceObjectType = (data: undefined | ResourceObjectTypesQueryT, { id }: { id: string }) => {
    if (!data) {
      return
    }

    const items = data.listResourceObjectTypes.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createResourceObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateResourceObjectTypeMutation, {
      onSuccess: () => {
        findResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteResourceObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceObjectTypeMutation, {
      onError: () => {
        findResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findResourceObjectTypesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => deleteResourceObjectType(data, { id }))),
        )
        findInfiniteResourceObjectTypesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => deleteResourceObjectType(page, { id }))),
          ),
        )
      },
      onSuccess: () => {
        findResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateResourceObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateResourceObjectTypeMutation, {
      onError: () => {
        findResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findResourceObjectTypesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateResourceObjectType(data, { diff, id }))),
        )
        findInfiniteResourceObjectTypesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => updateResourceObjectType(page, { diff, id }))),
          ),
        )
      },
      onSuccess: () => {
        findResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createResourceObjectTypeHandlers(), deleteResourceObjectTypeHandlers(), updateResourceObjectTypeHandlers()]
}
