import { pullById } from '@resnet/client-common/common/utils/array/pull-by-id'

export const removeRedundantMarkedAsDeletedOptions = <
  OptionT extends { id: string; key: string; isMarkedAsDeleted?: undefined | null | boolean },
>(
  options: OptionT[],
): OptionT[] => {
  const availableOptions = options.filter((option) => !option.isMarkedAsDeleted)

  const availableOptionKeysSet = new Set(availableOptions.map((option) => option.key))

  const markedAsDeletedOptions = options.filter((option) => option.isMarkedAsDeleted)

  const removedOptions = markedAsDeletedOptions.filter((option) => availableOptionKeysSet.has(option.key))

  return removedOptions.reduce((options, option) => pullById(options, option.id), options)
}
