import { useMemo, useState } from 'react'
import type { Descendant } from 'slate'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { withReact } from 'slate-react'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import { EMPTY_STATE, withMentions } from '../utils/editor'

export const useEditor = (defaultValue?: Descendant[]): typeof result => {
  const [value, setValue] = useState<Descendant[]>(() => defaultValue ?? EMPTY_STATE)

  const editor = useMemo(() => pipeline(createEditor(), withHistory, withReact, withMentions), [])

  const onResetEditor = useEventCallback(() => {
    setValue(EMPTY_STATE)
  })

  const result = { editor, onResetEditor, setValue, value }

  return result
}
