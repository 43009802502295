import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import type { CustomFieldPayloadUserPickerT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { SwitchField } from '@resnet/client-web/shared/form-dalaran/components/common/switch-field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export type UserPickerCustomFieldPayloadValueT = CustomFieldFragmentT

export type UserPickerCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, UserPickerCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
  isDraft: boolean
}

export const UserPickerCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, UserPickerCustomFieldPayloadValueT>,
>({
  form,
  name,
  isDraft,
}: UserPickerCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type RequiredPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadUserPickerT['required']>

  const requiredName = `${name}.payload.${CustomFieldTypeT.UserPickerT}.required` as RequiredPathT

  type MultiplePathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadUserPickerT['multiple']>

  const multipleName = `${name}.payload.${CustomFieldTypeT.UserPickerT}.multiple` as MultiplePathT

  const renderIsRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName}
      />
    )
  }

  const renderIsMultipleField = () => {
    return (
      <SwitchField
        disabled={!isDraft}
        disabledTooltipTitle="Multiple cannot be changed after creation"
        form={form}
        label="Multiple"
        name={multipleName}
      />
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderIsRequiredField()}
        {renderIsMultipleField()}
      </Box>
    )
  }

  return <>{renderSwitchFields()}</>
}
