import { Box } from '@mui/material'
import type { SxProps } from '@mui/system'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export const EmptyCell = ({ sx }: { sx?: SxProps }): React.ReactElement => {
  return (
    <Box sx={[{ alignItems: 'center', display: 'flex', gap: toPx(8), position: 'relative' }, sx ?? null].flat()}>
      <Box
        sx={{
          ...mapTypographyPresetToSx(typographyPresets.bodySmall),
          color: themeColors.overBackgroundDefault,
        }}
      >
        -
      </Box>
    </Box>
  )
}
