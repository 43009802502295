import { Box } from '@mui/material'
import type { UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { checkNonNullable, toNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import type { CampaignFragmentT } from '@resnet/client-api/api'

import { useCampaignOption } from '@resnet/client-shared/shared/campaigns/hooks/use-campaign-option'

import { ButtonButton, type ButtonBasePropsT } from '@resnet/client-web/shared/gdl/components/button'
import { Field } from '@resnet/client-web/shared/gdl/components/field'
import { IssueCampaignSelect } from '@resnet/client-web/shared/issues/components/issue-campaign'

export type IssueCampaignSelectFieldPropsT = {
  form: UseFormReturn<{ campaignId?: null | string }>
  size: ButtonBasePropsT<'button'>['size']
}

export const IssueCampaignSelectField = ({ form, size }: IssueCampaignSelectFieldPropsT) => {
  const { control } = form

  const {
    field: { value: campaignId, onChange: onChangeActual },
  } = useController({ control, name: 'campaignId' })

  const { optionQuery: campaignQuery } = useCampaignOption({
    id: toNonNullable(campaignId),
    options: { enabled: checkNonNullable(campaignId) },
  })

  const onChange = useEventCallback((campaign: undefined | null | CampaignFragmentT) => {
    onChangeActual(!campaign ? null : campaign.id)
  })

  const renderInput = () => {
    if (checkNonNullable(campaignId) && !campaignQuery.isSuccess) {
      return (
        <ButtonButton
          disabled
          isLoading
          color="primary"
          size={size}
          variant="contained"
        >
          Loading
        </ButtonButton>
      )
    }

    const campaign = campaignQuery.data

    return (
      <IssueCampaignSelect
        buttonSize={size}
        buttonVariant="outlined"
        sx={{ flexDirection: 'column' }}
        value={campaign}
        onChange={onChange}
      />
    )
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      <Box sx={{ height: '24px' }} />
      {renderInput()}
    </Field>
  )
}
