import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import { Suspense } from 'react'
import type { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import { Spinner } from '@resnet/client-web/shared/gdl/components/spinner'
import { placeContentCenterStyles, stretchColumnItemStyles } from '@resnet/client-web/styles/sx-presets'

type FormPropsT<TFieldValues extends FieldValues> = {
  children: React.ReactNode
  form: UseFormReturn<TFieldValues>
  onSubmit: SubmitHandler<TFieldValues>
  sx?: SxProps
}

export const Form = <TFieldValues extends FieldValues>({
  children,
  form,
  onSubmit: onSubmitActual,
  sx = null,
}: FormPropsT<TFieldValues>) => {
  const onSubmit = useEventCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation()

    form.handleSubmit(onSubmitActual)(event)
  })

  const renderFallback = () => {
    return (
      <Box sx={[stretchColumnItemStyles, placeContentCenterStyles].flat()}>
        <Spinner />
      </Box>
    )
  }

  const renderContent = () => {
    return (
      <Box
        component="form"
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          },
          sx,
        ].flat()}
        onSubmit={onSubmit}
      >
        {children}
      </Box>
    )
  }

  return <Suspense fallback={renderFallback()}>{renderContent()}</Suspense>
}
