import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { EntityTypeIdsT } from '@resnet/client-api/api'

export const entityTypeOptions = [
  { id: EntityTypeIdsT.WellT as const, name: 'Well' },
  { id: EntityTypeIdsT.BatteryT as const, name: 'Battery' },
  { id: EntityTypeIdsT.SiteT as const, name: 'Site' },
  { id: EntityTypeIdsT.CampaignT as const, name: 'Campaign' },
  { id: EntityTypeIdsT.InsightT as const, name: 'Insight' },
  { id: EntityTypeIdsT.IssueT as const, name: 'Issue' },
  { id: EntityTypeIdsT.RouteT as const, name: 'Route' },
  { id: EntityTypeIdsT.ResourceObjectT as const, name: 'Resource' },
  { id: EntityTypeIdsT.CredentialT as const, name: 'Credential' },
  { id: EntityTypeIdsT.FormObjectT as const, name: 'Form' },
]

export const entityTypeOptionsById = propToKey('id', entityTypeOptions)
