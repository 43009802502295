import { update } from '@resnet/client-common/common/utils/object/update'
import { useMapStateModel } from '@resnet/client-common/react/hooks/use-map-state-model'
import type { StateModelT } from '@resnet/client-common/react/types/state-model'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { type FilterPresetRuleFragmentT } from '@resnet/client-api/api'

export const useFilterRule = <FilterTypeT extends keyof FilterPresetRuleFragmentT>({
  filterPresetRuleModel,
  filterType,
}: {
  filterPresetRuleModel: StateModelT<FilterPresetRuleFragmentT>
  filterType: FilterTypeT
}) => {
  const filterPresetItemRulePayloadModel = useMapStateModel(
    filterPresetRuleModel,
    (filterPresetItemRule) => {
      return filterPresetItemRule[filterType]
    },
    (filterPresetItemRulePayload, filterPresetItemRule) => {
      return update(filterPresetItemRule, filterType, () => filterPresetItemRulePayload)
    },
  )

  return { filterPresetItemRulePayloadModel }
}

export const FilterRuleContainer = createHookContainer(useFilterRule)
