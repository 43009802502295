import { generatePath } from 'react-router-dom'

import type { IssueFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type IssueWithFeedPathnameT = Pick<IssueFragmentT, '__typename' | 'readableId'>

export const mapIssueToFeedPathname = <ValueT extends IssueWithFeedPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.issuesDetailsFeed, { issueId: value.readableId })
}
