import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldT } from '../../types/custom-field'

export const sectionHeaderCustomField = createConstrainer<CustomFieldT>()({
  id: CustomFieldTypeT.SectionHeaderT as const,
  name: 'Section Header',
  payload: null,
})
