import { useEffect, useState } from 'react'

import { subscribeTimeout } from '@resnet/client-common/web/utils/async/subscribe-timeout'

const defaultDebounceTime = 300

export const useDebounced = <ValueT>(value: ValueT, debounceTime = defaultDebounceTime): ValueT => {
  const [debounced, setDebounced] = useState(value)

  useEffect(
    () =>
      subscribeTimeout(() => {
        setDebounced(value)
      }, debounceTime),
    [value, debounceTime],
  )

  return debounced
}
