import { Box } from '@mui/material'

import { pluralize } from '@resnet/client-common/common/utils/string/pluralize'
import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import type { LikesQueryVariablesT } from '@resnet/client-api/api'
import { useLikesQuery } from '@resnet/client-api/api'

import { useProfileId } from '@resnet/client-shared/shared/users/hooks/use-profile-id'
import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'

import { Divider } from '@resnet/client-web/shared/gdl/components/divider'
import { Link } from '@resnet/client-web/shared/gdl/components/link'
import { ModalContent, createModalContainer, createModalHook } from '@resnet/client-web/shared/gdl/components/modal'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { UserCard, UserCardSkeleton } from '@resnet/client-web/shared/users/components/user-card'

import { messageTypeOptionById } from '../../constants/message-type-options'
import type { MessageT } from '../../types/message'
import { mapMessageToMessageType } from '../../utils/map-message-to-message-type'

type MessageCardLikedByModalPropsT = { message: MessageT }

export const MessageCardLikedByModal = ({ message }: MessageCardLikedByModalPropsT): React.ReactElement => {
  const { profileId } = useProfileId()

  const messageType = mapMessageToMessageType(message)

  const messageTypeOption = messageTypeOptionById[messageType]

  const filter: LikesQueryVariablesT['filter'] = (() => {
    switch (messageType) {
      case 'post': {
        return { postId: message.id }
      }
      case 'comment': {
        return { commentId: message.id }
      }
    }
  })()

  const likesQuery = useLikesQuery({ filter }, { select: (data) => data.listLikes.items })

  const renderHeaderTitle = () => {
    return `Who Liked The ${messageTypeOption.name}`
  }

  const renderUsers = () => {
    if (!likesQuery.isSuccess) {
      return Array.from({ length: 3 }, (item, index) => <UserCardSkeleton key={index} />)
    }

    const likes = likesQuery.data

    return likes.map((like) => {
      const { userId } = like

      const userIsCurrentUser = userId === profileId

      const type = !userIsCurrentUser ? undefined : 'You'

      return (
        <UserOptionContainer
          id={userId}
          key={userId}
        >
          {({ optionQuery: userQuery }) => {
            if (!userQuery.isSuccess) {
              return <UserCardSkeleton />
            }

            const user = userQuery.data

            return (
              <UserCard
                type={type}
                user={user}
              />
            )
          }}
        </UserOptionContainer>
      )
    })
  }

  const renderContent = () => {
    return (
      <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
        <ChildrenDivider dividerNode={<Divider sx={{ my: '8px' }} />}>{renderUsers()}</ChildrenDivider>
      </Box>
    )
  }

  return (
    <ModalContent sx={{ maxWidth: toPx(512), width: '100%' }}>
      {{
        content: renderContent(),
        header: { title: renderHeaderTitle() },
      }}
    </ModalContent>
  )
}

export const useMessageCardLikedByModal = createModalHook((props: MessageCardLikedByModalPropsT) => (
  <MessageCardLikedByModal {...props} />
))

export const MessageCardLikedByModalContainer = createModalContainer(useMessageCardLikedByModal)

export type MessageCardLikedByPropsT = { message: MessageT }

export const MessageCardLikedBy = ({ message }: MessageCardLikedByPropsT) => {
  const totalLikes = message.totalLikes ?? 0

  const renderLink = () => {
    return (
      <MessageCardLikedByModalContainer>
        {({ onOpen }) => (
          <Link
            sx={{ position: 'relative', textDecoration: 'none' }}
            type="button"
            onClick={() => {
              onOpen({ message })
            }}
          >
            {totalLikes} {pluralize(totalLikes, 'person')}
          </Link>
        )}
      </MessageCardLikedByModalContainer>
    )
  }

  return <>Liked by {renderLink()}</>
}
