import { Tooltip } from '@mui/material'
import { forwardRef } from 'react'

import { pluralize } from '@resnet/client-common/common/utils/string/pluralize'

import type { ButtonPropsT, ButtonRefT } from '../button'
import { Button } from '../button'

export const SelectMultipleButton = forwardRef(
  (
    {
      items,
      buttonSize,
      onClick,
      buttonIcon,
      itemIcon,
      label,
      itemName,
    }: {
      label: string
      itemIcon?: ButtonPropsT['icon']
      buttonIcon?: ButtonPropsT['icon']
      buttonSize: ButtonPropsT['size']
      items?: null | string[]
      onClick?: () => void
      itemName: string
    },
    ref: ButtonRefT,
  ) => {
    const renderContent = (): React.ReactNode => {
      if (!items || items.length === 0) {
        return label
      }

      if (items.length === 1) {
        return items[0]
      }

      return `${items.length} ${pluralize(items.length, itemName)}`
    }

    return (
      <Tooltip title={items?.join(', ')}>
        <Button
          color="primary"
          icon={!items || items.length === 0 ? buttonIcon : itemIcon}
          ref={ref}
          size={buttonSize}
          variant="outlined"
          onClick={onClick}
        >
          {renderContent()}
        </Button>
      </Tooltip>
    )
  },
)
