import type { SidesT } from '../types/sides'

import { mapNumberToSides } from './map-number-to-sides'
import { toPx } from './to-px'

export const mapPaddingToValue = ({
  padding: paddingActualActual,
  borderWidth: borderWidthActual = 0,
}: {
  padding: number | SidesT
  borderWidth?: number | SidesT
}) => {
  const paddingActual =
    typeof paddingActualActual === 'number' ? mapNumberToSides({ number: paddingActualActual }) : paddingActualActual

  const {
    bottom: paddingBottomActual = 0,
    left: paddingLeftActual = 0,
    right: paddingRightActual = 0,
    top: paddingTopActual = 0,
  } = paddingActual

  const borderWidth =
    typeof borderWidthActual === 'number' ? mapNumberToSides({ number: borderWidthActual }) : borderWidthActual

  const { bottom: borderBottom = 0, left: borderLeft = 0, right: borderRight = 0, top: borderTop = 0 } = borderWidth

  const padding = {
    bottom: paddingBottomActual - borderBottom,
    left: paddingLeftActual - borderLeft,
    right: paddingRightActual - borderRight,
    top: paddingTopActual - borderTop,
  }

  const { bottom: paddingBottom, left: paddingLeft, right: paddingRight, top: paddingTop } = padding

  if (paddingTop === paddingBottom && paddingTop === paddingLeft && paddingTop === paddingRight) {
    return toPx(paddingTop)
  }

  if (paddingTop === paddingBottom && paddingLeft === paddingRight) {
    return `${toPx(paddingTop)} ${toPx(paddingLeft)}`
  }

  if (paddingLeft === paddingRight) {
    return `${toPx(paddingTop)} ${toPx(paddingLeft)} ${toPx(paddingBottom)}`
  }

  return `${toPx(paddingTop)} ${toPx(paddingRight)} ${toPx(paddingBottom)} ${toPx(paddingLeft)}`
}
