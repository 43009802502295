import {
  useFollowersQuery,
  useFollowMutation,
  useInfiniteMyNotificationsReportQuery,
  useMyNotificationsReportQuery,
  useLikeCommentMutation,
  useLikePostMutation,
  useMarkAllAsReadMutation,
  useUnfollowMutation,
  useUnlikeCommentMutation,
  useUnlikePostMutation,
  useUpdateFollowersMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const infiniteMyNotificationReportQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteMyNotificationReportQueries = () =>
    findInfiniteQueries(queryClient, useInfiniteMyNotificationsReportQuery)

  const likePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikePostMutation, {
      onSuccess: () => {
        findInfiniteMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikePostMutation, {
      onSuccess: () => {
        findInfiniteMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const likeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikeCommentMutation, {
      onSuccess: () => {
        findInfiniteMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikeCommentMutation, {
      onSuccess: () => {
        findInfiniteMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const markAllAsReadHandlers = () => {
    return createMutationHookMutationCacheHandlers(useMarkAllAsReadMutation, {
      onError: () => {
        findInfiniteMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: () => {
        findInfiniteMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
      onSuccess: () => {
        findInfiniteMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    likePostHandlers(),
    unlikePostHandlers(),
    likeCommentHandlers(),
    unlikeCommentHandlers(),
    markAllAsReadHandlers(),
  ]
}

export const myNotificationReportQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findMyNotificationReportQueries = () => findQueries(queryClient, useMyNotificationsReportQuery)

  const likePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikePostMutation, {
      onSuccess: () => {
        findMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikePostMutation, {
      onSuccess: () => {
        findMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const likeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikeCommentMutation, {
      onSuccess: () => {
        findMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikeCommentMutation, {
      onSuccess: () => {
        findMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const markAllAsReadHandlers = () => {
    return createMutationHookMutationCacheHandlers(useMarkAllAsReadMutation, {
      onError: () => {
        findMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: () => {
        findMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
      onSuccess: () => {
        findMyNotificationReportQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    likePostHandlers(),
    unlikePostHandlers(),
    likeCommentHandlers(),
    unlikeCommentHandlers(),
    markAllAsReadHandlers(),
  ]
}

export const followersQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findFollowersQueries = () => findQueries(queryClient, useFollowersQuery)

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: () => {
        findFollowersQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: () => {
        findFollowersQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFollowersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFollowersMutation, {
      onSuccess: () => {
        findFollowersQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [followHandlers(), unfollowHandlers(), updateFollowersHandlers()]
}
