import type { UseQueryOptions } from '@tanstack/react-query'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { CampaignFragmentT, GetCampaignQueryT } from '@resnet/client-api/api'
import { useGetCampaignQuery } from '@resnet/client-api/api'

export type UseCampaignOptionPropsT = {
  id: string
  options?: UseQueryOptions<GetCampaignQueryT, unknown, CampaignFragmentT>
}

export const useCampaignOption = ({ id, options }: UseCampaignOptionPropsT) => {
  const optionQuery = useGetCampaignQuery({ id }, { ...options, select: (data) => data.getCampaign })

  return { optionQuery }
}

export const CampaignOptionContainer = createHookContainer(useCampaignOption)
