import classNames from 'classnames'
import { useFocused, useSelected } from 'slate-react'
import type { RenderElementProps, RenderLeafProps } from 'slate-react'

import styles from './styles.module.scss'

export const Mention = ({ attributes, children, element }: RenderElementProps): JSX.Element | null => {
  const selected = useSelected()

  const focused = useFocused()

  return element.type === 'mention' ? (
    <span
      className={classNames(styles.mention, selected && focused)}
      {...attributes}
      contentEditable={false}
    >
      @{element.character}
      {children}
    </span>
  ) : null
}

export const Element = (props: RenderElementProps): JSX.Element => {
  const { attributes, children, element } = props

  switch (element.type) {
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'mention':
      return <Mention {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps): JSX.Element => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}
