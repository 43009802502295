import { generatePath } from 'react-router-dom'

import type { FormObjectFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type FormObjectWithPathnameT = Pick<FormObjectFragmentT, '__typename' | 'readableId'>

export const mapFormObjectToPathname = <ValueT extends FormObjectWithPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.formObjectsDetails, { formObjectId: value.readableId })
}
