import { ApproveStatusT } from '@resnet/client-api/api'

export const ISSUE_LEVEL_APPROVAL_STATUS = {
  [ApproveStatusT.PendingT]: {
    feedbackLevel: 'feedbackWarning',
    name: 'Pending',
  },
  [ApproveStatusT.ApprovedT]: {
    feedbackLevel: 'feedbackSuccess',
    name: 'Approved',
  },
  [ApproveStatusT.RejectedT]: {
    feedbackLevel: 'feedbackCritical',
    name: 'Rejected',
  },
} as const
