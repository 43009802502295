import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { ThemePropsColorT } from '@resnet/client-shared/shared/gdl/themes'

export const propsBySize = createConstrainer<
  Record<
    string,
    {
      fontSize: number
      fontWeight: '400'
      iconOffset: number
      iconSize: number
      letterSpacing: number
      paddingHorizontal: number
      paddingVertical: number
    }
  >
>()({
  md: {
    fontSize: 12,
    fontWeight: '400',
    iconOffset: 4,
    iconSize: 16,
    letterSpacing: 0.8,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  sm: {
    fontSize: 10,
    fontWeight: '400',
    iconOffset: 4,
    iconSize: 12,
    letterSpacing: 0.8,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
})

export const propsByStatus = createConstrainer<
  Record<
    string,
    {
      backgroundColor: ThemePropsColorT
      borderColor: ThemePropsColorT
      color: ThemePropsColorT
    }
  >
>()({
  critical: {
    backgroundColor: 'surfaceNeutralDefault',
    borderColor: 'feedbackCritical',
    color: 'feedbackCritical',
  },
  default: {
    backgroundColor: 'surfaceNeutralDefault',
    borderColor: 'borderDefault',
    color: 'overBackgroundDefault',
  },
  info: {
    backgroundColor: 'surfaceNeutralDefault',
    borderColor: 'feedbackInfo',
    color: 'feedbackInfo',
  },
  primary: {
    backgroundColor: 'surfaceNeutralDefault',
    borderColor: 'basePrimary',
    color: 'basePrimary',
  },
  success: {
    backgroundColor: 'surfaceNeutralDefault',
    borderColor: 'feedbackSuccess',
    color: 'feedbackSuccess',
  },
  warning: {
    backgroundColor: 'surfaceNeutralDefault',
    borderColor: 'feedbackWarning',
    color: 'feedbackWarning',
  },
})
