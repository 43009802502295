import { Box, useEventCallback } from '@mui/material'
import { useEffect, useMemo } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { PathValue, UseControllerProps, UseControllerReturn } from 'react-hook-form'
import { useController, type FieldPathByValue, type FieldValues, type UseFormReturn, Controller } from 'react-hook-form'
import uuid4 from 'uuid4'

import { move } from '@resnet/client-common/common/utils/array/move'
import { pullById } from '@resnet/client-common/common/utils/array/pull-by-id'
import { push } from '@resnet/client-common/common/utils/array/push'
import { updateById } from '@resnet/client-common/common/utils/array/update-by-id'
import { checkNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { update } from '@resnet/client-common/common/utils/object/update'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { CustomFieldPayloadDropdownOptionFragmentT, CustomFieldPayloadDropdownT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import CircleSolidIcon from '@resnet/client-shared/assets/icons/circle-solid.svg'
import DragDotsSolidIcon from '@resnet/client-shared/assets/icons/drag-dots-solid.svg'
import MinusSolidIcon from '@resnet/client-shared/assets/icons/minus-solid.svg'
import PlusSolidIcon from '@resnet/client-shared/assets/icons/plus-solid.svg'
import { mapColorToPickerColorPreset } from '@resnet/client-shared/shared/common/utils/map-color-to-picker-color-preset'
import { mapPickerColorPresetToColor } from '@resnet/client-shared/shared/common/utils/map-picker-color-preset-to-color'
import { composeValidators } from '@resnet/client-shared/shared/forms/validators/compose-validators'
import { validateListRequired } from '@resnet/client-shared/shared/forms/validators/list-required'
import { createValidateMaxLength } from '@resnet/client-shared/shared/forms/validators/text-length'
import { validateTextRequired } from '@resnet/client-shared/shared/forms/validators/text-required'

import { mapIdToColor } from '@resnet/client-web/screens/field-dispatch-console-screen/modules/map/utils/map-id-to-color'
import { EmptyPanel } from '@resnet/client-web/shared/common/components/empty-panel'
import type { DraftableT } from '@resnet/client-web/shared/draftable/types/draftable'
import { SimpleStaticOptionsDropdownField } from '@resnet/client-web/shared/form-dalaran/components/common/simple-static-options-dropdown-field'
import { SwitchField } from '@resnet/client-web/shared/form-dalaran/components/common/switch-field'
import { TextField } from '@resnet/client-web/shared/form-dalaran/components/common/text-field'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { PresetColorSelector } from '@resnet/client-web/shared/gdl/components/color-selector'
import { Divider } from '@resnet/client-web/shared/gdl/components/divider'
import { FieldErrorText, FieldHelperText } from '@resnet/client-web/shared/gdl/components/field'
import { Popover } from '@resnet/client-web/shared/gdl/components/popover'
import { Popper, PopperContext } from '@resnet/client-web/shared/gdl/components/popper'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

import { mapOptionToOptionIdFromKey } from '../../utils/map-option-to-option-id-from-key'

const DropdownOptionColorPicker = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, NonNullable<CustomFieldPayloadDropdownOptionFragmentT>['color']>,
>({
  form,
  name,
  rules,
}: {
  form: UseFormReturn<TFieldValues>
  name: TPath
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
}): React.ReactNode => {
  const { field } = useController({ control: form.control, name, rules })

  const renderAnchor = (): React.ReactElement => {
    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, open }) => (
          <Box
            component="button"
            ref={setAnchorEl}
            sx={{
              all: 'unset',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            type="button"
            onClick={open}
          >
            <CircleSolidIcon
              color={mapPickerColorPresetToColor(field.value)}
              height={20}
              width={20}
            />
          </Box>
        )}
      </NonNullableContextContainer>
    )
  }

  const renderContent = (): React.ReactElement => {
    return (
      <NonNullableContextContainer Context={PopperContext}>
        {() => (
          <Popover sx={{ width: 'auto' }}>
            <PresetColorSelector
              disablePickerPopoverPortal
              value={field.value}
              onChange={(nextValue) => {
                field.onChange(nextValue as PathValue<TFieldValues, TPath>)
              }}
            />
          </Popover>
        )}
      </NonNullableContextContainer>
    )
  }

  return (
    <Popper placement="bottom-start">
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}

const DropdownOptionItem = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, NonNullable<CustomFieldPayloadDropdownOptionFragmentT>>,
>({
  id,
  form,
  name,
  index,
  onDeleteField,
  hasColorName,
}: {
  id: string
  form: UseFormReturn<TFieldValues>
  name: TPath
  hasColorName: FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['hasColor']>
  index: number
  onDeleteField?: ({ id }: { id: string }) => void
}) => {
  const onDelete = useEventCallback(() => {
    onDeleteField?.({ id })
  })

  const renderKey = () => {
    return (
      <TextField
        form={form}
        name={
          `${name}.key` as FieldPathByValue<TFieldValues, NonNullable<CustomFieldPayloadDropdownOptionFragmentT>['key']>
        }
        rules={{
          validate: composeValidators(validateTextRequired, createValidateMaxLength(60)),
        }}
      />
    )
  }

  const renderColorPickerButton = () => {
    return (
      <DropdownOptionColorPicker
        form={form}
        name={
          `${name}.color` as FieldPathByValue<
            TFieldValues,
            NonNullable<CustomFieldPayloadDropdownOptionFragmentT>['color']
          >
        }
      />
    )
  }

  const renderName = () => {
    return (
      <Controller
        control={form.control}
        name={hasColorName}
        render={({ field }) => (
          <TextField
            form={form}
            name={
              `${name}.name` as FieldPathByValue<
                TFieldValues,
                NonNullable<CustomFieldPayloadDropdownOptionFragmentT>['name']
              >
            }
            rules={{
              validate: composeValidators(validateTextRequired, createValidateMaxLength(60)),
            }}
            startSlot={!field.value ? undefined : renderColorPickerButton()}
          />
        )}
      />
    )
  }

  const renderKeyAndName = () => {
    return (
      <Box sx={{ display: 'flex', flexGrow: 1, gap: toPx(16), width: 0 }}>
        {renderKey()}
        {renderName()}
      </Box>
    )
  }

  const renderDeleteButton = () => {
    return (
      <Button
        color="default"
        icon={MinusSolidIcon}
        size="sm"
        sx={{ alignSelf: 'center' }}
        variant="contained"
        onClick={onDelete}
      />
    )
  }

  return (
    <Draggable
      draggableId={id}
      index={index}
    >
      {(draggable) => {
        const renderDragHandle = () => {
          return (
            <Box sx={{ alignSelf: 'center' }}>
              <Box
                {...draggable.dragHandleProps}
                sx={{ display: 'flex' }}
              >
                <DragDotsSolidIcon
                  fill={themeColors.overBackgroundBold}
                  height={16}
                  width={16}
                />
              </Box>
            </Box>
          )
        }

        return (
          <Box
            {...draggable.draggableProps}
            ref={draggable.innerRef}
            sx={{ alignItems: 'flex-start', display: 'flex', gap: toPx(8), marginTop: '8px' }}
          >
            {renderDragHandle()}
            {renderKeyAndName()}
            {renderDeleteButton()}
          </Box>
        )
      }}
    </Draggable>
  )
}

const DropdownOptionsField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['options']>,
>({
  form,
  name,
  rules,
  hasColorName,
  defaultValueName,
}: {
  form: UseFormReturn<TFieldValues>
  name: TPath
  hasColorName: FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['hasColor']>
  defaultValueName: FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['defaultValue']>
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
}): React.ReactNode => {
  const {
    field: { value: options, onChange },
    fieldState: { error },
  } = useController({
    control: form.control,
    name,
    rules,
  }) as unknown as UseControllerReturn<Record<string, DraftableT<CustomFieldPayloadDropdownOptionFragmentT>[]>>

  const hasColor = form.watch(hasColorName)

  const onAddOption = useEventCallback(() => {
    const id = uuid4()

    // TODO move away from fdc as separate util ?
    const color = !hasColor ? undefined : mapColorToPickerColorPreset(mapIdToColor(id))

    const newOption: DraftableT<CustomFieldPayloadDropdownOptionFragmentT> = {
      color,
      id,
      isDraft: true,
      key: '',
      name: '',
    }

    const nextOptions = push(options, newOption)

    onChange(nextOptions)
  })

  const onDeleteOption = useEventCallback(({ id }: { id: string }) => {
    const option = options.find((item) => item.id === id)

    assert(option, checkNonUndefined)

    const defaultValue = form.getValues(defaultValueName)

    if (defaultValue === option.key) {
      const nextDefaultValue = undefined as typeof defaultValue

      form.setValue(defaultValueName, nextDefaultValue)
    }

    const nextOptions = option.isDraft
      ? pullById(options, id)
      : updateById(options, id, (option) => update(option, 'isMarkedAsDeleted', () => true))

    onChange(nextOptions)
  })

  const onDragEnd = useEventCallback(({ destination, source }: DropResult) => {
    if (!destination) {
      return
    }

    onChange(move(options, source.index, destination.index))
  })

  useEffect(() => {
    const options = form.getValues(name) as CustomFieldPayloadDropdownOptionFragmentT[]

    options.forEach((option, index) => {
      type ColorPathT = FieldPathByValue<TFieldValues, NonNullable<CustomFieldPayloadDropdownOptionFragmentT>['color']>

      type ColorValueT = PathValue<TFieldValues, ColorPathT>

      const colorName = `${name}.${index}.color` as ColorPathT

      const colorValue = (!hasColor ? undefined : mapColorToPickerColorPreset(mapIdToColor(option.id))) as ColorValueT

      form.setValue(colorName, colorValue)
    })
  }, [form, hasColor, name])

  const renderAddNewOptionButton = () => {
    return (
      <Button
        color="primary"
        icon={PlusSolidIcon}
        size="md"
        variant="text"
        onClick={onAddOption}
      >
        Add Option
      </Button>
    )
  }

  const renderContent = () => {
    if (options.filter((option) => !option.isMarkedAsDeleted).length === 0) {
      return (
        <EmptyPanel>
          {{
            title: 'No options',
          }}
        </EmptyPanel>
      )
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="any">
            {(droppable) => (
              <Box ref={droppable.innerRef}>
                {options.map(({ id: optionId, isMarkedAsDeleted }, optionIndex) => {
                  if (isMarkedAsDeleted) {
                    return null
                  }

                  const fieldName = `${name}.${optionIndex}` as FieldPathByValue<
                    TFieldValues,
                    NonNullable<CustomFieldPayloadDropdownOptionFragmentT>
                  >

                  return (
                    <DropdownOptionItem
                      form={form}
                      hasColorName={hasColorName}
                      id={optionId}
                      index={optionIndex}
                      key={optionId}
                      name={fieldName}
                      onDeleteField={onDeleteOption}
                    />
                  )
                })}
                {droppable.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <Box sx={{ display: 'flex', gap: toPx(16), ml: '24px', mr: '40px', mt: '8px' }}>
          <FieldHelperText sx={{ flexGrow: 1, width: 0 }}>Key</FieldHelperText>
          <FieldHelperText sx={{ flexGrow: 1, width: 0 }}>Name</FieldHelperText>
        </Box>
      </Box>
    )
  }

  const renderFooter = () => {
    if (!error || Array.isArray(error)) {
      return null
    }

    return <FieldErrorText>{error.message}</FieldErrorText>
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {renderContent()}
      {renderAddNewOptionButton()}
      {renderFooter()}
    </Box>
  )
}

const DefaultValueField = <TFieldValues extends FieldValues>({
  form,
  name,
  hasColorName,
  optionsName,
}: {
  form: UseFormReturn<TFieldValues>
  name: FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['defaultValue']>
  hasColorName: FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['hasColor']>
  optionsName: FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['options']>
}) => {
  const hasColor = form.watch(hasColorName)

  const options = form.watch(optionsName)

  const availableOptions = useMemo(
    () => options.filter((option: CustomFieldPayloadDropdownOptionFragmentT) => !option.isMarkedAsDeleted),
    [options],
  )

  const optionsWithIdFromKey = mapOptionToOptionIdFromKey({
    hasColor,
    options: availableOptions,
  })

  return (
    <SimpleStaticOptionsDropdownField
      form={form}
      label="Default Value"
      name={name}
      options={optionsWithIdFromKey}
    />
  )
}

export type DropdownCustomFieldPayloadValueT = CustomFieldFragmentT

export type DropdownCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, DropdownCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const DropdownCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, DropdownCustomFieldPayloadValueT>,
>({
  form,
  name,
}: DropdownCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type RequiredPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['required']>

  const requiredName = `${name}.payload.${CustomFieldTypeT.DropdownT}.required` as RequiredPathT

  type HasColorPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['hasColor']>

  const hasColorName = `${name}.payload.${CustomFieldTypeT.DropdownT}.hasColor` as HasColorPathT

  type OptionsPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDropdownT['options']>

  const optionsName = `${name}.payload.${CustomFieldTypeT.DropdownT}.options` as OptionsPathT

  type DefaultValuePathT = FieldPathByValue<
    TFieldValues,
    undefined | null | CustomFieldPayloadDropdownT['defaultValue']
  >

  const defaultValueName = `${name}.payload.${CustomFieldTypeT.DropdownT}.defaultValue` as DefaultValuePathT

  const renderRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName}
      />
    )
  }

  const renderHasColorField = () => {
    return (
      <SwitchField
        form={form}
        label="Has Color"
        name={hasColorName}
      />
    )
  }

  const renderOptionsField = () => {
    return (
      <DropdownOptionsField
        defaultValueName={defaultValueName}
        form={form}
        hasColorName={hasColorName}
        name={optionsName}
        rules={{
          validate: validateListRequired,
        }}
      />
    )
  }

  const renderDefaultValueField = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <DefaultValueField
          form={form}
          hasColorName={hasColorName}
          name={defaultValueName}
          optionsName={optionsName}
        />
      </Box>
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderRequiredField()}
        {renderHasColorField()}
        <Divider />
        {renderOptionsField()}
        <Divider />
        {renderDefaultValueField()}
      </Box>
    )
  }

  return <>{renderSwitchFields()}</>
}
