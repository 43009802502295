import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import type { UploadResourceMutationVariablesT } from '@resnet/client-api/api'

import { diffUpdateResources } from '@resnet/client-shared/shared/resources/utils/diff-update-resources'

import type { ResourceT } from '../../files/types/resource'

import { useDeleteResources } from './use-delete-resources'
import { useUploadResources } from './use-upload-resources'

export const useUpdateResources = () => {
  const { deleteResources } = useDeleteResources()

  const { uploadResources } = useUploadResources()

  const updateResources = useEventCallback(
    async ({
      resources,
      nextResources,
      originType,
      originId,
    }: {
      resources?: undefined | null | ResourceT[]
      nextResources?: undefined | null | ResourceT[]
      originId: string
      originType: UploadResourceMutationVariablesT['originType']
    }) => {
      const { resourcesToUpload, resourcesToDelete } = diffUpdateResources(resources, nextResources)

      await Promise.all([
        deleteResources({
          resources: resourcesToDelete,
        }),
        uploadResources({
          originId,
          originType,
          resources: resourcesToUpload,
        }),
      ])
    },
  )

  return { updateResources }
}
