import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import { Link, type LinkProps } from 'react-router-dom'

import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

const getRegularCellSx = () => {
  return {
    alignItems: 'center',
    color: themeColors.overBackgroundDefault,
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    marginLeft: '-10px',
    marginRight: '-10px',
    paddingLeft: '10px',
    paddingRight: '10px',
  }
}

const getRegularCellMarkedAsDeletedSx = ({ isMarkedAsDeleted }: { isMarkedAsDeleted?: undefined | null | boolean }) => {
  if (isMarkedAsDeleted) {
    return {
      textDecoration: 'line-through',
    }
  }

  return null
}

export type RegularCellPropsT = MergeAllT<
  [
    BoxProps,
    {
      isMarkedAsDeleted?: undefined | null | boolean
    },
  ]
>

export const RegularCell = ({ children, isMarkedAsDeleted, sx = null, ...props }: RegularCellPropsT) => {
  return (
    <Box
      {...props}
      sx={[getRegularCellSx(), getRegularCellMarkedAsDeletedSx({ isMarkedAsDeleted }), sx].flat()}
    >
      {children}
    </Box>
  )
}

export type RegularLinkCellPropsT = MergeAllT<
  [
    BoxProps<'a'>,
    {
      to: LinkProps['to']
      isMarkedAsDeleted?: undefined | null | boolean
    },
  ]
>

export const RegularLinkCell = ({ children, isMarkedAsDeleted, sx = null, to, ...props }: RegularLinkCellPropsT) => {
  return (
    <Box
      {...props}
      component={Link}
      sx={[
        getRegularCellSx(),
        {
          textDecoration: 'none',
        },
        getRegularCellMarkedAsDeletedSx({ isMarkedAsDeleted }),
        sx,
      ].flat()}
      to={to}
    >
      {children}
    </Box>
  )
}
