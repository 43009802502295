import { Box, type SxProps } from '@mui/material'
import type { ControllerRenderProps, Path, PathValue, RegisterOptions } from 'react-hook-form'
import { useController, type UseFormReturn } from 'react-hook-form'

import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import { Field, FieldHelperText } from '@resnet/client-web/shared/gdl/components/field'
import { FieldErrorText } from '@resnet/client-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-web/shared/gdl/components/field'
import { TextInput } from '@resnet/client-web/shared/gdl/components/text-input'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export type TextFieldPropsT<FieldValuesT extends Record<string, AnyTypeT>, FieldNameT extends Path<FieldValuesT>> = {
  defaultValue?: PathValue<FieldValuesT, FieldNameT>
  disabled?: boolean
  disabledInputTooltipTitle?: string
  form: UseFormReturn<FieldValuesT>
  headerRight?: React.ReactNode
  helperText?: string
  label?: string
  name: FieldNameT
  placeholder?: string
  rules?: RegisterOptions<FieldValuesT, FieldNameT>
  startSlot?: React.ReactNode
  sx?: SxProps
  textArea?: boolean
  type?: string
}

const transformByType: Record<
  string,
  {
    input: (value: unknown) => string
    output: (e: React.ChangeEvent<HTMLInputElement>) => AnyTypeT
  }
> = {
  number: {
    input: (value?: number) => {
      if (!value) {
        return ''
      }

      return isNaN(value) || value === 0 ? '' : value.toString()
    },
    output: (e: React.ChangeEvent<HTMLInputElement>) => {
      const output = parseInt(e.target.value, 10)

      return isNaN(output) ? 0 : output
    },
  },
  numberFloat: {
    input: (value?: number) => {
      if (!value) {
        return ''
      }

      return isNaN(value) || value === 0 ? '' : value.toString()
    },
    output: (e: React.ChangeEvent<HTMLInputElement>) => {
      const output = parseFloat(e.target.value)

      return isNaN(output) ? 0 : output
    },
  },
}

const typeByActualType: Record<string, string> = {
  number: 'number',
  numberFloat: 'number',
}

const getFieldPropsByType = (field: ControllerRenderProps<AnyTypeT, AnyTypeT>, typeActual?: string) => {
  if (!typeActual) {
    return null
  }

  const transform = transformByType[typeActual]

  if (!transform) {
    return {
      type: typeByActualType[typeActual] ?? typeActual,
    }
  }

  return {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => field.onChange(transform.output(e)),
    type: typeByActualType[typeActual] ?? typeActual,
    value: transform.input(field.value),
  }
}

export const TextField = <FieldValuesT extends Record<string, AnyTypeT>, FieldNameT extends Path<FieldValuesT>>({
  defaultValue,
  disabled,
  disabledInputTooltipTitle,
  form,
  headerRight,
  helperText,
  label,
  name,
  placeholder = 'Type in here',
  rules,
  startSlot,
  sx,
  textArea,
  type,
}: TextFieldPropsT<FieldValuesT, FieldNameT>) => {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({ control: form.control, defaultValue, name, rules })

  const renderHeader = () => {
    if (!label && !headerRight) {
      return null
    }

    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
        {!label ? null : <FieldLabel>{label}</FieldLabel>}
        {headerRight}
      </Box>
    )
  }

  const renderInput = () => {
    return (
      <TextInput
        {...field}
        {...getFieldPropsByType(field, type)}
        component={textArea ? 'textarea' : 'input'}
        disabled={disabled}
        disabledTooltipTitle={disabledInputTooltipTitle}
        hasError={invalid}
        placeholder={placeholder}
        startSlot={startSlot}
        sx={[sx ?? null, !textArea ? null : { resize: 'vertical' }].flat()}
      />
    )
  }

  const renderHelperText = () => {
    if (!helperText) {
      return null
    }

    return <FieldHelperText>{helperText}</FieldHelperText>
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderHelperText()}
      {renderFooter()}
    </Field>
  )
}
