import type { UseQueryOptions } from '@tanstack/react-query'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { useGroupQuery, type GroupFragmentT, type GroupQueryT } from '@resnet/client-api/api'

export type UseGroupOptionPropsT = {
  id: string
  options?: UseQueryOptions<GroupQueryT, unknown, GroupFragmentT>
}

export const useGroupOption = ({ id, options }: UseGroupOptionPropsT) => {
  const optionQuery = useGroupQuery({ groupId: id }, { ...options, select: (data) => data.getGroup })

  return { optionQuery }
}

export const GroupOptionContainer = createHookContainer(useGroupOption)
