import { useRedeemCouponMutation, useInfiniteListMyCouponsQuery, useListMyCouponsQuery } from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const listMyCouponsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListMyCouponsQueries = () => findQueries(queryClient, useListMyCouponsQuery)

  const findInfiniteListMyCouponsQueries = () => findInfiniteQueries(queryClient, useInfiniteListMyCouponsQuery)

  const redeemCouponHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRedeemCouponMutation, {
      onSuccess: () => {
        findListMyCouponsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteListMyCouponsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [redeemCouponHandlers()]
}
