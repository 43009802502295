import type { SxProps } from '@mui/material'
import { Box, type BoxProps } from '@mui/material'
import { forwardRef, useMemo } from 'react'

import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import { mapColorToOverBackgroundColor } from '@resnet/client-shared/shared/gdl/utils/map-color-to-over-background-color'

import { themeColors } from '../../constants/theme-colors'
import { focusOutlineSx } from '../../sx-presets/focus-outline'

export type ColorSelectorButtonPropsT = MergeT<
  Omit<BoxProps<'button'>, 'ref' | 'color'>,
  {
    type?: 'button' | 'submit'
    disabled?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  }
> & {
  selected?: boolean
  color?: null | string
  icon?: ForwardableT<{ width: number; height: number }>
}

const rootSx: SxProps = [
  {
    all: 'unset',
  },
  {
    alignItems: 'center',
    borderColor: themeColors.borderDefault,
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    height: '32px',
    minWidth: 0,
    position: 'relative',
    transition: 'opacity 0.2s ease',
    width: '32px',
  },
  {
    '&:focus-visible': focusOutlineSx,
  },
  {
    '&:disabled, &[data-disabled="true"]': {
      backgroundColor: themeColors.actionsDisabled,
      borderColor: themeColors.actionsDisabled,
      color: themeColors.actionsOverDisabled,
      cursor: 'not-allowed',
    },
  },
  {
    '&:hover': {
      opacity: 0.8,
    },
  },
]

const contentSx: SxProps = [
  {
    alignItems: 'center',
    borderRadius: '6px',
    display: 'flex',
    gap: '8px',
    inset: '1px',
    justifyContent: 'center',
    position: 'absolute',
  },
]

const iconSize = 16

export const ColorSelectorButton = forwardRef<HTMLButtonElement, ColorSelectorButtonPropsT>(
  ({ icon, color, selected, ...restProps }, ref): React.ReactNode => {
    const renderIcon = (): React.ReactNode => {
      if (!icon) {
        return null
      }

      return renderForwardable(icon, { height: iconSize, width: iconSize })
    }

    const renderContent = (): React.ReactNode => {
      return <Box sx={[contentSx, { backgroundColor: color }].flat()}>{renderIcon()}</Box>
    }

    const rootSxColor = useMemo(() => {
      if (!icon) {
        return null
      }

      if (!color) {
        return themeColors.overBackgroundDefault
      }

      return mapColorToOverBackgroundColor(color)
    }, [color, icon])

    return (
      <Box
        component="button"
        ref={ref}
        sx={[
          rootSx,
          {
            color: rootSxColor,
          },
          !selected
            ? null
            : {
                borderColor: themeColors.basePrimary,
              },
        ].flat()}
        type="button"
        {...restProps}
      >
        {renderContent()}
      </Box>
    )
  },
)
