import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldT } from '../../types/custom-field'

export const dropdownCustomField = createConstrainer<CustomFieldT>()({
  id: CustomFieldTypeT.DropdownT as const,
  name: 'Dropdown',
  payload: createConstrainer<CustomFieldPayloadT>()({
    Dropdown: {
      defaultValue: null,
      hasColor: false,
      options: [],
      required: false,
    },
  }) as CustomFieldPayloadT,
})
