import { identity } from 'ramda'

import type { UseMutationOptions } from '@resnet/client-api/vendors/react-query'
import { useMutation } from '@resnet/client-api/vendors/react-query'

import { useAuthHTTPJSONPost } from '../api-fetchers/use-auth-http-json-post'
import { useUnauthorizedAuthHTTPJSONPost } from '../api-fetchers/use-unauthorized-auth-http-json-post'

export type SignInMutationVariablesT = {
  tenant: string
  email: string
  password: string
}

type SignInMutationDataT = {
  expired_in: number
  refreshToken: string
  token: string
  token_type: string
}

export const useSignInMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SignInMutationDataT, TError, SignInMutationVariablesT, TContext>,
) =>
  useMutation<SignInMutationDataT, TError, SignInMutationVariablesT, TContext>(
    ['SignIn'],
    useUnauthorizedAuthHTTPJSONPost<SignInMutationDataT, SignInMutationVariablesT>(() => '/sign-in', identity),
    options,
  )

export type ConfirmEmailMutationVariablesT = {
  tenant: string
  email: string
  confirmationCode: number
}

type ConfirmEmailMutationDataT = {
  token: string
  refreshToken: string
}

export const useConfirmEmailMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ConfirmEmailMutationDataT, TError, ConfirmEmailMutationVariablesT, TContext>,
) =>
  useMutation<ConfirmEmailMutationDataT, TError, ConfirmEmailMutationVariablesT, TContext>(
    ['ConfirmEmail'],
    useUnauthorizedAuthHTTPJSONPost<ConfirmEmailMutationDataT, ConfirmEmailMutationVariablesT>(
      () => '/confirm-email',
      identity,
    ),
    options,
  )

export type ResendConfirmEmailMutationVariablesT = {
  tenant: string
  email: string
}

type ResendConfirmEmailMutationDataT = boolean

export const useResendConfirmEmailMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ResendConfirmEmailMutationDataT, TError, ResendConfirmEmailMutationVariablesT, TContext>,
) =>
  useMutation<ResendConfirmEmailMutationDataT, TError, ResendConfirmEmailMutationVariablesT, TContext>(
    ['ResendConfirmEmail'],
    useUnauthorizedAuthHTTPJSONPost<ResendConfirmEmailMutationDataT, ResendConfirmEmailMutationVariablesT>(
      () => '/resend-confirm-email',
      identity,
    ),
    options,
  )

export type AcceptInviteMutationVariablesT = {
  tenant: string
  firstName: string
  lastName: string
  password: string
  token: string
  nickname: string
}

type AcceptInviteMutationDataT = {
  token: string
  refreshToken: string
}

export const useAcceptInviteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AcceptInviteMutationDataT, TError, AcceptInviteMutationVariablesT, TContext>,
) =>
  useMutation<AcceptInviteMutationDataT, TError, AcceptInviteMutationVariablesT, TContext>(
    ['AcceptInvite'],
    useUnauthorizedAuthHTTPJSONPost<AcceptInviteMutationDataT, AcceptInviteMutationVariablesT>(
      () => '/accept-invite',
      identity,
    ),
    options,
  )

export type RefreshTokensMutationVariablesT = {
  tenant: string
  refreshToken: string
}

export type RefreshTokensMutationDataT = {
  token: string
  refreshToken: string
}

export const useRefreshTokensMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<RefreshTokensMutationDataT, TError, RefreshTokensMutationVariablesT, TContext>,
) =>
  useMutation<RefreshTokensMutationDataT, TError, RefreshTokensMutationVariablesT, TContext>(
    ['RefreshTokens'],
    useUnauthorizedAuthHTTPJSONPost<RefreshTokensMutationDataT, RefreshTokensMutationVariablesT>(
      () => '/refresh-tokens',
      identity,
    ),
    options,
  )

export type PasswordRecoveryRequestMutationVariablesT = {
  tenant: string
  email: string
}

export type PasswordRecoveryRequestMutationDataT = boolean

export const usePasswordRecoveryRequestMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PasswordRecoveryRequestMutationDataT,
    TError,
    PasswordRecoveryRequestMutationVariablesT,
    TContext
  >,
) =>
  useMutation<PasswordRecoveryRequestMutationDataT, TError, PasswordRecoveryRequestMutationVariablesT, TContext>(
    ['PasswordRecoveryRequest'],
    useUnauthorizedAuthHTTPJSONPost<PasswordRecoveryRequestMutationDataT, PasswordRecoveryRequestMutationVariablesT>(
      () => '/password-recovery-request',
      identity,
    ),
    options,
  )

export type PasswordRecoveryConfirmMutationVariablesT = {
  tenant: string
  password: string
  code: string
}

export type PasswordRecoveryConfirmMutationDataT = {
  token: string
  refreshToken: string
}

export const usePasswordRecoveryConfirmMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PasswordRecoveryConfirmMutationDataT,
    TError,
    PasswordRecoveryConfirmMutationVariablesT,
    TContext
  >,
) =>
  useMutation<PasswordRecoveryConfirmMutationDataT, TError, PasswordRecoveryConfirmMutationVariablesT, TContext>(
    ['PasswordRecoveryConfirm'],
    useUnauthorizedAuthHTTPJSONPost<PasswordRecoveryConfirmMutationDataT, PasswordRecoveryConfirmMutationVariablesT>(
      () => '/password-recovery-confirm',
      identity,
    ),
    options,
  )

export type ChangePasswordMutationVariablesT = {
  currentPassword: string
  newPassword: string
}

export type ChangePasswordMutationDataT = {
  token: string
  refreshToken: string
}

export const useChangePasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ChangePasswordMutationDataT, TError, ChangePasswordMutationVariablesT, TContext>,
) =>
  useMutation<ChangePasswordMutationDataT, TError, ChangePasswordMutationVariablesT, TContext>(
    ['ChangePassword'],
    useAuthHTTPJSONPost<ChangePasswordMutationDataT, ChangePasswordMutationVariablesT>(
      () => '/change-password',
      identity,
    ),
    options,
  )

export type AdminChangePasswordMutationVariablesT = {
  email: string
  newPassword: string
}

export type AdminChangePasswordMutationDataT = {
  ok: number
  userId: string
}

export const useAdminChangePasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AdminChangePasswordMutationDataT,
    TError,
    AdminChangePasswordMutationVariablesT,
    TContext
  >,
) =>
  useMutation<AdminChangePasswordMutationDataT, TError, AdminChangePasswordMutationVariablesT, TContext>(
    ['AdminChangePassword'],
    useAuthHTTPJSONPost<AdminChangePasswordMutationDataT, AdminChangePasswordMutationVariablesT>(
      () => '/admin/change-password',
      identity,
    ),
    options,
  )

export type BlockUserMutationVariablesT = {
  id: string
}

export type BlockUserMutationDataT = boolean

export const useBlockUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<BlockUserMutationDataT, TError, BlockUserMutationVariablesT, TContext>,
) =>
  useMutation<BlockUserMutationDataT, TError, BlockUserMutationVariablesT, TContext>(
    useBlockUserMutation.getKey(),
    useAuthHTTPJSONPost<BlockUserMutationDataT, BlockUserMutationVariablesT>(() => '/block-user', identity),
    options,
  )

useBlockUserMutation.getKey = () => ['BlockUser']

export type UnblockUserMutationVariablesT = {
  id: string
}

export type UnblockUserMutationDataT = boolean

export const useUnblockUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UnblockUserMutationDataT, TError, UnblockUserMutationVariablesT, TContext>,
) =>
  useMutation<UnblockUserMutationDataT, TError, UnblockUserMutationVariablesT, TContext>(
    useUnblockUserMutation.getKey(),
    useAuthHTTPJSONPost<UnblockUserMutationDataT, UnblockUserMutationVariablesT>(() => '/unblock-user', identity),
    options,
  )

useUnblockUserMutation.getKey = () => ['UnblockUser']

export type SendInviteMutationVariablesT = {
  emails: string[]
}

export type SendInviteMutationDataT = { email: string; errors?: { code: string; message: string }[] }[]

export const useSendInviteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SendInviteMutationDataT, TError, SendInviteMutationVariablesT, TContext>,
) =>
  useMutation<SendInviteMutationDataT, TError, SendInviteMutationVariablesT, TContext>(
    useSendInviteMutation.getKey(),
    useAuthHTTPJSONPost<SendInviteMutationDataT, SendInviteMutationVariablesT>(() => '/send-invite', identity),
    options,
  )

useSendInviteMutation.getKey = () => ['SendInvite']

export type CancelInviteMutationVariablesT = {
  email: string
}

export type CancelInviteMutationDataT = { email: string }

export const useCancelInviteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CancelInviteMutationDataT, TError, CancelInviteMutationVariablesT, TContext>,
) =>
  useMutation<CancelInviteMutationDataT, TError, CancelInviteMutationVariablesT, TContext>(
    useCancelInviteMutation.getKey(),
    useAuthHTTPJSONPost<CancelInviteMutationDataT, CancelInviteMutationVariablesT>(() => '/cancel-invite', identity),
    options,
  )

useCancelInviteMutation.getKey = () => ['CancelInvite']
