import { checkIsQuerySuccess } from '@resnet/client-common/react-query/utils/check-is-query-success'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { UseQueryOptions, UseQueryResult } from '@resnet/client-api/vendors/react-query'

export type UseSuccessOptionPropsT<TQueryFnData, TData> = {
  useOption: (props: { id: string; options?: UseQueryOptions<TQueryFnData, unknown, TData> }) => {
    optionQuery: UseQueryResult<TData, unknown>
  }
  id: string
  options?: UseQueryOptions<TQueryFnData, unknown, TData>
}

export const useSuccessOption = <TQueryFnData, TData>({
  useOption,
  id,
  options,
}: UseSuccessOptionPropsT<TQueryFnData, TData>) => {
  const { optionQuery } = useOption({ id, options: { ...options, suspense: true } })

  assert(optionQuery, checkIsQuerySuccess)

  const option = optionQuery.data

  return { option }
}
