import { generatePath } from 'react-router-dom'

import type { WellFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type WellWithFeedPathnameT = Pick<WellFragmentT, '__typename' | 'readableId'>

export const mapWellToFeedPathname = <ValueT extends WellWithFeedPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.wellsDetailsFeed, { wellId: value.readableId })
}
