import type { AvatarTypeMap } from '@mui/material'
import { Avatar as MUIAvatar } from '@mui/material'

import type { ResourceFragmentT } from '@resnet/client-api/api'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { ImageResource } from '@resnet/client-web/shared/resources/components/image-resource'

export type AvatarPropsT = AvatarTypeMap['props'] & {
  maxFontSize?: number
  onClick?: () => void
  resource?: null | Pick<ResourceFragmentT, 'url' | 'optimized'>
  size?: number
}

const DEFAULT_SIZE = 48

const DEFAULT_MAX_FONT_SIZE = 20

const PADDING = 4

export const Avatar = ({
  alt,
  children,
  maxFontSize = DEFAULT_MAX_FONT_SIZE,
  onClick,
  resource,
  size = DEFAULT_SIZE,
  src,
  sx,
}: AvatarPropsT): React.ReactElement => {
  const renderContent = () => {
    if (resource) {
      return (
        <ImageResource
          alt={alt}
          resource={resource}
          sx={{
            borderRadius: '50%',
            height: toPx(size),
            lineHeight: toPx(size),
            objectFit: 'cover',
            textAlign: 'center',
            width: toPx(size),
          }}
        />
      )
    }

    return children
  }

  return (
    <MUIAvatar
      alt={alt}
      src={src}
      sx={[
        {
          backgroundColor: themeColors.surfaceNeutralDefault,
          borderColor: themeColors.borderDefault,
          borderStyle: 'solid',
          borderWidth: '1px',
          color: themeColors.overBackgroundDefault,
          flexShrink: 0,
          fontSize: `${Math.min(maxFontSize, size - PADDING * 2)}px`,
          height: `${size}px`,
          width: `${size}px`,
        },
        onClick
          ? {
              cursor: 'pointer',
            }
          : null,
        sx ?? null,
      ].flat()}
      onClick={onClick}
    >
      {renderContent()}
    </MUIAvatar>
  )
}
