import { Popover as MUIPopover } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

import { useToggle } from '@resnet/client-common/react/hooks/use-toggle'
import type { HookContainerChildrenT } from '@resnet/client-common/react/utils/create-hook-container'

type PopoverPropsT = React.ComponentProps<typeof MUIPopover> & {
  onClose?: () => void
}

export const Popover = (props: PopoverPropsT): React.ReactElement => (
  <MUIPopover
    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    transformOrigin={{ horizontal: 'left', vertical: -8 }}
    {...props}
  />
)

type PopoverControllerT = {
  isOpened: boolean
  setAnchorEl: (node: null | HTMLElement) => void
  onOpen: () => void
  onClose: () => void
}

type UsePopoverRenderContentT = (popoverController: PopoverControllerT) => React.ReactNode

type UsePopoverPopoverPropsT = Omit<PopoverPropsT, 'open' | 'anchorEl'>

export const usePopover = (renderContent: UsePopoverRenderContentT, popoverProps?: UsePopoverPopoverPropsT) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { value: isOpened, onOpen, onClose, onToggle } = useToggle()

  const popoverController = useMemo(
    () => ({ isOpened, onClose, onOpen, onToggle, setAnchorEl }),
    [isOpened, onClose, onOpen, onToggle],
  )

  const render = useCallback(() => {
    if (!isOpened || !anchorEl) {
      return null
    }

    return (
      <Popover
        {...popoverProps}
        open
        anchorEl={anchorEl}
        onClose={() => {
          onClose()
          popoverProps?.onClose?.()
        }}
      >
        {renderContent(popoverController)}
      </Popover>
    )
  }, [anchorEl, isOpened, onClose, popoverController, popoverProps, renderContent])

  return [render, popoverController] as const
}

export const PopoverContainer = ({
  renderContent,
  popoverProps,
  children,
}: {
  renderContent: UsePopoverRenderContentT
  popoverProps?: UsePopoverPopoverPropsT
  children: HookContainerChildrenT<PopoverControllerT>
}): React.ReactElement => {
  const [renderPopover, popoverController] = usePopover(renderContent, popoverProps)

  return (
    <>
      {children(popoverController)}
      {renderPopover()}
    </>
  )
}
