import type { QueryClient } from '@tanstack/react-query'

import { pullById } from '@resnet/client-common/common/utils/array/pull-by-id'
import { transform } from '@resnet/client-common/common/utils/object/transform'

import {
  useDeleteInsightPresetMutation,
  useGetMyDefaultInsightPresetQuery,
  useListInsightPresetsQuery,
  useSetMyDefaultInsightPresetMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const listInsightPresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListInsightPresetsQueries = () => findQueries(queryClient, useListInsightPresetsQuery)

  const deleteInsightPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteInsightPresetMutation, {
      onError: () => {
        findListInsightPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListInsightPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listInsightPresets: (listInsightPresets) =>
                transform(listInsightPresets, {
                  items: (items) => pullById(items, id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListInsightPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [deleteInsightPresetHandlers()]
}

export const getMyDefaultInsightPresetMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findGetMyDefaultInsightPreset = () => findQueries(queryClient, useGetMyDefaultInsightPresetQuery)

  const setMyDefaultInsightPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSetMyDefaultInsightPresetMutation, {
      onError: () => {
        findGetMyDefaultInsightPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultInsightPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultInsightPreset: () => id,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultInsightPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteInsightPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteInsightPresetMutation, {
      onError: () => {
        findGetMyDefaultInsightPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultInsightPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultInsightPreset: (getMyDefaultInsightPreset) =>
                getMyDefaultInsightPreset === id ? null : getMyDefaultInsightPreset,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultInsightPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [setMyDefaultInsightPresetHandlers(), deleteInsightPresetHandlers()]
}
