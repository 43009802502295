import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Editor, Range, Transforms } from 'slate'
import type { CustomTypes } from 'slate'
import { ReactEditor } from 'slate-react'

import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { MentionsMenu } from '@resnet/client-web/components/slate/mentions-menu'

import { insertMention } from '../utils/common'

import { useMentionGroups } from './use-mention-groups'

enum MOTION {
  UP = 'up',
  DOWN = 'down',
}

const getNextIndex = (items: unknown[], index: number): number => (index >= items.length - 1 ? 0 : index + 1)
const getPrevIndex = (items: unknown[], index: number): number => (index <= 0 ? items.length - 1 : index - 1)

export const useMentions = (editor: CustomTypes['Editor']): typeof result => {
  const [target, setTarget] = useState<Range | undefined>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [index, setIndex] = useState(0)
  const [groupIndex, setGroupIndex] = useState(0)
  const [search, setSearch] = useState('')

  const { mentionGroups: mentionGroupsActual } = useMentionGroups({ enabled: Boolean(target), search })

  const mentionGroups = useMemo(() => {
    return mentionGroupsActual.filter((mentionGroup) => mentionGroup.items.length)
  }, [mentionGroupsActual])

  const setIndexes = useCallback(
    (direction: MOTION) => {
      let nextGroupIndex = groupIndex
      let nextIndex =
        direction === MOTION.DOWN
          ? getNextIndex(mentionGroups[groupIndex].items, index)
          : getPrevIndex(mentionGroups[groupIndex].items, index)

      if (nextIndex === 0 && direction === MOTION.DOWN) {
        nextGroupIndex = getNextIndex(mentionGroups, groupIndex)
      } else if (index === 0 && direction === MOTION.UP) {
        nextGroupIndex = getPrevIndex(mentionGroups, groupIndex)
        nextIndex = mentionGroups[nextGroupIndex].items.length - 1
      }

      setIndex(nextIndex)
      setGroupIndex(nextGroupIndex)
    },
    [mentionGroups, groupIndex, index],
  )

  const applySuggestion = useCallback(() => {
    const value = mentionGroups[groupIndex].items[index]

    if (target && value) {
      Transforms.select(editor, target)

      switch (value.__typename) {
        case 'Well': {
          insertMention(editor, {
            entityTypeId: 'well',
            mentionId: value.id,
            mentionType: 'entity',
            name: value.name,
          })
          break
        }
        case 'Battery': {
          insertMention(editor, {
            entityTypeId: 'battery',
            mentionId: value.id,
            mentionType: 'entity',
            name: value.name,
          })
          break
        }
        case 'Site': {
          insertMention(editor, {
            entityTypeId: 'site',
            mentionId: value.id,
            mentionType: 'entity',
            name: value.name,
          })
          break
        }
        case 'Route': {
          insertMention(editor, {
            entityTypeId: 'route',
            mentionId: value.id,
            mentionType: 'entity',
            name: value.name,
          })
          break
        }
        case 'Group': {
          insertMention(editor, {
            mentionId: value.id,
            mentionType: 'group',
            name: value.name,
          })
          break
        }
        case 'User': {
          insertMention(editor, {
            mentionId: value.id,
            mentionType: 'user',
            name: mapUserToTitle(value),
          })
          break
        }
      }

      setTarget(undefined)

      // would be nice to have this in useEffect instead but I can't think of proper deps
      setTimeout(() => {
        ReactEditor.focus(editor)
      })
    }
  }, [editor, target, mentionGroups, index, groupIndex])

  useLayoutEffect(() => {
    if (target && mentionGroups.length > 0) {
      const domRange = ReactEditor.toDOMRange(editor, target)

      setAnchorEl(domRange.commonAncestorContainer.parentNode as HTMLElement)
    }
  }, [mentionGroups.length, editor, target])

  const onChangeWithMention = useCallback(() => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)

      // This is used to handle @ single char
      const wordBefore = Editor.before(editor, start, { unit: 'word' })

      const blockRange = wordBefore && Editor.range(editor, { offset: 0, path: wordBefore?.path }, wordBefore)
      const blockText = blockRange && Editor.string(editor, blockRange)
      const slicedBlockText = blockText ? blockText.slice(0, wordBefore.offset) : ''

      const atLastIndex = slicedBlockText.lastIndexOf('@')

      const modifiedWordBefore = wordBefore && { offset: atLastIndex, path: wordBefore.path }

      const currentWordRange = modifiedWordBefore && Editor.range(editor, start, modifiedWordBefore)

      // This is used for proper placement when going from @ to @\w
      const currentCharRange = Editor.range(editor, start, Editor.before(editor, start, { unit: 'character' }))
      const currentWordText = currentWordRange && Editor.string(editor, currentWordRange)
      const currentWordMatch = currentWordText && currentWordText.match(/@$/)

      const beforeRange = modifiedWordBefore && Editor.range(editor, modifiedWordBefore, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/@(.+)$/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)

      if (afterMatch) {
        if (beforeMatch) {
          setTarget(beforeRange)
          setSearch(beforeMatch[1])
          setIndex(0)
          setGroupIndex(0)

          return
        }

        if (currentWordMatch) {
          setTarget(currentCharRange)
          setSearch('')
          setIndex(0)
          setGroupIndex(0)

          return
        }
      }
    }

    setTarget(undefined)
  }, [editor, setSearch])

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (target) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            setIndexes(MOTION.DOWN)
            break
          case 'ArrowUp':
            event.preventDefault()
            setIndexes(MOTION.UP)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            applySuggestion()
            setTarget(undefined)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(undefined)
            setSearch('')
            break
        }
      }
    },
    [target, setIndexes, editor, applySuggestion, setSearch],
  )

  const mentionsMenuElement = useMemo(
    () => (
      <MentionsMenu
        anchorEl={anchorEl}
        currentGroupIndex={groupIndex}
        currentIndex={index}
        groups={mentionGroups}
        setGroupIndex={setGroupIndex}
        setIndex={setIndex}
        show={Boolean(target && mentionGroups.some(({ items }) => items.length))}
        onItemClick={applySuggestion}
      />
    ),
    [anchorEl, target, mentionGroups, index, groupIndex, applySuggestion],
  )

  const result = {
    mentionsMenuElement,
    onChangeWithMention,
    onKeyDownWithMention: onKeyDown,
  }

  return result
}
