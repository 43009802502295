import type { ExecutionResult } from 'graphql'
import { useEffect, useState } from 'react'

import { getGraphQlWsClient } from '@resnet/client-api/services/graphql-ws-client'

export const useGraphQLSubscription = <TData extends ExecutionResult<Record<string, unknown>>>(query: string) => {
  const [data, setData] = useState<null | TData>(null)
  const [error, setError] = useState<unknown>(null)
  const [isCompleted, setIsCompleted] = useState(false)

  useEffect(() => {
    let isConnectionClosed = false

    const unsubscribe = getGraphQlWsClient().subscribe(
      {
        query,
      },
      {
        complete: () => {
          if (isConnectionClosed) {
            return
          }

          setIsCompleted(true)
        },
        error: (error) => {
          setError(error)
        },
        next: (data: TData) => {
          setData(data)
        },
      },
    )

    return () => {
      isConnectionClosed = true
      unsubscribe()
    }
  }, [query])

  return {
    data,
    error,
    isCompleted,
    isError: Boolean(error),
  }
}
