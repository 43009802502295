import { useState } from 'react'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { transform } from '@resnet/client-common/common/utils/object/transform'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { SuccessOptionQueryContainer } from '@resnet/client-common/react-query/hooks/use-success-option-query'

import type { MinimalEntityFragmentT, SearchEntitiesQueryVariablesT } from '@resnet/client-api/api'
import { EntityTypeIdsT } from '@resnet/client-api/api'

import {
  entityTypeOptions,
  entityTypeOptionsById,
} from '@resnet/client-shared/shared/entities/constants/entity-type-options'
import { EntityOptionContainer } from '@resnet/client-shared/shared/entities/hooks/use-entity-option'
import { mapEntityToPathname } from '@resnet/client-shared/shared/entities/utils/map-entity-to-pathname'
import { mapEntityToTitle } from '@resnet/client-shared/shared/entities/utils/map-entity-to-title'

import { SimpleStaticOptionsDropdown } from '@resnet/client-web/shared/common/components/simple-static-options-dropdown'
import { EntityDropdown } from '@resnet/client-web/shared/entities/components/entity-dropdown'
import {
  ListField,
  ListFieldFooterColumn,
  ListFieldItemName,
  ListFieldItemReadableId,
  ListFieldItemTypeName,
} from '@resnet/client-web/shared/gdl/components/list-field'

export type EntityRelationsFieldValueT = undefined | string[]

export type EntityRelationsFieldPropsT<TFieldValues extends FieldValues> = {
  label: string
  form: UseFormReturn<TFieldValues>
  name: FieldPathByValue<TFieldValues, EntityRelationsFieldValueT>
}

export const EntityRelationsField = <TFieldValues extends FieldValues>({
  label,
  form,
  name,
}: EntityRelationsFieldPropsT<TFieldValues>) => {
  const { control } = form

  const {
    field: { value: valueActual, onChange: onChangeActual },
  } = useController({ control, name })

  const value = valueActual as Exclude<EntityRelationsFieldValueT, undefined>

  const onChange = onChangeActual as (value: Exclude<EntityRelationsFieldValueT, undefined>) => void

  const [activeEntityTypeId, setActiveEntityTypeId] = useState<EntityTypeIdsT>(EntityTypeIdsT.WellT)

  const onActiveEntityTypeIdChange = useEventCallback((optionId: undefined | null | EntityTypeIdsT) => {
    if (!optionId) {
      return
    }

    setActiveEntityTypeId(optionId)
  })

  const withEntityDropdownQueryVariables = (input: SearchEntitiesQueryVariablesT) => {
    if (!activeEntityTypeId) {
      return input
    }

    return transform(input, {
      filter: (filter) => transform(filter ?? {}, { entityTypeId: () => ({ in: [activeEntityTypeId] }) }),
    })
  }

  const onItemRemove = useEventCallback((item: string) => {
    onChange(value.filter((currentItem) => currentItem !== item))
  })

  const onItemDropdownChange = useEventCallback((item: undefined | null | string) => {
    if (!item) {
      return
    }

    onChange(value.concat([item]))
  })

  const renderItemName = (item: string) => {
    return (
      <ListFieldItemName
        ItemOptionContainer={EntityOptionContainer<MinimalEntityFragmentT>}
        item={item}
        mapItemOptionToPathname={mapEntityToPathname}
        mapItemOptionToTitle={mapEntityToTitle}
      />
    )
  }

  const renderItemTypeName = (item: string) => {
    return (
      <ListFieldItemTypeName
        ItemOptionContainer={EntityOptionContainer<MinimalEntityFragmentT>}
        ItemTypeOptionContainer={SuccessOptionQueryContainer<EntityTypeIdsT>}
        item={item}
        mapItemOptionToItemType={(x) => x.__typename as EntityTypeIdsT}
        mapItemTypeOptionToTitle={({ id: entityTypeId }) => entityTypeOptionsById[entityTypeId].name}
      />
    )
  }

  const renderItemReadableId = (item: string) => {
    return (
      <ListFieldItemReadableId
        ItemOptionContainer={EntityOptionContainer<MinimalEntityFragmentT>}
        item={item}
        mapItemOptionToShortReadableId={(x) => x.readableId}
      />
    )
  }

  const renderItemTypeSelectFooterColumn = () => {
    return (
      <ListFieldFooterColumn name="Entity type">
        <SimpleStaticOptionsDropdown
          options={entityTypeOptions}
          value={activeEntityTypeId}
          onChange={onActiveEntityTypeIdChange}
        />
      </ListFieldFooterColumn>
    )
  }

  const renderItemSelectFooterColumn = () => {
    return (
      <ListFieldFooterColumn name="Entity">
        <EntityDropdown
          exclude={value}
          value={null}
          withQueryVariables={withEntityDropdownQueryVariables}
          onChange={onItemDropdownChange}
        />
      </ListFieldFooterColumn>
    )
  }

  return (
    <ListField
      items={value}
      label={label}
      renderItemName={renderItemName}
      renderItemReadableId={renderItemReadableId}
      renderItemSelectFooterColumn={renderItemSelectFooterColumn}
      renderItemTypeName={renderItemTypeName}
      renderItemTypeSelectFooterColumn={renderItemTypeSelectFooterColumn}
      onItemRemove={onItemRemove}
    />
  )
}
