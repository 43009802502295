import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import type { HookContainerT } from '@resnet/client-common/react/utils/create-hook-container'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import { DropdownInput } from '@resnet/client-web/shared/gdl/components/dropdown-input'
import { Popover } from '@resnet/client-web/shared/gdl/components/popover'
import type { PopperPropsT } from '@resnet/client-web/shared/gdl/components/popper'
import { Popper, PopperContext } from '@resnet/client-web/shared/gdl/components/popper'
import type { SelectDropdownPropsT } from '@resnet/client-web/shared/gdl/components/select-dropdown'
import { SelectDropdown } from '@resnet/client-web/shared/gdl/components/select-dropdown'
import { SelectSearch } from '@resnet/client-web/shared/gdl/components/select-dropdown/components/select-search'
import type { AbstractOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'

import { DropdownText } from '../../gdl/components/dropdown-text'
import type { OptionContainerT } from '../../gdl/types/option-container'

export type SelectDropdownContainerT<QueryVariablesT extends object, OptionT extends AbstractOptionT> = HookContainerT<
  {
    exclude?: string[]
    withQueryVariables?: (input: QueryVariablesT) => QueryVariablesT
  },
  {
    dropdownProps: {
      isLoading: boolean
      optionHeight?: number
      options: OptionT[]
    }
    searchProps: {
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
      onClear: () => void
      value: string
    }
  }
>

export type CreateDropdownComponentPropsT<QueryVariablesT extends object, OptionT extends AbstractOptionT> = {
  OptionContainer?: OptionContainerT<OptionT>
  SelectDropdownContainer: SelectDropdownContainerT<QueryVariablesT, OptionT>
  getOptionLabel?: (option: OptionT) => string
  getOptionDescription?: (option: OptionT) => string
  getOptionMedia?: (option: OptionT) => MediaT
} & Pick<SelectDropdownPropsT<OptionT>, 'skeletonProps' | 'header' | 'optionHeight'>

export type DropdownComponentPropsT<QueryVariablesT extends object> = MergeT<
  Omit<PopperPropsT, 'children'>,
  {
    disablePortal?: boolean
    exclude?: string[]
    onClose?: () => void
    onChange: (value: undefined | null | string) => void
    placement?: React.ComponentProps<typeof Popper>['placement']
    value: undefined | null | string
    withQueryVariables?: (input: QueryVariablesT) => QueryVariablesT
    dropDownHeader?: React.ReactNode
    hasError?: boolean
    disabled?: boolean
    onCurrentValueQueryError?: () => void
  }
>

export const createDropdownComponent =
  <QueryVariablesT extends object, OptionT extends AbstractOptionT>({
    getOptionLabel,
    getOptionDescription,
    OptionContainer,
    SelectDropdownContainer,
    getOptionMedia,
    ...rest
  }: CreateDropdownComponentPropsT<QueryVariablesT, OptionT>) =>
  ({
    disablePortal,
    exclude,
    onChange,
    onClose,
    placement,
    value,
    withQueryVariables,
    dropDownHeader,
    hasError,
    disabled,
    onCurrentValueQueryError,
  }: DropdownComponentPropsT<QueryVariablesT>) => {
    const renderAnchor = (): React.ReactElement => {
      return (
        <NonNullableContextContainer Context={PopperContext}>
          {({ setAnchorEl, isOpened, open }) => (
            <DropdownInput
              disabled={disabled}
              hasError={hasError}
              isOpened={isOpened}
              ref={setAnchorEl}
              onClick={open}
            >
              <DropdownText
                OptionContainer={OptionContainer}
                getOptionLabel={getOptionLabel}
                getOptionMedia={getOptionMedia}
                value={value}
                onCurrentValueQueryError={onCurrentValueQueryError}
              />
            </DropdownInput>
          )}
        </NonNullableContextContainer>
      )
    }

    const renderContent = (): React.ReactElement => {
      return (
        <Popover>
          <SelectDropdownContainer
            exclude={exclude}
            withQueryVariables={withQueryVariables}
          >
            {({ searchProps, dropdownProps }) => (
              <SelectDropdown
                {...dropdownProps}
                {...rest}
                getOptionDescription={getOptionDescription}
                getOptionLabel={getOptionLabel}
                getOptionMedia={getOptionMedia}
                header={dropDownHeader}
                search={<SelectSearch {...searchProps} />}
                value={value}
                onChange={onChange}
              />
            )}
          </SelectDropdownContainer>
        </Popover>
      )
    }

    return (
      <Popper
        disablePortal={disablePortal}
        placement={placement}
        onClose={onClose}
      >
        {{
          anchor: renderAnchor,
          content: renderContent,
        }}
      </Popper>
    )
  }
