import { Box } from '@mui/material'

import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

export const Surface = forwardFunctionalComponentRef(
  (
    {
      sx,
      color = 'neutral',
      onClick,
      component,
      ...props
    }: MergeT<
      React.ComponentProps<typeof Box>,
      {
        color?: 'neutral' | 'variant' | 'primary'
        to?: string
      }
    >,
    ref: React.Ref<HTMLElement>,
  ): React.ReactElement => {
    const colorProps = {
      neutral: {
        backgroundColor: themeColors.surfaceNeutralDefault,
        hoverBackgroundColor: themeColors.surfaceNeutralHover,
        pressedBackgroundColor: themeColors.surfaceNeutralPressed,
      },
      primary: {
        backgroundColor: themeColors.surfacePrimaryDefault,
        hoverBackgroundColor: themeColors.surfacePrimaryHover,
        pressedBackgroundColor: themeColors.surfacePrimaryPressed,
      },
      variant: {
        backgroundColor: themeColors.surfaceVariantDefault,
        hoverBackgroundColor: themeColors.surfaceVariantHover,
        pressedBackgroundColor: themeColors.surfaceVariantPressed,
      },
    }[color]

    const isClickable = Boolean(onClick) || Boolean(props.to)

    return (
      <Box
        {...props}
        component={isClickable ? component ?? 'button' : 'div'}
        ref={ref}
        sx={[
          {
            all: 'unset',
          },
          {
            backgroundColor: colorProps.backgroundColor,
            boxSizing: 'border-box',
            display: 'block',
            position: 'relative',
          },
          !isClickable
            ? []
            : [
                {
                  cursor: 'pointer',
                },
                {
                  '&:active': { backgroundColor: colorProps.pressedBackgroundColor },
                  '&:hover': { backgroundColor: colorProps.hoverBackgroundColor },
                },
              ],
          sx ?? null,
        ].flat()}
        onClick={onClick}
      />
    )
  },
)
