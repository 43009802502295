import { type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { CheckboxCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/checkbox/custom-field-value'

import { LabelPreview } from '@resnet/client-web/shared/gdl/components/label'
import { SwitchPreview } from '@resnet/client-web/shared/gdl/components/switch'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type CheckboxFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | CheckboxCustomFieldValueT
}

export const CheckboxFormUserFormPreview = ({ field, value = false }: CheckboxFormUserFormPreviewPropsT) => {
  return (
    <ObjectDetailsRow>
      <LabelPreview
        description={field.name}
        size="md"
      >
        <SwitchPreview
          size="sm"
          value={value}
        />
      </LabelPreview>
    </ObjectDetailsRow>
  )
}
