import { useState } from 'react'

import { equalByValuesStrict } from '@resnet/client-common/common/utils/object/equal-by-values'
import type { StateModelT } from '@resnet/client-common/react/types/state-model'

/*
 * It works similar to useState, except instead of initial value (initializer function) it accepts createState function
 * and dependencies list. Every time dependencies changes it'll return newly created state during same render phase.
 * See: https://reactjs.org/docs/react-component.html#static-getderivedstatefromprops
 */

export const useDerivedState = <StateT>(
  createState: (state?: StateT) => StateT,
  deps: unknown[],
): StateModelT<StateT> => {
  const [depsState, setDepsState] = useState(deps)

  const [stateActual, setState] = useState(() => createState())

  let state = stateActual

  if (!equalByValuesStrict(depsState, deps)) {
    state = createState(stateActual)
    setDepsState(deps)
  }

  if (state !== stateActual) {
    setState(() => {
      return state
    })
  }

  return [state, setState]
}
