import { jwtDecode } from 'jwt-decode'
import { useMemo } from 'react'

import { memo } from '@resnet/client-common/common/utils/function/memo'

import type { UserRolesT } from '@resnet/client-api/api'
import { useAuth } from '@resnet/client-api/auth-api/auth-context'

export type ProfileTokenPayloadT = {
  roles: UserRolesT[]
  sub: string
  ten: string
}

const profileTokenDecodeMemoized = memo((token: string) => jwtDecode(token) as ProfileTokenPayloadT)

export const useMaybeProfileTokenPayload = () => {
  const { tokens } = useAuth()

  const profileTokenPayload = useMemo(() => {
    if (!tokens) {
      return null
    }

    const { accessToken } = tokens

    return profileTokenDecodeMemoized(accessToken)
  }, [tokens])

  return { profileTokenPayload }
}
