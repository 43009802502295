import produce from 'immer'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import {
  useDeleteResourceMutation,
  useInfiniteResourcesQuery,
  useResourcesQuery,
  useUploadResourceMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const resourcesQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findResourcesQueriesByRefId = ({ refId }: { refId: string }) => {
    return findQueries(queryClient, useResourcesQuery, (query) => {
      const variables = query.queryKey[1]

      if (variables && variables.filter && variables.filter.refId && variables.filter.refId.eq) {
        return variables.filter.refId.eq === refId
      }

      if (variables && variables.filter && variables.filter.refId && variables.filter.refId.notEq) {
        return variables.filter.refId.notEq !== refId
      }

      if (variables && variables.filter && variables.filter.refId && variables.filter.refId.in) {
        return variables.filter.refId.in.includes(refId)
      }

      return true
    })
  }

  const findResourcesQueriesByItemId = ({ id }: { id: string }) => {
    return findQueries(queryClient, useResourcesQuery, (query) => {
      return assertedNonNullable(query.state.data?.listResources?.items).some((item) => item.id === id)
    })
  }

  const deleteItemFromResourcesQueries = ({ id }: { id: string }) => {
    findResourcesQueriesByItemId({ id }).forEach((query) => {
      setQueryData(queryClient)(query)(
        produce((draft) => {
          const items = assertedNonNullable(draft?.listResources?.items)

          if (!items) {
            return
          }

          const index = items.findIndex((item) => item.id === id)

          if (index === -1) {
            return
          }

          items.splice(index, 1)
        }),
      )
    })
  }

  const uploadResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUploadResourceMutation, {
      onSuccess: (data, { originId }) => {
        findResourcesQueriesByRefId({ refId: originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceMutation, {
      onError: (error, { id }) => {
        findResourcesQueriesByItemId({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        deleteItemFromResourcesQueries({ id })
      },
      onSuccess: ({ deleteResource: { deleteResourceIds } }) => {
        for (const id of deleteResourceIds) {
          findResourcesQueriesByItemId({ id }).forEach(invalidateQuery(queryClient))
        }
      },
    })
  }

  return [uploadResourceHandlers(), deleteResourceHandlers()]
}

export const infiniteResourcesQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteResourcesQueriesByRefId = ({ refId }: { refId: string }) => {
    return findInfiniteQueries(queryClient, useInfiniteResourcesQuery, (query) => {
      const variables = query.queryKey[1]

      if (variables && variables.filter && variables.filter.refId && variables.filter.refId.eq) {
        return variables.filter.refId.eq === refId
      }

      if (variables && variables.filter && variables.filter.refId && variables.filter.refId.notEq) {
        return variables.filter.refId.notEq !== refId
      }

      if (variables && variables.filter && variables.filter.refId && variables.filter.refId.in) {
        return variables.filter.refId.in.includes(refId)
      }

      return true
    })
  }

  const findInfiniteResourcesQueriesByItemId = ({ id }: { id: string }) => {
    return findInfiniteQueries(queryClient, useInfiniteResourcesQuery, (query) => {
      return assertedNonNullable(query.state.data?.pages).some((page) =>
        page.listResources.items.some((item) => item.id === id),
      )
    })
  }

  const deleteItemFromInfiniteResourcesQueries = ({ id }: { id: string }) => {
    findInfiniteResourcesQueriesByItemId({ id }).forEach((query) => {
      setQueryData(queryClient)(query)(
        produce((draft) => {
          const pages = assertedNonNullable(draft?.pages)

          for (const page of pages) {
            const items = assertedNonNullable(page?.listResources?.items)

            if (!items) {
              return
            }

            const index = items.findIndex((item) => item.id === id)

            if (index === -1) {
              return
            }

            items.splice(index, 1)
          }
        }),
      )
    })
  }

  const uploadResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUploadResourceMutation, {
      onSuccess: (data, { originId }) => {
        findInfiniteResourcesQueriesByRefId({ refId: originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceMutation, {
      onError: (error, { id }) => {
        findInfiniteResourcesQueriesByItemId({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        deleteItemFromInfiniteResourcesQueries({ id })
      },
      onSuccess: ({ deleteResource: { deleteResourceIds } }) => {
        deleteResourceIds.forEach((id) => {
          findInfiniteResourcesQueriesByItemId({ id }).forEach(invalidateQuery(queryClient))
        })
      },
    })
  }

  return [uploadResourceHandlers(), deleteResourceHandlers()]
}
