import { generatePath } from 'react-router-dom'

import type { BatteryFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type BatteryWithPathnameT = Pick<BatteryFragmentT, '__typename' | 'readableId'>

export const mapBatteryToPathname = <ValueT extends BatteryWithPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.batteriesDetails, { batteryId: value.readableId })
}
