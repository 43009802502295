import EmptySolidIcon from '@resnet/client-shared/assets/icons/empty-solid.svg'

export const nullOptionHeight = 40

export const nullOptionId = '@null' as const

export const nullOption = { id: nullOptionId }

export const defaultNullOptionLabel = '(Blank)'

export const defaultNullOptionMedia = { icon: <EmptySolidIcon />, type: 'icon' as const }
