import produce from 'immer'

import type {
  InputResourceObjectCompanyT,
  ResourceObjectCompanyQueryT,
  ResourceObjectCompaniesQueryT,
} from '@resnet/client-api/api'
import {
  useCreateResourceObjectCompanyMutation,
  useDeleteResourceObjectCompanyMutation,
  useInfiniteResourceObjectCompaniesQuery,
  useResourceObjectCompanyQuery,
  useResourceObjectCompaniesQuery,
  useUpdateResourceObjectCompanyMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const resourceObjectCompanyQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findResourceObjectCompanyQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useResourceObjectCompanyQuery, (data) => data.getResourceObjectCompany, { id })

  const updateResourceObjectCompany = (
    data: undefined | ResourceObjectCompanyQueryT,
    { diff }: { diff: InputResourceObjectCompanyT },
  ) => {
    if (!data) {
      return
    }

    const item = data.getResourceObjectCompany

    Object.assign(item, diff)
  }

  const deleteResourceObjectCompanyHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceObjectCompanyMutation, {
      onSuccess: (data, { id }) => {
        findResourceObjectCompanyQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateResourceObjectCompanyHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateResourceObjectCompanyMutation, {
      onError: (error, { id }) => {
        findResourceObjectCompanyQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findResourceObjectCompanyQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateResourceObjectCompany(data, { diff }))),
        )
      },
      onSuccess: (data, { id }) => {
        findResourceObjectCompanyQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [deleteResourceObjectCompanyHandlers(), updateResourceObjectCompanyHandlers()]
}

export const resourceObjectCompaniesQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findResourceObjectCompaniesQueries = () => findQueries(queryClient, useResourceObjectCompaniesQuery)

  const findInfiniteResourceObjectCompaniesQueries = () =>
    findInfiniteQueries(queryClient, useInfiniteResourceObjectCompaniesQuery)

  const updateResourceObjectCompany = (
    data: undefined | ResourceObjectCompaniesQueryT,
    { id, diff }: { id: string; diff: InputResourceObjectCompanyT },
  ) => {
    if (!data) {
      return
    }

    const items = data.listResourceObjectCompanies.items

    const item = items.find((item) => item.id === id)

    if (!item) {
      return
    }

    Object.assign(item, diff)
  }

  const deleteResourceObjectCompany = (data: undefined | ResourceObjectCompaniesQueryT, { id }: { id: string }) => {
    if (!data) {
      return
    }

    const items = data.listResourceObjectCompanies.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createResourceObjectCompanyHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateResourceObjectCompanyMutation, {
      onSuccess: () => {
        findResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteResourceObjectCompanyHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceObjectCompanyMutation, {
      onError: () => {
        findResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findResourceObjectCompaniesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => deleteResourceObjectCompany(data, { id }))),
        )
        findInfiniteResourceObjectCompaniesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => deleteResourceObjectCompany(page, { id }))),
          ),
        )
      },
      onSuccess: () => {
        findResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateResourceObjectCompanyHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateResourceObjectCompanyMutation, {
      onError: () => {
        findResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findResourceObjectCompaniesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateResourceObjectCompany(data, { diff, id }))),
        )
        findInfiniteResourceObjectCompaniesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => updateResourceObjectCompany(page, { diff, id }))),
          ),
        )
      },
      onSuccess: () => {
        findResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectCompaniesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createResourceObjectCompanyHandlers(),
    deleteResourceObjectCompanyHandlers(),
    updateResourceObjectCompanyHandlers(),
  ]
}
