import { Box } from '@mui/material'
import { type FieldPathByValue, type FieldValues, type UseFormReturn } from 'react-hook-form'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { CustomFieldPayloadDateT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { DateField } from '@resnet/client-web/shared/form-dalaran/components/common/date-field'
import { SwitchField } from '@resnet/client-web/shared/form-dalaran/components/common/switch-field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export type DateCustomFieldPayloadValueT = CustomFieldFragmentT

export const DateCustomFieldPayloadDefaultValueContainer = createHookContainer(
  <TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, DateCustomFieldPayloadValueT>>({
    form,
    name,
  }: {
    form: UseFormReturn<TFieldValues>
    name: TPath
  }) => {
    const { watch } = form

    type AddTimeEnabledPathT = FieldPathByValue<
      TFieldValues,
      undefined | null | CustomFieldPayloadDateT['addTimeEnabled']
    >

    const addTimeEnabledName = `${name}.payload.${CustomFieldTypeT.DateT}.addTimeEnabled` as AddTimeEnabledPathT

    const addTimeEnabled = watch(addTimeEnabledName)

    return { addTimeEnabled }
  },
)

export type DateCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, DateCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const DateCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, DateCustomFieldPayloadValueT>,
>({
  form,
  name,
}: DateCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type RequiredPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDateT['required']>

  const requiredName = `${name}.payload.${CustomFieldTypeT.DateT}.required` as RequiredPathT

  type AddTimeEnabledPathT = FieldPathByValue<
    TFieldValues,
    undefined | null | CustomFieldPayloadDateT['addTimeEnabled']
  >

  const addTimeEnabledName = `${name}.payload.${CustomFieldTypeT.DateT}.addTimeEnabled` as AddTimeEnabledPathT

  type DefaultValuePathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDateT['defaultValue']>

  const defaultValueName = `${name}.payload.${CustomFieldTypeT.DateT}.defaultValue` as DefaultValuePathT

  const renderRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName}
      />
    )
  }

  const renderAddTimeEnabledField = () => {
    return (
      <SwitchField
        form={form}
        label="Add Time"
        name={addTimeEnabledName}
      />
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderRequiredField()}
        {renderAddTimeEnabledField()}
      </Box>
    )
  }

  const renderDefaultValue = () => {
    return (
      <DateCustomFieldPayloadDefaultValueContainer
        form={form}
        name={name}
      >
        {({ addTimeEnabled }) => (
          <Box sx={{ display: 'flex' }}>
            <DateField
              form={form}
              label="Default Value"
              name={defaultValueName}
              showTime={addTimeEnabled}
            />
          </Box>
        )}
      </DateCustomFieldPayloadDefaultValueContainer>
    )
  }

  return (
    <>
      {renderSwitchFields()}
      {renderDefaultValue()}
    </>
  )
}
