import { omit } from 'ramda'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { GetGameQueryVariablesT } from '@resnet/client-api/api'
import {
  useCreateGameMutation,
  useGetGameQuery,
  useInfiniteListGamesQuery,
  useUpdateGameMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findExactQuery, findInfiniteQueries } from '@resnet/client-api/utils/find-queries'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const listGamesQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findInfiniteListGamesQueries = () => findInfiniteQueries(queryClient, useInfiniteListGamesQuery)

  const invalidateQueries = () => {
    findInfiniteListGamesQueries()
      .filter(checkNonNullable)
      .forEach((query) => {
        queryClient.invalidateQueries(query.queryKey, { exact: true })
      })
  }

  const updateGameHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateGameMutation, {
      onSuccess: () => {
        invalidateQueries()
      },
    })
  }

  const createGameHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateGameMutation, {
      onSuccess: () => {
        invalidateQueries()
      },
    })
  }

  return [updateGameHandlers(), createGameHandlers()]
}

export const gameQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findGetGameQuery = ({ id }: { id: string }) => findExactQuery(queryClient, useGetGameQuery, { id })

  const invalidateQueries = (variables: Pick<GetGameQueryVariablesT, 'id'>) => {
    ;[findGetGameQuery(variables)].filter(checkNonNullable).forEach((query) => {
      queryClient.invalidateQueries(query.queryKey, { exact: true })
    })
  }

  const updateGameHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateGameMutation, {
      onMutate: ({ id, data: updateData }) => {
        ;[findGetGameQuery({ id })].filter(checkNonNullable).forEach((query) => {
          setQueryData(queryClient)(query)((data) => {
            const getGame = data?.getGame

            if (!getGame) {
              return data
            }

            return {
              ...data,
              getGame: Object.assign({}, getGame, omit(['issueTypes'], updateData)),
            }
          })
        })
      },
      onSuccess: (_data, variables) => {
        invalidateQueries(variables)
      },
    })
  }

  return [updateGameHandlers()]
}
