import { getNestedRoutes } from './get-nested-routes'

type WithPrefixT<PrefixT extends string, StringT extends string> = `${PrefixT}${Capitalize<StringT>}`

type FlattenRoutesKeysT<RoutesT extends Record<string, string | [string, Record<string, string>]>> = {
  [K in keyof RoutesT]: RoutesT[K] extends string
    ? K
    : K | WithPrefixT<Extract<K, string>, Extract<keyof RoutesT[K][1], string>>
}[keyof RoutesT]

export const flattenRoutes = <RoutesT extends Record<string, string | [string, Record<string, string>]>>(
  routes: RoutesT,
) =>
  Object.keys(routes).reduce((acc, key) => {
    const value = routes[key]

    if (typeof value === 'string') {
      Object.assign(acc, { [key]: value })
    } else {
      Object.assign(
        acc,
        { [key]: value[0] },
        Object.fromEntries(
          Object.entries(getNestedRoutes(value[0], value[1])).map(([nestedKey, nestedValue]) => [
            key + nestedKey[0].toUpperCase() + nestedKey.slice(1),
            nestedValue,
          ]),
        ),
      )
    }

    return acc
  }, {}) as Record<FlattenRoutesKeysT<RoutesT>, string>
