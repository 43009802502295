import type { UseQueryOptions } from '@tanstack/react-query'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { UserFragmentT, UserQueryT } from '@resnet/client-api/api'
import { useUserQuery } from '@resnet/client-api/api'

export type UseUserOptionPropsT = { id: string; options?: UseQueryOptions<UserQueryT, unknown, UserFragmentT> }

export const useUserOption = ({ id, options }: UseUserOptionPropsT) => {
  const optionQuery = useUserQuery({ id }, { ...options, select: (data) => data.getUser })

  return { optionQuery }
}

export const UserOptionContainer = createHookContainer(useUserOption)
