import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'

import type { MinimalEntityFragmentT } from '@resnet/client-api/api'

import type { MediaT } from '../../gdl/types/media'

import { mapEntityToIcon } from './map-entity-to-icon'
import { mapEntityToTitle } from './map-entity-to-title'

export const mapEntityToMedia = (entity: MinimalEntityFragmentT): MediaT => {
  if ('avatarId' in entity) {
    return {
      name: mapEntityToTitle(entity),
      resourceId: entity.avatarId,
      type: 'avatar',
    }
  }

  return {
    icon: mapEntityToIcon(entity) as ForwardableT<React.SVGProps<SVGSVGElement>>,
    type: 'iconAvatar',
  }
}
