import { Box, Tooltip } from '@mui/material'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import type { SkeletonItemT } from '@resnet/client-web/shared/async/hooks/use-loadable-list'
import { checkIsSkeletonItem, useLoadableList } from '@resnet/client-web/shared/async/hooks/use-loadable-list'
import { VirtualizedList, calculateMaxHeight } from '@resnet/client-web/shared/gdl/components/virtualized-list'
import type { AbstractOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'

import { Divider } from '../../components/divider'
import type { ListActionItemActionPropsT } from '../list'
import { RadioListItem, RadioListItemSkeleton } from '../radio-list'

import { SelectDropdownMessagePanel } from './components/select-dropdown-message-panel'
import type { SelectDropdownOptionsBottomBoundary } from './components/select-dropdown-options-boundary'
import { nullOptionHeight, nullOptionId } from './constants/null-option'
import { selectDropdownSizesConfig } from './constants/select-dropdown-config'
import { getSelectOptionLabel } from './utils/get-select-option-label'

export type SelectDropdownPropsT<OptionT extends AbstractOptionT> = {
  bottomBoundary?: React.ComponentProps<typeof SelectDropdownOptionsBottomBoundary>
  error?: string
  getOptionActions?: (option: OptionT) => ListActionItemActionPropsT[]
  getOptionDisabled?: (option: OptionT) => boolean
  getOptionLabel?: typeof getSelectOptionLabel<OptionT>
  getOptionDescription?: (option: OptionT) => React.ReactNode
  getOptionMedia?: (option: OptionT) => undefined | MediaT
  getOptionTooltip?: (option: OptionT) => undefined | string
  header?: React.ReactNode
  isLoading?: boolean
  notFoundMessage?: string
  onChange?: (value: undefined | null | OptionT['id']) => void
  optionHeight?: number
  options: undefined | null | OptionT[]
  search?: React.ReactNode
  skeletonProps?: React.ComponentProps<typeof RadioListItemSkeleton>
  value: undefined | null | OptionT['id']
}

export const SelectDropdown = <OptionT extends AbstractOptionT>({
  bottomBoundary,
  error,
  getOptionActions,
  getOptionDisabled,
  getOptionLabel = getSelectOptionLabel,
  getOptionDescription,
  getOptionMedia,
  getOptionTooltip,
  header,
  isLoading = false,
  notFoundMessage = 'No options found',
  onChange,
  optionHeight = selectDropdownSizesConfig.optionHeight,
  options,
  search,
  skeletonProps,
  value,
}: SelectDropdownPropsT<OptionT>): React.ReactElement => {
  if (!options) {
    throw new Error('should be non nullable')
  }

  const { data } = useLoadableList({
    data: options,
    isFetching: isLoading,
    skeletonsCount: selectDropdownSizesConfig.maxVisibleOptions,
  })

  const renderOptions = (): React.ReactNode => {
    if (error) {
      return (
        <Box sx={{ py: `${selectDropdownSizesConfig.listPadding}px` }}>
          <SelectDropdownMessagePanel>{error}</SelectDropdownMessagePanel>
        </Box>
      )
    }

    if (data.length === 0) {
      return (
        <Box sx={{ py: `${selectDropdownSizesConfig.listPadding}px` }}>
          <SelectDropdownMessagePanel>{notFoundMessage}</SelectDropdownMessagePanel>
        </Box>
      )
    }

    const renderItemTooltipWrapper = (option: OptionT | SkeletonItemT, child: React.ReactElement) => {
      if (checkIsSkeletonItem(option)) {
        return child
      }

      const tooltip = getOptionTooltip?.(option)

      if (!tooltip) {
        return child
      }

      return <Tooltip title={tooltip}>{child}</Tooltip>
    }

    return (
      <VirtualizedList
        data={data}
        gap={selectDropdownSizesConfig.gap}
        getItemSize={(item) => {
          if (item.id === nullOptionId) {
            return nullOptionHeight
          }

          return optionHeight
        }}
        maxHeight={calculateMaxHeight({ ...selectDropdownSizesConfig, optionHeight })}
        onEndReached={() => bottomBoundary?.onObserve?.()}
      >
        {({ item: option, style }) => {
          const isSelected = option.id === value

          return renderItemTooltipWrapper(
            option,
            <Box
              key={option.id}
              style={style}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              {checkIsSkeletonItem(option) ? (
                <RadioListItemSkeleton
                  sx={{ height: optionHeight }}
                  {...skeletonProps}
                />
              ) : (
                <RadioListItem
                  showNameTooltip
                  actions={getOptionActions?.(option)}
                  description={getOptionDescription?.(option)}
                  disabled={getOptionDisabled?.(option)}
                  isSelected={isSelected}
                  media={getOptionMedia?.(option)}
                  name={getOptionLabel(option)}
                  onClick={() => {
                    onChange?.(isSelected ? null : option.id)
                  }}
                />
              )}
            </Box>,
          )
        }}
      </VirtualizedList>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <ChildrenDivider dividerNode={<Divider />}>
        {search ?? null}
        {header ?? null}
        {renderOptions()}
      </ChildrenDivider>
    </Box>
  )
}
