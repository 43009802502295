import { Box } from '@mui/material'

import type { HookContainerT } from '@resnet/client-common/react/utils/create-hook-container'
import type { QueryT } from '@resnet/client-common/react-query/types/query'

import MinusSolidIcon from '@resnet/client-shared/assets/icons/minus-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { TextSkeleton } from '@resnet/client-web/shared/async/components/text-skeleton'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { FieldLabel, FieldReadonlyInput } from '@resnet/client-web/shared/gdl/components/field'
import { Field } from '@resnet/client-web/shared/gdl/components/field'
import { FieldHelperText } from '@resnet/client-web/shared/gdl/components/field'
import { Link } from '@resnet/client-web/shared/gdl/components/link'
import { TextOverflow } from '@resnet/client-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

import type { AbstractOptionT } from '../../types/abstract-option'

const readableIdColumnWidth = 112

const listItemPadding = 8

const listItemGap = 8

const listItemButtonWidth = 32

const columnSx = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: 0,
  }
}

export type ListFieldPropsT = {
  items: string[]
  label: string
  onItemRemove: (item: string) => void
  renderItemName: (item: string) => React.ReactNode
  renderItemReadableId: (item: string) => React.ReactNode
  renderItemSelectFooterColumn: () => React.ReactNode
  renderItemTypeName: (item: string) => React.ReactNode
  renderItemTypeSelectFooterColumn: () => React.ReactNode
}

export const ListField = ({
  items,
  label,
  onItemRemove,
  renderItemName,
  renderItemReadableId,
  renderItemSelectFooterColumn,
  renderItemTypeName,
  renderItemTypeSelectFooterColumn,
}: ListFieldPropsT) => {
  const renderLabel = () => {
    return <FieldLabel>{label}</FieldLabel>
  }

  const renderList = () => {
    if (items.length === 0) {
      return null
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {items.map((item) => {
          const renderItemNameAndItemTypeNameColumn = () => {
            return (
              <Box sx={columnSx()}>
                {renderItemName(item)}
                {renderItemTypeName(item)}
              </Box>
            )
          }

          const renderItemReadableIdColumn = () => {
            return (
              <Box
                sx={[
                  columnSx(),
                  {
                    flexGrow: 0,
                    width: toPx(readableIdColumnWidth - listItemPadding - listItemButtonWidth - listItemGap),
                  },
                ]}
              >
                {renderItemReadableId(item)}
              </Box>
            )
          }

          const renderRemoveButton = () => {
            return (
              <Button
                color="default"
                icon={<MinusSolidIcon />}
                size="sm"
                variant="contained"
                onClick={() => {
                  onItemRemove(item)
                }}
              />
            )
          }

          return (
            <Box
              key={item}
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: toPx(listItemGap),
                padding: toPx(listItemPadding),
              }}
            >
              {renderItemNameAndItemTypeNameColumn()}
              {renderItemReadableIdColumn()}
              {renderRemoveButton()}
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderFooter = () => {
    const renderReadableIdFooterColumn = () => {
      return (
        <Field sx={{ width: toPx(readableIdColumnWidth) }}>
          <FieldReadonlyInput value="-" />
          <FieldHelperText>UID</FieldHelperText>
        </Field>
      )
    }

    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
        {renderItemTypeSelectFooterColumn()}
        {renderItemSelectFooterColumn()}
        {renderReadableIdFooterColumn()}
      </Box>
    )
  }

  return (
    <Field>
      {renderLabel()}
      {renderList()}
      {renderFooter()}
    </Field>
  )
}

export type ListFieldWithoutTypeAndReadableIdPropsT = {
  items: string[]
  label: string
  onItemRemove: (item: string) => void
  renderItemName: (item: string) => React.ReactNode
  renderItemSelectFooterColumn: () => React.ReactNode
}

export const ListFieldWithoutTypeAndReadableId = ({
  items,
  label,
  onItemRemove,
  renderItemName,
  renderItemSelectFooterColumn,
}: ListFieldWithoutTypeAndReadableIdPropsT) => {
  const renderLabel = () => {
    return <FieldLabel>{label}</FieldLabel>
  }

  const renderList = () => {
    if (items.length === 0) {
      return null
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {items.map((item) => {
          const renderItemNameAndItemTypeNameColumn = () => {
            return <Box sx={columnSx()}>{renderItemName(item)}</Box>
          }

          const renderRemoveButton = () => {
            return (
              <Button
                color="default"
                icon={<MinusSolidIcon />}
                size="sm"
                variant="contained"
                onClick={() => {
                  onItemRemove(item)
                }}
              />
            )
          }

          return (
            <Box
              key={item}
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: toPx(listItemGap),
                padding: toPx(listItemPadding),
              }}
            >
              {renderItemNameAndItemTypeNameColumn()}
              {renderRemoveButton()}
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderFooter = () => {
    return <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>{renderItemSelectFooterColumn()}</Box>
  }

  return (
    <Field>
      {renderLabel()}
      {renderList()}
      {renderFooter()}
    </Field>
  )
}

export type ListFieldItemNamePropsT<ItemOptionT extends AbstractOptionT> = {
  item: string
  ItemOptionContainer: HookContainerT<{ id: string }, { optionQuery: QueryT<ItemOptionT> }>
  mapItemOptionToPathname: (itemOption: ItemOptionT) => string
  mapItemOptionToTitle: (itemOption: ItemOptionT) => string
}

export const ListFieldItemName = <ItemOptionT extends AbstractOptionT>({
  item,
  ItemOptionContainer,
  mapItemOptionToPathname,
  mapItemOptionToTitle,
}: ListFieldItemNamePropsT<ItemOptionT>) => {
  return (
    <ItemOptionContainer id={item}>
      {({ optionQuery: itemOptionQuery }) => {
        if (!itemOptionQuery.isSuccess) {
          return (
            <TextSkeleton
              contentSx={{ width: '70%' }}
              typographyPreset={typographyPresets.titleMedium}
            />
          )
        }

        const itemOption = itemOptionQuery.data

        return (
          <Box sx={{ display: 'flex' }}>
            <Link
              sx={[
                mapTypographyPresetToSx(typographyPresets.titleMedium),
                {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              ]}
              to={mapItemOptionToPathname(itemOption)}
            >
              {mapItemOptionToTitle(itemOption)}
            </Link>
          </Box>
        )
      }}
    </ItemOptionContainer>
  )
}

export type ListFieldItemTypeNamePropsT<
  ItemOptionT extends AbstractOptionT,
  ItemTypeOptionT extends AbstractOptionT,
> = {
  item: string
  ItemOptionContainer: HookContainerT<{ id: string }, { optionQuery: QueryT<ItemOptionT> }>
  ItemTypeOptionContainer: HookContainerT<{ id: string }, { optionQuery: QueryT<ItemTypeOptionT> }>
  mapItemOptionToItemType: (itemOption: ItemOptionT) => string
  mapItemTypeOptionToTitle: (itemTypeOption: ItemTypeOptionT) => string
}

export const ListFieldItemTypeName = <ItemOptionT extends AbstractOptionT, ItemTypeOptionT extends AbstractOptionT>({
  item,
  ItemOptionContainer,
  ItemTypeOptionContainer,
  mapItemOptionToItemType,
  mapItemTypeOptionToTitle,
}: ListFieldItemTypeNamePropsT<ItemOptionT, ItemTypeOptionT>) => {
  return (
    <ItemOptionContainer id={item}>
      {({ optionQuery: itemOptionQuery }) => {
        const skeletonElement = (
          <TextSkeleton
            contentSx={{ width: '50%' }}
            typographyPreset={typographyPresets.bodySmall}
          />
        )

        if (!itemOptionQuery.isSuccess) {
          return skeletonElement
        }

        const itemOption = itemOptionQuery.data

        const itemType = mapItemOptionToItemType(itemOption)

        return (
          <ItemTypeOptionContainer id={itemType}>
            {({ optionQuery: itemTypeOptionQuery }) => {
              if (!itemTypeOptionQuery.isSuccess) {
                return skeletonElement
              }

              const itemTypeOption = itemTypeOptionQuery.data

              return (
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={[
                      mapTypographyPresetToSx(typographyPresets.bodySmall),
                      {
                        color: themeColors.overBackgroundMuted,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      },
                    ]}
                  >
                    {mapItemTypeOptionToTitle(itemTypeOption)}
                  </Box>
                </Box>
              )
            }}
          </ItemTypeOptionContainer>
        )
      }}
    </ItemOptionContainer>
  )
}

export type ListFieldItemReadableIdPropsT<ItemOptionT extends AbstractOptionT> = {
  item: string
  ItemOptionContainer: HookContainerT<{ id: string }, { optionQuery: QueryT<ItemOptionT> }>
  mapItemOptionToShortReadableId: (itemOption: ItemOptionT) => string
}

export const ListFieldItemReadableId = <ItemOptionT extends AbstractOptionT>({
  item,
  ItemOptionContainer,
  mapItemOptionToShortReadableId,
}: ListFieldItemReadableIdPropsT<ItemOptionT>) => {
  return (
    <ItemOptionContainer id={item}>
      {({ optionQuery: itemOptionQuery }) => {
        if (!itemOptionQuery.isSuccess) {
          return <TextSkeleton typographyPreset={typographyPresets.bodySmall} />
        }

        const itemOption = itemOptionQuery.data

        const shortReadableId = mapItemOptionToShortReadableId(itemOption)

        return (
          <Box
            sx={[
              mapTypographyPresetToSx(typographyPresets.bodySmall),
              { color: themeColors.overBackgroundBold, display: 'flex', justifyContent: 'flex-end' },
            ]}
          >
            <TextOverflow>{shortReadableId}</TextOverflow>
          </Box>
        )
      }}
    </ItemOptionContainer>
  )
}

export type ListFieldFooterColumnPropsT = { name: string; children: React.ReactNode }

export const ListFieldFooterColumn = ({ name, children }: ListFieldFooterColumnPropsT) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(8), width: 0 }}>
      {children}
      <FieldHelperText>{name}</FieldHelperText>
    </Box>
  )
}
