import { useMemo, useCallback } from 'react'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { AbstractSimpleOptionT } from '../../../types/abstract-option'
import { getSimpleOptionLabel } from '../../../utils/get-simple-option-label'
import type { SelectPropsT } from '../../select'

export const useSimpleNonNullableSelect = <OptionT extends Readonly<AbstractSimpleOptionT>>({
  options,
  value: valueProp,
  onChange: onChangeProp,
}: {
  options: readonly OptionT[]
  value: OptionT['id']
  onChange?: (value: OptionT['id']) => void
}) => {
  const value = useMemo(
    () => assertedNonNullable(options.find((option) => option.id === valueProp)),
    [options, valueProp],
  )

  const onChange = useCallback(
    (option: null | OptionT) => {
      if (!option) {
        return
      }

      onChangeProp?.(option.id)
    },
    [onChangeProp],
  )

  const children = useCallback<SelectPropsT<OptionT>['children']>(
    ({ children, ...rest }) => children({ ...rest, options }),
    [options],
  )

  return { children, getOptionLabel: getSimpleOptionLabel, onChange, options, value }
}

export const SimpleNonNullableSelectContainer = createHookContainer(useSimpleNonNullableSelect)
