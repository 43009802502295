import type { SxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'
import type { UseQueryOptions } from '@tanstack/react-query'
import { Suspense, useCallback, useContext, useMemo, useState } from 'react'

import type { MaybeT } from '@resnet/client-common/common/types/common'
import { assertedNonNullable, assertedNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { withNested } from '@resnet/client-common/react/enhancers/with-nested'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'
import { checkIsQuerySuccess } from '@resnet/client-common/react-query/utils/check-is-query-success'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type {
  CampaignFragmentT,
  GetCampaignQueryT,
  GetCampaignQueryVariablesT,
  IssueFragmentT,
} from '@resnet/client-api/api'
import {
  SortDirectionT,
  useGetCampaignQuery,
  useCampaignTotalIssuesQuery,
  useListCampaignsQuery,
  useDeleteCampaignMutation,
  useUpdateIssueMutation,
} from '@resnet/client-api/api'
import type { UpdateIssueMutationMetaT } from '@resnet/client-api/modules/issue/types/meta'

import CircleDotsSolidIcon from '@resnet/client-shared/assets/icons/circle-dots-solid.svg'
import DeleteSolidIcon from '@resnet/client-shared/assets/icons/delete-solid.svg'
import ErrorSolidIcon from '@resnet/client-shared/assets/icons/error-solid.svg'
import { campaignColorOptionsById } from '@resnet/client-shared/shared/campaigns/constants/campaign-color-options'
import { mapCampaignToTitle } from '@resnet/client-shared/shared/campaigns/utils/map-campaign-to-title'

import { DeleteModalContent } from '@resnet/client-web/shared/common/components/delete-modal-content'
import type { ButtonButtonPropsT } from '@resnet/client-web/shared/gdl/components/button'
import { Button, ButtonBase } from '@resnet/client-web/shared/gdl/components/button'
import { ModalContext, useModal } from '@resnet/client-web/shared/gdl/components/modal'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { usePopover } from '@resnet/client-web/shared/gdl/legacy-components/popover'
import { SelectDropdown } from '@resnet/client-web/shared/gdl/legacy-components/select-dropdown'
import { mapGradientToCSSGradient } from '@resnet/client-web/shared/gradients/utils/map-gradient-to-css-gradient'

type DeleteCampaignModalContentPropsT = { params: { campaign: CampaignFragmentT } }

const DeleteCampaignModalContent = withNested<DeleteCampaignModalContentPropsT, DeleteCampaignModalContentPropsT>(
  (Nested) => (props) =>
    (
      <Suspense fallback={null}>
        <Nested {...props} />
      </Suspense>
    ),
  ({ params }) => {
    const campaignId = params.campaign.id

    const campaignTotalIssuesQuery = useCampaignTotalIssuesQuery(
      { campaignId },
      { select: (data) => assertedNonNullable(data.campaignTotalIssues), suspense: true },
    )

    assert(campaignTotalIssuesQuery, checkIsQuerySuccess)

    const campaignTotalIssues = campaignTotalIssuesQuery.data

    const { onClose } = assertedNonNullable(useContext(ModalContext))

    const { mutate: deleteCampaignMutationMutate } = useDeleteCampaignMutation({ onSuccess: onClose })

    const onDelete = useCallback(() => {
      deleteCampaignMutationMutate({ id: campaignId })
    }, [deleteCampaignMutationMutate, campaignId])

    return (
      <DeleteModalContent
        targetName={params.campaign.name}
        onCancel={onClose}
        onDelete={onDelete}
      >
        {{
          cancelButtonLabel: 'Cancel',
          deleteButtonLabel: 'Delete Campaign',
          description: (
            <>
              <Box sx={{ color: themeColors.overBackgroundBold, fontSize: '14px', lineHeight: '120%' }}>
                You are about to delete{' '}
                <Box
                  component="span"
                  sx={{ fontWeight: 700 }}
                >
                  {params.campaign.name}
                </Box>{' '}
                campaign.
              </Box>
              <Box sx={{ color: themeColors.overBackgroundBold, fontSize: '14px', lineHeight: '120%' }}>
                If you proceed, the campaign will be deleted and removed from {campaignTotalIssues} issues it has been
                applied to.
              </Box>
              <Box
                sx={{
                  color: themeColors.overBackgroundBold,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '120%',
                }}
              >
                Please type in the campaign name below to delete it.
              </Box>
            </>
          ),
          header: { title: 'Delete Campaign' },
        }}
      </DeleteModalContent>
    )
  },
)

const SelectIssueCampaignDropdownContainer = createHookContainer(
  ({
    value,
    onChange,
    onDeleteCampaign,
  }: {
    value: undefined | MaybeT<CampaignFragmentT>
    onChange?: (value: MaybeT<CampaignFragmentT>) => void
    onDeleteCampaign?: (params: DeleteCampaignModalContentPropsT['params']) => void
  }) => {
    const [search, setSearch] = useState('')

    const onSearchChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
      setSearch(event.currentTarget.value)
    }, [])

    const dropdownSearch = useMemo((): React.ComponentProps<typeof SelectDropdown>['search'] => {
      return { onChange: onSearchChange, placeholder: 'Type to search or create...', value: search }
    }, [search, onSearchChange])

    const campaignsQuery = useListCampaignsQuery(
      { filter: { name: { startsWith: search } }, sort: { tag: SortDirectionT.AscT } },
      { keepPreviousData: true, select: (data) => assertedNonNullable(data.listCampaigns?.items) },
    )

    const getOptionActions = useCallback(
      (campaign: CampaignFragmentT) => [
        {
          Icon: DeleteSolidIcon,
          id: 'delete',
          onClick: () => {
            onDeleteCampaign?.({ campaign })
          },
        },
      ],
      [onDeleteCampaign],
    )

    return {
      getOptionActions,
      getOptionLabel: mapCampaignToTitle,
      isLoading: campaignsQuery.isLoading,
      notFoundMessage: 'No Campaigns Found',
      onChange,
      options: campaignsQuery.data,
      search: dropdownSearch,
      value,
    }
  },
)

export const IssueCampaignSelect = ({
  buttonSize = 'sm',
  buttonVariant = 'text',
  value,
  onChange: onChangeProp,
  sx = null,
}: {
  buttonSize?: ButtonButtonPropsT['size']
  buttonVariant?: ButtonButtonPropsT['variant']
  value: MaybeT<CampaignFragmentT>
  onChange?: (value: MaybeT<CampaignFragmentT>) => void
  sx?: SxProps
}): React.ReactElement => {
  const [
    renderSelectIssueCampaignPopover,
    { setAnchorEl, onOpen: onOpenSelectCampaignDropdown, onClose: onCloseSelectCampaignDropdown },
  ] = usePopover(() => (
    <SelectIssueCampaignDropdownContainer
      value={value}
      onChange={onChange}
      onDeleteCampaign={onDeleteCampaign}
    >
      {(props) => <SelectDropdown<CampaignFragmentT> {...props} />}
    </SelectIssueCampaignDropdownContainer>
  ))

  const onChange = useCallback(
    (value: MaybeT<CampaignFragmentT>) => {
      onCloseSelectCampaignDropdown()
      onChangeProp?.(value)
    },
    [onCloseSelectCampaignDropdown, onChangeProp],
  )

  const [renderDeleteCampaignModal, { onOpen: onOpenDeleteCampaignModal }] = useModal<
    DeleteCampaignModalContentPropsT['params']
  >((params) => <DeleteCampaignModalContent params={params} />)

  const onDeleteCampaign = useCallback(
    ({ campaign }: DeleteCampaignModalContentPropsT['params']) => {
      onOpenDeleteCampaignModal({ campaign })
      onCloseSelectCampaignDropdown()
    },
    [onCloseSelectCampaignDropdown, onOpenDeleteCampaignModal],
  )

  const renderCampaign = (): React.ReactNode => {
    if (value) {
      const campaignColorProps = campaignColorOptionsById[value.color]

      const renderNote = (): React.ReactNode => {
        if (!value.archived) {
          return null
        }

        return (
          <Tooltip title="Archived campaign">
            <span>*</span>
          </Tooltip>
        )
      }

      const getSx = (): SxProps => {
        if (value.archived) {
          return {
            backgroundColor: themeColors.surfaceNeutralDefault,
            color: themeColors.overBackgroundBold,
            fontStyle: 'italic',
          }
        }

        return {
          backgroundImage: mapGradientToCSSGradient(campaignColorProps.gradient),
          color: campaignColorProps.color,
        }
      }

      return (
        <ButtonBase
          borderSize={0}
          component="button"
          data-campaign-color={value.color}
          size={buttonSize}
          sx={getSx()}
          type="button"
          onClick={onOpenSelectCampaignDropdown}
        >
          {renderNote()}
          {mapCampaignToTitle(value)}
        </ButtonBase>
      )
    }

    return (
      <Button
        color="primary"
        icon={<CircleDotsSolidIcon />}
        size={buttonSize}
        variant={buttonVariant}
        onClick={onOpenSelectCampaignDropdown}
      >
        Add Campaign
      </Button>
    )
  }

  return (
    <>
      <Box
        ref={setAnchorEl}
        sx={[{ display: 'flex', maxWidth: '100%' }, sx].flat()}
      >
        {renderCampaign()}
      </Box>
      {renderSelectIssueCampaignPopover()}
      {renderDeleteCampaignModal()}
    </>
  )
}

export const IssueCampaignContent = ({
  issue,
  buttonSize = 'sm',
  campaignQueryOptions,
}: {
  issue: IssueFragmentT
  buttonSize?: React.ComponentProps<typeof ButtonBase>['size']
  campaignQueryOptions?: UseQueryOptions<GetCampaignQueryT, unknown, CampaignFragmentT>
}): React.ReactElement => {
  const meta: UpdateIssueMutationMetaT = { issue }

  const { mutate: updateIssueMutationMutate } = useUpdateIssueMutation({ meta })

  const onChange = useCallback(
    (campaign: MaybeT<CampaignFragmentT>) => {
      updateIssueMutationMutate({ data: { campaignId: !campaign ? null : campaign.id }, id: issue.id })
    },
    [updateIssueMutationMutate, issue.id],
  )

  const { enabled, variables }: { enabled: boolean; variables: GetCampaignQueryVariablesT } = !issue.campaignId
    ? { enabled: false, variables: undefined as unknown as GetCampaignQueryVariablesT }
    : { enabled: true, variables: { id: issue.campaignId } }

  const campaignQuery = useGetCampaignQuery(variables, {
    ...campaignQueryOptions,
    enabled,
    select: (data) => assertedNonUndefined(data.getCampaign),
  })

  const value = campaignQuery.data

  return (
    <IssueCampaignSelect
      buttonSize={buttonSize}
      value={value}
      onChange={onChange}
    />
  )
}

export const ErrorBoundaryIssueCampaignFallback = ({
  buttonSize = 'sm',
  onClick,
}: {
  buttonSize?: React.ComponentProps<typeof ButtonBase>['size']
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  return (
    <Tooltip title="Click to try to reload the component">
      <ButtonBase
        Icon={ErrorSolidIcon}
        size={buttonSize}
        sx={{ border: `1px solid ${themeColors.borderDefault}` }}
        onClick={onClick}
      >
        Not loaded
      </ButtonBase>
    </Tooltip>
  )
}

export const IssueCampaign = (props: React.ComponentProps<typeof IssueCampaignContent>): React.ReactElement => {
  return (
    <ErrorBoundary
      fallback={({ onRecover }) => (
        <ErrorBoundaryIssueCampaignFallback
          buttonSize={props.buttonSize}
          onClick={onRecover}
        />
      )}
    >
      <IssueCampaignContent {...props} />
    </ErrorBoundary>
  )
}
