import type { MutationCacheHandlersT } from '../types/mutation-cache-handlers'

export const combineMutationCacheHandlers = (
  mutationCacheHandlersList: MutationCacheHandlersT[],
): MutationCacheHandlersT => ({
  onError: (...args) => {
    mutationCacheHandlersList.forEach((mutationCacheHandlers) => {
      mutationCacheHandlers.onError?.(...args)
    })
  },
  onMutate: (...args) => {
    mutationCacheHandlersList.forEach((mutationCacheHandlers) => {
      mutationCacheHandlers.onMutate?.(...args)
    })
  },
  onSuccess: (...args) => {
    mutationCacheHandlersList.forEach((mutationCacheHandlers) => {
      mutationCacheHandlers.onSuccess?.(...args)
    })
  },
})
