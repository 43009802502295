import { Box, type BoxProps } from '@mui/material'
import { Link, type LinkProps } from 'react-router-dom'

import type { MergeT } from '@resnet/client-common/typescript/types/merge'

type MessageCardLinkPropsT = MergeT<Omit<BoxProps<'a'>, 'children'>, LinkProps>

export const MessageCardLink = ({ sx = null, ...props }: MessageCardLinkPropsT) => {
  return (
    <Box
      {...props}
      component={Link}
      sx={[{ all: 'unset' }, { cursor: 'pointer', inset: 0, position: 'absolute' }, sx].flat()}
    />
  )
}
