import { cloneElement } from 'react'

type WrapPropsT = {
  children: React.ReactNode
  with: undefined | null | false | React.ReactElement | ((children: React.ReactNode) => React.ReactNode)
}

export const Wrap = ({ with: withWrapper, children }: WrapPropsT): React.ReactElement => {
  if (!withWrapper) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  if (typeof withWrapper === 'function') {
    return <>{withWrapper(children)}</>
  }

  return cloneElement(withWrapper, {}, children)
}
