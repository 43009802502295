import { checkIsBattery } from '../../batteries/utils/check-is-battery'
import type { BatteryWithReadableIdT } from '../../batteries/utils/map-battery-to-readable-id'
import { mapBatteryToReadableId } from '../../batteries/utils/map-battery-to-readable-id'
import { checkIsSite } from '../../sites/utils/check-is-site'
import type { SiteWithReadableIdT } from '../../sites/utils/map-site-to-readable-id'
import { mapSiteToReadableId } from '../../sites/utils/map-site-to-readable-id'
import { checkIsWell } from '../../wells/utils/check-is-well'
import type { WellWithReadableIdT } from '../../wells/utils/map-well-to-readable-id'
import { mapWellToReadableId } from '../../wells/utils/map-well-to-readable-id'

export type AssetWithReadableIdT = BatteryWithReadableIdT | SiteWithReadableIdT | WellWithReadableIdT

export const mapAssetToReadableId = <ValueT extends AssetWithReadableIdT>(value: ValueT): string => {
  if (checkIsBattery(value)) {
    return mapBatteryToReadableId(value)
  }

  if (checkIsSite(value)) {
    return mapSiteToReadableId(value)
  }

  if (checkIsWell(value)) {
    return mapWellToReadableId(value)
  }

  throw new Error(`Unknown asset type: ${value.__typename}`)
}
