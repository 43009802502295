import { useAppActionsQuery, useResolveAppActionMutation } from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const appActionsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findAppActionsQueries = () => findQueries(queryClient, useAppActionsQuery)

  const resolveAppActionHandlers = () => {
    return createMutationHookMutationCacheHandlers(useResolveAppActionMutation, {
      onSuccess: () => {
        findAppActionsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [resolveAppActionHandlers()]
}
