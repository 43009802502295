import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import type { CustomFieldPayloadSketchT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { SwitchField } from '@resnet/client-web/shared/form-dalaran/components/common/switch-field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export type SketchCustomFieldPayloadValueT = CustomFieldFragmentT

export type SketchCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, SketchCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
  isDraft: boolean
}

export const SketchCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, SketchCustomFieldPayloadValueT>,
>({
  form,
  name,
  isDraft,
}: SketchCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type RequiredPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadSketchT['required']>

  const requiredName = `${name}.payload.${CustomFieldTypeT.SketchT}.required` as RequiredPathT

  type MultiplePathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadSketchT['multiple']>

  const multipleName = `${name}.payload.${CustomFieldTypeT.SketchT}.multiple` as MultiplePathT

  const renderRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName}
      />
    )
  }

  const renderMultipleField = () => {
    return (
      <SwitchField
        disabled={!isDraft}
        disabledTooltipTitle="Multiple cannot be changed after creation"
        form={form}
        label="Multiple"
        name={multipleName}
      />
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderRequiredField()}
        {renderMultipleField()}
      </Box>
    )
  }

  return <>{renderSwitchFields()}</>
}
