import type { UserFragmentT } from '@resnet/client-api/api'

export const getFirstLastName = (user: Pick<UserFragmentT, 'firstName' | 'lastName'>): null | string => {
  const firstName = user.firstName?.trim()

  const lastName = user.lastName?.trim()

  if (!firstName || !lastName) {
    return null
  }

  return `${firstName} ${lastName}`
}
