import { memo } from 'react'

import { equalByValuesStrict } from '@resnet/client-common/common/utils/object/equal-by-values'

export const MemoContainer = memo<{ deps: React.DependencyList; children: () => React.ReactNode }>(
  ({ children }) => <>{children()}</>,
  (left, right) => equalByValuesStrict(left.deps, right.deps),
)

export const MemoPropsContainer = memo<{ children: React.ReactElement }>(
  ({ children }) => children,
  (left, right) => equalByValuesStrict(left.children.props, right.children.props),
)
