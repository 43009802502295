import type { UserPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/user-picker/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import { UserPickerFormUserFormPreview } from './components/user-picker-custom-field-user-form-preview'

export const renderUserFormPreview: CustomFieldT['renderUserFormPreview'] = ({ field, value }) => {
  return (
    <UserPickerFormUserFormPreview
      field={field}
      value={value as undefined | UserPickerCustomFieldValueT}
    />
  )
}
