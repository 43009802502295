import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { LocationDescriptor } from 'history'
import { omit } from 'ramda'
import { Link as RouterLink } from 'react-router-dom'

import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

export type LinkLinkTypePropsT = {
  type?: 'link'
  to: LocationDescriptor
}

export type LinkButtonTypePropsT = {
  type: 'button' | 'submit'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export type LinkAnchorTypePropsT = {
  type: 'anchor'
  rel?: 'noreferrer'
  target?: '_blank'
  href: string
  download?: string
}

export type LinkCommonPropsT = {
  sx?: SxProps
  children?: React.ReactNode
  disabled?: boolean
}

export type LinkPropsT = LinkCommonPropsT & (LinkLinkTypePropsT | LinkButtonTypePropsT | LinkAnchorTypePropsT)

type LinkRefT = React.ForwardedRef<HTMLButtonElement | HTMLAnchorElement>

export const Link = forwardFunctionalComponentRef(
  ({ sx, children, disabled, ...props }: LinkPropsT, ref: LinkRefT): React.ReactElement => {
    const rootSx: SxProps = [
      {
        all: 'unset',
      },
      {
        color: themeColors.basePrimary,
        cursor: 'pointer',
        position: 'relative',
        textDecoration: 'underline',
      },
      disabled
        ? {
            opacity: 0.6,
            pointerEvents: 'none',
          }
        : null,
      sx ?? null,
    ].flat()

    switch (props.type) {
      case 'submit':
      case 'button': {
        const buttonProps = props

        return (
          <Box
            {...buttonProps}
            component="button"
            ref={ref as React.ForwardedRef<HTMLButtonElement>}
            sx={rootSx}
          >
            {children}
          </Box>
        )
      }
      case 'anchor': {
        const anchorProps = omit(['type'], props)

        return (
          <Box
            {...anchorProps}
            component="a"
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
            sx={rootSx}
          >
            {children}
          </Box>
        )
      }
      default: {
        const linkProps = omit(['type'], props)

        return (
          <Box
            {...linkProps}
            component={RouterLink}
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
            sx={rootSx}
          >
            {children}
          </Box>
        )
      }
    }
  },
)
