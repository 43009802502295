import { useDerivedState } from './use-derived-state'

export const usePrevious = <ValueT>(value: ValueT): undefined | ValueT => {
  const [state] = useDerivedState<{
    prev: undefined | ValueT
    current: ValueT
  }>(
    (prevState) => {
      return {
        current: value,
        prev: prevState?.current,
      }
    },
    [value],
  )

  return state.prev
}
