import { ApiError } from '@resnet/client-common/network/errors/api-error'

import type { QueryFunctionContext } from '@resnet/client-api/vendors/react-query'

import { checkIsAnyClientExceptionError } from '../utils/check-is-any-client-error'

export const useUnauthorizedHTTPRequest = <TData, TVariables>(
  request: (variables: TVariables, signal?: AbortSignal) => Promise<TData>,
) => {
  return async (variables: TVariables, context?: QueryFunctionContext): Promise<TData> => {
    try {
      return await request(variables, context?.signal)
    } catch (error) {
      if (checkIsAnyClientExceptionError(error)) {
        throw new ApiError(error.response.errors?.[0])
      }

      throw error
    }
  }
}
