import { useMemo } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import type { EntityAccessT } from '@resnet/client-api/api'

import { useProfileId } from '@resnet/client-shared/shared/users/hooks/use-profile-id'

import { SimpleStaticOptionsDropdown } from '@resnet/client-web/shared/common/components/simple-static-options-dropdown'
import { Field } from '@resnet/client-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-web/shared/gdl/components/field'

const publicOption = { id: 'public' as const, name: 'Public' }

const privateOption = { id: 'private' as const, name: 'Private' }

const privateOrPublicOptions = [publicOption, privateOption]

const mapFormValueToDropdownValue = (value?: null | boolean) => {
  if (!value) {
    return publicOption.id
  }

  return privateOption.id
}

type EntityPermitTypeSelectFieldPropsT = {
  form: UseFormReturn<{ access?: null | Omit<EntityAccessT, '__typename'> }>
}

export const EntityPermitTypeSelectField = ({ form }: EntityPermitTypeSelectFieldPropsT) => {
  const { profileId } = useProfileId()

  const { field } = useController({ control: form.control, name: 'access' })

  const accessPrivate = field.value?.private

  const value = useMemo(() => mapFormValueToDropdownValue(accessPrivate), [accessPrivate])

  const onChange = useEventCallback((optionId: undefined | null | (typeof privateOrPublicOptions)[number]['id']) => {
    if (optionId === publicOption.id) {
      field.onChange({ private: false, roles: [], users: [] })

      return
    }

    field.onChange({ private: true, roles: [], users: [profileId] })
  })

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      <FieldLabel>Access Control</FieldLabel>
      <SimpleStaticOptionsDropdown
        options={privateOrPublicOptions}
        value={value}
        onChange={onChange}
      />
    </Field>
  )
}
