import { checkIsEntity } from '../../entities/utils/check-is-entity'
import type { EntityWithPathnameT } from '../../entities/utils/map-entity-to-pathname'
import { mapEntityToPathname } from '../../entities/utils/map-entity-to-pathname'
import { checkIsGroup } from '../../groups/utils/check-is-group'
import type { GroupWithPathnameT } from '../../groups/utils/map-group-to-pathname'
import { mapGroupToPathname } from '../../groups/utils/map-group-to-pathname'

export type OriginWithPathnameT = EntityWithPathnameT | GroupWithPathnameT

export const mapOriginToPathname = <ValueT extends OriginWithPathnameT>(value: ValueT): string => {
  if (checkIsGroup(value)) {
    return mapGroupToPathname(value)
  }

  if (checkIsEntity(value)) {
    return mapEntityToPathname(value)
  }

  throw new Error(`Unknown origin type: ${value.__typename}`)
}
