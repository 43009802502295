import { useState } from 'react'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { transform } from '@resnet/client-common/common/utils/object/transform'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { SuccessOptionQueryContainer } from '@resnet/client-common/react-query/hooks/use-success-option-query'

import type { MinimalAssetFragmentT, SearchEntitiesQueryVariablesT } from '@resnet/client-api/api'
import { AssetEntityTypeIdT } from '@resnet/client-api/api'

import {
  assetTypeOptionsById,
  assetTypeOptions,
} from '@resnet/client-shared/shared/assets/constants/asset-type-options'
import { EntityOptionContainer } from '@resnet/client-shared/shared/entities/hooks/use-entity-option'
import { mapEntityToPathname } from '@resnet/client-shared/shared/entities/utils/map-entity-to-pathname'
import { mapEntityToTitle } from '@resnet/client-shared/shared/entities/utils/map-entity-to-title'

import { SimpleStaticOptionsDropdown } from '@resnet/client-web/shared/common/components/simple-static-options-dropdown'
import { EntityDropdown } from '@resnet/client-web/shared/entities/components/entity-dropdown'
import {
  ListField,
  ListFieldFooterColumn,
  ListFieldItemName,
  ListFieldItemReadableId,
  ListFieldItemTypeName,
} from '@resnet/client-web/shared/gdl/components/list-field'

export type AssetsListFieldValueT = undefined | string[]

export type AssetsListFieldPropsT<TFieldValues extends FieldValues> = {
  label: string
  form: UseFormReturn<TFieldValues>
  name: FieldPathByValue<TFieldValues, AssetsListFieldValueT>
}

export const AssetsListField = <TFieldValues extends FieldValues>({
  label,
  form,
  name,
}: AssetsListFieldPropsT<TFieldValues>) => {
  const { control } = form

  const {
    field: { value: valueActual, onChange: onChangeActual },
  } = useController({ control, name })

  const value = valueActual as Exclude<AssetsListFieldValueT, undefined>

  const onChange = onChangeActual as (value: Exclude<AssetsListFieldValueT, undefined>) => void

  const [activeAssetTypeId, setActiveAssetTypeId] = useState<AssetEntityTypeIdT>(AssetEntityTypeIdT.WellT)

  const onActiveAssetTypeIdChange = useEventCallback((optionId: undefined | null | AssetEntityTypeIdT) => {
    if (!optionId) {
      return
    }

    setActiveAssetTypeId(optionId)
  })

  const withAssetDropdownQueryVariables = (input: SearchEntitiesQueryVariablesT) => {
    if (!activeAssetTypeId) {
      return input
    }

    return transform(input, {
      filter: (filter) => transform(filter ?? {}, { entityTypeId: () => ({ in: [activeAssetTypeId] }) }),
    })
  }

  const onItemRemove = useEventCallback((item: string) => {
    onChange(value.filter((currentItem) => currentItem !== item))
  })

  const onItemDropdownChange = useEventCallback((item: undefined | null | string) => {
    if (!item) {
      return
    }

    onChange(value.concat([item]))
  })

  const renderItemName = (item: string) => {
    return (
      <ListFieldItemName
        ItemOptionContainer={EntityOptionContainer<MinimalAssetFragmentT>}
        item={item}
        mapItemOptionToPathname={mapEntityToPathname}
        mapItemOptionToTitle={mapEntityToTitle}
      />
    )
  }

  const renderItemTypeName = (item: string) => {
    return (
      <ListFieldItemTypeName
        ItemOptionContainer={EntityOptionContainer<MinimalAssetFragmentT>}
        ItemTypeOptionContainer={SuccessOptionQueryContainer<AssetEntityTypeIdT>}
        item={item}
        mapItemOptionToItemType={(x) => x.__typename as AssetEntityTypeIdT}
        mapItemTypeOptionToTitle={({ id: assetType }) => assetTypeOptionsById[assetType].name}
      />
    )
  }

  const renderItemReadableId = (item: string) => {
    return (
      <ListFieldItemReadableId
        ItemOptionContainer={EntityOptionContainer<MinimalAssetFragmentT>}
        item={item}
        mapItemOptionToShortReadableId={(x) => x.readableId}
      />
    )
  }

  const renderItemTypeSelectFooterColumn = () => {
    return (
      <ListFieldFooterColumn name="Asset type">
        <SimpleStaticOptionsDropdown
          options={assetTypeOptions}
          value={activeAssetTypeId}
          onChange={onActiveAssetTypeIdChange}
        />
      </ListFieldFooterColumn>
    )
  }

  const renderItemSelectFooterColumn = () => {
    return (
      <ListFieldFooterColumn name="Asset">
        <EntityDropdown
          exclude={value}
          value={null}
          withQueryVariables={withAssetDropdownQueryVariables}
          onChange={onItemDropdownChange}
        />
      </ListFieldFooterColumn>
    )
  }

  return (
    <ListField
      items={value}
      label={label}
      renderItemName={renderItemName}
      renderItemReadableId={renderItemReadableId}
      renderItemSelectFooterColumn={renderItemSelectFooterColumn}
      renderItemTypeName={renderItemTypeName}
      renderItemTypeSelectFooterColumn={renderItemTypeSelectFooterColumn}
      onItemRemove={onItemRemove}
    />
  )
}
