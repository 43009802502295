import { useMapFieldModel } from '@resnet/client-common/react/hooks/use-map-field-model'

import { SimpleStaticOptionsMultipleDropdown } from '@resnet/client-web/shared/common/components/simple-static-options-multiple-dropdown'
import type { Popper } from '@resnet/client-web/shared/gdl/components/popper'
import type { AbstractSimpleOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'
import { getSimpleOptionLabel } from '@resnet/client-web/shared/gdl/utils/get-simple-option-label'

export const SimpleStaticOptionsMultipleFilter = <OptionT extends AbstractSimpleOptionT>({
  disablePortal,
  emptyValueText,
  onChange: onChangeProp,
  options,
  placement,
  popperRef,
  value: valueProp,
}: {
  disablePortal?: boolean
  emptyValueText?: string
  onChange: (nextValue: undefined | null | OptionT['id'][]) => void
  options: OptionT[]
  placement?: React.ComponentProps<typeof Popper>['placement']
  popperRef?: React.ComponentProps<typeof Popper>['popperRef']
  value: undefined | null | OptionT['id'][]
}) => {
  const [value, onChange] = useMapFieldModel(
    [valueProp, onChangeProp],
    (inputValue) => inputValue ?? [],
    (outputValue) => (outputValue.length === 0 ? undefined : outputValue),
  )

  return (
    <SimpleStaticOptionsMultipleDropdown
      disablePortal={disablePortal}
      emptyValueText={emptyValueText}
      getOptionLabel={getSimpleOptionLabel}
      options={options}
      placement={placement}
      popperRef={popperRef}
      value={value}
      onChange={onChange}
    />
  )
}
