import { useCallback } from 'react'

import { checkIsAppleOs } from '@resnet/client-common/web/utils/os/checkIsAppleOs'

const metaKeyId = checkIsAppleOs() ? 'metaKey' : 'ctrlKey'

export const useSendHotkeyEventHandler = (
  onSendHotkeyDown?: () => void,
): React.KeyboardEventHandler<HTMLDivElement> => {
  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!event[metaKeyId] || event.key.toLowerCase() !== 'enter' || !onSendHotkeyDown) {
        return
      }

      onSendHotkeyDown()
    },
    [onSendHotkeyDown],
  )

  return onKeyDown
}
