import type { FieldPathByValue, FieldValues, PathValue, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { NumberCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/number/custom-field-value'

import { NumberField } from '@resnet/client-web/shared/form-dalaran/components/common/number-field'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type NumberCustomFieldUserFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | NumberCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const NumberCustomFieldUserFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | NumberCustomFieldValueT>,
>({
  field,
  form,
  name,
}: NumberCustomFieldUserFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const numberPayload = payload[CustomFieldTypeT.NumberT]

  assert(numberPayload, checkNonNullable)

  const { defaultValue, isIntegerEnabled, maxValue, minValue, required } = numberPayload

  return (
    <ObjectDetailsRow>
      <NumberField
        defaultValue={defaultValue as PathValue<TFieldValues, TPath>}
        form={form}
        label={field.name}
        name={name}
        rules={{
          validate: (value) => {
            if (required && !checkNonNullable(value)) {
              return 'This field is required'
            }

            if (checkNonNullable(value) && checkNonNullable(minValue) && value < minValue) {
              return `Value must be greater than ${minValue}`
            }

            if (checkNonNullable(value) && checkNonNullable(maxValue) && value > maxValue) {
              return `Value must be less than ${maxValue}`
            }

            if (isIntegerEnabled && checkNonNullable(value) && !Number.isInteger(value)) {
              return 'Value must be an integer'
            }

            return true
          },
        }}
      />
    </ObjectDetailsRow>
  )
}
