import type { FieldPathByValue, FieldValues, PathValue, UseControllerReturn } from 'react-hook-form'
import { useController, type UseControllerProps, type UseFormReturn } from 'react-hook-form'

import { SimpleStaticOptionsMultipleDropdown } from '@resnet/client-web/shared/common/components/simple-static-options-multiple-dropdown'
import { Field, FieldErrorText, FieldHelperText, FieldLabel } from '@resnet/client-web/shared/gdl/components/field'
import type { AbstractSimpleOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'

export type StaticSimpleOptionsMultipleDropdownFieldValueT = undefined | string[]

export type StaticSimpleOptionsMultipleDropdownFieldPropsT<
  FieldValuesT extends FieldValues,
  OptionT extends AbstractSimpleOptionT,
> = {
  defaultValue?: PathValue<FieldValuesT, FieldPathByValue<FieldValuesT, StaticSimpleOptionsMultipleDropdownFieldValueT>>
  emptyIsNullValue?: boolean
  form: UseFormReturn<FieldValuesT>
  helperText?: string
  label?: string
  name: FieldPathByValue<FieldValuesT, StaticSimpleOptionsMultipleDropdownFieldValueT>
  options: OptionT[]
  rules?: UseControllerProps<
    FieldValuesT,
    FieldPathByValue<FieldValuesT, StaticSimpleOptionsMultipleDropdownFieldValueT>
  >['rules']
}

export const StaticSimpleOptionsMultipleDropdownField = <
  FieldValuesT extends FieldValues,
  OptionT extends AbstractSimpleOptionT,
>({
  defaultValue: defaultValueActual,
  form,
  helperText,
  label,
  name,
  options,
  rules,
}: StaticSimpleOptionsMultipleDropdownFieldPropsT<FieldValuesT, OptionT>) => {
  const { control } = form

  const defaultValue =
    defaultValueActual ??
    ([] as PathValue<FieldValuesT, FieldPathByValue<FieldValuesT, StaticSimpleOptionsMultipleDropdownFieldValueT>>)

  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
  }) as unknown as UseControllerReturn<
    Record<string, Exclude<StaticSimpleOptionsMultipleDropdownFieldValueT, undefined>>
  >

  const renderHeader = () => {
    if (!label) {
      return null
    }

    return <FieldLabel>{label}</FieldLabel>
  }

  const renderInput = () => {
    return (
      <SimpleStaticOptionsMultipleDropdown
        hasError={invalid}
        options={options}
        value={value}
        onChange={onChange}
      />
    )
  }

  const renderHelperText = () => {
    if (!helperText) {
      return null
    }

    return <FieldHelperText>{helperText}</FieldHelperText>
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderHelperText()}
      {renderFooter()}
    </Field>
  )
}
