import { useContext, useEffect, useId, useMemo } from 'react'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { DrawersRegistryContext } from '../components/drawers-registry-context'

export const useDrawersRegistry = ({ open }: { open?: boolean }) => {
  const drawersRegistryContextValue = useContext(DrawersRegistryContext)

  const id = useId()

  assert(drawersRegistryContextValue, checkNonNullable, '"drawersRegistryContextValue" is expected to not be nullable')

  const { register, registry } = drawersRegistryContextValue

  useEffect(() => {
    if (open) {
      return register(id)
    }

    return undefined
  }, [id, open, register])

  const isFirstDrawerOpened = useMemo(() => registry.indexOf(id) === 0, [registry, id])

  return {
    isFirstDrawerOpened,
    registry,
  }
}
