import { transform } from '@resnet/client-common/common/utils/object/transform'

import { FilterModeT } from '@resnet/client-api/api'

import { mapStringFilterItemsToMergedEmpty } from '@resnet/client-web/shared/filters/utils/map-string-filter-items-to-merged-empty'
import { FilterRuleContainer } from '@resnet/client-web/shared/presets/hooks/use-filter-rule'
import { mapFilterModeToKey } from '@resnet/client-web/shared/presets/utils/map-filter-mode-to-key'

import type { CustomFieldT } from '../../types/custom-field'
import { mapCustomFieldToFullKey, mapCustomFieldToKey } from '../../utils/map-custom-field-to-key'

export const createFilterOption: CustomFieldT['createFilterOption'] = ({
  field,
  fieldValuesKey,
  filterObjectType,
  renderDistinctValuesFilter,
  updateFieldsFilter,
}) => {
  return {
    apply: (input, rule) => {
      const { string: payload } = rule

      if (!payload) {
        return input
      }

      const { mode, items } = payload

      if (mode === FilterModeT.IncludeT && items.length === 0) {
        return input
      }

      return updateFieldsFilter(input, (fieldsFilter) =>
        transform(fieldsFilter ?? {}, {
          [mapCustomFieldToKey(field)]: () => ({
            [mapFilterModeToKey(mode)]: mapStringFilterItemsToMergedEmpty(items),
          }),
        }),
      )
    },
    id: mapCustomFieldToFullKey(field, fieldValuesKey),
    name: field.name,
    objectType: filterObjectType,
    renderContent: ({ filterPresetRuleModel, popperRef }) => {
      return (
        <FilterRuleContainer
          filterPresetRuleModel={filterPresetRuleModel}
          filterType="string"
        >
          {({ filterPresetItemRulePayloadModel: [value, onChange] }) => {
            return renderDistinctValuesFilter({
              field: mapCustomFieldToFullKey(field, fieldValuesKey),
              onChange,
              popperRef,
              value,
            })
          }}
        </FilterRuleContainer>
      )
    },
  }
}
