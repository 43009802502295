import type { InputIdFilterT } from '../api'

export const matchesIdFilter = (id: string, filter: undefined | null | InputIdFilterT) => {
  if (filter?.eq) {
    return filter.eq === id
  }

  if (filter?.in) {
    return filter.in.includes(id)
  }

  if (filter?.notEq) {
    return filter.notEq !== id
  }

  if (filter?.notIn) {
    return !filter.notIn.includes(id)
  }

  return true
}
