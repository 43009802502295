import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldT } from '../../types/custom-field'

export const textCustomField = createConstrainer<CustomFieldT>()({
  id: CustomFieldTypeT.TextT as const,
  name: 'Text',
  payload: createConstrainer<CustomFieldPayloadT>()({
    Text: {
      defaultValue: '',
      multiline: false,
      placeholder: '',
      required: false,
    },
  }) as CustomFieldPayloadT,
})
