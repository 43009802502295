import { useState } from 'react'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { transform } from '@resnet/client-common/common/utils/object/transform'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useMapFieldModel } from '@resnet/client-common/react/hooks/use-map-field-model'

import type { ResourceObjectsQueryVariablesT } from '@resnet/client-api/api'
import { useResourceObjectTypesQuery } from '@resnet/client-api/api'

import { ResourceObjectOptionContainer } from '@resnet/client-shared/shared/resource-objects/hooks/use-resource-object-option'
import { mapResourceObjectToPathname } from '@resnet/client-shared/shared/resource-objects/utils/map-resource-object-to-pathname'
import { mapResourceObjectToTitle } from '@resnet/client-shared/shared/resource-objects/utils/map-resource-object-to-title'
import { mapResourceObjectTypeToTitle } from '@resnet/client-shared/shared/resource-objects/utils/map-resource-object-type-to-title'

import {
  ListField,
  ListFieldFooterColumn,
  ListFieldItemName,
  ListFieldItemReadableId,
  ListFieldItemTypeName,
} from '@resnet/client-web/shared/gdl/components/list-field'
import { ResourceObjectDropdown } from '@resnet/client-web/shared/resource-objects/components/resource-object-dropdown'
import { ResourceObjectTypeDropdown } from '@resnet/client-web/shared/resource-objects/components/resource-object-type-dropdown'
import { ResourceObjectTypeOptionContainer } from '@resnet/client-web/shared/resource-objects/hooks/use-resource-object-type-option'

export type ResourceObjectRelationFieldValueT = undefined | null | string

export type ResourceObjectRelationFieldPropsT<TFieldValues extends FieldValues> = {
  label: string
  form: UseFormReturn<TFieldValues>
  name: FieldPathByValue<TFieldValues, ResourceObjectRelationFieldValueT>
}

export const ResourceObjectRelationField = <TFieldValues extends FieldValues>({
  label,
  form,
  name,
}: ResourceObjectRelationFieldPropsT<TFieldValues>) => {
  const { control } = form

  const {
    field: { value: valueActualActual, onChange: onChangeActualActual },
  } = useController({ control, name })

  const valueActual = valueActualActual as Exclude<ResourceObjectRelationFieldValueT, undefined>

  const onChangeActual = onChangeActualActual as (value: Exclude<ResourceObjectRelationFieldValueT, undefined>) => void

  // Even so this component is supposed to be used with a single value it's design is implemented to display multiple ones.
  // Perhaps in future we will unlock it for multiple values so then we can drop this mapping without affecting other code.
  const [value, onChange] = useMapFieldModel(
    [valueActual, onChangeActual],
    (inputValue) => (!inputValue ? [] : [inputValue]),
    (outputValue) => (outputValue.length === 0 ? null : outputValue[outputValue.length - 1]),
  )

  const [activeResourceObjectTypeId, setActiveResourceObjectTypeId] = useState<undefined | null | string>(null)

  const defaultResourceObjectTypeQuery = useResourceObjectTypesQuery(
    { limit: 1 },
    { enabled: !activeResourceObjectTypeId, select: (data) => data.listResourceObjectTypes.items.at(0) },
  )

  if (!activeResourceObjectTypeId && defaultResourceObjectTypeQuery.isSuccess && defaultResourceObjectTypeQuery.data) {
    setActiveResourceObjectTypeId(defaultResourceObjectTypeQuery.data.id)
  }

  const withResourceObjectDropdownQueryVariables = (input: ResourceObjectsQueryVariablesT) => {
    if (!activeResourceObjectTypeId) {
      return input
    }

    return transform(input, {
      filter: (filter) =>
        transform(filter ?? {}, { resourceObjectTypeId: () => ({ in: [activeResourceObjectTypeId] }) }),
    })
  }

  const onItemRemove = useEventCallback((item: string) => {
    onChange(value.filter((currentItem) => currentItem !== item))
  })

  const onItemDropdownChange = useEventCallback((item: undefined | null | string) => {
    if (!item) {
      return
    }

    onChange(value.concat([item]))
  })

  const renderItemName = (item: string) => {
    return (
      <ListFieldItemName
        ItemOptionContainer={ResourceObjectOptionContainer}
        item={item}
        mapItemOptionToPathname={mapResourceObjectToPathname}
        mapItemOptionToTitle={mapResourceObjectToTitle}
      />
    )
  }

  const renderItemTypeName = (item: string) => {
    return (
      <ListFieldItemTypeName
        ItemOptionContainer={ResourceObjectOptionContainer}
        ItemTypeOptionContainer={ResourceObjectTypeOptionContainer}
        item={item}
        mapItemOptionToItemType={(x) => x.resourceObjectTypeId}
        mapItemTypeOptionToTitle={mapResourceObjectTypeToTitle}
      />
    )
  }

  const renderItemReadableId = (item: string) => {
    return (
      <ListFieldItemReadableId
        ItemOptionContainer={ResourceObjectOptionContainer}
        item={item}
        mapItemOptionToShortReadableId={(x) => x.readableId}
      />
    )
  }

  const renderItemTypeSelectFooterColumn = () => {
    return (
      <ListFieldFooterColumn name="Resource type">
        <ResourceObjectTypeDropdown
          value={activeResourceObjectTypeId}
          onChange={setActiveResourceObjectTypeId}
        />
      </ListFieldFooterColumn>
    )
  }

  const renderItemSelectFooterColumn = () => {
    return (
      <ListFieldFooterColumn name="Resource">
        <ResourceObjectDropdown
          exclude={value}
          value={null}
          withQueryVariables={withResourceObjectDropdownQueryVariables}
          onChange={onItemDropdownChange}
        />
      </ListFieldFooterColumn>
    )
  }

  return (
    <ListField
      items={value}
      label={label}
      renderItemName={renderItemName}
      renderItemReadableId={renderItemReadableId}
      renderItemSelectFooterColumn={renderItemSelectFooterColumn}
      renderItemTypeName={renderItemTypeName}
      renderItemTypeSelectFooterColumn={renderItemTypeSelectFooterColumn}
      onItemRemove={onItemRemove}
    />
  )
}
