import { ClientError as GraphqlClientError } from 'graphql-request'

import { NETWORK_STATUS } from '@resnet/client-common/network/constants/network-status'
import { HTTPClientError } from '@resnet/client-common/network/errors/http-client-error'

export const checkIsHttpClientError = (error: unknown): error is HTTPClientError => error instanceof HTTPClientError

export const checkIsGraphqlClientError = (error: unknown): error is GraphqlClientError =>
  error instanceof GraphqlClientError

export const checkIsAnyClientError = (error: unknown): error is HTTPClientError | GraphqlClientError =>
  checkIsHttpClientError(error) || checkIsGraphqlClientError(error)

export const checkIsAnyClientUnauthorizedError = (error: unknown): error is HTTPClientError | GraphqlClientError =>
  checkIsAnyClientError(error) && [NETWORK_STATUS.UNAUTHORIZED].includes(error.response.status)

// those errors can be handled by UI
export const checkIsAnyClientExceptionError = (error: unknown): error is HTTPClientError | GraphqlClientError =>
  checkIsAnyClientError(error) &&
  [NETWORK_STATUS.OK, NETWORK_STATUS.FORBIDDEN, NETWORK_STATUS.BANDWIDTH_LIMIT_EXCEEDED].includes(error.response.status)
