import { checkIsAsset } from '../../assets/utils/check-is-asset'
import type { AssetWithPathnameT } from '../../assets/utils/map-asset-to-pathname'
import { mapAssetToPathname } from '../../assets/utils/map-asset-to-pathname'
import { checkIsCampaign } from '../../campaigns/utils/check-is-campaign'
import type { CampaignWithPathnameT } from '../../campaigns/utils/map-campaign-to-pathname'
import { mapCampaignToPathname } from '../../campaigns/utils/map-campaign-to-pathname'
import { checkIsCredential } from '../../credentials/utils/check-is-credential'
import type { CredentialWithPathnameT } from '../../credentials/utils/map-credential-to-pathname'
import { mapCredentialToPathname } from '../../credentials/utils/map-credential-to-pathname'
import { checkIsFormObject } from '../../form-objects/utils/check-is-form-object'
import type { FormObjectWithPathnameT } from '../../form-objects/utils/map-form-object-to-pathname'
import { mapFormObjectToPathname } from '../../form-objects/utils/map-form-object-to-pathname'
import { checkIsInsight } from '../../insights/utils/check-is-insight'
import type { InsightWithPathnameT } from '../../insights/utils/map-insight-to-pathname'
import { mapInsightToPathname } from '../../insights/utils/map-insight-to-pathname'
import { checkIsIssue } from '../../issues/utils/check-is-issue'
import type { IssueWithPathnameT } from '../../issues/utils/map-issue-to-pathname'
import { mapIssueToPathname } from '../../issues/utils/map-issue-to-pathname'
import { checkIsResourceObject } from '../../resource-objects/utils/check-is-resource-object'
import type { ResourceObjectWithPathnameT } from '../../resource-objects/utils/map-resource-object-to-pathname'
import { mapResourceObjectToPathname } from '../../resource-objects/utils/map-resource-object-to-pathname'
import { checkIsRoute } from '../../routes/utils/check-is-route'
import type { RouteWithPathnameT } from '../../routes/utils/map-route-to-pathname'
import { mapRouteToPathname } from '../../routes/utils/map-route-to-pathname'

export type EntityWithPathnameT =
  | AssetWithPathnameT
  | CampaignWithPathnameT
  | CredentialWithPathnameT
  | FormObjectWithPathnameT
  | IssueWithPathnameT
  | InsightWithPathnameT
  | ResourceObjectWithPathnameT
  | RouteWithPathnameT

export const mapEntityToPathname = <ValueT extends EntityWithPathnameT>(value: ValueT) => {
  if (checkIsAsset(value)) {
    return mapAssetToPathname(value)
  }

  if (checkIsCampaign(value)) {
    return mapCampaignToPathname(value)
  }

  if (checkIsCredential(value)) {
    return mapCredentialToPathname(value)
  }

  if (checkIsFormObject(value)) {
    return mapFormObjectToPathname(value)
  }

  if (checkIsIssue(value)) {
    return mapIssueToPathname(value)
  }

  if (checkIsInsight(value)) {
    return mapInsightToPathname(value)
  }

  if (checkIsResourceObject(value)) {
    return mapResourceObjectToPathname(value)
  }

  if (checkIsRoute(value)) {
    return mapRouteToPathname(value)
  }

  throw new Error(`Unknown entity type: ${value.__typename}`)
}
