import type { ColorT } from '@resnet/client-api/api'

import { pickerColorPresetById } from '@resnet/client-shared/shared/gdl/constants/picker-color-presets'

export const mapPickerColorPresetToColor = (color?: null | ColorT): undefined | string => {
  const { preset, custom } = color ?? {}

  if (custom) {
    return custom
  }

  if (preset) {
    return pickerColorPresetById[preset].value
  }

  return undefined
}
