import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { checkNonNullable, toNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { toInitials } from '@resnet/client-common/common/utils/string/to-initials'
import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { ResourceFragmentT } from '@resnet/client-api/api'

import LogoSolidIcon from '@resnet/client-shared/assets/icons/logo-solid.svg'
import type { TypographyPresetT } from '@resnet/client-shared/shared/gdl/types/typography-preset'
import { useResourceOption } from '@resnet/client-shared/shared/resources/hooks/use-resource-option'

import type { CircularSkeletonPropsT } from '@resnet/client-web/shared/async/components/circular-skeleton'
import { CircularSkeleton } from '@resnet/client-web/shared/async/components/circular-skeleton'
import { ImageResource } from '@resnet/client-web/shared/resources/components/image-resource'

import { themeColors } from '../../constants/theme-colors'
import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'

const sizes = createConstrainer<{ id: string; avatarSize: number; nameTypographyPreset: TypographyPresetT }[]>()([
  { avatarSize: 16, id: 'xs' as const, nameTypographyPreset: { fontSize: 8, letterSpacing: -0.4, lineHeight: 10 } },
  { avatarSize: 24, id: 'sm' as const, nameTypographyPreset: { fontSize: 11, lineHeight: 11 } },
  { avatarSize: 32, id: 'md' as const, nameTypographyPreset: { fontSize: 13, lineHeight: 16 } },
  { avatarSize: 44, id: 'lg' as const, nameTypographyPreset: { fontSize: 22, lineHeight: 24 } },
  { avatarSize: 64, id: 'xl' as const, nameTypographyPreset: { fontSize: 27, lineHeight: 32 } },
  { avatarSize: 128, id: 'xxl' as const, nameTypographyPreset: { fontSize: 48, lineHeight: 48 } },
])

const sizeById = propToKey('id', sizes)

const avatarSx = {
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
}

const imageAvatarContentSx = ({ avatarSize }: { avatarSize: number }) => ({
  borderRadius: '50%',
  height: toPx(avatarSize),
  objectFit: 'cover',
  width: toPx(avatarSize),
})

const regularAvatarContentSx = ({ avatarSize }: { avatarSize: number }) => ({
  alignItems: 'center',
  backgroundColor: themeColors.surfaceNeutralDefault,
  borderColor: themeColors.basePrimary,
  borderRadius: '50%',
  borderStyle: 'solid',
  borderWidth: toPx(1),
  display: 'flex',
  height: toPx(avatarSize),
  justifyContent: 'center',
  width: toPx(avatarSize),
})

export type AvatarSkeletonPropsT = MergeAllT<
  [
    CircularSkeletonPropsT,
    {
      size: (typeof sizes)[number]['id']
    },
  ]
>

export const AvatarSkeleton = ({ size, ...props }: AvatarSkeletonPropsT) => {
  const { avatarSize } = sizeById[size]

  return (
    <CircularSkeleton
      {...props}
      size={avatarSize}
    />
  )
}

export type AvatarPropsT = MergeAllT<
  [
    BoxProps,
    {
      name?: string
      resource?: undefined | null | Pick<ResourceFragmentT, 'url' | 'optimized'>
      size: (typeof sizes)[number]['id']
    },
  ]
>

export const Avatar = ({ name, resource, size, sx = null, ...props }: AvatarPropsT) => {
  const { avatarSize, nameTypographyPreset } = sizeById[size]

  const renderContent = (): React.ReactNode => {
    if (resource) {
      return (
        <ImageResource
          alt={`Avatar of ${name}`}
          resource={resource}
          sx={imageAvatarContentSx({ avatarSize })}
        />
      )
    }

    if (name) {
      return (
        <Box sx={regularAvatarContentSx({ avatarSize })}>
          <Box sx={[mapTypographyPresetToSx(nameTypographyPreset), { color: themeColors.basePrimary }].flat()}>
            {toInitials(name)}
          </Box>
        </Box>
      )
    }

    return null
  }

  return (
    <Box
      {...props}
      sx={[avatarSx, sx].flat()}
    >
      {renderContent()}
    </Box>
  )
}

export type LoadableAvatarPropsT = MergeAllT<
  [
    Omit<AvatarPropsT, 'resource'>,
    {
      resourceId?: undefined | null | string
    },
  ]
>

export const LoadableAvatar = ({ resourceId, ...props }: LoadableAvatarPropsT) => {
  const { optionQuery: resourceQuery } = useResourceOption({
    id: toNonNullable(resourceId),
    options: { enabled: checkNonNullable(resourceId), useErrorBoundary: false },
  })

  if (checkNonNullable(resourceId) && !resourceQuery.isSuccess && !resourceQuery.isError) {
    return <AvatarSkeleton {...props} />
  }

  const resource = resourceQuery.data

  return (
    <Avatar
      {...props}
      resource={resource}
    />
  )
}

export type IconAvatarPropsT = MergeT<
  BoxProps,
  {
    size: (typeof sizes)[number]['id']
    icon: ForwardableT<React.SVGProps<SVGSVGElement>>
  }
>

export const IconAvatar = ({ size, icon, sx = null, ...props }: IconAvatarPropsT) => {
  const { avatarSize } = sizeById[size]

  const iconSize = avatarSize / 2

  const renderContent = (): React.ReactNode => {
    return (
      <Box sx={regularAvatarContentSx({ avatarSize })}>
        {renderForwardable(icon, { fill: themeColors.basePrimary, height: iconSize, width: iconSize })}
      </Box>
    )
  }

  return (
    <Box
      {...props}
      sx={[avatarSx, sx].flat()}
    >
      {renderContent()}
    </Box>
  )
}

export type ResNetAvatarPropsT = Omit<IconAvatarPropsT, 'icon'>

export const ResNetAvatar = (props: ResNetAvatarPropsT) => {
  return (
    <IconAvatar
      {...props}
      icon={<LogoSolidIcon />}
    />
  )
}
