import stringify from 'json-stable-stringify'

export const memo = <ParamsT extends unknown[], ReturnT>(fn: (...params: ParamsT) => ReturnT) => {
  const cache: Record<string, ReturnT> = Object.create(null)

  const wrappedFn = (...params: ParamsT) => {
    const key = stringify(params)

    if (!(key in cache)) {
      cache[key] = fn(...params)
    }

    return cache[key]
  }

  return wrappedFn
}
