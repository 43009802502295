import { Box } from '@mui/material'
import { useContext } from 'react'

import { assertedNonNull } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { ClickAwayListener } from '@resnet/client-web/shared/registry/components/click-away-listener'
import { FocusTrap } from '@resnet/client-web/shared/registry/components/focus-trap'

import { toPx } from '../../utils/to-px'
import { Outline } from '../outline'
import { PopperContext, PopperTransitionContext } from '../popper'

export const Popover = forwardFunctionalComponentRef(
  (
    {
      sx,
      children,
      isFocusTrapOpen = true,
      onBeforeClickAway,
      onBeforeEscape,
      ...props
    }: React.ComponentProps<typeof Box> & {
      isFocusTrapOpen?: boolean
      onBeforeClickAway?: (
        event: MouseEvent,
        options: { preventDefault: () => void; anchorEl: null | HTMLElement },
      ) => void
      onBeforeEscape?: (options: { preventDefault: () => void; anchorEl: null | HTMLElement }) => void
    },
    ref: React.Ref<HTMLDivElement>,
  ): React.ReactElement => {
    const { close, anchorEl } = assertedNonNull(useContext(PopperContext))

    const { in: transitionIn = false } = useContext(PopperTransitionContext) ?? {}

    const checkIsFocusTrapEnabled = useEventCallback(() => {
      return transitionIn
    })

    const borderRadius = toPx(8)

    const onClickAway = useEventCallback((event: MouseEvent) => {
      const preventRef = { current: false }

      const preventDefault = () => {
        preventRef.current = true
      }

      onBeforeClickAway?.(event, { anchorEl, preventDefault })

      if (preventRef.current) {
        return
      }

      close()
    })

    const onKeyDown = useEventCallback((event: React.KeyboardEvent) => {
      if (event.key !== 'Escape') {
        return
      }

      event.stopPropagation()

      const preventRef = { current: false }

      const preventDefault = () => {
        preventRef.current = true
      }

      onBeforeEscape?.({ anchorEl, preventDefault })

      if (preventRef.current) {
        return
      }

      close()
    })

    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FocusTrap
            isEnabled={checkIsFocusTrapEnabled}
            open={isFocusTrapOpen}
          >
            <Box
              {...props}
              ref={ref}
              sx={[
                {
                  backgroundColor: themeColors.surfaceNeutralDefault,
                  borderRadius,
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: toPx(16),
                  outline: 'unset',
                  padding: toPx(8),
                  position: 'relative',
                  width: toPx(275),
                },
                sx ?? null,
              ].flat()}
              tabIndex={-1}
              onKeyDown={onKeyDown}
            >
              {children}
              <Outline
                color="borderBold"
                sx={{ borderRadius }}
              />
            </Box>
          </FocusTrap>
        </Box>
      </ClickAwayListener>
    )
  },
)
