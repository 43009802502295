import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { NumberCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/number/custom-field-value'

import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'

import type { CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createColumn: CustomFieldT['createColumn'] = ({ field, fieldValuesKey }) => {
  const getValue = (row: Record<string, unknown>) => getFieldValue<NumberCustomFieldValueT>(field, fieldValuesKey, row)

  return {
    field: mapCustomFieldToFullKey(field, fieldValuesKey),
    flex: 1,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      return <RegularCell>{groupingKey}</RegularCell>
    },
    groupable: true,
    groupingValueGetter: (params) => {
      const value = getValue(params.row)

      if (value === undefined || value === null) {
        return undefined
      }

      return value.toString()
    },
    headerName: field.name,
    minWidth: 150,
    renderCell: (params) => {
      const value = params.value as ReturnType<typeof getValue>

      if (value === undefined || value === null) {
        return <RegularCell>-</RegularCell>
      }

      return <RegularCell>{value}</RegularCell>
    },
    sortable: true,
    valueGetter: (params) => {
      const value = getValue(params.row)

      return value
    },
  }
}
