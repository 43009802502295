import { Box, useEventCallback } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useContext } from 'react'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { ResourceFragmentT } from '@resnet/client-api/api'
import { useDeleteResourceMutation } from '@resnet/client-api/api'

import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { createModalContainer, ModalContext } from '@resnet/client-web/shared/gdl/components/modal'
import { createModalHook } from '@resnet/client-web/shared/gdl/components/modal'
import { ModalContent } from '@resnet/client-web/shared/gdl/components/modal'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export const AttachmentDeleteConfirmationModal = ({
  attachment,
  onDeleteSuccess,
}: {
  attachment: Pick<ResourceFragmentT, 'id' | 'filename'>
  onDeleteSuccess?: () => void
}): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()

  const { onClose } = assertedNonNullable(useContext(ModalContext))

  const { id, filename } = attachment

  const { mutateAsync: deleteResource } = useDeleteResourceMutation()

  const onKeepAttachment = useEventCallback(() => {
    onClose?.()
  })

  const onDeleteAttachment = useEventCallback(async () => {
    try {
      await deleteResource({ id })

      enqueueSnackbar('Attachment was deleted.', { variant: 'success' })

      onDeleteSuccess?.()

      onClose?.()
    } catch (error) {
      enqueueSnackbar('Attachment was not deleted.', { variant: 'error' })
    }
  })

  return (
    <ModalContent sx={{ maxWidth: toPx(512), width: '100%' }}>
      {{
        content: (
          <Box sx={{ flexGrow: 1 }}>
            This will permanently delete the attachment{' '}
            <Box
              component="span"
              sx={{ fontWeight: 'bold' }}
            >
              {filename}
            </Box>{' '}
            and you won’t be able to undo it. Are you sure?
          </Box>
        ),
        footer: (
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Button
              color="primary"
              size="md"
              variant="outlined"
              onClick={onKeepAttachment}
            >
              Keep Attachment
            </Button>
            <Button
              color="danger"
              size="md"
              variant="outlined"
              onClick={onDeleteAttachment}
            >
              Delete Attachment
            </Button>
          </Box>
        ),
        header: {
          title: 'Delete Attachment Forever?',
        },
      }}
    </ModalContent>
  )
}

export const useAttachmentDeleteConfirmationModal = createModalHook(
  (params: React.ComponentProps<typeof AttachmentDeleteConfirmationModal>) => (
    <AttachmentDeleteConfirmationModal {...params} />
  ),
)

export const AttachmentDeleteConfirmationModalContainer = createModalContainer(useAttachmentDeleteConfirmationModal)
