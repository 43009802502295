import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldT } from '../../types/custom-field'

export const attachmentCustomField = createConstrainer<CustomFieldT>()({
  id: CustomFieldTypeT.AttachmentT as const,
  name: 'Attachment',
  payload: createConstrainer<CustomFieldPayloadT>()({
    Attachment: {
      multiple: false,
      required: false,
    },
  }) as CustomFieldPayloadT,
})
