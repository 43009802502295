import { useContext } from 'react'

import type { MaybeT } from '@resnet/client-common/common/types/common'
import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import { getApiHttpClient } from '@resnet/client-api/services/api-http-client'

import { AuthContext } from '../auth-api/auth-context'

import { useRequest } from './use-request'

export const useHTTPJSONPost = <TData, TVariables>(
  mapVariablesToUrl: (variables: TVariables) => string,
  mapVariablesToBody: (variables: TVariables) => unknown,
) => {
  const { requestHeaders } = assertedNonNullable(useContext(AuthContext))

  return useRequest<TData, TVariables>(
    async (token: MaybeT<string>, variables: TVariables, signal?: AbortSignal): Promise<TData> => {
      const response: TData = await getApiHttpClient().post(
        mapVariablesToUrl(variables),
        JSON.stringify(mapVariablesToBody(variables)),
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json',
            ...requestHeaders,
          },
          signal,
        },
      )

      return response
    },
  )
}
