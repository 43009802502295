import { checkIsBattery } from '../../batteries/utils/check-is-battery'
import type { BatteryWithFeedPathnameT } from '../../batteries/utils/map-battery-to-feed-pathname'
import { mapBatteryToFeedPathname } from '../../batteries/utils/map-battery-to-feed-pathname'
import { checkIsSite } from '../../sites/utils/check-is-site'
import type { SiteWithFeedPathnameT } from '../../sites/utils/map-site-to-feed-pathname'
import { mapSiteToFeedPathname } from '../../sites/utils/map-site-to-feed-pathname'
import { checkIsWell } from '../../wells/utils/check-is-well'
import type { WellWithFeedPathnameT } from '../../wells/utils/map-well-to-feed-pathname'
import { mapWellToFeedPathname } from '../../wells/utils/map-well-to-feed-pathname'

export type AssetWithFeedPathnameT = BatteryWithFeedPathnameT | SiteWithFeedPathnameT | WellWithFeedPathnameT

export const mapAssetToFeedPathname = <ValueT extends AssetWithFeedPathnameT>(value: ValueT): string => {
  if (checkIsBattery(value)) {
    return mapBatteryToFeedPathname(value)
  }

  if (checkIsSite(value)) {
    return mapSiteToFeedPathname(value)
  }

  if (checkIsWell(value)) {
    return mapWellToFeedPathname(value)
  }

  throw new Error(`Unknown asset type: ${value.__typename}`)
}
