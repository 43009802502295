import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'

export const stringifySearchParams = (searchParams: Record<string, unknown>): string =>
  pipeline(
    searchParams,
    Object.entries,
    (x) => x.filter(([, value]) => value !== undefined),
    (x) => x.map((entry) => entry.join('=')),
    (x) => x.join('&'),
    (x) => (x.length === 0 ? '' : '?' + x),
  )
