import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { ErrorBoundaryFallbackEffectContainer } from '@resnet/client-common/react/hooks/use-error-boundary-fallback-effect'

import { FilterModeT } from '@resnet/client-api/api'

import type { AbstractOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'

import type { OptionContainerT } from '../../types/option-container'
import { DropdownInputPlaceholder, DropdownInputText, DropdownInputTextSkeleton } from '../dropdown-input'
import { renderCount } from '../dropdown-input/utils/render-count'
import { defaultNullOptionLabel } from '../select-dropdown/constants/null-option'

export type MultipleDropdownTextPropsT<
  OptionT extends AbstractOptionT,
  ItemT extends null | OptionT['id'] = OptionT['id'],
> = {
  mode: FilterModeT
  value?: ItemT[]
  nullOptionLabel?: React.ReactNode
  getOptionLabel: (option: OptionT) => string
  OptionContainer: OptionContainerT<OptionT>
  onCurrentValueQueryError?: () => void
  multipleOptionSuffix?: string
}

export const MultipleDropdownText = <
  OptionT extends AbstractOptionT,
  ItemT extends null | OptionT['id'] = OptionT['id'],
>({
  mode,
  value,
  nullOptionLabel = defaultNullOptionLabel,
  getOptionLabel,
  OptionContainer,
  onCurrentValueQueryError,
  multipleOptionSuffix = 'selected',
}: MultipleDropdownTextPropsT<OptionT, ItemT>) => {
  if (mode === FilterModeT.ExcludeT) {
    return <DropdownInputText>{renderCount(Infinity)}</DropdownInputText>
  }

  if (!value || value.length === 0) {
    return <DropdownInputPlaceholder>Not Selected</DropdownInputPlaceholder>
  }

  if (value.length === 1) {
    const item = value[0]

    if (item === null) {
      return <DropdownInputText>{nullOptionLabel}</DropdownInputText>
    }

    return (
      <ErrorBoundary
        fallback={({ onRecover, recoveredCount }) => (
          <ErrorBoundaryFallbackEffectContainer
            onError={onCurrentValueQueryError}
            onRecover={recoveredCount > 0 ? undefined : onRecover}
          >
            {() => <DropdownInputPlaceholder>Not Found</DropdownInputPlaceholder>}
          </ErrorBoundaryFallbackEffectContainer>
        )}
      >
        <OptionContainer id={item}>
          {({ optionQuery }) => {
            if (!optionQuery.isSuccess) {
              return <DropdownInputTextSkeleton />
            }

            const option = optionQuery.data

            return <DropdownInputText>{getOptionLabel(option)}</DropdownInputText>
          }}
        </OptionContainer>
      </ErrorBoundary>
    )
  }

  return (
    <DropdownInputText>
      {renderCount(value.length)} {multipleOptionSuffix}
    </DropdownInputText>
  )
}
