import type { SystemStyleObject } from '@mui/system'

import type {
  TypographyPresetFontFamilyT,
  TypographyPresetT,
} from '@resnet/client-shared/shared/gdl/types/typography-preset'

import { toPx } from './to-px'

const defaultFontFamily: TypographyPresetFontFamilyT = 'IBMPlexSans'

export const mapTypographyPresetToSx = (typographyPreset: TypographyPresetT): SystemStyleObject => ({
  fontFamily: typographyPreset.fontFamily === defaultFontFamily ? undefined : typographyPreset.fontFamily,
  fontSize: toPx(typographyPreset.fontSize),
  fontWeight: typographyPreset.fontWeight,
  letterSpacing: toPx(typographyPreset.letterSpacing),
  lineHeight: toPx(typographyPreset.lineHeight),
})
