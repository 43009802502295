import type { CustomFieldT } from '../../types/custom-field'

import { DropdownCustomFieldPayload } from './components/dropdown-custom-field-payload'

export const renderPayload: CustomFieldT['renderPayload'] = ({ form, name }) => {
  return (
    <DropdownCustomFieldPayload
      form={form}
      name={name}
    />
  )
}
