import type { StateModelT } from "@resnet/client-common/react/types/state-model";
import { forwardFunctionalComponentRef } from "@resnet/client-common/react/utils/forward-functional-component-ref";
import type { MergeT } from "@resnet/client-common/typescript/types/merge";

import type { PostFragmentT } from "@resnet/client-api/api";

import { OfficeRegularPostCard } from "./OfficeRegularPostCard";
import { MessageCardLayoutPropsT } from "@resnet/client-web/shared/feed/components/message-card-layout";

export type PostCardPropsT = MergeT<
  Omit<MessageCardLayoutPropsT, "children">,
  {
    post: PostFragmentT;
    contentHeightModel?: StateModelT<null | number>;
    isMostRecentAIBotPost?: boolean;
  }
>;

export const OfficePostCard = forwardFunctionalComponentRef(
  ({ post, isMostRecentAIBotPost, ...props }: PostCardPropsT, ref) => {
    if (post.__typename === "BotPost") {
      return null;
    }
    return <OfficeRegularPostCard {...props} post={post} ref={ref} />;
  }
);
