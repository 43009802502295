import { generatePath } from 'react-router-dom'

import type { CampaignFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type CampaignWithFeedPathnameT = Pick<CampaignFragmentT, '__typename' | 'readableId'>

export const mapCampaignToFeedPathname = <ValueT extends CampaignWithFeedPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.campaignsDetailsFeed, { campaignId: value.readableId })
}
