import type { QueryClient } from '@tanstack/react-query'

import { updateById } from '@resnet/client-common/common/utils/array/update-by-id'
import { transform } from '@resnet/client-common/common/utils/object/transform'

import {
  useCreateCompanyFilterPresetMutation,
  useCreateMyFilterPresetMutation,
  useDeleteCompanyFilterPresetMutation,
  useDeleteMyFilterPresetMutation,
  useGetMyDefaultFilterPresetQuery,
  useListCompanyFilterPresetsQuery,
  useListMyFilterPresetsQuery,
  useSetMyDefaultFilterPresetMutation,
  useUpdateCompanyFilterPresetMutation,
  useUpdateMyFilterPresetMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const listCompanyFilterPresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListCompanyFilterPresetsQueries = () => findQueries(queryClient, useListCompanyFilterPresetsQuery)

  const createCompanyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCompanyFilterPresetMutation, {
      onSuccess: () => {
        findListCompanyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCompanyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCompanyFilterPresetMutation, {
      onError: () => {
        findListCompanyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, items, name }) => {
        findListCompanyFilterPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyFilterPresets: (listCompanyFilterPresets) =>
                transform(listCompanyFilterPresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      items: [items].flat(),
                      name: name ?? preset.name,
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCompanyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCompanyFilterPresetMutation, {
      onError: () => {
        findListCompanyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListCompanyFilterPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyFilterPresets: (listCompanyFilterPresets) =>
                transform(listCompanyFilterPresets, {
                  items: (items) => items.filter((item) => item.id !== id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createCompanyFilterPresetHandlers(), deleteCompanyFilterPresetHandlers(), updateCompanyFilterPresetHandlers()]
}

export const listMyFilterPresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListMyFilterPresetsQueries = () => findQueries(queryClient, useListMyFilterPresetsQuery)

  const createMyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateMyFilterPresetMutation, {
      onSuccess: () => {
        findListMyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateMyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateMyFilterPresetMutation, {
      onError: () => {
        findListMyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, items, name }) => {
        findListMyFilterPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyFilterPresets: (listMyFilterPresets) =>
                transform(listMyFilterPresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      items: [items].flat(),
                      name: name ?? preset.name,
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteMyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteMyFilterPresetMutation, {
      onError: () => {
        findListMyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListMyFilterPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyFilterPresets: (listMyFilterPresets) =>
                transform(listMyFilterPresets, {
                  items: (items) => items.filter((item) => item.id !== id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyFilterPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createMyFilterPresetHandlers(), deleteMyFilterPresetHandlers(), updateMyFilterPresetHandlers()]
}
export const getMyDefaultFilterPresetMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findGetMyDefaultFilterPreset = () => findQueries(queryClient, useGetMyDefaultFilterPresetQuery)

  const setMyDefaultFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSetMyDefaultFilterPresetMutation, {
      onError: () => {
        findGetMyDefaultFilterPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultFilterPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultFilterPreset: () => id,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultFilterPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCompanyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCompanyFilterPresetMutation, {
      onError: () => {
        findGetMyDefaultFilterPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultFilterPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultFilterPreset: (getMyDefaultFilterPreset) =>
                getMyDefaultFilterPreset === id ? null : getMyDefaultFilterPreset,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultFilterPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteMyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteMyFilterPresetMutation, {
      onError: () => {
        findGetMyDefaultFilterPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultFilterPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultFilterPreset: (getMyDefaultFilterPreset) =>
                getMyDefaultFilterPreset === id ? null : getMyDefaultFilterPreset,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultFilterPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [setMyDefaultFilterPresetHandlers(), deleteCompanyFilterPresetHandlers(), deleteMyFilterPresetHandlers()]
}
