import type { SxProps } from '@mui/system'

import { Tag } from '@resnet/client-web/shared/gdl/components/tag'

export const NotFoundCell = ({ sx }: { sx?: SxProps }): React.ReactElement => {
  return (
    <Tag
      uppercase
      status="warning"
      sx={sx}
    >
      Not Found
    </Tag>
  )
}
