import type { StateModelT } from '../types/state-model'
import { setStateReducer } from '../utils/set-state-reducer'

import { useDerivedState } from './use-derived-state'
import { useEventCallback } from './use-event-callback'

export const useMapStateModel = <InputStateT, OutputStateT>(
  inputStateModel: StateModelT<InputStateT>,
  mapInputStateToOutputState: (inputState: InputStateT) => OutputStateT,
  mapOutputStateToInputState: (outputState: OutputStateT, inputState: InputStateT) => InputStateT,
): StateModelT<OutputStateT> => {
  const [inputState, setInputState] = inputStateModel

  const [outputState] = useDerivedState(() => mapInputStateToOutputState(inputState), [inputState])

  const setOutputState = useEventCallback((action: React.SetStateAction<OutputStateT>): void => {
    setInputState((inputState) => {
      const outputState = mapInputStateToOutputState(inputState)

      const nextOutputState = setStateReducer(outputState, action)

      const nextInputState = mapOutputStateToInputState(nextOutputState, inputState)

      return nextInputState
    })
  })

  return [outputState, setOutputState]
}
