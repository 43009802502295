import { checkIsAsset } from '../../assets/utils/check-is-asset'
import type { AssetWithFeedPathnameT } from '../../assets/utils/map-asset-to-feed-pathname'
import { mapAssetToFeedPathname } from '../../assets/utils/map-asset-to-feed-pathname'
import { checkIsCampaign } from '../../campaigns/utils/check-is-campaign'
import type { CampaignWithFeedPathnameT } from '../../campaigns/utils/map-campaign-to-feed-pathname'
import { mapCampaignToFeedPathname } from '../../campaigns/utils/map-campaign-to-feed-pathname'
import { checkIsCredential } from '../../credentials/utils/check-is-credential'
import type { CredentialWithFeedPathnameT } from '../../credentials/utils/map-credential-to-feed-pathname'
import { mapCredentialToFeedPathname } from '../../credentials/utils/map-credential-to-feed-pathname'
import { checkIsFormObject } from '../../form-objects/utils/check-is-form-object'
import type { FormObjectWithFeedPathnameT } from '../../form-objects/utils/map-form-object-to-feed-pathname'
import { mapFormObjectToFeedPathname } from '../../form-objects/utils/map-form-object-to-feed-pathname'
import { checkIsInsight } from '../../insights/utils/check-is-insight'
import type { InsightWithFeedPathnameT } from '../../insights/utils/map-insight-to-feed-pathname'
import { mapInsightToFeedPathname } from '../../insights/utils/map-insight-to-feed-pathname'
import { checkIsIssue } from '../../issues/utils/check-is-issue'
import type { IssueWithFeedPathnameT } from '../../issues/utils/map-issue-to-feed-pathname'
import { mapIssueToFeedPathname } from '../../issues/utils/map-issue-to-feed-pathname'
import { checkIsResourceObject } from '../../resource-objects/utils/check-is-resource-object'
import type { ResourceObjectWithFeedPathnameT } from '../../resource-objects/utils/map-resource-object-to-feed-pathname'
import { mapResourceObjectToFeedPathname } from '../../resource-objects/utils/map-resource-object-to-feed-pathname'
import { checkIsRoute } from '../../routes/utils/check-is-route'
import type { RouteWithFeedPathnameT } from '../../routes/utils/map-route-to-feed-pathname'
import { mapRouteToFeedPathname } from '../../routes/utils/map-route-to-feed-pathname'

export type EntityWithFeedPathnameT =
  | AssetWithFeedPathnameT
  | CampaignWithFeedPathnameT
  | CredentialWithFeedPathnameT
  | FormObjectWithFeedPathnameT
  | IssueWithFeedPathnameT
  | InsightWithFeedPathnameT
  | ResourceObjectWithFeedPathnameT
  | RouteWithFeedPathnameT

export const mapEntityToFeedPathname = <ValueT extends EntityWithFeedPathnameT>(value: ValueT) => {
  if (checkIsAsset(value)) {
    return mapAssetToFeedPathname(value)
  }

  if (checkIsCampaign(value)) {
    return mapCampaignToFeedPathname(value)
  }

  if (checkIsCredential(value)) {
    return mapCredentialToFeedPathname(value)
  }

  if (checkIsFormObject(value)) {
    return mapFormObjectToFeedPathname(value)
  }

  if (checkIsIssue(value)) {
    return mapIssueToFeedPathname(value)
  }

  if (checkIsInsight(value)) {
    return mapInsightToFeedPathname(value)
  }

  if (checkIsResourceObject(value)) {
    return mapResourceObjectToFeedPathname(value)
  }

  if (checkIsRoute(value)) {
    return mapRouteToFeedPathname(value)
  }

  throw new Error(`Unknown entity type: ${value.__typename}`)
}
