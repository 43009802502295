import type { QueryClient } from '@tanstack/react-query'

import { pullById } from '@resnet/client-common/common/utils/array/pull-by-id'
import { updateById } from '@resnet/client-common/common/utils/array/update-by-id'
import { transform } from '@resnet/client-common/common/utils/object/transform'

import {
  useCreateCompanyMapPresetMutation,
  useCreateMyMapPresetMutation,
  useDeleteCompanyMapPresetMutation,
  useDeleteMyMapPresetMutation,
  useGetMyDefaultMapPresetQuery,
  useListCompanyMapPresetsQuery,
  useListMyMapPresetsQuery,
  useSetMyDefaultMapPresetMutation,
  useUpdateCompanyMapPresetMutation,
  useUpdateMyMapPresetMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const listCompanyMapPresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListCompanyMapPresetsQueries = () => findQueries(queryClient, useListCompanyMapPresetsQuery)

  const createCompanyMapPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCompanyMapPresetMutation, {
      onSuccess: () => {
        findListCompanyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCompanyMapPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCompanyMapPresetMutation, {
      onError: () => {
        findListCompanyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, layers, style }) => {
        findListCompanyMapPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyMapPresets: (listCompanyMapPresets) =>
                transform(listCompanyMapPresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      layers: [layers].flat(),
                      style,
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCompanyMapPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCompanyMapPresetMutation, {
      onError: () => {
        findListCompanyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListCompanyMapPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyMapPresets: (listCompanyMapPresets) =>
                transform(listCompanyMapPresets, {
                  items: (items) => pullById(items, id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createCompanyMapPresetHandlers(), updateCompanyMapPresetHandlers(), deleteCompanyMapPresetHandlers()]
}

export const listMyMapPresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListMyMapPresetsQueries = () => findQueries(queryClient, useListMyMapPresetsQuery)

  const createMyMapPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateMyMapPresetMutation, {
      onSuccess: () => {
        findListMyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateMyMapPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateMyMapPresetMutation, {
      onError: () => {
        findListMyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, layers, style }) => {
        findListMyMapPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyMapPresets: (listMyMapPresets) =>
                transform(listMyMapPresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      layers: [layers].flat(),
                      style,
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteMyMapPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteMyMapPresetMutation, {
      onError: () => {
        findListMyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListMyMapPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyMapPresets: (listMyMapPresets) =>
                transform(listMyMapPresets, {
                  items: (items) => pullById(items, id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyMapPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createMyMapPresetHandlers(), updateMyMapPresetHandlers(), deleteMyMapPresetHandlers()]
}
export const getMyDefaultMapPresetMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findGetMyDefaultMapPreset = () => findQueries(queryClient, useGetMyDefaultMapPresetQuery)

  const setMyDefaultMapPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSetMyDefaultMapPresetMutation, {
      onError: () => {
        findGetMyDefaultMapPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultMapPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultMapPreset: () => id,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultMapPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCompanyMapPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCompanyMapPresetMutation, {
      onError: () => {
        findGetMyDefaultMapPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultMapPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultMapPreset: (getMyDefaultMapPreset) =>
                getMyDefaultMapPreset === id ? null : getMyDefaultMapPreset,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultMapPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteMyMapPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteMyMapPresetMutation, {
      onError: () => {
        findGetMyDefaultMapPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultMapPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultMapPreset: (getMyDefaultMapPreset) =>
                getMyDefaultMapPreset === id ? null : getMyDefaultMapPreset,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultMapPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [setMyDefaultMapPresetHandlers(), deleteCompanyMapPresetHandlers(), deleteMyMapPresetHandlers()]
}
