import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'
import { foldElement } from '@resnet/client-common/react/utils/fold-element'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import type { MinimalOriginFragmentT } from '@resnet/client-api/api'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import { mapOriginToPathname } from '@resnet/client-shared/shared/origins/utils/map-origin-to-pathname'
import { mapOriginToReadableId } from '@resnet/client-shared/shared/origins/utils/map-origin-to-readable-id'
import { mapOriginToTitle } from '@resnet/client-shared/shared/origins/utils/map-origin-to-title'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { focusOutlineSx } from '@resnet/client-web/shared/gdl/sx-presets/focus-outline'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export type MessageCardOriginPropsT = MergeT<Omit<BoxProps<'a'>, 'children'>, { origin: MinimalOriginFragmentT }>

export const MessageCardOrigin = ({ origin, sx = null, ...props }: MessageCardOriginPropsT) => {
  const pathname = mapOriginToPathname(origin)

  const to = { pathname }

  return (
    <Box
      {...props}
      component={Link}
      sx={[
        {
          all: 'unset',
        },
        {
          alignItems: 'center',
          backgroundColor: themeColors.surfaceVariantDefault,
          borderRadius: toPx(8),
          cursor: 'pointer',
          display: 'flex',
          padding: toPx(8),
          position: 'relative',
        },
        {
          '&:focus': focusOutlineSx,
          '&:hover': {
            backgroundColor: themeColors.surfaceVariantHover,
          },
        },
        {
          '&:active': {
            backgroundColor: themeColors.surfaceVariantPressed,
          },
        },
        sx,
      ].flat()}
      to={to}
    >
      <ChildrenDivider
        dividerNode={
          <Box
            component="span"
            sx={[
              mapTypographyPresetToSx(typographyPresets.overlineLarge),
              { color: themeColors.overBackgroundDefault },
            ]}
          >
            {'\u00A0·\u00A0'}
          </Box>
        }
      >
        <Box
          component="span"
          sx={[mapTypographyPresetToSx(typographyPresets.bodySmall), { color: themeColors.overBackgroundBold }]}
        >
          {mapOriginToTitle(origin)}
        </Box>
        {foldElement(
          <Box
            component="span"
            sx={[
              mapTypographyPresetToSx(typographyPresets.overlineLarge),
              { color: themeColors.overBackgroundDefault },
            ]}
          >
            {mapOriginToReadableId(origin)}
          </Box>,
        )}
      </ChildrenDivider>
    </Box>
  )
}
