import { difference } from 'ramda'

import { RelationOperationT, type UpdateRelationsMutationVariablesT } from '@resnet/client-api/api'

export const mapFormValuesToUpdateRelationsMutationVariables = <
  FormValuesT extends { relations?: undefined | null | string[] },
>({
  entityId,
  values,
  relations: relationsActual,
}: {
  entityId: string
  values: FormValuesT
  relations?: undefined | null | string[]
}): UpdateRelationsMutationVariablesT => {
  const relations = relationsActual ?? []

  const nextRelations = values.relations ?? []

  return {
    entityId,
    operations: [
      ...difference(relations, nextRelations).map((id) => ({
        operation: RelationOperationT.DeleteT,
        relatedEntityId: id,
      })),
      ...difference(nextRelations, relations).map((id) => ({
        operation: RelationOperationT.AddT,
        relatedEntityId: id,
      })),
    ].flat(),
  }
}
