import { createContext } from 'react'

import type { MinimalOriginFragmentT, PostFragmentT } from '@resnet/client-api/api'

export type StandaloneFeedT = { type: 'standalone' }

export type PostsFeedT = { type: 'posts'; origin?: MinimalOriginFragmentT; isOriginScreen?: boolean }

export type CommentsFeedT = { type: 'comments'; post: PostFragmentT }

export type FeedT = StandaloneFeedT | PostsFeedT | CommentsFeedT

export const FeedContext = createContext<FeedT>({ type: 'standalone' })

export const checkIsStandaloneFeed = (feed: FeedT): feed is StandaloneFeedT => feed.type === 'standalone'

export const checkIsPostsFeed = (feed: FeedT): feed is PostsFeedT => feed.type === 'posts'

export const checkIsCommentsFeed = (feed: FeedT): feed is CommentsFeedT => feed.type === 'comments'
