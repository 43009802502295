export type SubscriberT<ActionT> = (action: ActionT) => void

export type SubscribeT<ActionT> = (subscriber: SubscriberT<ActionT>) => () => void

export type DispatchT<ActionT> = (action: ActionT) => void

export type EmitterT<ActionT> = {
  dispatch: DispatchT<ActionT>
  subscribe: SubscribeT<ActionT>
}

export const createEmitter = <ActionT>(): EmitterT<ActionT> => {
  const subscribers = new Set<SubscriberT<ActionT>>()

  const dispatch: DispatchT<ActionT> = (action) => {
    for (const subscriber of Array.from(subscribers)) {
      subscriber(action)
    }
  }

  const subscribe: SubscribeT<ActionT> = (subscriber) => {
    subscribers.add(subscriber)

    return () => {
      subscribers.delete(subscriber)
    }
  }

  return {
    dispatch,
    subscribe,
  }
}
