import { Box } from '@mui/material'
import type { SxProps } from '@mui/material'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useEffect } from 'react'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { UserFragmentT, UserQueryT } from '@resnet/client-api/api'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import { useUserOption } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { Link } from '@resnet/client-web/shared/gdl/components/link'
import { TextOverflow } from '@resnet/client-web/shared/gdl/components/text-overflow'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { UserAvatar } from '@resnet/client-web/shared/users/components/user-avatar'
import { mapUserToPathname } from '@resnet/client-web/shared/users/utils/map-user-to-pathname'

import { EmptyCell } from '../empty-cell'
import { NotFoundCell } from '../not-found-cell'
import { SkeletonCell } from '../skeleton-cell'

export const UserCellContainer = createHookContainer(
  ({
    id,
    options,
    onSuccess,
  }: {
    id: string
    options?: UseQueryOptions<UserQueryT, unknown, UserFragmentT>
    onSuccess?: () => void
  }) => {
    const { optionQuery: userQuery } = useUserOption({ id, options })

    useEffect(() => {
      if (userQuery.isSuccess) {
        onSuccess?.()
      }
    }, [onSuccess, userQuery.isSuccess])

    return { userQuery }
  },
)

export const UserCellContent = ({ user, sx }: { user: UserFragmentT; sx?: SxProps }): React.ReactElement => {
  return (
    <Box
      sx={[
        { alignItems: 'center', display: 'flex', flexGrow: 1, gap: toPx(8), minWidth: 0, position: 'relative' },
        sx ?? null,
      ].flat()}
    >
      <UserAvatar
        size="xs"
        user={user}
      />
      <Link
        sx={{
          ...mapTypographyPresetToSx(typographyPresets.bodySmall),
          '&::before': { content: '""', inset: 0, position: 'absolute' },
          display: 'flex',
          flexGrow: 1,
          minWidth: 0,
        }}
        to={mapUserToPathname(user)}
      >
        <TextOverflow>{mapUserToTitle(user)}</TextOverflow>
      </Link>
    </Box>
  )
}

export const UserCell = ({
  id,
  sx,
  options,
  onSuccess,
}: {
  id: undefined | null | string
  sx?: SxProps
  options?: UseQueryOptions<UserQueryT, unknown, UserFragmentT>
  onSuccess?: () => void
}): React.ReactElement => {
  if (!id) {
    return <EmptyCell sx={sx} />
  }

  return (
    <UserCellContainer
      id={id}
      options={options}
      onSuccess={onSuccess}
    >
      {({ userQuery }) => {
        if (!userQuery.isSuccess) {
          return (
            <SkeletonCell
              withMedia
              sx={sx}
            />
          )
        }

        const user = userQuery.data

        if (!user) {
          return <NotFoundCell sx={sx} />
        }

        return (
          <UserCellContent
            sx={sx}
            user={user}
          />
        )
      }}
    </UserCellContainer>
  )
}
