import { format, isToday, isYesterday } from 'date-fns'

export const formatDateToDefaultFormat = (date: string | Date): string => {
  const dateValue = new Date(date)

  if (isToday(dateValue)) {
    return `Today, ${format(dateValue, 'hh:mm a')}`
  }

  if (isYesterday(dateValue)) {
    return `Yesterday, ${format(dateValue, 'hh:mm a')}`
  }

  return format(dateValue, 'MM/dd/yyyy hh:mm a')
}
