import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { FormulaCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/formula/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { FormulaFormCustomFieldValueT } from './form-custom-field-value'
import { mapFormulaToValue } from './utils/map-formula-to-value'

export const mapFormCustomFieldValueToCustomFieldValue: CustomFieldT<
  FormulaCustomFieldValueT,
  FormulaFormCustomFieldValueT
>['mapFormCustomFieldValueToCustomFieldValue'] = ({ field, fieldValues }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const formulaPayload = payload[CustomFieldTypeT.FormulaT]

  assert(formulaPayload, checkNonNullable)

  const { value: formula } = formulaPayload

  return mapFormulaToValue(formula, fieldValues)
}
