import { checkIsQuerySuccess } from '@resnet/client-common/react-query/utils/check-is-query-success'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { PostFragmentT } from '@resnet/client-api/api'

import { useOriginOption } from '@resnet/client-shared/shared/origins/hooks/use-origin-option'

export const usePostOrigin = ({ post }: { post: PostFragmentT }) => {
  const { originId, originType } = post

  const { optionQuery: originQuery } = useOriginOption({
    id: originId,
    options: { suspense: true },
    type: originType,
  })

  assert(originQuery, checkIsQuerySuccess, '"originQuery" should be loaded')

  const origin = originQuery.data

  return { origin }
}
