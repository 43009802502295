import type { FieldPathByValue, FieldValues, PathValue, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { TextCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/text/custom-field-value'
import { validateTextRequired } from '@resnet/client-shared/shared/forms/validators/text-required'

import { TextField } from '@resnet/client-web/shared/form-dalaran/components/common/text-field'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type TextCustomFieldUserFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | TextCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const TextCustomFieldUserFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | TextCustomFieldValueT>,
>({
  field,
  form,
  name,
}: TextCustomFieldUserFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const textPayload = payload[CustomFieldTypeT.TextT]

  assert(textPayload, checkNonNullable)

  const { defaultValue, multiline, placeholder, required } = textPayload

  return (
    <ObjectDetailsRow>
      <TextField
        defaultValue={defaultValue as PathValue<TFieldValues, TPath>}
        form={form}
        label={field.name}
        name={name}
        placeholder={placeholder}
        rules={!required ? undefined : { validate: validateTextRequired }}
        textArea={multiline}
      />
    </ObjectDetailsRow>
  )
}
