import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldT } from '../../types/custom-field'

export const discriminatorCustomField = createConstrainer<CustomFieldT>()({
  id: CustomFieldTypeT.DiscriminatorT as const,
  name: 'Discriminator',
  payload: createConstrainer<CustomFieldPayloadT>()({
    Discriminator: {
      defaultValue: null,
      options: [],
      required: false,
    },
  }) as CustomFieldPayloadT,
})
