import type { BoxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import AngleLeftSolidIcon from '@resnet/client-shared/assets/icons/angle-left-solid.svg'
import AngleRightSolidIcon from '@resnet/client-shared/assets/icons/angle-right-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { Divider } from '@resnet/client-web/shared/gdl/components/divider'
import { TextOverflow } from '@resnet/client-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import {
  layoutSidebarBorderLeft,
  layoutSidebarHorizontalPadding,
  layoutSidebarVerticalPadding,
  layoutSidebarWidth,
} from '@resnet/client-web/shared/layout/constants/common'

export type SidebarPropsT = BoxProps

const SidebarToggleButton = ({ onClick, tooltip = 'Expand' }: { onClick: () => void; tooltip?: string }) => {
  return (
    <Box
      sx={{
        borderBottomWidth: '0px',
        borderColor: themeColors.borderBold,
        borderLeftWidth: toPx(layoutSidebarBorderLeft),
        borderRightWidth: '0px',
        borderStyle: 'solid',
        borderTopWidth: '0px',
        display: 'flex',
        flexDirection: 'column',
        paddingX: '8px',
        paddingY: '24px',
        width: '48px',
      }}
    >
      <Tooltip title={tooltip}>
        <Button
          color="primary"
          icon={<AngleLeftSolidIcon />}
          size="sm"
          variant="text"
          onClick={onClick}
        />
      </Tooltip>
    </Box>
  )
}

export const Sidebar = ({ sx = null, children, ...props }: SidebarPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          backgroundColor: themeColors.background,
          borderBottomWidth: toPx(0),
          borderColor: themeColors.borderBold,
          borderLeftWidth: toPx(layoutSidebarBorderLeft),
          borderRightWidth: toPx(0),
          borderStyle: 'solid',
          borderTopWidth: toPx(0),
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          pb: toPx(layoutSidebarVerticalPadding),
          pl: toPx(layoutSidebarHorizontalPadding - layoutSidebarBorderLeft),
          pr: toPx(layoutSidebarHorizontalPadding),
          pt: toPx(layoutSidebarVerticalPadding),
          width: toPx(layoutSidebarWidth),
        },
        sx,
      ].flat()}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(16) }}>
        <ChildrenDivider dividerNode={<Divider />}>{children}</ChildrenDivider>
      </Box>
    </Box>
  )
}

export type CollapsiblePropsT = {
  isCollapsed: boolean
  onToggle: () => void
  tooltip?: string
}

export const CollapsibleSidebar = ({
  children,
  isCollapsed,
  onToggle,
  ...props
}: SidebarPropsT & CollapsiblePropsT) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: isCollapsed ? '48px' : toPx(layoutSidebarWidth),
      }}
    >
      {isCollapsed ? <SidebarToggleButton onClick={onToggle} /> : <Sidebar {...props}>{children}</Sidebar>}
    </Box>
  )
}

export type SidebarHeaderPropsT = BoxProps

export const SidebarHeader = ({ sx = null, children, ...props }: SidebarHeaderPropsT) => {
  return (
    <Box
      {...props}
      sx={[{ alignItems: 'center', display: 'flex', gap: toPx(8) }, sx].flat()}
    >
      {children}
    </Box>
  )
}

export const CollapsibleSidebarHeader = ({
  onToggle,
  tooltip = 'Collapse',
  sx = null,
  children,
  ...props
}: SidebarHeaderPropsT & Omit<CollapsiblePropsT, 'isCollapsed'>) => {
  return (
    <Box
      {...props}
      sx={[{ alignItems: 'center', display: 'flex', gap: toPx(8) }, sx].flat()}
    >
      {children}

      <Tooltip title={tooltip}>
        <Button
          color="primary"
          icon={<AngleRightSolidIcon />}
          size="sm"
          sx={{
            justifySelf: 'end',
          }}
          variant="text"
          onClick={onToggle}
        />
      </Tooltip>
    </Box>
  )
}

export type SidebarTitlePropsT = BoxProps

export const SidebarTitle = ({ sx = null, children, ...props }: SidebarTitlePropsT) => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.headlineMedium),
        {
          color: themeColors.overBackgroundBold,
          display: 'flex',
          flexGrow: 1,
          width: 0,
        },
        sx,
      ].flat()}
    >
      <TextOverflow>{children}</TextOverflow>
    </Box>
  )
}

export type SidebarScrollableContentPropsT = BoxProps

export const SidebarScrollableContent = ({ sx = null, children, ...props }: SidebarScrollableContentPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100%',
          ml: toPx(-(layoutSidebarHorizontalPadding - layoutSidebarBorderLeft)),
          mr: toPx(-layoutSidebarHorizontalPadding),
          overflow: 'auto',
          pl: toPx(layoutSidebarHorizontalPadding - layoutSidebarBorderLeft),
          pr: toPx(layoutSidebarHorizontalPadding),
        },
        sx,
      ].flat()}
    >
      {children}
    </Box>
  )
}

export const SidebarFooter = ({ children }: { children: { left?: React.ReactNode; right?: React.ReactNode } }) => {
  const gap = 16

  const renderLeft = () => {
    if (!children.left) {
      return null
    }

    return <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(gap) }}>{children.left}</Box>
  }

  const renderRight = () => {
    if (!children.right) {
      return null
    }

    return <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(gap), ml: 'auto' }}>{children.right}</Box>
  }

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(gap) }}>
      {renderLeft()}
      {renderRight()}
    </Box>
  )
}
