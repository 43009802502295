import { useEffect, useState } from 'react'

import { subscribeEvent } from '@resnet/client-common/common/utils/event/subscribe-event'

const useQueryMatches = (mediaQueryList: MediaQueryList) => {
  const [matches, setMatches] = useState(() => mediaQueryList.matches)

  useEffect(
    () =>
      subscribeEvent(mediaQueryList, 'change', (event: MediaQueryListEvent) => {
        setMatches(event.matches)
      }),
    [mediaQueryList],
  )

  return matches
}

const prefersColorSchemeDarkMediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')

const prefersColorSchemeLightMediaQueryList = window.matchMedia('(prefers-color-scheme: light)')

export const useSystemColorScheme = () => {
  const isPrefersColorSchemeDark = useQueryMatches(prefersColorSchemeDarkMediaQueryList)

  const isPrefersColorSchemeLight = useQueryMatches(prefersColorSchemeLightMediaQueryList)

  const colorScheme = (() => {
    if (isPrefersColorSchemeDark) {
      return 'dark'
    }

    if (isPrefersColorSchemeLight) {
      return 'light'
    }

    return 'dark'
  })()

  return colorScheme
}
