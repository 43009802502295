import { useEffect } from 'react'
import type { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import { checkIsEmptyDeep, checkIsNotEmptyDeep } from '@resnet/client-common/common/utils/any/check-is-empty-deep'
import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

export const useRestoreFormDirtyState = <TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
  restoredValues: null | TFieldValues,
) => {
  useEffect(() => {
    if (checkIsEmptyDeep(restoredValues) || checkIsNotEmptyDeep(form.formState.touchedFields)) {
      return
    }

    const dumbFieldName = 'dumbField' as Path<TFieldValues>

    const dumbFieldValue = ' ' as AnyTypeT

    form.register(dumbFieldName)

    form.setValue(dumbFieldName, dumbFieldValue, { shouldDirty: true, shouldTouch: true })

    form.unregister(dumbFieldName)
  }, [form, restoredValues])
}
