import { useCallbackRefEffect } from '@resnet/client-common/react/hooks/use-callback-ref-effect'
import { subscribeTimeout } from '@resnet/client-common/web/utils/async/subscribe-timeout'

export const useAutoSelectRef = (
  shouldBeSelected: boolean,
): React.RefCallback<HTMLInputElement | HTMLTextAreaElement> => {
  return useCallbackRefEffect<HTMLInputElement | HTMLTextAreaElement>(
    (node) => {
      if (!node || !shouldBeSelected) {
        return
      }

      return subscribeTimeout(() => node.select(), 0)
    },
    [shouldBeSelected],
  )
}
