import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import { attachmentCustomField } from '../presets/attachment/custom-field'
import { checkboxCustomField } from '../presets/checkbox/custom-field'
import { dateCustomField } from '../presets/date/custom-field'
import { discriminatorCustomField } from '../presets/discriminator/custom-field'
import { dropdownCustomField } from '../presets/dropdown/custom-field'
import { entityPickerCustomField } from '../presets/entity-picker/custom-field'
import { formulaCustomField } from '../presets/formula/custom-field'
import { numberCustomField } from '../presets/number/custom-field'
import { optionGroupCustomField } from '../presets/option-group/custom-field'
import { sectionHeaderCustomField } from '../presets/section-header/custom-field'
import { sketchCustomField } from '../presets/sketch/custom-field'
import { textCustomField } from '../presets/text/custom-field'
import { userPickerCustomField } from '../presets/user-picker/custom-field'
import type { CustomFieldT } from '../types/custom-field'

export const customFields = createConstrainer<CustomFieldT[]>()([
  sectionHeaderCustomField,
  textCustomField,
  numberCustomField,
  checkboxCustomField,
  dateCustomField,
  attachmentCustomField,
  userPickerCustomField,
  entityPickerCustomField,
  dropdownCustomField,
  optionGroupCustomField,
  sketchCustomField,
  formulaCustomField,
  discriminatorCustomField,
])

export const customFieldsById = propToKey('id', customFields)
