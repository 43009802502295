import type { MinimalAssetFragmentT } from '@resnet/client-api/api'

import OilBatterySolidIcon from '@resnet/client-shared/assets/icons/oil-battery-solid.svg'
import OilSiteSolidIcon from '@resnet/client-shared/assets/icons/oil-site-solid.svg'
import OilWellSolidIcon from '@resnet/client-shared/assets/icons/oil-well-solid.svg'

import { checkIsBattery } from '../../batteries/utils/check-is-battery'
import { checkIsSite } from '../../sites/utils/check-is-site'
import { checkIsWell } from '../../wells/utils/check-is-well'

export const mapAssetToIcon = <ValueT extends Pick<MinimalAssetFragmentT, '__typename'>>(value: ValueT) => {
  if (checkIsBattery(value)) {
    return OilBatterySolidIcon
  }

  if (checkIsSite(value)) {
    return OilSiteSolidIcon
  }

  if (checkIsWell(value)) {
    return OilWellSolidIcon
  }

  throw new Error(`Unknown asset type: ${value.__typename}`)
}
