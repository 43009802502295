import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'

export type MessageCardMetaPropsT = BoxProps

export const MessageCardMeta = ({ sx = null, children, ...props }: MessageCardMetaPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.captionRegular),
        { color: themeColors.overBackgroundMuted },
        sx,
      ].flat()}
    >
      <ChildrenDivider dividerNode=" · ">{children}</ChildrenDivider>
    </Box>
  )
}
