import type { CustomFieldT } from '../../types/custom-field'

import { DiscriminatorCustomFieldUserFormField } from './components/discriminator-custom-field-user-form-field'

export const renderUserFormField: CustomFieldT['renderUserFormField'] = ({ form, name, field }) => {
  return (
    <DiscriminatorCustomFieldUserFormField
      field={field}
      form={form}
      name={name}
    />
  )
}
