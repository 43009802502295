import { generatePath } from 'react-router-dom'

import type { InsightFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type InsightWithPathnameT = Pick<InsightFragmentT, '__typename' | 'readableId'>

export const mapInsightToPathname = <ValueT extends InsightWithPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.insightsDetails, { insightId: value.readableId })
}
