import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import type { ResourceFragmentT } from '@resnet/client-api/api'
import { useDeleteResourceMutation } from '@resnet/client-api/api'

export const useDeleteResources = () => {
  const { mutateAsync: deleteResource } = useDeleteResourceMutation()

  const deleteResources = useEventCallback(async ({ resources }: { resources: ResourceFragmentT[] }) => {
    await Promise.all(
      resources.map(async (resource) => {
        await deleteResource({
          id: resource.id,
        })
      }),
    )
  })

  return { deleteResources }
}
