import { FilterModeT } from '@resnet/client-api/api'

export const mapFilterModeToKey = (mode: FilterModeT) => {
  switch (mode) {
    case FilterModeT.IncludeT: {
      return 'in'
    }
    case FilterModeT.ExcludeT: {
      return 'notIn'
    }
  }
}
