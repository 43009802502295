import type { Location } from 'history'
import { useMemo } from 'react'
import type { SetStateAction } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { equalByValuesStrict } from '@resnet/client-common/common/utils/object/equal-by-values'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useSameRef } from '@resnet/client-common/react/hooks/use-same-ref'
import { setStateReducer } from '@resnet/client-common/react/utils/set-state-reducer'

export type MapLocationToStateT<StateT, LocationStateT = unknown> = (
  currentLocation: Location<LocationStateT>,
) => StateT

export type MapStateToLocationT<StateT, LocationStateT = unknown> = (
  nextState: StateT,
  location: Location<LocationStateT>,
) => Location

export const useLocationState = <StateT, LocationStateT = unknown>(
  mapLocationToState: MapLocationToStateT<StateT, LocationStateT>,
  mapStateToLocation: MapStateToLocationT<StateT, LocationStateT>,
  comparator: (left: StateT, right: StateT) => boolean = Object.is,
): [StateT, (action: SetStateAction<StateT>, type?: 'PUSH' | 'REPLACE') => void] => {
  const browserHistory = useHistory()

  const currentLocation = useLocation<LocationStateT>()

  const state = useSameRef(
    useMemo(() => mapLocationToState(currentLocation), [mapLocationToState, currentLocation]),
    comparator,
  )

  const setState = useEventCallback((action: SetStateAction<StateT>, type: 'PUSH' | 'REPLACE' = 'REPLACE') => {
    const navigate = (value: Location): void => {
      switch (type) {
        case 'PUSH': {
          browserHistory.push(value)
          break
        }
        case 'REPLACE': {
          browserHistory.replace(value)
          break
        }
      }
    }

    const nextState = setStateReducer(state, action)

    const nextLocation = mapStateToLocation(nextState, browserHistory.location as Location<LocationStateT>)

    if (equalByValuesStrict(browserHistory.location, nextLocation)) {
      return
    }

    navigate(nextLocation)
  })

  return [state, setState]
}
