import type { AbstractOptionT } from '../../../types/abstract-option'
import { nullOption } from '../constants/null-option'

export const checkIsNullOptionId = (optionId: string): optionId is (typeof nullOption)['id'] => {
  return optionId === nullOption.id
}

export const checkIsNullOption = (option: AbstractOptionT): option is typeof nullOption => {
  return option.id === nullOption.id
}
