import produce from 'immer'

import {
  useAddGroupMembersMutation,
  useChangeGroupDescriptionMutation,
  useChangeGroupNameMutation,
  useDeleteGroupMutation,
  useGroupQuery,
  useJoinGroupMutation,
  useLeaveGroupMutation,
  useRemoveGroupMembersMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const groupQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findGroupQueries = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGroupQuery, (data) => data.getGroup, { id })

  const joinGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useJoinGroupMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const leaveGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLeaveGroupMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const changeGroupNameHandlers = () => {
    return createMutationHookMutationCacheHandlers(useChangeGroupNameMutation, {
      onError: (error, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ groupId, name }) => {
        findGroupQueries({ id: groupId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const group = draft?.getGroup

              if (!group) {
                return
              }

              Object.assign(group, { name })
            }),
          )
        })
      },
    })
  }

  const changeGroupDescriptionHandlers = () => {
    return createMutationHookMutationCacheHandlers(useChangeGroupDescriptionMutation, {
      onError: (error, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ groupId, description }) => {
        findGroupQueries({ id: groupId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const group = draft?.getGroup

              if (!group) {
                return
              }

              Object.assign(group, { description })
            }),
          )
        })
      },
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const removeGroupMembersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRemoveGroupMembersMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const addGroupMembersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useAddGroupMembersMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteGroupMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    joinGroupHandlers(),
    leaveGroupHandlers(),
    changeGroupNameHandlers(),
    changeGroupDescriptionHandlers(),
    removeGroupMembersHandlers(),
    addGroupMembersHandlers(),
    deleteGroupHandlers(),
  ]
}
