import { Box } from '@mui/material'
import { memo } from 'react'
import type { Path, PathValue, UseControllerProps, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import { Field, FieldErrorText } from '@resnet/client-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-web/shared/gdl/components/field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { UserDropdownMultiple } from '@resnet/client-web/shared/users/components/user-dropdown-muliple'

type UserSelectFieldPropsT<FieldValuesT extends Record<string, AnyTypeT>, FieldNameT extends Path<FieldValuesT>> = {
  defaultValue?: PathValue<FieldValuesT, FieldNameT>
  form: UseFormReturn<FieldValuesT>
  headerRight?: React.ReactNode
  name: FieldNameT
  label: string
  rules?: UseControllerProps<FieldValuesT, FieldNameT>['rules']
  nullOptionLabel?: React.ReactNode
  nullOptionEnabled?: boolean
}

export const UserSelectMultipleField = memo(
  <FieldValuesT extends Record<string, AnyTypeT>, FieldNameT extends Path<FieldValuesT>>({
    form,
    name,
    label,
    rules,
    defaultValue,
    headerRight,
    nullOptionEnabled,
    nullOptionLabel,
  }: UserSelectFieldPropsT<FieldValuesT, FieldNameT>) => {
    const {
      field,
      fieldState: { error, invalid },
    } = useController({ control: form.control, defaultValue, name, rules })

    const renderHeader = () => {
      return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
          <FieldLabel>{label}</FieldLabel>
          {headerRight}
        </Box>
      )
    }

    const renderInput = () => {
      return (
        <UserDropdownMultiple
          hasError={invalid}
          {...field}
          nullOptionEnabled={nullOptionEnabled}
          nullOptionLabel={nullOptionLabel}
          onChange={(value: PathValue<FieldValuesT, FieldNameT>) => {
            field.onChange(value)
          }}
        />
      )
    }

    const renderFooter = () => {
      if (!error) {
        return null
      }

      return <FieldErrorText>{error.message as string}</FieldErrorText>
    }

    return (
      <Field sx={{ flexGrow: 1, width: 0 }}>
        {renderHeader()}
        {renderInput()}
        {renderFooter()}
      </Field>
    )
  },
)
