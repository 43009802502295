import { generatePath } from 'react-router-dom'

import type { ResourceObjectFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type ResourceObjectWithPathnameT = Pick<ResourceObjectFragmentT, '__typename' | 'readableId'>

export const mapResourceObjectToPathname = <ValueT extends ResourceObjectWithPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.resourceObjectsDetails, { resourceObjectId: value.readableId })
}
