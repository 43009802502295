import type { CustomFieldT } from '../../types/custom-field'

import { AttachmentCustomFieldPayload } from './components/attachment-custom-field-payload'

export const renderPayload: CustomFieldT['renderPayload'] = ({ form, name, isDraft }) => {
  return (
    <AttachmentCustomFieldPayload
      form={form}
      isDraft={isDraft}
      name={name}
    />
  )
}
