import { generatePath } from 'react-router-dom'

import type { RouteFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type RouteWithPathnameT = Pick<RouteFragmentT, '__typename' | 'readableId'>

export const mapRouteToPathname = <ValueT extends RouteWithPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.routesDetails, { routeId: value.readableId })
}
