import type { MinimalEntityFragmentT } from '@resnet/client-api/api'

import { EntityOptionContainer } from '@resnet/client-shared/shared/entities/hooks/use-entity-option'
import {
  mapEntityToEntityTypeId,
  mapEntityToEntityTypeIdTitle,
} from '@resnet/client-shared/shared/entities/utils/map-entity-to-entity-type-id'
import { mapEntityToMedia } from '@resnet/client-shared/shared/entities/utils/map-entity-to-media'
import { mapEntityToTitle } from '@resnet/client-shared/shared/entities/utils/map-entity-to-title'

import { createDropdownComponent } from '@resnet/client-web/shared/common/factories/create-dropdown-component'

import { EntitiesSelectDropdownContainer } from '../../hooks/use-entities-select-dropdown-azeroth'

export const getOptionDescription = <ValueT extends MinimalEntityFragmentT>(value: ValueT) =>
  mapEntityToEntityTypeIdTitle(mapEntityToEntityTypeId(value))

export const EntityDropdown = createDropdownComponent({
  OptionContainer: EntityOptionContainer,
  SelectDropdownContainer: EntitiesSelectDropdownContainer,
  getOptionDescription,
  getOptionLabel: mapEntityToTitle,
  getOptionMedia: mapEntityToMedia,
  optionHeight: 60,
  skeletonProps: { media: { type: 'avatar' as const } },
})
