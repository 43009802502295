import { feedRoutes } from '@resnet/client-shared/shared/origins/routes/feed-routes'

export const wellDetailsScreenRoutes = {
  attachments: '/attachments',
  feed: feedRoutes,
  issues: '/issues',
  productionData: '/production-data',
  relations: '/relations',
  scheduling: '/scheduling',
}
