import type { OptionGroupCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/option-group/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import { OptionGroupFormUserFormPreview } from './components/option-group-custom-field-user-form-preview'

export const renderUserFormPreview: CustomFieldT['renderUserFormPreview'] = ({ value, field }) => {
  return (
    <OptionGroupFormUserFormPreview
      field={field}
      value={value as undefined | OptionGroupCustomFieldValueT}
    />
  )
}
