import React, { useEffect, useMemo, useState } from "react";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import { GlobalStyles as MUIGlobalStyles } from "@mui/material";
import { ThemeContext, useTheme } from "@resnet/client-shared/shared/gdl/contexts/theme-context";
import { themes } from "@resnet/client-shared/shared/gdl/themes";
import {
  mapThemePropsToThemeColorsCustomProperties,
  themeColors,
} from "@resnet/client-web/shared/gdl/constants/theme-colors";

import { useLocalStorageState } from "@resnet/client-common/react/hooks/use-local-storage-state";
import { MemoProvider } from "@resnet/client-common/react/components/memo-provider";
import { SnackbarProvider } from "notistack";
import { toPx } from "@resnet/client-web/shared/gdl/utils/to-px";
import { useColorSchemeModel } from "@resnet/client-web/shared/color-scheme/hooks/use-color-scheme-model";
import { useSystemColorScheme } from "@resnet/client-web/shared/color-scheme/hooks/use-system-color-scheme";
import { layoutHeaderHeight } from "@resnet/client-web/shared/layout/constants/common";
import { queryClient } from "@resnet/client-api/services/query-client";
import { DrawersRegistryProvider } from "@resnet/client-web/shared/registry/components/drawers-registry-provider";
import { LayerRegistryProvider } from "@resnet/client-web/shared/registry/components/layer-registry-provider";

import { useEventCallback } from "@resnet/client-common/react/hooks/use-event-callback";
import { FocusTrapRegistryProvider } from "@resnet/client-web/shared/registry/components/focus-trap-registry-provider";
import { setApiHttpClient } from "@resnet/client-api/services/api-http-client";
import { setAuthApiHttpClient } from "@resnet/client-api/services/auth-api-http-client";
import { setGraphQlClient } from "@resnet/client-api/services/graphql-client";
import { setGraphQlWsClient } from "@resnet/client-api/services/graphql-ws-client";
import { AuthContext } from "@resnet/client-api/auth-api/auth-context";
import Root from "./Root";
import { BrowserRouter } from "react-router-dom";
import { AuthTokensT } from "@resnet/client-api/auth-api/types";
import { QueryClientProvider } from "@resnet/client-api/vendors/react-query";
import { TeamsProvider } from "../context/teams-context";

const GDLProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [colorScheme] = useColorSchemeModel();

  const systemColorScheme = useSystemColorScheme();

  const theme = (() => {
    if (colorScheme === "system") {
      return systemColorScheme;
    }

    return colorScheme;
  })();

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};

const GlobalStyles = (): React.ReactElement => {
  const theme = useTheme();

  const themeColorsCustomProperties = useMemo(() => mapThemePropsToThemeColorsCustomProperties(themes[theme]), [theme]);

  return (
    <MUIGlobalStyles
      styles={{
        body: {
          backgroundColor: themeColors.background,
          color: themeColors.overBackgroundDefault,
        },
        html: {
          ...themeColorsCustomProperties,
          "--header-height": toPx(layoutHeaderHeight),
          "--layers-footer": 100,
          "--layers-header": 300,
          "--layers-panels": 200,
          colorScheme: theme,
          fontFamily: '"IBM Plex Sans", sans-serif',
        },
      }}
    />
  );
};

const requestHeaders = {
  "wt-system-name": "outlook-addin",
};

const App = (): React.ReactElement => {
  const theme = useTheme();
  const [tenant, setTenant] = useLocalStorageState<string>("tenant");
  const [tokens, setTokens] = useLocalStorageState<AuthTokensT>("authTokens");

  setAuthApiHttpClient(tenant);
  setApiHttpClient(tenant);
  setGraphQlClient(tenant);
  setGraphQlWsClient(tenant);

  useEffect(() => {
    setAuthApiHttpClient(tenant);
    setApiHttpClient(tenant);
    setGraphQlClient(tenant);
    setGraphQlWsClient(tenant);
  }, [tenant]);

  const getLatestTokens = useEventCallback(() => tokens);

  const muiTheme = useMemo(() => {
    return createTheme({
      palette: {
        mode: theme,
        primary: {
          main: themes[theme].colors.basePrimary,
        },
      },
      typography: {
        fontFamily: ["IBM Plex Sans", "sans-serif"].join(","),
      },
    });
  }, [theme]);

  return (
    <GDLProvider>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <StylesProvider injectFirst>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
              <MemoProvider
                Context={AuthContext}
                value={{ getLatestTokens, requestHeaders, setTenant, setTokens, tenant, tokens }}
              >
                <BrowserRouter>
                  <SnackbarProvider maxSnack={3}>
                    {/* <LoadingBoundary> */}
                    <LayerRegistryProvider>
                      <DrawersRegistryProvider>
                        <FocusTrapRegistryProvider>
                          <TeamsProvider>
                            <Root />
                          </TeamsProvider>
                        </FocusTrapRegistryProvider>
                      </DrawersRegistryProvider>
                    </LayerRegistryProvider>
                    {/* </LoadingBoundary> */}
                  </SnackbarProvider>
                </BrowserRouter>
              </MemoProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
      </QueryClientProvider>
    </GDLProvider>
  );
};

export default App;
