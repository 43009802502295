import type { AbstractOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'

import { DropdownInputPlaceholder, DropdownInputText } from '../dropdown-input'

const defaultPlaceholder = 'Not Selected'

export type StaticOptionsDropdownTextPropsT<OptionT extends AbstractOptionT> = {
  value: undefined | null | OptionT['id']
  options: OptionT[]
  getOptionLabel: (option: OptionT) => string
  placeholder?: string
}

export const StaticOptionsDropdownText = <OptionT extends AbstractOptionT>({
  value,
  options,
  getOptionLabel,
  placeholder = defaultPlaceholder,
}: StaticOptionsDropdownTextPropsT<OptionT>): React.ReactElement => {
  if (!value) {
    return <DropdownInputPlaceholder>{placeholder}</DropdownInputPlaceholder>
  }

  const selectedOption = options.find((option) => option.id === value)

  if (!selectedOption) {
    return <DropdownInputPlaceholder>Option not found</DropdownInputPlaceholder>
  }

  return <DropdownInputText>{getOptionLabel(selectedOption)}</DropdownInputText>
}
