import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useDerivedState } from '@resnet/client-common/react/hooks/use-derived-state'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

import { focusOutlineSx } from '../../sx-presets/focus-outline'
import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'

export const mapNumberToString = ({ number }: { number: null | number }) => {
  if (!checkNonNullable(number)) {
    return ''
  }

  return number.toString()
}

export const mapStringToNumber = ({ string }: { string: string }) => {
  return pipeline(
    string,
    (string) => (string === '' ? null : Number(string)),
    (number) => (Number.isNaN(number) ? null : number),
  )
}

export type NumberInputPropsT = MergeAllT<
  [
    BoxProps<'input'>,
    {
      disabled?: boolean
      hasError?: boolean
      onChange?: (value: null | number) => void
      value: null | number
    },
  ]
>

export type NumberInputRefT = React.ForwardedRef<HTMLInputElement>

export const NumberInput = forwardFunctionalComponentRef(
  (
    {
      disabled = false,
      hasError,
      onBlur: onBlurActual,
      onChange: onChangeActual,
      sx = null,
      value: valueActual,
      ...props
    }: NumberInputPropsT,
    ref: NumberInputRefT,
  ) => {
    const [value, setValue] = useDerivedState(() => mapNumberToString({ number: valueActual }), [valueActual])

    const onChange = useEventCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value)
    })

    const onBlur = useEventCallback((event: React.FocusEvent<HTMLInputElement>) => {
      const nextValueActual = mapStringToNumber({ string: value })

      const nextValue = mapNumberToString({ number: nextValueActual })

      setValue(nextValue)

      event.target.value = nextValue

      onChangeActual?.(nextValueActual)

      onBlurActual?.(event)
    })

    const borderRadius = 8

    const borderWidth = 1

    return (
      <Box
        {...props}
        component="input"
        disabled={disabled}
        ref={ref}
        sx={[
          { all: 'unset' },
          mapTypographyPresetToSx(typographyPresets.bodyMedium),
          {
            '&::placeholder': {
              color: themeColors.overBackgroundFaded,
            },
            backgroundColor: themeColors.surfaceNeutralDefault,
            borderColor: themeColors.borderFaded,
            borderRadius: toPx(borderRadius),
            borderStyle: 'solid',
            borderWidth: toPx(borderWidth),
            color: themeColors.overBackgroundDefault,
            position: 'relative',
            px: toPx(16 - borderWidth),
            py: toPx(8 - borderWidth),
          },
          {
            '&:focus': focusOutlineSx,
          },
          !hasError ? {} : { borderColor: themeColors.feedbackCritical },
          !disabled ? {} : { cursor: 'not-allowed', opacity: 0.64 },
          sx,
        ].flat()}
        type="number"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    )
  },
)
