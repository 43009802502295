import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { NumberCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/number/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { NumberFormCustomFieldValueT } from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  NumberCustomFieldValueT,
  NumberFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const numberPayload = payload[CustomFieldTypeT.NumberT]

  assert(numberPayload, checkNonNullable)

  const { defaultValue = null } = numberPayload

  return defaultValue
}
