import produce from 'immer'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { omit } from '@resnet/client-common/common/utils/object/omit'

import {
  useCreateSiteMutation,
  useFollowMutation,
  useInfiniteSitesQuery,
  useGetSiteQuery,
  useSitesQuery,
  useUnfollowMutation,
  useUpdateFollowersMutation,
  useUpdateSiteMutation,
  useUploadResourceMutation,
  useDeleteSiteMutation,
  useUpdateRelationsMutation,
  useArchiveSiteMutation,
  useUnarchiveSiteMutation,
  useUpdateEntityTypeMutation,
  EntityTypeIdsT,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const getSiteQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findSiteQueries = () => {
    return findQueries(queryClient, useGetSiteQuery)
  }

  const findSiteQueriesById = ({ id }: { id: string }) => {
    return findGetQueriesById(queryClient, useGetSiteQuery, (data) => data.getSite, { id })
  }

  const updateSiteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateSiteMutation, {
      onError: (error, { id }) => {
        findSiteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findSiteQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const site = draft?.getSite

              if (!site) {
                return
              }

              Object.assign(site, omit(['relations'], data))
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findSiteQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(() => ({
            getSite: data.updateSite,
          }))
        })
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findSiteQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findSiteQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFollowersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFollowersMutation, {
      onSuccess: (data, { entityId }) => {
        findSiteQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const uploadResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUploadResourceMutation, {
      onSuccess: (data, { originId }) => {
        findSiteQueriesById({ id: originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteSiteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteSiteMutation, {
      onSuccess: (data, { id }) => {
        findSiteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveSiteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveSiteMutation, {
      onSuccess: (data, { id }) => {
        findSiteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveSiteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveSiteMutation, {
      onSuccess: (data, { id }) => {
        findSiteQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findSiteQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.SiteT) {
          return
        }

        findSiteQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    updateSiteHandlers(),
    followHandlers(),
    unfollowHandlers(),
    updateFollowersHandlers(),
    uploadResourceHandlers(),
    deleteSiteHandlers(),
    updateRelationsHandlers(),
    archiveSiteHandlers(),
    unarchiveSiteHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const sitesQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findSitesQueries = () => findQueries(queryClient, useSitesQuery)

  const findInfiniteSitesQueries = () => findInfiniteQueries(queryClient, useInfiniteSitesQuery)

  const findInfiniteSitesQueriesById = ({ id }: { id: string }) =>
    findInfiniteQueries(queryClient, useInfiniteSitesQuery, (query) =>
      (query.state.data?.pages ?? []).some((page) => (page.listSites?.items ?? []).some((site) => site.id === id)),
    )

  const createSiteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateSiteMutation, {
      onSuccess: () => {
        findInfiniteSitesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateSiteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateSiteMutation, {
      onError: (error, { id }) => {
        findInfiniteSitesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findInfiniteSitesQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.pages.flatMap((page) => assertedNonNullable(page.listSites?.items))

              if (!items) {
                return
              }

              const site = items.find((item) => item.id === id)

              if (!site) {
                return
              }

              Object.assign(site, omit(['relations'], data))
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInfiniteSitesQueriesById({ id }).forEach(invalidateQuery(queryClient))

        findSitesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInfiniteSitesQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInfiniteSitesQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteSiteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteSiteMutation, {
      onError: (error, { id }) => {
        findInfiniteSitesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInfiniteSitesQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              draft?.pages?.forEach((page) => {
                const items = page.listSites?.items

                if (!items) {
                  return
                }

                const siteIndex = items.findIndex((item) => item.id === id)

                if (siteIndex < 0) {
                  return
                }

                items.splice(siteIndex, 1)
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInfiniteSitesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findInfiniteSitesQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveSiteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveSiteMutation, {
      onSuccess: (data, { id }) => {
        findInfiniteSitesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveSiteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveSiteMutation, {
      onSuccess: (data, { id }) => {
        findInfiniteSitesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.SiteT) {
          return
        }

        findInfiniteSitesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createSiteHandlers(),
    updateSiteHandlers(),
    followHandlers(),
    unfollowHandlers(),
    deleteSiteHandlers(),
    updateRelationsHandlers(),
    archiveSiteHandlers(),
    unarchiveSiteHandlers(),
    updateEntityTypeHandlers(),
  ]
}
