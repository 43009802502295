import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useIntersectionObserver } from '@resnet/client-common/react/hooks/use-intersection-observer'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

export type EndBoundaryPropsT = MergeT<
  BoxProps,
  {
    onObserve?: () => void
    offset?: number
    layout?: 'horizontal' | 'vertical'
  }
>

export const EndBoundary = ({ onObserve, offset = 0, sx = null, layout = 'vertical', ...props }: EndBoundaryPropsT) => {
  const ref = useIntersectionObserver(
    useEventCallback((entry) => {
      if (!entry.isIntersecting) {
        return
      }

      onObserve?.()
    }),
  )

  return (
    <Box
      {...props}
      ref={ref}
      sx={[
        {
          transform: layout === 'horizontal' ? `translateX(${offset}px)` : `translateY(${offset}px)`,
        },
        sx,
      ].flat()}
    />
  )
}
