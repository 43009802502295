import type { QueryClient } from '@tanstack/react-query'
import produce from 'immer'

import { useGetEntityTypeQuery, useUpdateEntityTypeMutation } from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const getEntityTypeQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findGetEntityTypeQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetEntityTypeQuery, (data) => data.getEntityType, { id })

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onError: (error, { id }) => {
        findGetEntityTypeQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findGetEntityTypeQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => {
              if (!data) {
                return
              }

              const entityType = data.getEntityType

              if (!entityType) {
                return
              }

              Object.assign(entityType, diff)
            }),
          ),
        )
      },
      onSuccess: (data, { id }) => {
        findGetEntityTypeQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateEntityTypeHandlers()]
}
