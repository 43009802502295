import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

export const fitNumber = ({
  number,
  isInteger,
  max,
  min,
}: {
  number: null | number
  isInteger?: boolean
  max?: undefined | null | number
  min?: undefined | null | number
}) => {
  return pipeline(
    number,
    (number) => (!checkNonNullable(number) ? number : Math.max(min ?? -Infinity, number)),
    (number) => (!checkNonNullable(number) ? number : Math.min(max ?? Infinity, number)),
    (number) => (!checkNonNullable(number) || !isInteger ? number : Math.trunc(number)),
  )
}
