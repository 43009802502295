import { Box } from '@mui/material'

import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { TextSkeleton } from '@resnet/client-web/shared/async/components/text-skeleton'
import { AvatarSkeleton } from '@resnet/client-web/shared/gdl/components/avatar'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

import { MessageCardHeaderLayout } from '../message-card-header-layout'
import type { MessageCardLayoutPropsT } from '../message-card-layout'
import { MessageCardLayout } from '../message-card-layout'

export const MessageCardSkeleton = forwardFunctionalComponentRef((props: MessageCardLayoutPropsT, ref) => {
  const renderHeaderAvatar = () => {
    return <AvatarSkeleton size="md" />
  }

  const renderHeaderName = () => {
    return (
      <TextSkeleton
        sx={{ width: '50%' }}
        typographyPreset={typographyPresets.labelMedium}
      />
    )
  }

  const renderHeaderMeta = () => {
    return (
      <TextSkeleton
        contentSx={{ width: '70%' }}
        typographyPreset={typographyPresets.captionRegular}
      />
    )
  }

  const renderHeader = () => {
    return (
      <MessageCardHeaderLayout>
        {{
          avatar: renderHeaderAvatar(),
          meta: renderHeaderMeta(),
          name: renderHeaderName(),
        }}
      </MessageCardHeaderLayout>
    )
  }

  const renderMessage = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <TextSkeleton typographyPreset={typographyPresets.bodySmall} />
        <TextSkeleton typographyPreset={typographyPresets.bodySmall} />
        <TextSkeleton
          contentSx={{ width: '70%' }}
          typographyPreset={typographyPresets.bodySmall}
        />
      </Box>
    )
  }

  const renderControls = () => {
    return <Box sx={{ flexShrink: 0, height: toPx(32) }} />
  }

  return (
    <MessageCardLayout
      {...props}
      ref={ref}
    >
      {renderHeader()}
      {renderMessage()}
      <Box sx={{ flexShrink: 0, height: toPx(1) }} />
      {renderControls()}
    </MessageCardLayout>
  )
})
