import { useMemo, useRef } from 'react'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

export const setRef = <C>(
  ref: undefined | null | React.MutableRefObject<C | null> | ((instance: C | null) => void),
  node: C | null,
): void => {
  if (typeof ref === 'function') {
    ref(node)
  } else if (typeof ref === 'object' && ref !== null) {
    ref.current = node
  }
}

export const useCombineRefs = <C>(
  ...refs: (undefined | null | React.Ref<C | null>)[]
): React.MutableRefObject<C | null> => {
  const nodeRef = useRef<C | null>(null)

  const proxyRef = useMemo(() => {
    return {
      get current() {
        return nodeRef.current
      },
      set current(node) {
        nodeRef.current = node

        for (const ref of refs) {
          setRef(ref, node)
        }
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs)

  return proxyRef
}

export const CombineRefsContainer = createHookContainer(<C>({ refs }: { refs: React.Ref<C | null>[] }) =>
  useCombineRefs(...refs),
)
