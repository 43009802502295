import produce from 'immer'

import type { InputUpdateFormT, GetFormQueryT, ListFormsQueryT } from '@resnet/client-api/api'
import {
  useArchiveFormMutation,
  useCreateFormMutation,
  useDeleteFormMutation,
  useInfiniteListFormsQuery,
  useGetFormQuery,
  useListFormsQuery,
  useUnarchiveFormMutation,
  useUpdateFormMutation,
  useCreateUserFormMutation,
  useUploadResourceMutation,
  useRequestToFillUserFormMutation,
  useUpdateRelationsMutation,
  EntityTypeIdsT,
  useUpdateEntityTypeMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const formObjectQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findFormObjectQueries = () => {
    return findQueries(queryClient, useGetFormQuery)
  }

  const findFormObjectQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetFormQuery, (data) => data.getForm, { id })

  const updateFormObject = (data: undefined | GetFormQueryT, { diff }: { diff: InputUpdateFormT }) => {
    if (!data) {
      return
    }

    const item = data.getForm

    Object.assign(item, diff)
  }

  const deleteFormObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteFormMutation, {
      onSuccess: (data, { id }) => {
        findFormObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFormObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFormMutation, {
      onError: (error, { id }) => {
        findFormObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findFormObjectQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateFormObject(data, { diff }))),
        )
      },
      onSuccess: (data, { id }) => {
        findFormObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveFormObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveFormMutation, {
      onSuccess: (data, { id }) => {
        findFormObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveFormObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveFormMutation, {
      onSuccess: (data, { id }) => {
        findFormObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateUserFormMutation, {
      onSuccess: (data, { formId }) => {
        findFormObjectQueriesById({ id: formId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const uploadResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUploadResourceMutation, {
      onSuccess: (data, { originId }) => {
        findFormObjectQueriesById({ id: originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const requestToFillUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRequestToFillUserFormMutation, {
      onSuccess: (data, { formId }) => {
        findFormObjectQueriesById({ id: formId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findFormObjectQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.FormObjectT) {
          return
        }

        findFormObjectQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    deleteFormObjectHandlers(),
    updateFormObjectHandlers(),
    archiveFormObjectHandlers(),
    unarchiveFormObjectHandlers(),
    createUserFormHandlers(),
    uploadResourceHandlers(),
    requestToFillUserFormHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const formObjectsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findFormObjectsQueries = () => findQueries(queryClient, useListFormsQuery)

  const findInfiniteFormObjectsQueries = () => findInfiniteQueries(queryClient, useInfiniteListFormsQuery)

  const updateFormObject = (
    data: undefined | ListFormsQueryT,
    { id, diff }: { id: string; diff: InputUpdateFormT },
  ) => {
    if (!data) {
      return
    }

    const items = data.listForms.items

    const item = items.find((item) => item.id === id)

    if (!item) {
      return
    }

    Object.assign(item, diff)
  }

  const deleteFormObject = (data: undefined | ListFormsQueryT, { id }: { id: string }) => {
    if (!data) {
      return
    }

    const items = data.listForms.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createFormObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateFormMutation, {
      onSuccess: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteFormObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteFormMutation, {
      onError: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findFormObjectsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => deleteFormObject(data, { id }))),
        )
        findInfiniteFormObjectsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => deleteFormObject(page, { id }))),
          ),
        )
      },
      onSuccess: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFormObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFormMutation, {
      onError: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findFormObjectsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateFormObject(data, { diff, id }))),
        )
        findInfiniteFormObjectsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => updateFormObject(page, { diff, id }))),
          ),
        )
      },
      onSuccess: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveFormObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveFormMutation, {
      onSuccess: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveFormObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveFormMutation, {
      onSuccess: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateUserFormMutation, {
      onSuccess: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const requestToFillUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRequestToFillUserFormMutation, {
      onSuccess: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: () => {
        findFormObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.FormObjectT) {
          return
        }

        findInfiniteFormObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createFormObjectHandlers(),
    deleteFormObjectHandlers(),
    updateFormObjectHandlers(),
    archiveFormObjectHandlers(),
    unarchiveFormObjectHandlers(),
    createUserFormHandlers(),
    requestToFillUserFormHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}
