import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import { formatDateToDefaultFormat } from '@resnet/client-shared/shared/common/utils/format-date'

import type { MessageT } from '../../types/message'

export type MessageCardCreatedAtPropsT = { message: MessageT }

export const MessageCardCreatedAt = ({ message }: MessageCardCreatedAtPropsT) => {
  const renderCreateAt = () => {
    const { createdAt } = message

    return formatDateToDefaultFormat(createdAt)
  }

  const renderEdited = () => {
    if (!('editedAt' in message)) {
      return null
    }

    const { editedAt } = message

    if (!editedAt) {
      return null
    }

    return '(edited)'
  }

  return (
    <ChildrenDivider dividerNode=" ">
      {renderCreateAt()}
      {renderEdited()}
    </ChildrenDivider>
  )
}
