import type { UpdateRelationsMutationVariablesT } from '@resnet/client-api/api'

export const withUpdateRelationsOperationsCountCheck = (
  updateRelationsActual: (variables: UpdateRelationsMutationVariablesT) => unknown,
) => {
  const updateRelations = async (updateRelationsMutationVariables: UpdateRelationsMutationVariablesT) => {
    const { operations } = updateRelationsMutationVariables

    if (Array.isArray(operations) && operations.length === 0) {
      return
    }

    await updateRelationsActual(updateRelationsMutationVariables)
  }

  return updateRelations
}
