import { useContext } from 'react'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import { AuthContext } from '../auth-api/auth-context'
import { getAuthApiHttpClient } from '../services/auth-api-http-client'

import { useUnauthorizedHTTPRequest } from './use-unauthorized-http-request'

export const useUnauthorizedAuthHTTPJSONPost = <TData, TVariables>(
  mapVariablesToUrl: (variables: TVariables) => string,
  mapVariablesToBody: (variables: TVariables) => unknown,
) => {
  const { requestHeaders } = assertedNonNullable(useContext(AuthContext))

  return useUnauthorizedHTTPRequest<TData, TVariables>(
    async (variables: TVariables, signal?: AbortSignal): Promise<TData> => {
      const response: TData = await getAuthApiHttpClient().post(
        mapVariablesToUrl(variables),
        JSON.stringify(mapVariablesToBody(variables)),
        {
          headers: {
            'Content-Type': 'application/json',
            ...requestHeaders,
          },
          signal,
        },
      )

      return response
    },
  )
}
