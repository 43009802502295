import { isObject } from './is-object'

export const equalByValues = (
  comparator: (leftProp: unknown, rightProp: unknown) => boolean,
  left: unknown,
  right: unknown,
): boolean => {
  if (Object.is(left, right)) {
    return true
  }

  if (!isObject(left) || !isObject(right)) {
    return false
  }

  const leftKeys = Object.keys(left)
  const rightKeys = Object.keys(right)

  if (leftKeys.length !== rightKeys.length) {
    return false
  }

  return leftKeys.every((key) => comparator(left[key], right[key]))
}

export const equalByValuesStrict = (left: unknown, right: unknown): boolean => equalByValues(Object.is, left, right)
