import { Box, useEventCallback } from '@mui/material'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { UseControllerProps, UseControllerReturn } from 'react-hook-form'
import { useController, type FieldPathByValue, type FieldValues, type UseFormReturn } from 'react-hook-form'
import uuid4 from 'uuid4'

import { move } from '@resnet/client-common/common/utils/array/move'
import { pullById } from '@resnet/client-common/common/utils/array/pull-by-id'
import { push } from '@resnet/client-common/common/utils/array/push'
import { updateById } from '@resnet/client-common/common/utils/array/update-by-id'
import { checkNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { update } from '@resnet/client-common/common/utils/object/update'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { CustomFieldPayloadOptionGroupOptionT, CustomFieldPayloadOptionGroupT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import DragDotsSolidIcon from '@resnet/client-shared/assets/icons/drag-dots-solid.svg'
import MinusSolidIcon from '@resnet/client-shared/assets/icons/minus-solid.svg'
import PlusSolidIcon from '@resnet/client-shared/assets/icons/plus-solid.svg'
import { composeValidators } from '@resnet/client-shared/shared/forms/validators/compose-validators'
import { validateListRequired } from '@resnet/client-shared/shared/forms/validators/list-required'
import { createValidateMaxLength } from '@resnet/client-shared/shared/forms/validators/text-length'
import { validateTextRequired } from '@resnet/client-shared/shared/forms/validators/text-required'

import { EmptyPanel } from '@resnet/client-web/shared/common/components/empty-panel'
import type { DraftableT } from '@resnet/client-web/shared/draftable/types/draftable'
import { SwitchField } from '@resnet/client-web/shared/form-dalaran/components/common/switch-field'
import { TextField } from '@resnet/client-web/shared/form-dalaran/components/common/text-field'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { Divider } from '@resnet/client-web/shared/gdl/components/divider'
import { FieldErrorText, FieldHelperText } from '@resnet/client-web/shared/gdl/components/field'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export const OptionGroupOptionItem = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, NonNullable<CustomFieldPayloadOptionGroupOptionT>>,
>({
  id,
  form,
  name,
  index,
  onDeleteField,
}: {
  id: string
  form: UseFormReturn<TFieldValues>
  name: TPath
  index: number
  onDeleteField?: ({ id }: { id: string }) => void
}) => {
  const onDelete = useEventCallback(() => {
    onDeleteField?.({ id })
  })

  return (
    <Draggable
      draggableId={id}
      index={index}
    >
      {(draggable) => {
        const renderDragHandle = () => {
          return (
            <Box sx={{ alignSelf: 'center' }}>
              <Box
                {...draggable.dragHandleProps}
                sx={{ display: 'flex' }}
              >
                <DragDotsSolidIcon
                  fill={themeColors.overBackgroundBold}
                  height={16}
                  width={16}
                />
              </Box>
            </Box>
          )
        }

        const renderKey = () => {
          return (
            <TextField
              form={form}
              name={
                `${name}.key` as FieldPathByValue<
                  TFieldValues,
                  NonNullable<CustomFieldPayloadOptionGroupOptionT>['key']
                >
              }
              rules={{
                validate: composeValidators(validateTextRequired, createValidateMaxLength(60)),
              }}
            />
          )
        }

        const renderName = () => {
          return (
            <TextField
              form={form}
              name={
                `${name}.name` as FieldPathByValue<
                  TFieldValues,
                  NonNullable<CustomFieldPayloadOptionGroupOptionT>['name']
                >
              }
              rules={{
                validate: composeValidators(validateTextRequired, createValidateMaxLength(60)),
              }}
            />
          )
        }

        const renderKeyAndName = () => {
          return (
            <Box sx={{ display: 'flex', flexGrow: 1, gap: toPx(16), width: 0 }}>
              {renderKey()}
              {renderName()}
            </Box>
          )
        }

        const renderDeleteButton = () => {
          return (
            <Button
              color="default"
              icon={MinusSolidIcon}
              size="sm"
              sx={{ alignSelf: 'center' }}
              variant="contained"
              onClick={onDelete}
            />
          )
        }

        return (
          <Box
            {...draggable.draggableProps}
            ref={draggable.innerRef}
            sx={{ alignItems: 'flex-start', display: 'flex', gap: toPx(8), marginTop: '8px' }}
          >
            {renderDragHandle()}
            {renderKeyAndName()}
            {renderDeleteButton()}
          </Box>
        )
      }}
    </Draggable>
  )
}

const OptionGroupOptionsField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadOptionGroupT['options']>,
>({
  form,
  name,
  rules,
}: {
  form: UseFormReturn<TFieldValues>
  name: TPath
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
}): React.ReactNode => {
  const {
    field: { value: options, onChange },
    fieldState: { error },
  } = useController({
    control: form.control,
    name,
    rules,
  }) as unknown as UseControllerReturn<Record<string, DraftableT<CustomFieldPayloadOptionGroupOptionT>[]>>

  const onAddOption = useEventCallback(() => {
    const newOption: DraftableT<CustomFieldPayloadOptionGroupOptionT> = {
      id: uuid4(),
      isDraft: true,
      key: '',
      name: '',
    }

    const nextOptions = push(options, newOption)

    onChange(nextOptions)
  })

  const onDeleteOption = useEventCallback(({ id }: { id: string }) => {
    const option = options.find((item) => item.id === id)

    assert(option, checkNonUndefined)

    const nextOptions = option.isDraft
      ? pullById(options, id)
      : updateById(options, id, (option) => update(option, 'isMarkedAsDeleted', () => true))

    onChange(nextOptions)
  })

  const onDragEnd = useEventCallback(({ destination, source }: DropResult) => {
    if (!destination) {
      return
    }

    onChange(move(options, source.index, destination.index))
  })

  const renderAddNewOptionButton = () => {
    return (
      <Button
        color="primary"
        icon={PlusSolidIcon}
        size="md"
        variant="text"
        onClick={onAddOption}
      >
        Add Option
      </Button>
    )
  }

  const renderContent = () => {
    if (options.filter((option) => !option.isMarkedAsDeleted).length === 0) {
      return (
        <EmptyPanel>
          {{
            title: 'No options',
          }}
        </EmptyPanel>
      )
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="any">
            {(droppable) => (
              <Box ref={droppable.innerRef}>
                {options.map(({ id: optionId, isMarkedAsDeleted }, optionIndex) => {
                  if (isMarkedAsDeleted) {
                    return null
                  }

                  const fieldName = `${name}.${optionIndex}` as FieldPathByValue<
                    TFieldValues,
                    NonNullable<CustomFieldPayloadOptionGroupOptionT>
                  >

                  return (
                    <OptionGroupOptionItem
                      form={form}
                      id={optionId}
                      index={optionIndex}
                      key={optionId}
                      name={fieldName}
                      onDeleteField={onDeleteOption}
                    />
                  )
                })}
                {droppable.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <Box sx={{ display: 'flex', gap: toPx(16), ml: '24px', mr: '40px', mt: '8px' }}>
          <FieldHelperText sx={{ flexGrow: 1, width: 0 }}>Key</FieldHelperText>
          <FieldHelperText sx={{ flexGrow: 1, width: 0 }}>Name</FieldHelperText>
        </Box>
      </Box>
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message}</FieldErrorText>
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {renderContent()}
      {renderAddNewOptionButton()}
      {renderFooter()}
    </Box>
  )
}

export type OptionGroupCustomFieldPayloadValueT = CustomFieldFragmentT

export type OptionGroupCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, OptionGroupCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
  isDraft: boolean
}

export const OptionGroupCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, OptionGroupCustomFieldPayloadValueT>,
>({
  form,
  name,
  isDraft,
}: OptionGroupCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type RequiredPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadOptionGroupT['required']>

  const requiredName = `${name}.payload.${CustomFieldTypeT.OptionGroupT}.required` as RequiredPathT

  type MultiplePathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadOptionGroupT['multiple']>

  const multipleName = `${name}.payload.${CustomFieldTypeT.OptionGroupT}.multiple` as MultiplePathT

  type QuestionPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadOptionGroupT['question']>

  const questionName = `${name}.payload.${CustomFieldTypeT.OptionGroupT}.question` as QuestionPathT

  type OptionsPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadOptionGroupT['options']>

  const optionsName = `${name}.payload.${CustomFieldTypeT.OptionGroupT}.options` as OptionsPathT

  const renderIsRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName}
      />
    )
  }

  const renderIsMultipleFields = () => {
    return (
      <SwitchField
        disabled={!isDraft}
        disabledTooltipTitle="Multiple cannot be changed after creation"
        form={form}
        label="Multiple"
        name={multipleName}
      />
    )
  }

  const renderQuestionField = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <TextField
          form={form}
          label="Question"
          name={questionName}
          placeholder="Enter Placeholder"
        />
      </Box>
    )
  }

  const renderOptionsField = () => {
    return (
      <OptionGroupOptionsField
        form={form}
        name={optionsName}
        rules={{ validate: validateListRequired }}
      />
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderIsRequiredField()}
        {renderIsMultipleFields()}
        {renderQuestionField()}
        <Divider />
        {renderOptionsField()}
      </Box>
    )
  }

  return <>{renderSwitchFields()}</>
}
