import { checkIsQuerySuccess } from '@resnet/client-common/react-query/utils/check-is-query-success'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { type RegularPostFragmentT } from '@resnet/client-api/api'

import { useUserOption } from '@resnet/client-shared/shared/users/hooks/use-user-option'

export const useRegularPostUser = ({ post }: { post: RegularPostFragmentT }) => {
  const { userId } = post

  const { optionQuery: userQuery } = useUserOption({
    id: userId,
    options: { suspense: true },
  })

  assert(userQuery, checkIsQuerySuccess, '"userQuery" should be loaded')

  const user = userQuery.data

  return { user }
}
