import type { CustomFieldT } from '../../types/custom-field'

import { FormulaCustomFieldPayload } from './components/formula-custom-field-payload'

export const renderPayload: CustomFieldT['renderPayload'] = ({ form, name }) => {
  return (
    <FormulaCustomFieldPayload
      form={form}
      name={name}
    />
  )
}
