import { type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { NumberCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/number/custom-field-value'

import { FieldPreview } from '@resnet/client-web/shared/gdl/components/field-preview'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type NumberFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | NumberCustomFieldValueT
}

export const NumberFormUserFormPreview = ({ field, value }: NumberFormUserFormPreviewPropsT) => {
  return (
    <ObjectDetailsRow>
      <FieldPreview
        label={field.name}
        value={value}
      />
    </ObjectDetailsRow>
  )
}
