import { Box, List, ListItem } from '@mui/material'
import type { DragDropContextProps } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { updateAt } from '@resnet/client-common/common/utils/array/update-at'
import { uniqId } from '@resnet/client-common/common/utils/object/uniq-id'
import { update } from '@resnet/client-common/common/utils/object/update'
import { MemoContainer } from '@resnet/client-common/react/components/memo-container'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { ApprovalLevelT, ApproverT, UserFragmentT } from '@resnet/client-api/api'
import { IssueStatusT } from '@resnet/client-api/api'
import { useUserQuery } from '@resnet/client-api/api'
import { ApproveStatusT } from '@resnet/client-api/api'
import { queryClient } from '@resnet/client-api/services/query-client'

import DeleteSolidIcon from '@resnet/client-shared/assets/icons/delete-solid.svg'
import DragDotsSolidIcon from '@resnet/client-shared/assets/icons/drag-dots-solid.svg'
import PlusSolidIcon from '@resnet/client-shared/assets/icons/plus-solid.svg'
import { mapApprovalStatusToLabel } from '@resnet/client-shared/shared/issues/utils/map-approval-status-to-label'

import { FieldTitle } from '@resnet/client-web/shared/form/components/field-title'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { Accordion } from '@resnet/client-web/shared/gdl/legacy-components/accordion-two'
import { usePopover } from '@resnet/client-web/shared/gdl/legacy-components/popover'
import { Select } from '@resnet/client-web/shared/gdl/legacy-components/select'
import { SelectDropdown } from '@resnet/client-web/shared/gdl/legacy-components/select-dropdown'
import { SimpleNonNullableSelectContainer } from '@resnet/client-web/shared/gdl/legacy-components/select-dropdown/hooks/use-simple-non-nullable-select'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { UsersSelectDropdownContainer } from '@resnet/client-web/shared/users/hooks/use-users-select-dropdown'

import { Approver } from '../issue-approval-approver'
import { IssueApprovalTag } from '../issue-approval-tag'

export type IssueApprovalLevelT = MergeAllT<
  [
    ApprovalLevelT,
    {
      isDraft?: boolean
      approvers: ApproverT[]
      id: string
    },
  ]
>

export type CreateIssueApprovalPropsT = {
  disabled?: boolean
  error?: boolean
  issueStatus?: IssueStatusT
  onChange?: (value: IssueApprovalLevelT[]) => void
  value: IssueApprovalLevelT[]
  isPreview?: boolean
}

type IssueApprovalLevelPropsT = { excludeIds?: string[]; onChange: (value: null | UserFragmentT) => void }

export const emptyApproval: IssueApprovalLevelT[] = [
  {
    approvers: [],
    id: uniqId(),
    isDraft: true,
    requiredApprovals: 1,
    status: ApproveStatusT.PendingT,
  },
]

const getUsersWhoApproved = (value?: null | ApproverT[]): ApproverT[] => {
  if (!value) {
    return []
  }

  return value.filter((approver) => approver.status === ApproveStatusT.ApprovedT)
}

const approversRequiredOptions = Array.from({ length: 5 }, (_, index) => ({
  id: String(index + 1),
  name: `Needs to be approved by ${index + 1} people`,
}))

const IssueApprovalLevelContainer = createHookContainer(({ excludeIds, onChange }: IssueApprovalLevelPropsT) => {
  const [renderSelectUserPopover, { setAnchorEl, onOpen, onClose }] = usePopover(() => (
    <UsersSelectDropdownContainer excludeIds={excludeIds}>
      {(props) => (
        <SelectDropdown
          value={null}
          onChange={(user) => {
            onChange(user)
            onClose()
          }}
          {...props}
        />
      )}
    </UsersSelectDropdownContainer>
  ))

  return {
    onClose,
    onOpen,
    renderSelectUserPopover,
    setAnchorEl,
  }
})

export const IssueApproval = ({
  value,
  error,
  onChange,
  issueStatus,
  isPreview = false,
}: CreateIssueApprovalPropsT): React.ReactElement => {
  const allSelectedUserIds: string[] = value.flatMap((item) => item.approvers.map((approver) => approver.id))

  const invalidLevelIndex = error
    ? value.findIndex((item) => item.approvers && item.approvers.length < (item.requiredApprovals ?? 0))
    : -1

  const onSelectApprover = (index: number, user: null | UserFragmentT) => {
    if (!user) {
      return
    }

    queryClient.setQueryData(useUserQuery.getKey({ id: user.id }), () => ({
      getUser: user,
    }))

    const nextValue = updateAt(value, index, (item) => {
      return update(item, 'approvers', (approvers) =>
        approvers.concat({ id: user.id, status: ApproveStatusT.PendingT, updatedAt: '' }),
      )
    })

    onChange?.(nextValue)
  }

  const onRemoveApprover = (index: number, userId: string) => {
    const nextValue = updateAt(value, index, (item) => {
      return update(item, 'approvers', (approvers) => approvers.filter((item) => item.id !== userId))
    })

    onChange?.(nextValue)
  }

  const onChangeApproversRequired = (index: number, requiredApprovals: number) => {
    const nextValue = updateAt(value, index, (item) => {
      return update(item, 'requiredApprovals', () => requiredApprovals)
    })

    onChange?.(nextValue)
  }

  const onAddApprovalLevel = () => {
    if (value.length >= 5) {
      return
    }

    onChange?.(
      value.concat({
        approvers: [],
        id: uniqId(),
        isDraft: true,
        requiredApprovals: 1,
        status: ApproveStatusT.PendingT,
      }),
    )
  }

  const onRemoveApprovalLevel = (index: number) => {
    if (value.length < 2) {
      return
    }

    onChange?.(value.filter((_, itemIndex) => itemIndex !== index))
  }

  const onDragEnd: DragDropContextProps['onDragEnd'] = ({ source, destination }) => {
    if (!destination) {
      return
    }

    const nextValue = value.filter((_, index) => index !== source.index)

    onChange?.([...nextValue.slice(0, destination.index), value[source.index], ...nextValue.slice(destination.index)])
  }

  const renderLevelContent = (index: number, level: IssueApprovalLevelT, isAwaiting: boolean): React.ReactNode => {
    return (
      <IssueApprovalLevelContainer
        excludeIds={allSelectedUserIds}
        onChange={(userId) => onSelectApprover(index, userId)}
      >
        {({ onOpen: onOpenSelectUserDropdown, renderSelectUserPopover, setAnchorEl }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {isPreview ? null : (
              <SimpleNonNullableSelectContainer
                options={approversRequiredOptions}
                value={String(value[index].requiredApprovals)}
                onChange={(value) => onChangeApproversRequired(index, Number(value))}
              >
                {(props) => (
                  <Select
                    {...props}
                    dropdownSx={{ width: '300px' }}
                  />
                )}
              </SimpleNonNullableSelectContainer>
            )}
            <List>
              {level.approvers.map((approver) => (
                <ListItem
                  disablePadding
                  key={approver.id}
                  sx={{ px: '4px', py: '8px' }}
                >
                  <Approver
                    approver={approver}
                    isAwaiting={isAwaiting}
                    isPreview={isPreview}
                    level={level}
                    onRemove={(userId) => onRemoveApprover(index, userId)}
                  />
                </ListItem>
              ))}
            </List>
            {isPreview ? null : (
              <>
                <Button
                  color="primary"
                  icon={<PlusSolidIcon />}
                  ref={setAnchorEl}
                  size="md"
                  sx={{ alignSelf: 'flex-start' }}
                  variant="text"
                  onClick={onOpenSelectUserDropdown}
                >
                  Add approvers
                </Button>
                {renderSelectUserPopover()}
              </>
            )}
          </Box>
        )}
      </IssueApprovalLevelContainer>
    )
  }

  const renderAccordion = (level: IssueApprovalLevelT, index: number) => {
    const isApprovedOrRejected = level.status === ApproveStatusT.ApprovedT || level.status === ApproveStatusT.RejectedT

    const isPendingLevel = index === 0 || value[index - 1].status === ApproveStatusT.ApprovedT

    const isAwaiting = !isApprovedOrRejected && (issueStatus !== IssueStatusT.InReviewT || !isPendingLevel)

    // NOTE this mimics 4th status which does not exists on BE, but is present in UI
    const levelApprovalStatusLabel = isAwaiting ? { name: 'Awaiting approval' } : mapApprovalStatusToLabel(level)

    return (
      <Accordion
        feedbackLevel={invalidLevelIndex !== index ? undefined : 'feedbackCritical'}
        initialIsOpened={!isPreview}
      >
        {{
          content: () => renderLevelContent(index, level, isAwaiting),
          header: {
            title: (
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}>
                {level.isDraft ? null : (
                  <IssueApprovalTag
                    {...levelApprovalStatusLabel}
                    size="sm"
                  />
                )}
                <Box>
                  {getUsersWhoApproved(level.approvers).length} / {level.requiredApprovals}
                </Box>
              </Box>
            ),
          },
        }}
      </Accordion>
    )
  }

  if (isPreview) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {value.map((item, index) => {
          return (
            <Box
              data-testid="approval-level-preview"
              key={item.id}
            >
              <FieldTitle>{`Approval level ${index + 1}`}</FieldTitle>
              {renderAccordion(item, index)}
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(16) }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="any">
          {(droppable) => (
            <Box
              ref={droppable.innerRef}
              sx={{ marginTop: '-16px' }}
            >
              <MemoContainer deps={[value, invalidLevelIndex]}>
                {() =>
                  value.map((item, index) => {
                    return (
                      <Draggable
                        draggableId={item.id}
                        index={index}
                        key={item.id}
                      >
                        {(draggable) => (
                          <Box
                            {...draggable.draggableProps}
                            data-testid="approval-level"
                            ref={draggable.innerRef}
                            style={draggable.draggableProps.style}
                            sx={{ marginTop: '16px' }}
                          >
                            <Box sx={{ pl: '28px' }}>
                              <FieldTitle>{`Approval level ${index + 1}`}</FieldTitle>
                            </Box>
                            <Box
                              key={index}
                              sx={{ display: 'flex', gap: '8px' }}
                            >
                              <Box sx={{ alignItems: 'center', display: 'flex', height: '38px' }}>
                                <Box
                                  {...draggable.dragHandleProps}
                                  data-testid="move-approval-level"
                                >
                                  <DragDotsSolidIcon
                                    fill={themeColors.overBackgroundBold}
                                    height={20}
                                    width={20}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{ flexGrow: 1, width: 0 }}>{renderAccordion(item, index)}</Box>
                              <Box sx={{ alignItems: 'center', height: '38px' }}>
                                <Button
                                  color="primary"
                                  data-testid="delete-approval-level"
                                  disabled={value.length < 2}
                                  icon={<DeleteSolidIcon />}
                                  size="md"
                                  variant="text"
                                  onClick={() => onRemoveApprovalLevel(index)}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    )
                  })}
              </MemoContainer>
              {droppable.placeholder}
            </Box>
          )}
        </Droppable>
        <Button
          color="primary"
          disabled={value.length >= 5}
          icon={<PlusSolidIcon />}
          size="md"
          sx={{ alignSelf: 'flex-start' }}
          variant="outlined"
          onClick={onAddApprovalLevel}
        >
          Add Approval Level
        </Button>
      </DragDropContext>
    </Box>
  )
}
