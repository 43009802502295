import { Box } from '@mui/material'

import { visuallyHiddenStyles } from '@resnet/client-web/styles/sx-presets'

export const FileInput = ({
  disabled,
  accept,
  multiple,
  onChange,
}: {
  disabled?: boolean
  accept?: string
  multiple?: boolean
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void
}): React.ReactElement => {
  return (
    <Box
      accept={accept}
      component="input"
      disabled={disabled}
      multiple={multiple}
      sx={visuallyHiddenStyles}
      type="file"
      value=""
      onChange={onChange}
    />
  )
}
