import type { CustomFieldFragmentT } from '@resnet/client-api/api'

import type { CustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/types/custom-field-value'
import type { CustomFieldValuesT } from '@resnet/client-shared/shared/custom-fields/types/custom-field-values'

import { mapCustomFieldToKey } from './map-custom-field-to-key'

export const getFieldValue = <ValueT extends CustomFieldValueT>(
  field: CustomFieldFragmentT,
  fieldValuesKey: string,
  row: Record<string, unknown>,
) => {
  const fieldValues = row[fieldValuesKey] as undefined | null | CustomFieldValuesT

  if (!fieldValues || fieldValues[mapCustomFieldToKey(field)] === undefined) {
    return undefined
  }

  const value = fieldValues[mapCustomFieldToKey(field)] as ValueT

  return value
}
