import type { NestedT } from '@resnet/client-common/common/types/common'
import { mapValues } from '@resnet/client-common/common/utils/object/map-values'

type GetNestedRouteT = {
  <RouteT extends string>(base: string, route: RouteT): RouteT
  <RouteT extends NestedT<string>>(base: string, route: RouteT): RouteT
}

export const getNestedRoutes: GetNestedRouteT = (base: string, route: NestedT<string>) => {
  if (typeof route === 'string') {
    return base === '/' ? route : base + route
  }

  return mapValues((route) => getNestedRoutes(base, route), route)
}
