import { identity } from 'ramda'
import { useMemo, useState } from 'react'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { transform } from '@resnet/client-common/common/utils/object/transform'
import { useDebounced } from '@resnet/client-common/react/hooks/use-debounced'

import { defaultLimit } from '@resnet/client-api/constants/variables'
import type { InfiniteQueryHookT } from '@resnet/client-api/types/query-hook'

import { createGetNextPageParam } from '@resnet/client-shared/shared/common/factories/create-get-next-page-param'

import type { ListActionItemActionPropsT } from '@resnet/client-web/shared/gdl/components/list'
import { useSearchHandlers } from '@resnet/client-web/shared/gdl/components/search'
import type { AbstractOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'

const defaultGetOptionActions = (): [undefined, undefined] => [undefined, undefined]

export type CreateUseSelectDropdownPropsT<QueryVariablesT, QueryDataT, OptionT extends AbstractOptionT> = {
  limit?: number
  mapOptionsQueryDataToOptions: (data: QueryDataT) => OptionT[]
  useOptionsQuery: InfiniteQueryHookT<QueryVariablesT, QueryDataT>
  withSearch?: (input: QueryVariablesT, props: { search: string }) => QueryVariablesT
  useGetOptionActions?: () => [
    undefined | ((option: OptionT) => ListActionItemActionPropsT[]),
    undefined | (() => React.ReactNode),
  ]
}

export type UseSelectDropdownPropsT<QueryVariablesT> = {
  exclude?: string[]
  withQueryVariables?: (input: QueryVariablesT) => QueryVariablesT
}

export const createUseSelectDropdown =
  <QueryVariablesT, QueryDataT, OptionT extends AbstractOptionT>({
    limit = defaultLimit,
    mapOptionsQueryDataToOptions,
    useOptionsQuery,
    withSearch: withSearchActual = identity,
    useGetOptionActions = defaultGetOptionActions,
  }: CreateUseSelectDropdownPropsT<QueryVariablesT, QueryDataT, OptionT>) =>
  ({
    exclude,
    withQueryVariables = identity,
  }: UseSelectDropdownPropsT<QueryVariablesT>): [typeof result, React.ReactNode] => {
    const [searchActual, setSearch] = useState('')

    const search = useDebounced(searchActual)

    const [getOptionActions, renderOptionActionsElements] = useGetOptionActions()

    const withSearch = (input: QueryVariablesT) => {
      if (search.length === 0) {
        return input
      }

      return withSearchActual(input, { search })
    }

    const withLimit = (input: QueryVariablesT) => {
      return transform(input ?? {}, {
        limit: () => limit,
      }) as QueryVariablesT
    }

    const optionsQuery = useOptionsQuery<QueryDataT>(
      pipeline({} as QueryVariablesT, withSearch, withLimit, withQueryVariables),
      {
        getNextPageParam: createGetNextPageParam(limit, (data) => mapOptionsQueryDataToOptions(data).length),
      },
    )

    const optionsQueryFetchNextPage = optionsQuery.fetchNextPage

    const options = useMemo(
      () =>
        pipeline(
          optionsQuery.data,
          (data) => (!data ? [] : data.pages),
          (pages) => pages.flatMap(mapOptionsQueryDataToOptions),
          (options) => (!exclude ? options : options.filter((option) => !exclude.includes(option.id))),
        ),
      [exclude, optionsQuery.data],
    )

    const { onChange: onSearchChange, onClear: onSearchClear } = useSearchHandlers({ setSearch })

    const bottomBoundary = useMemo(() => {
      if (optionsQuery.isFetchingNextPage || !optionsQuery.hasNextPage) {
        return
      }

      return { onObserve: optionsQueryFetchNextPage }
    }, [optionsQuery.hasNextPage, optionsQuery.isFetchingNextPage, optionsQueryFetchNextPage])

    const dropdownProps = {
      bottomBoundary,
      getOptionActions,
      isLoading: optionsQuery.isFetching,
      optionHeight: 48,
      options,
    }

    const searchProps = {
      onChange: onSearchChange,
      onClear: onSearchClear,
      value: searchActual,
    }

    const result = {
      dropdownProps,
      searchProps,
    }

    return [result, renderOptionActionsElements?.()]
  }
