import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { EntityPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/entity-picker/custom-field-value'
import { validateRequired } from '@resnet/client-shared/shared/forms/validators/any-required'
import { validateListRequired } from '@resnet/client-shared/shared/forms/validators/list-required'

import { EntitySelectField } from '@resnet/client-web/shared/form-dalaran/components/entity/entity-select-field'
import { EntitySelectMultipleField } from '@resnet/client-web/shared/form-dalaran/components/entity/entity-select-multiple-field'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type EntityPickerFormEntityCustomFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | EntityPickerCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const EntityPickerFormEntityCustomField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | EntityPickerCustomFieldValueT>,
>({
  field,
  form,
  name,
}: EntityPickerFormEntityCustomFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const entityPickerPayload = payload[CustomFieldTypeT.EntityPickerT]

  assert(entityPickerPayload, checkNonNullable)

  const { multiple, required, entityTypeId: entityTypeIds } = entityPickerPayload

  const renderField = () => {
    if (!multiple) {
      return (
        <EntitySelectField
          entityTypeIds={entityTypeIds}
          form={form as UseFormReturn<FieldValues>}
          label={field.name}
          name={name}
          rules={!required ? undefined : { validate: validateRequired }}
        />
      )
    }

    return (
      <EntitySelectMultipleField
        defaultValue={[]}
        entityTypeIds={entityTypeIds}
        form={form as UseFormReturn<FieldValues>}
        label={field.name}
        name={name}
        nullOptionEnabled={false}
        rules={!required ? undefined : { validate: validateListRequired }}
      />
    )
  }

  return <ObjectDetailsRow>{renderField()}</ObjectDetailsRow>
}
