import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { TypographyPresetT } from '../types/typography-preset'

export const typographyPresets = createConstrainer<Record<string, TypographyPresetT>>()({
  bodyMedium: {
    fontFamily: 'IBMPlexSans',
    fontSize: 16,
    fontWeight: '400',
    letterSpacing: 0.25,
    lineHeight: 24,
  },
  bodySmall: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14,
    fontWeight: '400',
    letterSpacing: 0.4,
    lineHeight: 20,
  },
  captionBold: {
    fontFamily: 'IBMPlexSans',
    fontSize: 12,
    fontWeight: '600',
    letterSpacing: 0.8,
    lineHeight: 16,
  },
  captionRegular: {
    fontFamily: 'IBMPlexSans',
    fontSize: 12,
    fontWeight: '400',
    letterSpacing: 0.8,
    lineHeight: 16,
  },
  displayLarge: {
    fontFamily: 'IBMPlexSans',
    fontSize: 57,
    fontWeight: '700',
    letterSpacing: -0.25,
    lineHeight: 64,
  },
  headlineLarge: {
    fontFamily: 'IBMPlexSans',
    fontSize: 32,
    fontWeight: '600',
    lineHeight: 40,
  },
  headlineMedium: {
    fontFamily: 'IBMPlexSans',
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 32,
  },
  headlineSmall: {
    fontFamily: 'IBMPlexSans',
    fontSize: 24,
    fontWeight: '600',
    lineHeight: 32,
  },
  labelLarge: {
    fontFamily: 'IBMPlexSans',
    fontSize: 15,
    fontWeight: '600',
    letterSpacing: 0.1,
    lineHeight: 24,
  },
  labelMedium: {
    fontFamily: 'IBMPlexSans',
    fontSize: 13,
    fontWeight: '600',
    letterSpacing: 0.3,
    lineHeight: 20,
  },
  labelSmall: {
    fontFamily: 'IBMPlexSans',
    fontSize: 11,
    fontWeight: '500',
    letterSpacing: 0.5,
    lineHeight: 16,
  },
  overlineLarge: {
    fontFamily: 'IBMPlexSans',
    fontSize: 12,
    fontWeight: '400',
    letterSpacing: 1,
    lineHeight: 16,
  },
  overlineSmall: {
    fontFamily: 'IBMPlexSans',
    fontSize: 10,
    fontWeight: '400',
    letterSpacing: 1,
    lineHeight: 12,
  },
  titleLarge: {
    fontFamily: 'IBMPlexSans',
    fontSize: 22,
    fontWeight: '500',
    lineHeight: 28,
  },
  titleMedium: {
    fontFamily: 'IBMPlexSans',
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: 0.1,
    lineHeight: 24,
  },
  titleSmall: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0.1,
    lineHeight: 20,
  },
})
