import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

export const topThreeColors = [
  {
    id: 'gold',
    overSurface: 'overSurfaceGamificationGold',
    surface: 'surfaceGamificationGold',
    surfaceHover: 'surfaceGamificationGoldHover',
    surfacePressed: 'surfaceGamificationGoldPressed',
  },
  {
    id: 'silver',
    overSurface: 'overSurfaceGamificationSilver',
    surface: 'surfaceGamificationSilver',
    surfaceHover: 'surfaceGamificationSilverHover',
    surfacePressed: 'surfaceGamificationSilverPressed',
  },
  {
    id: 'bronze',
    overSurface: 'overSurfaceGamificationBronze',
    surface: 'surfaceGamificationBronze',
    surfaceHover: 'surfaceGamificationBronzeHover',
    surfacePressed: 'surfaceGamificationBronzePressed',
  },
] as const

export const topThreeColorsById = propToKey('id', topThreeColors)
