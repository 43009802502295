import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import type { HTTPClientT } from '@resnet/client-common/network/utils/create-http-client'
import { createHTTPClient } from '@resnet/client-common/network/utils/create-http-client'

export const getApiHttpClientBaseUrl = (tenant: string): string => {
  const baseUrl = process.env.PUBLIC_API_ENDPOINT

  if (baseUrl !== undefined) {
    return baseUrl
  }

  switch (tenant) {
    case 'dev': {
      return 'https://api.dev.resnet.ai'
    }
    case 'demo':
    case 'test': {
      return 'https://api.staging.resnet.ai'
    }
    default: {
      return 'https://api.resnet.ai'
    }
  }
}

let apiHttpClient: null | HTTPClientT = null

export const getApiHttpClient = (): HTTPClientT => {
  return assertedNonNullable(apiHttpClient)
}

export const setApiHttpClient = (tenant: string): void => {
  apiHttpClient = createHTTPClient(getApiHttpClientBaseUrl(tenant))
}
