import type { CustomFieldT } from '../../types/custom-field'

import { FormulaCustomFieldUserFormField } from './components/formula-custom-field-user-form-field'

export const renderUserFormField: CustomFieldT['renderUserFormField'] = ({ field, fieldValuesName, form }) => {
  return (
    <FormulaCustomFieldUserFormField
      field={field}
      fieldValuesName={fieldValuesName}
      form={form}
    />
  )
}
