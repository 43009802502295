import { transform } from '@resnet/client-common/common/utils/object/transform'

import { BooleanFilter } from '@resnet/client-web/shared/filters/components/boolean-filter'
import { FilterRuleContainer } from '@resnet/client-web/shared/presets/hooks/use-filter-rule'

import type { CustomFieldT } from '../../types/custom-field'
import { mapCustomFieldToFullKey, mapCustomFieldToKey } from '../../utils/map-custom-field-to-key'

export const createFilterOption: CustomFieldT['createFilterOption'] = ({
  field,
  fieldValuesKey,
  filterObjectType,
  updateFieldsFilter,
}) => {
  return {
    apply: (input, rule) => {
      const { boolean: payload } = rule

      if (payload === undefined || payload === null) {
        return input
      }

      return updateFieldsFilter(input, (fields) =>
        transform(fields ?? {}, {
          [mapCustomFieldToKey(field)]: () => payload,
        }),
      )
    },
    id: mapCustomFieldToFullKey(field, fieldValuesKey),
    name: field.name,
    objectType: filterObjectType,
    renderContent: ({ filterPresetRuleModel, popperRef }) => {
      return (
        <FilterRuleContainer
          filterPresetRuleModel={filterPresetRuleModel}
          filterType="boolean"
        >
          {({ filterPresetItemRulePayloadModel: [value, onChange] }) => {
            return (
              <BooleanFilter
                popperRef={popperRef}
                value={value}
                onChange={onChange}
              />
            )
          }}
        </FilterRuleContainer>
      )
    },
  }
}
