export type PromiseRefT<ReturnT> = { current: null | Promise<ReturnT> }

// This utility is suitable when you need to prevent function to be executed concurrently:
// - once function was called same pending promise will be returned on consequent calls
// - as soon as promise will be fulfilled next function call will create new promise

export const memoPromise =
  <ReturnT>(promiseRef: PromiseRefT<ReturnT>, fn: () => Promise<ReturnT>): (() => Promise<ReturnT>) =>
  () => {
    if (!promiseRef.current) {
      promiseRef.current = fn().finally(() => {
        promiseRef.current = null
      })
    }

    return promiseRef.current
  }
