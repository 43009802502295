import { createContext } from 'react'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useNonNullableContext } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { AuthTokensT } from './types'

export const AuthContext = createContext<null | {
  getLatestTokens: () => null | AuthTokensT
  setTenant: React.Dispatch<React.SetStateAction<null | string>>
  setTokens: React.Dispatch<React.SetStateAction<null | AuthTokensT>>
  tenant: null | string
  tokens: null | AuthTokensT
  requestHeaders: null | Record<string, string>
}>(null)

export const useAuth = () => useNonNullableContext(AuthContext)

export const useIsAuthorized = (): boolean => {
  const { tenant, tokens } = useAuth()

  const isAuthorized = Boolean(tenant && tokens && tokens.accessToken)

  return isAuthorized
}

export const useSignOut = () => {
  const { setTokens } = useAuth()

  return useEventCallback(() => {
    setTokens(null)
  })
}

export const useTenant = () => {
  const { tenant } = useAuth()

  assert(tenant, checkNonNullable, '"tenant" should be non nullable')

  return { tenant }
}
