import type { CustomFieldT } from '../../types/custom-field'

import { CheckboxCustomFieldUserFormField } from './components/checkbox-custom-field-user-form-field'

export const renderUserFormField: CustomFieldT['renderUserFormField'] = ({ field, form, name }) => {
  return (
    <CheckboxCustomFieldUserFormField
      field={field}
      form={form}
      name={name}
    />
  )
}
