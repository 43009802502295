import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { foldElement } from '@resnet/client-common/react/utils/fold-element'

import { type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { EntityPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/entity-picker/custom-field-value'

import { EntityListItemCard } from '@resnet/client-web/shared/entities/components/entity-list-item-card'
import {
  FieldPreviewLabel,
  FieldPreviewLayout,
  FieldPreviewValueContent,
} from '@resnet/client-web/shared/gdl/components/field-preview'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type EntityPickerFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | EntityPickerCustomFieldValueT
}
export const EntityPickerFormUserFormPreview = ({
  field,
  value: valueActual,
}: EntityPickerFormUserFormPreviewPropsT) => {
  const value = [valueActual].flat()

  return (
    <ObjectDetailsRow>
      <FieldPreviewLayout>
        <FieldPreviewLabel>{field.name}</FieldPreviewLabel>
        <FieldPreviewValueContent>
          {foldElement(
            <>
              {value.filter(checkNonNullable).map((entityId) => (
                <EntityListItemCard
                  withLink
                  id={entityId}
                  key={entityId}
                />
              ))}
            </>,
          )}
        </FieldPreviewValueContent>
      </FieldPreviewLayout>
    </ObjectDetailsRow>
  )
}
