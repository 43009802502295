import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldT } from '../../types/custom-field'

export const numberCustomField = createConstrainer<CustomFieldT>()({
  id: CustomFieldTypeT.NumberT as const,
  name: 'Number',
  payload: createConstrainer<CustomFieldPayloadT>()({
    Number: {
      defaultValue: null,
      isIntegerEnabled: false,
      maxValue: null,
      minValue: null,
      required: false,
    },
  }) as CustomFieldPayloadT,
})
