import { useState } from 'react'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { StateModelT } from '../types/state-model'

import { useCallbackRefEffect } from './use-callback-ref-effect'
import { useEventCallback } from './use-event-callback'
import { subscribeResizeObserver } from './use-resize-observer'

export const useSubscribeRect = (listener: (rect: DOMRect) => void) => {
  const elementRef = useCallbackRefEffect<HTMLElement>(
    (element) => {
      if (!element) {
        return
      }

      listener(element.getBoundingClientRect())

      return subscribeResizeObserver(element, (entry) => {
        listener(entry.contentRect)
      })
    },
    [listener],
  )

  return elementRef
}

export const useRect = () => {
  const [rect, setRect] = useState<null | DOMRect>(null)

  const elementRef = useSubscribeRect(setRect)

  return [elementRef, rect] as const
}

export const RectContainer = createHookContainer(useRect)

export const useHeightModel = (heightModel: StateModelT<null | number>) => {
  const [height, setHeight] = heightModel

  const elementRef = useSubscribeRect(
    useEventCallback(({ height }) => {
      setHeight(height)
    }),
  )

  return [elementRef, height] as const
}

export const useHeight = (defaultHeight?: number) => {
  const [elementRef, height] = useHeightModel(useState(defaultHeight ?? null))

  return [elementRef, height] as const
}
