import type { CustomFieldT } from '../../types/custom-field'

import { SketchCustomFieldPayload } from './components/sketch-custom-field-payload'

export const renderPayload: CustomFieldT['renderPayload'] = ({ form, name, isDraft }) => {
  return (
    <SketchCustomFieldPayload
      form={form}
      isDraft={isDraft}
      name={name}
    />
  )
}
