import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Checkbox } from "@resnet/client-web/shared/gdl/components/checkbox";
import { useTeamsData } from "../../../context/teams-context";

export const Attachments = ({
  selectedAttachments,
  setSelectedAttachments,
}: {
  selectedAttachments: [];
  setSelectedAttachments: (value: []) => void;
}): React.ReactElement => {
  const {
    attachments
  } = useTeamsData();

  console.log('attachments', attachments)

  const Attachement = ({ attachment }: { attachment:  }) => {
    return (
      <Box key={attachment.id} sx={{ display: "flex", flexDirection: "row" }}>
        <Checkbox
          size="md"
          onChange={() => {
            const index = selectedAttachments.findIndex(
              (selectedAttachment) => selectedAttachment.id === attachment.id
            );
            if (index !== -1) {
              setSelectedAttachments(
                selectedAttachments.filter((selectedAttachment) => selectedAttachment.id !== attachment.id)
              );
            } else {
              setSelectedAttachments([...selectedAttachments, { ...attachment }]);
            }
          }}
          value={selectedAttachments.some((selectedAttachment) => selectedAttachment.id === attachment.id)}
        />
        <p>{attachment.name}</p>
      </Box>
    );
  };

  if (!attachments) return <Box>No attachments</Box>;

  return (
    <Box>
      {attachments.map((attachment) => (
        <Attachement attachment={attachment} />
      ))}
    </Box>
  );
};
