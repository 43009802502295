import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { pluralize } from '@resnet/client-common/common/utils/string/pluralize'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { SketchCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/sketch/custom-field-value'

import { Tag } from '@resnet/client-web/shared/gdl/components/tag'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'

import type { CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createColumn: CustomFieldT['createColumn'] = ({ field, fieldValuesKey }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const attachmentPayload = payload[CustomFieldTypeT.SketchT]

  assert(attachmentPayload, checkNonNullable)

  const { multiple } = attachmentPayload

  const getValue = (row: Record<string, unknown>) => getFieldValue<SketchCustomFieldValueT>(field, fieldValuesKey, row)

  return {
    field: mapCustomFieldToFullKey(field, fieldValuesKey),
    flex: 1,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      return <RegularCell>{groupingKey}</RegularCell>
    },
    groupable: multiple ? false : true,
    groupingValueGetter: (params) => {
      const value = getValue(params.row)

      if (value === undefined) {
        return undefined
      }

      const length = [value].flat().filter(checkNonNullable).length

      const text = `${length} ${pluralize(length, 'Sketch')}`

      return text
    },
    headerName: field.name,
    minWidth: 150,
    renderCell: (params) => {
      const value = params.value as ReturnType<typeof getValue>

      if (value === undefined) {
        return <RegularCell>-</RegularCell>
      }

      const length = [value].flat().filter(checkNonNullable).length

      const text = `${length} ${pluralize(length, 'Sketch')}`

      return <Tag uppercase>{text}</Tag>
    },
    sortable: true,
    valueGetter: (params) => {
      const value = getValue(params.row)

      return value
    },
  }
}
