import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { IssueTypeT } from '@resnet/client-api/api'

import { issueTypesById } from '@resnet/client-shared/shared/issues/constants/issue-type-props'

import type { TagPropsT } from '@resnet/client-web/shared/gdl/components/tag'
import { Tag } from '@resnet/client-web/shared/gdl/components/tag'

import { IssueTypeIcon } from '../issue-type-icon'

export type IssueTypeTagPropsT = MergeAllT<
  [
    TagPropsT,
    {
      type: IssueTypeT
    },
  ]
>

export const IssueTypeTag = ({ type, ...props }: IssueTypeTagPropsT) => {
  const { name } = issueTypesById[type]

  return (
    <Tag
      {...props}
      uppercase
      icon={<IssueTypeIcon type={type} />}
    >
      {name}
    </Tag>
  )
}
