import type { MaybeT } from '@resnet/client-common/common/types/common'
import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { ApiError } from '@resnet/client-common/network/errors/api-error'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import type { QueryFunctionContext } from '@resnet/client-api/vendors/react-query'

import { useAuth } from '../auth-api/auth-context'
import { useRefreshAccessToken } from '../auth-api/use-refresh-access-token'
import { checkIsAnyClientUnauthorizedError } from '../utils/check-is-any-client-error'
import { checkIsAnyClientExceptionError } from '../utils/check-is-any-client-error'

export const useRequest = <TData, TVariables>(
  request: (token: MaybeT<string>, variables: TVariables, signal?: AbortSignal) => Promise<TData>,
) => {
  const { getLatestTokens } = useAuth()

  const refreshAccessToken = useRefreshAccessToken()

  return useEventCallback(async (variables: TVariables, context?: QueryFunctionContext): Promise<TData> => {
    try {
      const { accessToken } = assertedNonNullable(getLatestTokens())

      return await request(accessToken, variables, context?.signal)
    } catch (error) {
      if (checkIsAnyClientUnauthorizedError(error)) {
        await refreshAccessToken()

        throw error
      }

      if (checkIsAnyClientExceptionError(error)) {
        throw new ApiError(error.response.errors?.[0])
      }

      throw error
    }
  })
}
