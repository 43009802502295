import {
  useInfiniteListRelationsQuery,
  useListRelationsQuery,
  useUpdateRelationsMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const relationsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findRelationsQueriesByEntityId = ({ entityId }: { entityId: string }) =>
    findQueries(queryClient, useListRelationsQuery, (query) => query.queryKey[1].entityId === entityId)

  const findInfiniteRelationsQueriesByEntityId = ({ entityId }: { entityId: string }) =>
    findInfiniteQueries(queryClient, useInfiniteListRelationsQuery, (query) => query.queryKey[1].entityId === entityId)

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findRelationsQueriesByEntityId({ entityId }).forEach(invalidateQuery(queryClient))
        findInfiniteRelationsQueriesByEntityId({ entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateRelationsHandlers()]
}
