import MuiClickAwayListener from '@mui/material/ClickAwayListener'
import { useContext } from 'react'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { LayerRegistryContext } from '../layer-registry-context'
import { ModalLayerContext } from '../modal-layer-context'

export const ClickAwayListener = ({
  children,
  onClickAway: onClickAwayProp,
  ...props
}: { children: React.ReactElement } & React.ComponentProps<typeof MuiClickAwayListener>) => {
  const modalLayerContextValue = useContext(ModalLayerContext)
  const layerContextValue = useContext(LayerRegistryContext)

  assert(
    layerContextValue,
    checkNonNullable,
    '"value" is expected to be defined, it might be consumer was rendered outside of provider',
  )

  const onClickAway = useEventCallback((event: MouseEvent) => {
    const handleEvent = () => {
      onClickAwayProp(event)
    }

    const { registry: layers } = layerContextValue

    const layersTotal = layers.length

    const isOnTopMostLayer = layersTotal === 0 || modalLayerContextValue?.isTopMostLayer

    if (!isOnTopMostLayer) {
      return
    }

    handleEvent()
  })

  return (
    <MuiClickAwayListener
      onClickAway={onClickAway}
      {...props}
    >
      {children}
    </MuiClickAwayListener>
  )
}
