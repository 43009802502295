import type { Value as ExpressionValueT } from 'expr-eval'

import { expressionParser } from '@resnet/client-shared/services/expression-parser'

import type { FormCustomFieldValuesT } from '../../../types/form-custom-field-values'

export const mapFormulaToValue = (formula: string, fieldValues: undefined | FormCustomFieldValuesT) => {
  try {
    const evaluated = expressionParser.parse(formula).evaluate(fieldValues as ExpressionValueT)

    if (Number.isNaN(evaluated)) {
      return null
    }

    return String(evaluated)
  } catch (error) {
    return null
  }
}
