import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { DateCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/date/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { DateFormCustomFieldValueT } from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  DateCustomFieldValueT,
  DateFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const datePayload = payload[CustomFieldTypeT.DateT]

  assert(datePayload, checkNonNullable)

  const { defaultValue = null } = datePayload

  return defaultValue
}
