import type { PickDistributiveT } from '@resnet/client-common/common/types/common'

import type { MinimalEntityFragmentT } from '@resnet/client-api/api'
import { EntityTypeIdsT } from '@resnet/client-api/api'

export const mapEntityToEntityTypeId = <ValueT extends PickDistributiveT<MinimalEntityFragmentT, '__typename'>>(
  value: ValueT,
) => {
  return value.__typename as Extract<EntityTypeIdsT, ValueT['__typename']>
}

// NOTE extends string due to conflict between api generated and WaterMelon DB types
export const mapEntityToEntityTypeIdTitle = <ValueT extends string>(value: ValueT): string => {
  switch (value) {
    case EntityTypeIdsT.ResourceObjectT:
      return 'Resource'
    case EntityTypeIdsT.FormObjectT:
      return 'Form'
    default:
      return value
  }
}
