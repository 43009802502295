import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import { useMemo, useState } from 'react'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useSubscribeRect } from '@resnet/client-common/react/hooks/use-rect'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import type { ResourceFragmentT } from '@resnet/client-api/api'

import { sizeOptions } from '@resnet/client-shared/shared/resources/constants/size-options'

export type ImageResourcePropsT = MergeT<BoxProps<'img'>, { resource: Pick<ResourceFragmentT, 'url' | 'optimized'> }>

export const ImageResource = ({ resource, ...props }: ImageResourcePropsT) => {
  const [sizeActual, setSize] = useState<null | (typeof sizeOptions)[number]['id']>(sizeOptions[0].id)

  const ref = useSubscribeRect((rect) => {
    const { width, height } = rect

    const sizeOption = sizeOptions.find((sizeOption) => sizeOption.size >= width && sizeOption.size >= height)

    const size = !sizeOption ? null : sizeOption.id

    setSize(size)
  })

  const [deoptimized, setDeoptimized] = useState(false)

  const onError = useEventCallback(() => {
    setDeoptimized(true)
  })

  const size = deoptimized ? null : sizeActual

  const src = useMemo(() => {
    if (!size || !resource.optimized) {
      return resource.url
    }

    const optimizedSize = resource.optimized[size]

    if (!optimizedSize) {
      return resource.url
    }

    return optimizedSize.url
  }, [size, resource.url, resource.optimized])

  return (
    <Box
      {...props}
      component="img"
      ref={ref}
      src={src}
      onError={onError}
    />
  )
}
