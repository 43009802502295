import type { CustomFieldFragmentT, CustomFieldScopeFragmentT } from '@resnet/client-api/api'

export const checkFieldMatchesScope = ({
  field,
  scope,
}: {
  field: CustomFieldFragmentT
  scope?: CustomFieldScopeFragmentT
}) => {
  if (!field.scope && !scope) {
    return true
  }

  if (field.scope && scope && field.scope.field === scope.field && field.scope.value === scope.value) {
    return true
  }

  return false
}
