import { Box, type BoxProps, type SxProps } from '@mui/material'
import { Link, type LinkProps } from 'react-router-dom'

import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

export type LinkablePropsT = MergeAllT<
  [
    BoxProps,
    {
      sx?: (props: { isLink: boolean }) => SxProps
      to?: LinkProps['to']
    },
  ]
>

export const Linkable = forwardFunctionalComponentRef(
  ({ children, sx, ...props }: LinkablePropsT, ref: React.ForwardedRef<HTMLElement>) => {
    const isLink = props.to !== undefined

    return (
      <Box
        {...props}
        component={!isLink ? undefined : Link}
        ref={ref}
        sx={sx?.({ isLink })}
      >
        {children}
      </Box>
    )
  },
)
