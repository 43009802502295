import { useDerivedState } from './use-derived-state'
import { useResizeObserver } from './use-resize-observer'

export const useHasOverflow = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const [hasOverflow, setHasOverflow] = useDerivedState(() => false, [enabled])

  const elementRef = useResizeObserver(
    !enabled
      ? undefined
      : (entry) => {
          setHasOverflow(entry.target.scrollWidth > entry.target.clientWidth)
        },
  )

  return { elementRef, hasOverflow }
}
