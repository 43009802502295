import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { CampaignColorT } from '@resnet/client-api/api'

import { systemColors } from '../../gdl/constants/system-colors'

export const campaignColorOptions = [
  {
    color: systemColors.white,
    gradient: {
      angle: 107.73,
      colors: ['#c33764', '#1d2671'],
      locations: [0, 0.9104],
    },
    id: CampaignColorT.CelestialT as const,
    name: 'Celestial',
  },
  {
    color: systemColors.white,
    gradient: {
      angle: 107.73,
      colors: ['#34e89e', '#0f3443'],
      locations: [0, 0.9104],
    },
    id: CampaignColorT.PacificDreamT as const,
    name: 'Pacific Dream',
  },
  {
    color: systemColors.white,
    gradient: {
      angle: 107.73,
      colors: ['#200122', '#6f0000'],
      locations: [0, 0.9104],
    },
    id: CampaignColorT.LoveAndLibertyT as const,
    name: 'Love And Liberty',
  },
  {
    color: systemColors.white,
    gradient: {
      angle: 107.73,
      colors: ['#0575e6', '#021b79'],
      locations: [0, 0.9104],
    },
    id: CampaignColorT.VeryBlueT as const,
    name: 'Very Blue',
  },
  {
    color: systemColors.black,
    gradient: {
      angle: 107.73,
      colors: ['#dce35b', '#45b649'],
      locations: [0, 0.9104],
    },
    id: CampaignColorT.EasyMedT as const,
    name: 'EasyMed',
  },
  {
    color: systemColors.black,
    gradient: {
      angle: 107.73,
      colors: ['#3494e6', '#ec6ead'],
      locations: [0, 0.9104],
    },
    id: CampaignColorT.ViceCityT as const,
    name: 'Vice City',
  },
  {
    color: systemColors.white,
    gradient: {
      angle: 107.73,
      colors: ['#f3904f', '#3b4371'],
      locations: [0, 0.9104],
    },
    id: CampaignColorT.DawnT as const,
    name: 'Dawn',
  },
  {
    color: systemColors.black,
    gradient: {
      angle: 107.73,
      colors: ['#ee0979', '#fa4f22', '#ff6a00'],
      locations: [0, 0.6541, 0.9104],
    },
    id: CampaignColorT.IbizaSunsetT as const,
    name: 'Ibiza Sunset',
  },
  {
    color: systemColors.black,
    gradient: {
      angle: 107.73,
      colors: ['#f4c4f3', '#fc67fa'],
      locations: [0, 0.9104],
    },
    id: CampaignColorT.BlackRoseT as const,
    name: 'Black Rose',
  },
  {
    color: systemColors.black,
    gradient: {
      angle: 113.65,
      colors: ['#00c3ff', '#ffff1c'],
      locations: [0.018, 1],
    },
    id: CampaignColorT.BradyBradyFunFunT as const,
    name: 'Brady Brady Fun Fun',
  },
  {
    color: systemColors.black,
    gradient: {
      angle: 111.49,
      colors: ['#fff', '#fffc00'],
      locations: [0.0186, 1],
    },
    // eslint-disable-next-line spellcheck/spell-checker
    id: CampaignColorT.SnapchatT as const,
    // eslint-disable-next-line spellcheck/spell-checker
    name: 'Snapchat',
  },
  {
    color: systemColors.white,
    gradient: {
      angle: 111.49,
      colors: ['#f0c', '#339'],
      locations: [0.0186, 1],
    },
    id: CampaignColorT.CosmicFusionT as const,
    name: 'Cosmic Fusion',
  },
  {
    color: systemColors.black,
    gradient: {
      angle: 111.49,
      colors: ['#ef32d9', '#89fffd'],
      locations: [0.0186, 1],
    },
    id: CampaignColorT.AzurePopT as const,
    name: 'Azure Pop',
  },
  {
    color: systemColors.black,
    gradient: {
      angle: 104.04,
      colors: ['#4ecdc4', '#556270'],
      locations: [0.0696, 0.9609],
    },
    id: CampaignColorT.DiscoT as const,
    name: 'Disco',
  },
  {
    color: systemColors.black,
    gradient: {
      angle: 104.04,
      colors: ['#56ab2f', '#a8e063'],
      locations: [0.0696, 0.9609],
    },
    id: CampaignColorT.LushT as const,
    name: 'Lush',
  },
]

export const campaignColorOptionsById = propToKey('id', campaignColorOptions)
