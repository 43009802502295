import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'

import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { ThemePropsColorT } from '@resnet/client-shared/shared/gdl/themes'

import { themeColors } from '../../constants/theme-colors'
import { toPx } from '../../utils/to-px'

const directions = [
  { id: 'horizontal' as const, sizePropName: 'height' as const },
  { id: 'vertical' as const, sizePropName: 'width' as const },
]

const directionById = propToKey('id', directions)

const colors = createConstrainer<{ id: ThemePropsColorT }[]>()([
  { id: 'borderDefault' as const },
  { id: 'borderBold' as const },
])

export const Divider = ({
  direction = directions[0].id,
  color = colors[0].id,
  sx,
}: {
  direction?: (typeof directions)[number]['id']
  color?: (typeof colors)[number]['id']
  sx?: SxProps
}): React.ReactElement => {
  const { sizePropName } = directionById[direction]

  return (
    <Box sx={[{ backgroundColor: themeColors[color], flexShrink: 0, [sizePropName]: toPx(1) }, sx ?? null].flat()} />
  )
}
