import { Button } from "@resnet/client-web/shared/gdl/components/button";
import { Divider, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { Form } from "@resnet/client-web/shared/form-dalaran/components/common/form";
import { toPx } from "@resnet/client-web/shared/gdl/utils/to-px";
import { Surface } from "@resnet/client-web/shared/gdl/components/surface";
import { Label } from "@resnet/client-web/shared/gdl/components/label";
import { ChildrenDivider } from "@resnet/client-common/react/components/children-transformer";
import { ObjectDetailsRow } from "@resnet/client-web/shared/object/components/object-details-row";
import { TextField } from "@resnet/client-web/shared/form-dalaran/components/common/text-field";
import { themeColors } from "@resnet/client-web/shared/gdl/constants/theme-colors";
import { composeValidators } from "@resnet/client-shared/shared/forms/validators/compose-validators";
import { createValidateMaxLength } from "@resnet/client-shared/shared/forms/validators/text-length";
import { validateTextRequired } from "@resnet/client-shared/shared/forms/validators/text-required";
import Logo from "@resnet/client-shared/assets/patterns/logo.svg";
import { SignInMutationVariablesT, useSignInMutation } from "@resnet/client-api/auth-api";
import React from "react";
import { useAuth } from "@resnet/client-api/auth-api/auth-context";
import { useSnackbar } from "notistack";

const LoginScreen = (): React.ReactElement => {
  const form = useForm<SignInMutationVariablesT>({
    shouldFocusError: false,
  });

  const { setTenant, setTokens } = useAuth();

  const signInMutation = useSignInMutation();

  const signInMutationMutate = signInMutation.mutate;

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data: SignInMutationVariablesT) => {
    setTenant(data.tenant === "dev" ? "dev" : "prod");

    signInMutationMutate(
      {
        email: form.getValues().email,
        password: form.getValues().password,
        tenant: form.getValues().tenant,
      },
      {
        onError: (error) => {
          console.log(error);
          enqueueSnackbar("The information provided is not correct!", {
            variant: "error",
          });
        },
        onSuccess: (data) => {
          enqueueSnackbar("You have succesfully logged in.", {
            variant: "success",
          });
          setTenant(form.getValues().tenant);
          setTokens({
            accessToken: data.token,
            refreshToken: data.refreshToken,
          });

          console.log(data);
        },
      }
    );
  };

  const renderFields = () => {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: toPx(16),
          }}
        >
          <Logo fill={themeColors.basePrimary} />
          <Label size="md">Login to ResNet AI</Label>
        </Box>
        <Surface
          sx={{
            display: "flex",
            borderRadius: toPx(8),
            flexDirection: "column",
            margin: toPx(16),
            padding: toPx(16),
            gap: toPx(8),
          }}
        >
          <ObjectDetailsRow>
            <TextField
              form={form}
              label="Company ID"
              name="tenant"
              placeholder="Enter Company ID"
              rules={{
                validate: composeValidators(validateTextRequired, createValidateMaxLength(100)),
              }}
            />
          </ObjectDetailsRow>
          <ObjectDetailsRow>
            <TextField
              form={form}
              label="E-mail"
              name="email"
              placeholder="Enter your e-mail"
              rules={{
                validate: composeValidators(validateTextRequired, createValidateMaxLength(100)),
              }}
            />
          </ObjectDetailsRow>
          <ObjectDetailsRow>
            <TextField
              form={form}
              label="Password"
              name="password"
              type="password"
              placeholder="Enter your password"
              rules={{
                validate: composeValidators(validateTextRequired, createValidateMaxLength(100)),
              }}
            />
          </ObjectDetailsRow>
          <Button type="submit" color="primary" size="md" variant="contained">
            Login
          </Button>
        </Surface>
      </Box>
    );
  };

  return (
    <Form form={form} sx={{ flexGrow: 1, gap: toPx(16) }} onSubmit={onSubmit}>
      <ChildrenDivider dividerNode={<Divider />}>{renderFields()}</ChildrenDivider>
    </Form>
  );
};

export default LoginScreen;
