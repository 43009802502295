import type { DraftableT } from '../types/draftable'

export const undraft = <ValueT extends Record<string, unknown>>(input: DraftableT<ValueT>): ValueT => {
  if (!input.isDraft) {
    return input as ValueT
  }

  const { isDraft: _isDraft, ...output } = input

  return output as unknown as ValueT
}
