import produce from 'immer'

import type {
  InputUpdateUserCredentialT,
  GetUserCredentialQueryT,
  ListUserCredentialsQueryT,
} from '@resnet/client-api/api'
import {
  useCreateUserCredentialMutation,
  useDeleteUserCredentialMutation,
  useInfiniteListUserCredentialsQuery,
  useGetUserCredentialQuery,
  useListUserCredentialsQuery,
  useUpdateUserCredentialMutation,
  useArchiveUserCredentialMutation,
  useUnarchiveUserCredentialMutation,
  useGetCredentialQuery,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const getUserCredentialQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findUserCredentialQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetUserCredentialQuery, (data) => data.getUserCredential, { id })

  const findCredentialQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetCredentialQuery, (data) => data.getCredential, { id })

  const updateUserCredential = (
    data: undefined | GetUserCredentialQueryT,
    { diff }: { diff: InputUpdateUserCredentialT },
  ) => {
    if (!data) {
      return
    }

    const item = data.getUserCredential

    Object.assign(item, diff)
  }

  const deleteUserCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteUserCredentialMutation, {
      onSuccess: (data, { id }) => {
        findUserCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findCredentialQueriesById({ id: data.deleteUserCredential.credentialId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateUserCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateUserCredentialMutation, {
      onError: (error, { id }) => {
        findUserCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findUserCredentialQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateUserCredential(data, { diff }))),
        )
      },
      onSuccess: (data, { id }) => {
        findUserCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findCredentialQueriesById({ id: data.updateUserCredential.credentialId }).forEach(invalidateQuery(queryClient))
        findCredentialQueriesById({ id: data.updateUserCredential.credentialId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveUserCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveUserCredentialMutation, {
      onSuccess: (data, { id }) => {
        findUserCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveUserCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveUserCredentialMutation, {
      onSuccess: (data, { id }) => {
        findUserCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    deleteUserCredentialHandlers(),
    updateUserCredentialHandlers(),
    archiveUserCredentialHandlers(),
    unarchiveUserCredentialHandlers(),
  ]
}

export const listUserCredentialsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findUserCredentialsQueries = () => findQueries(queryClient, useListUserCredentialsQuery)

  const findInfiniteUserCredentialsQueries = () => findInfiniteQueries(queryClient, useInfiniteListUserCredentialsQuery)

  const findCredentialQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetCredentialQuery, (data) => data.getCredential, { id })

  const updateUserCredential = (
    data: undefined | ListUserCredentialsQueryT,
    { id, diff }: { id: string; diff: InputUpdateUserCredentialT },
  ) => {
    if (!data) {
      return
    }

    const items = data.listUserCredentials.items

    const item = items.find((item) => item.id === id)

    if (!item) {
      return
    }

    Object.assign(item, diff)
  }

  const deleteUserCredential = (data: undefined | ListUserCredentialsQueryT, { id }: { id: string }) => {
    if (!data) {
      return
    }

    const items = data.listUserCredentials.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createUserCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateUserCredentialMutation, {
      onSuccess: (data) => {
        findUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        setTimeout(() => {
          findCredentialQueriesById({ id: data.createUserCredential.credentialId }).forEach(
            invalidateQuery(queryClient),
          )
          //NOTE wait for attachments counters to update
        }, 3000)
      },
    })
  }

  const deleteUserCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteUserCredentialMutation, {
      onError: () => {
        findUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findUserCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => deleteUserCredential(data, { id }))),
        )
        findInfiniteUserCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => deleteUserCredential(page, { id }))),
          ),
        )
      },
      onSuccess: (data) => {
        findUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        findCredentialQueriesById({ id: data.deleteUserCredential.credentialId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateUserCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateUserCredentialMutation, {
      onError: () => {
        findUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findUserCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateUserCredential(data, { diff, id }))),
        )
        findInfiniteUserCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => updateUserCredential(page, { diff, id }))),
          ),
        )
      },
      onSuccess: (data) => {
        findUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        findCredentialQueriesById({ id: data.updateUserCredential.credentialId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveUserCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveUserCredentialMutation, {
      onSuccess: () => {
        findUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveUserCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveUserCredentialMutation, {
      onSuccess: () => {
        findUserCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createUserCredentialHandlers(),
    deleteUserCredentialHandlers(),
    updateUserCredentialHandlers(),
    archiveUserCredentialHandlers(),
    unarchiveUserCredentialHandlers(),
  ]
}
