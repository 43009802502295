import type { SubscribeT } from '@resnet/client-common/common/utils/emitter/create-emitter'
import { createEmitter } from '@resnet/client-common/common/utils/emitter/create-emitter'
import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { setStateReducer } from '@resnet/client-common/react/utils/set-state-reducer'

export type ReadableStoreT<ValueT> = {
  get: () => ValueT
  subscribe: (subscriber: (value: ValueT) => void) => () => void
}

export type StoreT<ValueT> = {
  get: () => ValueT
  subscribe: (subscriber: (value: ValueT) => void) => () => void
  set: React.Dispatch<React.SetStateAction<ValueT>>
}

export const createStore = <ValueT>(initialValue: ValueT): StoreT<ValueT> => {
  const { subscribe, dispatch } = createEmitter<ValueT>()

  let value = initialValue

  const get = (): ValueT => {
    return value
  }

  const set = (action: React.SetStateAction<ValueT>): void => {
    const nextValue = setStateReducer<ValueT>(value, action)

    if (nextValue === value) {
      return
    }

    value = nextValue

    dispatch(value)
  }

  return {
    get,
    set,
    subscribe,
  }
}

export const syncWithStore = <ValueT>(
  store: ReadableStoreT<ValueT>,
  synchronizer: (value: ValueT) => void,
): (() => void) => {
  synchronizer(store.get())

  return store.subscribe(synchronizer)
}

export const createPropStore = <ValueT extends Record<string, unknown>, PropNameT extends keyof ValueT>(
  store: StoreT<ValueT>,
  propName: PropNameT,
): StoreT<ValueT[PropNameT]> => {
  const get = (): ValueT[PropNameT] => {
    const value = store.get()

    return value[propName]
  }

  const set = (action: React.SetStateAction<ValueT[PropNameT]>): void => {
    const value = store.get()

    const propValue = value[propName]

    const nextPropValue = setStateReducer<ValueT[PropNameT]>(propValue, action)

    if (nextPropValue === propValue) {
      return
    }

    store.set({ ...value, [propName]: nextPropValue })
  }

  const subscribe: SubscribeT<ValueT[PropNameT]> = (subscriber) =>
    store.subscribe((value) => subscriber(value[propName]))

  return {
    get,
    set,
    subscribe,
  }
}

export const combineStores = <ValuesT extends Record<string, unknown>, ResultT>(
  stores: { [K in keyof ValuesT]: ReadableStoreT<ValuesT[K]> },
  project: (values: ValuesT) => ResultT,
): ReadableStoreT<ResultT> => {
  const getValues = (): ValuesT => {
    return pipeline(stores, Object.entries, (x) => x.map(([key, store]) => [key, store.get()]), Object.fromEntries)
  }

  let value: ResultT = project(getValues())

  const get = (): ResultT => {
    return value
  }

  const subscribe: SubscribeT<ResultT> = (subscriber) => {
    const unsubscribes = Object.values(stores).map((store) => {
      return store.subscribe(() => {
        const nextValue = project(getValues())

        if (nextValue === value) {
          return
        }

        value = nextValue

        subscriber(nextValue)
      })
    })

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe())
    }
  }

  return { get, subscribe }
}
