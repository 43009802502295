import type { CustomFieldT } from '../../types/custom-field'

import { DropdownCustomFieldUserFormField } from './components/dropdown-custom-field-user-form-field'

export const renderUserFormField: CustomFieldT['renderUserFormField'] = ({ form, name, field }) => {
  return (
    <DropdownCustomFieldUserFormField
      field={field}
      form={form}
      name={name}
    />
  )
}
