import type { FieldPathByValue, FieldValues, UseControllerReturn } from 'react-hook-form'
import { useController, type UseFormReturn } from 'react-hook-form'

import type { IssueStatusT } from '@resnet/client-api/api'

import { EmptyPanel } from '@resnet/client-web/shared/common/components/empty-panel'
import { Field, FieldErrorText } from '@resnet/client-web/shared/gdl/components/field'
import type { IssueApprovalLevelT } from '@resnet/client-web/shared/issues/components/issue-approval'
import { IssueApproval } from '@resnet/client-web/shared/issues/components/issue-approval'

import type { SwitchFieldValueT } from '../../common/switch-field'

export type IssueApprovalFieldValueT = undefined | IssueApprovalLevelT[]

export type IssueApprovalFieldPropsT<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues>
  issueStatusName: FieldPathByValue<TFieldValues, undefined | IssueStatusT>
  name: FieldPathByValue<TFieldValues, IssueApprovalFieldValueT>
  needsApprovalName: FieldPathByValue<TFieldValues, SwitchFieldValueT>
}

export const IssueApprovalField = <TFieldValues extends FieldValues>({
  form,
  issueStatusName,
  name,
  needsApprovalName,
}: IssueApprovalFieldPropsT<TFieldValues>) => {
  const { control } = form

  const issueStatus = form.watch(issueStatusName) as Exclude<undefined | IssueStatusT, undefined>

  const needsApproval = form.watch(needsApprovalName) as Exclude<SwitchFieldValueT, undefined>

  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    control,
    name,
    rules: {
      validate: (value: IssueApprovalLevelT[]) => {
        if (!needsApproval) {
          return true
        }

        const invalidItemIndex = value.findIndex((item) => item.approvers.length < (item.requiredApprovals ?? 0))

        if (invalidItemIndex !== -1) {
          return `You do not have enough approvers for level ${invalidItemIndex + 1}`
        }

        return true
      },
    },
  }) as unknown as UseControllerReturn<Record<string, Exclude<IssueApprovalFieldValueT, undefined>>>

  if (!needsApproval) {
    return <EmptyPanel>{{ description: 'Approval is not needed' }}</EmptyPanel>
  }

  const renderError = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message}</FieldErrorText>
  }

  return (
    <Field>
      <IssueApproval
        error={invalid}
        issueStatus={issueStatus}
        value={value}
        onChange={onChange}
      />
      {renderError()}
    </Field>
  )
}

export type IssueApprovalFieldPreviewPropsT = {
  value: Exclude<IssueApprovalFieldValueT, undefined>
  needsApproval: Exclude<SwitchFieldValueT, undefined>
  issueStatus: IssueStatusT
}

export const IssueApprovalFieldPreview = ({ value, needsApproval, issueStatus }: IssueApprovalFieldPreviewPropsT) => {
  if (!needsApproval) {
    return <EmptyPanel>{{ description: 'Approval is not needed' }}</EmptyPanel>
  }

  return (
    <IssueApproval
      isPreview
      issueStatus={issueStatus}
      value={value}
    />
  )
}
