export type ValidationErrorDescriptorT = {
  code?: string
  message?: string
}

export class ValidationError extends Error {
  code: string

  constructor({ code = 'AnUnknownError', message = '' }: ValidationErrorDescriptorT = {}) {
    super(code)

    this.name = code
    this.code = code
    this.message = message
  }
}
