import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { TextCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/text/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { TextFormCustomFieldValueT } from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  TextCustomFieldValueT,
  TextFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const textPayload = payload[CustomFieldTypeT.TextT]

  assert(textPayload, checkNonNullable)

  const { defaultValue } = textPayload

  return defaultValue
}
