export const mimetypes = {
  'application/pdf': { extension: 'pdf' as const, type: 'pdf' as const },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    extension: 'docx' as const,
    type: 'word' as const,
  },
  'image/avif': { extension: 'avif' as const, type: 'image' as const },
  'image/gif': { extension: 'gif' as const, type: 'image' as const },
  'image/jpeg': { extension: 'jpeg' as const, type: 'image' as const },
  'image/jpg': { extension: 'jpg' as const, type: 'image' as const },
  'image/pjpeg': { extension: 'pjpeg' as const, type: 'image' as const },
  'image/png': { extension: 'png' as const, type: 'image' as const },
  'image/webp': { extension: 'webp' as const, type: 'image' as const },
  'image/x-png': { extension: 'png' as const, type: 'image' as const },
  'video/mp4': { extension: 'mp4' as const, type: 'video' as const },
  'video/quicktime': { extension: 'mov' as const, type: 'video' as const },
  'video/webm': { extension: 'webm' as const, type: 'video' as const },
}

export const unknownMimetype = 'unknown/unknown' as const
