import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

type PipelineT = {
  <A, B>(data: A, fn1: (arg: A) => B): B
  <A, B, C>(data: A, fn1: (arg: A) => B, fn2: (arg: B) => C): C
  <A, B, C, D>(data: A, fn1: (arg: A) => B, fn2: (arg: B) => C, fn3: (arg: C) => D): D
  <A, B, C, D, E>(data: A, fn1: (arg: A) => B, fn2: (arg: B) => C, fn3: (arg: C) => D, fn4: (arg: D) => E): E
  <A, B, C, D, E, F>(
    data: A,
    fn1: (arg: A) => B,
    fn2: (arg: B) => C,
    fn3: (arg: C) => D,
    fn4: (arg: D) => E,
    fn5: (arg: E) => F,
  ): F
  <A, B, C, D, E, F, G>(
    data: A,
    fn1: (arg: A) => B,
    fn2: (arg: B) => C,
    fn3: (arg: C) => D,
    fn4: (arg: D) => E,
    fn5: (arg: E) => F,
    fn6: (arg: F) => G,
  ): G
  <A, B, C, D, E, F, G, H>(
    data: A,
    fn1: (arg: A) => B,
    fn2: (arg: B) => C,
    fn3: (arg: C) => D,
    fn4: (arg: D) => E,
    fn5: (arg: E) => F,
    fn6: (arg: F) => G,
    fn7: (arg: G) => H,
  ): H
  <A, B, C, D, E, F, G, H, I>(
    data: A,
    fn1: (arg: A) => B,
    fn2: (arg: B) => C,
    fn3: (arg: C) => D,
    fn4: (arg: D) => E,
    fn5: (arg: E) => F,
    fn6: (arg: F) => G,
    fn7: (arg: G) => H,
    fn8: (arg: H) => I,
  ): I
  <A, B, C, D, E, F, G, H, I, J>(
    data: A,
    fn1: (arg: A) => B,
    fn2: (arg: B) => C,
    fn3: (arg: C) => D,
    fn4: (arg: D) => E,
    fn5: (arg: E) => F,
    fn6: (arg: F) => G,
    fn7: (arg: G) => H,
    fn8: (arg: H) => I,
    fn9: (arg: I) => J,
  ): J
  <A, B, C, D, E, F, G, H, I, J, K>(
    data: A,
    fn1: (arg: A) => B,
    fn2: (arg: B) => C,
    fn3: (arg: C) => D,
    fn4: (arg: D) => E,
    fn5: (arg: E) => F,
    fn6: (arg: F) => G,
    fn7: (arg: G) => H,
    fn8: (arg: H) => I,
    fn9: (arg: I) => J,
    fn10: (arg: J) => K,
  ): K
  <A, B, C, D, E, F, G, H, I, J, K, L>(
    data: A,
    fn1: (arg: A) => B,
    fn2: (arg: B) => C,
    fn3: (arg: C) => D,
    fn4: (arg: D) => E,
    fn5: (arg: E) => F,
    fn6: (arg: F) => G,
    fn7: (arg: G) => H,
    fn8: (arg: H) => I,
    fn9: (arg: I) => J,
    fn10: (arg: J) => K,
    fn11: (arg: K) => L,
  ): L
  <A, B, C, D, E, F, G, H, I, J, K, L, M>(
    data: A,
    fn1: (arg: A) => B,
    fn2: (arg: B) => C,
    fn3: (arg: C) => D,
    fn4: (arg: D) => E,
    fn5: (arg: E) => F,
    fn6: (arg: F) => G,
    fn7: (arg: G) => H,
    fn8: (arg: H) => I,
    fn9: (arg: I) => J,
    fn10: (arg: J) => K,
    fn11: (arg: K) => L,
    fn12: (arg: L) => M,
  ): M
}

export const pipeline: PipelineT = (...args: AnyTypeT[]) =>
  args.reduce(<ArgT, ResultT>(arg: ArgT, fn: (arg: ArgT) => ResultT) => fn(arg))
