import type { DiscriminatorCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/discriminator/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import { DiscriminatorCustomFieldUserFormPreview } from './components/discriminator-custom-field-user-form-preview'

export const renderUserFormPreview: CustomFieldT['renderUserFormPreview'] = ({ field, value }) => {
  return (
    <DiscriminatorCustomFieldUserFormPreview
      field={field}
      value={value as undefined | DiscriminatorCustomFieldValueT}
    />
  )
}
