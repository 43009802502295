import type { QueryClient } from '@tanstack/react-query'

import { updateById } from '@resnet/client-common/common/utils/array/update-by-id'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { transform } from '@resnet/client-common/common/utils/object/transform'

import {
  useCreateCompanyViewPresetMutation,
  useCreateMyViewPresetMutation,
  useDeleteCompanyViewPresetMutation,
  useDeleteMyViewPresetMutation,
  useGetMyDefaultViewPresetQuery,
  useListCompanyViewPresetsQuery,
  useListMyViewPresetsQuery,
  useSetMyDefaultViewPresetMutation,
  useUpdateCompanyViewPresetMutation,
  useUpdateMyViewPresetMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const listMyViewPresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListMyViewPresetsQueries = () => findQueries(queryClient, useListMyViewPresetsQuery)

  const createMyViewPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateMyViewPresetMutation, {
      onSuccess: () => {
        findListMyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateMyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateMyViewPresetMutation, {
      onError: () => {
        findListMyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({
        id,
        name,
        groupBy,
        columns,
        kanbanColumns,
        ganttColumns,
        ganttTimeFrame,
        showGanttEstimatedBars,
        ganttColorBy,
      }) => {
        findListMyViewPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyViewPresets: (listMyViewPresets) =>
                transform(listMyViewPresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      columns: [columns ?? preset.columns].flat(),
                      ganttColorBy,
                      ganttColumns: [ganttColumns ?? preset.ganttColumns].flat().filter(checkNonNullable),
                      ganttTimeFrame,
                      groupBy,
                      kanbanColumns: [kanbanColumns ?? preset.kanbanColumns].flat().filter(checkNonNullable),
                      name: name ?? preset.name,
                      showGanttEstimatedBars,
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteMyViewPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteMyViewPresetMutation, {
      onError: () => {
        findListMyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListMyViewPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyViewPresets: (listMyViewPresets) =>
                transform(listMyViewPresets, { items: (items) => items.filter((viewPreset) => viewPreset.id !== id) }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateMyFilterPresetHandlers(), createMyViewPresetHandlers(), deleteMyViewPresetHandlers()]
}

export const listCompanyViewPresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListCompanyViewPresetsQueries = () => findQueries(queryClient, useListCompanyViewPresetsQuery)

  const createCompanyViewPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCompanyViewPresetMutation, {
      onSuccess: () => {
        findListCompanyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCompanyFilterPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCompanyViewPresetMutation, {
      onError: () => {
        findListCompanyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({
        id,
        name,
        groupBy,
        columns,
        kanbanColumns,
        ganttColumns,
        ganttColorBy,
        ganttTimeFrame,
        showGanttEstimatedBars,
      }) => {
        findListCompanyViewPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyViewPresets: (listCompanyViewPresets) =>
                transform(listCompanyViewPresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      columns: [columns ?? preset.columns].flat(),
                      ganttColorBy,
                      ganttColumns: [ganttColumns ?? preset.ganttColumns].flat().filter(checkNonNullable),
                      ganttTimeFrame,
                      groupBy,
                      kanbanColumns: [kanbanColumns ?? preset.kanbanColumns].flat().filter(checkNonNullable),
                      name: name ?? preset.name,
                      showGanttEstimatedBars,
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCompanyViewPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCompanyViewPresetMutation, {
      onError: () => {
        findListCompanyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListCompanyViewPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyViewPresets: (listCompanyViewPresets) =>
                transform(listCompanyViewPresets, {
                  items: (items) => items.filter((viewPreset) => viewPreset.id !== id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyViewPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateCompanyFilterPresetHandlers(), createCompanyViewPresetHandlers(), deleteCompanyViewPresetHandlers()]
}

export const getMyDefaultViewPresetMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findGetMyDefaultViewPreset = () => findQueries(queryClient, useGetMyDefaultViewPresetQuery)

  const setMyDefaultViewPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSetMyDefaultViewPresetMutation, {
      onError: () => {
        findGetMyDefaultViewPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultViewPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultViewPreset: () => id,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultViewPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteMyViewPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteMyViewPresetMutation, {
      onError: () => {
        findGetMyDefaultViewPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultViewPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultViewPreset: (getMyDefaultViewPreset) =>
                getMyDefaultViewPreset === id ? null : getMyDefaultViewPreset,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultViewPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [setMyDefaultViewPresetHandlers(), deleteMyViewPresetHandlers()]
}
