import { Tooltip } from '@mui/material'

import HelpSolidIcon from '@resnet/client-shared/assets/icons/help-solid.svg'

import { Button } from '@resnet/client-web/shared/gdl/components/button'

export const feedbackUrl = 'https://support.resnet.ai/'

export const NavBarFeedbackButton = (): React.ReactElement => {
  return (
    <Tooltip title="Support Center">
      <Button
        color="default"
        href={feedbackUrl}
        icon={<HelpSolidIcon />}
        size="sm"
        target="_blank"
        type="anchor"
        variant="contained"
      />
    </Tooltip>
  )
}
