import { checkSetHas } from '@resnet/client-common/common/utils/set/check-set-has'

import type { MinimalAssetFragmentT } from '@resnet/client-api/api'

import { assetTypenamesSet } from '../constants/asset-typenames'

export const checkIsAsset = <ValueT extends { __typename: string }>(
  value: ValueT,
): value is Extract<ValueT, Pick<MinimalAssetFragmentT, '__typename'>> => {
  return checkSetHas(assetTypenamesSet, value.__typename)
}
