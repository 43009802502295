import { useContext, useEffect, useId } from 'react'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { FocusTrapRegistryContext } from '../components/focus-trap-registry-context'

export const useFocusTrapRegistry = ({ open }: { open?: boolean }) => {
  const focusTrapRegistryContextValue = useContext(FocusTrapRegistryContext)

  const id = useId()

  assert(focusTrapRegistryContextValue, checkNonNullable, '"FocusTrapRegistryContext" is expected to not be nullable')

  const { registryRef, register } = focusTrapRegistryContextValue

  useEffect(() => {
    if (open) {
      return register(id)
    }

    return undefined
  }, [open, id, register])

  return {
    id,
    registryRef,
  }
}
