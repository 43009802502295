import { useCallbackRefEffect } from '@resnet/client-common/react/hooks/use-callback-ref-effect'
import { subscribeTimeout } from '@resnet/client-common/web/utils/async/subscribe-timeout'

export const useAutoFocusRef = (
  shouldBeFocused: boolean,
): React.RefCallback<HTMLInputElement | HTMLTextAreaElement> => {
  return useCallbackRefEffect<HTMLInputElement | HTMLTextAreaElement>(
    (node) => {
      if (!node || !shouldBeFocused) {
        return
      }

      return subscribeTimeout(() => node.focus(), 0)
    },
    [shouldBeFocused],
  )
}
