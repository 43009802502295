export const insertBetween = <ItemT, DividerT>(
  list: ItemT[],
  divider: (index: number, item: ItemT) => DividerT,
): (ItemT | DividerT)[] => {
  return list.reduce<(ItemT | DividerT)[]>((acc, item, index) => {
    acc.push(item)

    if (index < list.length - 1) {
      acc.push(divider(index, item))
    }

    return acc
  }, [])
}
