import type { SxProps } from '@mui/material'
import { Box, Modal as MUIModal } from '@mui/material'
import { Suspense, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { subscribeEvent } from '@resnet/client-common/common/utils/event/subscribe-event'
import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { MemoProvider } from '@resnet/client-common/react/components/memo-provider'
import type { HookContainerChildrenT } from '@resnet/client-common/react/utils/create-hook-container'

import { AsideHeader } from '@resnet/client-web/shared/gdl/components/aside-header'
import { Divider } from '@resnet/client-web/shared/gdl/components/divider'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { FocusTrap } from '@resnet/client-web/shared/registry/components/focus-trap'
import { ModalLayerContext } from '@resnet/client-web/shared/registry/components/modal-layer-context'
import { useLayerRegistry } from '@resnet/client-web/shared/registry/hooks/use-layer-registry'

type ModalContextT = {
  onClose?: () => void
}

export const ModalContext = createContext<null | ModalContextT>(null)

type ModalPropsT = React.ComponentProps<typeof MUIModal> & {
  onClose?: () => void
}

export const Modal = ({ onClose, open, ...rest }: ModalPropsT): React.ReactElement => {
  const { isTopMostLayer } = useLayerRegistry({ open })

  useEffect(
    () =>
      subscribeEvent<KeyboardEvent>(document, 'keydown', (event) => {
        if (event.key !== 'Escape') {
          return
        }

        onClose?.()
      }),
    [onClose],
  )

  return (
    <MemoProvider
      Context={ModalContext}
      value={{ onClose }}
    >
      <MemoProvider
        Context={ModalLayerContext}
        value={{ isTopMostLayer }}
      >
        <MUIModal
          {...rest}
          open={open}
          onClose={onClose}
        />
      </MemoProvider>
    </MemoProvider>
  )
}

type ModalContentPropsT = {
  sx?: SxProps
  children?: {
    header?: {
      title?: React.ReactNode
      subtitle?: React.ReactNode
    }
    content?: React.ReactNode
    footer?: React.ReactNode
  }
}

export const ModalContent = ({ sx = [], children }: ModalContentPropsT): React.ReactElement => {
  const { onClose } = assertedNonNullable(useContext(ModalContext))

  const renderHeader = (): React.ReactNode => {
    const header = children?.header

    return (
      <>
        <AsideHeader onClose={onClose}>{header}</AsideHeader>
        <Box sx={{ marginTop: '8px' }} />
        <Divider />
        <Box sx={{ marginTop: '24px' }} />
      </>
    )
  }

  const renderFooter = (): React.ReactNode => {
    const footer = children?.footer

    if (!footer) {
      return
    }

    return (
      <>
        <Box sx={{ marginTop: '24px' }} />
        <Divider />
        <Box sx={{ marginTop: '24px' }} />
        {footer}
      </>
    )
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: 0,
        justifyContent: 'center',
        padding: toPx(32),
      }}
    >
      <Box
        sx={[
          {
            backgroundColor: themeColors.surfaceNeutralDefault,
            borderRadius: toPx(8),
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
            padding: toPx(32),
            pointerEvents: 'auto',
          },
          sx,
        ].flat()}
      >
        {renderHeader()}
        {children?.content}
        {renderFooter()}
      </Box>
    </Box>
  )
}

export type ModalControllerT<ParamsT extends unknown = undefined> = {
  onOpen: ParamsT extends undefined ? (params?: ParamsT) => void : (params: ParamsT) => void
  onClose: () => void
}

type UseModalRenderContentT<ParamsT extends unknown = undefined> = (
  params: ParamsT,
  controller: ModalControllerT<ParamsT>,
) => React.ReactElement

type UseModalModalPropsT = Omit<ModalPropsT, 'open'>

export const useModal = <ParamsT extends unknown = undefined>(
  renderContent: (params: ParamsT, modalController: ModalControllerT<ParamsT>) => React.ReactElement,
  modalProps?: Omit<ModalPropsT, 'open'>,
) => {
  const [modal, setModal] = useState<null | { params: ParamsT }>(null)

  const onOpen = useCallback((params: ParamsT) => {
    setModal({ params })
  }, []) as ParamsT extends undefined ? (params?: ParamsT) => void : (params: ParamsT) => void

  const onClose = useCallback(() => {
    setModal(null)
  }, [])

  const modalController = useMemo(() => ({ onClose, onOpen }), [onOpen, onClose])

  const render = useCallback(() => {
    if (!modal) {
      return
    }

    const { params } = modal

    return (
      <Modal
        {...modalProps}
        open
        onClose={() => {
          setModal(null)
          modalProps?.onClose?.()
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pointerEvents: 'none',
          }}
        >
          <FocusTrap open>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                height: 0,
                outline: 'unset',
                pointerEvents: 'none',
              }}
              tabIndex={-1}
            >
              <Suspense fallback={null}>{renderContent(params, modalController)}</Suspense>
            </Box>
          </FocusTrap>
        </Box>
      </Modal>
    )
  }, [modalProps, modal, renderContent, modalController])

  return [render, modalController] as const
}

export const createModalHook =
  <ParamsT extends unknown = undefined>(renderContent: UseModalRenderContentT<ParamsT>) =>
  (modalProps?: UseModalModalPropsT) =>
    useModal(renderContent, modalProps)

export const createModalContainer =
  <ParamsT extends unknown = undefined>(
    modalHook: (modalProps?: UseModalModalPropsT) => readonly [() => React.ReactNode, ModalControllerT<ParamsT>],
  ) =>
  ({
    modalProps,
    children,
  }: {
    modalProps?: UseModalModalPropsT
    children: HookContainerChildrenT<ModalControllerT<ParamsT>>
  }): React.ReactElement => {
    const [renderModal, modalController] = modalHook(modalProps)

    return (
      <>
        {children(modalController)}
        {renderModal()}
      </>
    )
  }
