import type { UndefinedKeysT } from '@resnet/client-common/common/types/common'

export const fold = <ObjectT extends Record<string, unknown>>(
  object: ObjectT,
): ObjectT extends Record<string, undefined>
  ? undefined
  : keyof ObjectT extends UndefinedKeysT<ObjectT>
  ? undefined | ObjectT
  : ObjectT => {
  const isFoldable = Object.keys(object).every((key) => object[key] === undefined)

  if (isFoldable) {
    // @ts-ignore
    return undefined
  }

  // @ts-ignore
  return object
}
