import { useRef } from 'react'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

export const useResourcesGallery = () => {
  const refsRef = useRef<(null | { onOpen?: () => void })[]>([])

  const register = useEventCallback((index: number, ref: null | { onOpen?: () => void }) => {
    refsRef.current[index] = ref
  })

  const openNext = useEventCallback((index: number) => {
    const refs = refsRef.current

    const next = refs[index + 1]

    if (!next) {
      return
    }

    next.onOpen?.()
  })

  const openPrev = useEventCallback((index: number) => {
    const refs = refsRef.current

    const prev = refs[index - 1]

    if (!prev) {
      return
    }

    prev.onOpen?.()
  })

  return { openNext, openPrev, register }
}

export const ResourcesGalleryContainer = createHookContainer(useResourcesGallery)
