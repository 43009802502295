import type { UserFragmentT } from '@resnet/client-api/api'

import { mapUserToTitle } from './map-user-to-title'

type MediaT = { src?: string; children?: string }

// NOTE: can't extract user avatar src syncronously, but this function is deprecated so i won't fix it
export const mapUserToMedia = (user: UserFragmentT): MediaT => ({
  children: mapUserToTitle(user).toUpperCase().slice(0, 1),
  src: undefined,
})
