import { generatePath } from 'react-router-dom'

import type { SiteFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type SiteWithPathnameT = Pick<SiteFragmentT, '__typename' | 'readableId'>

export const mapSiteToPathname = <ValueT extends SiteWithPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.sitesDetails, { siteId: value.readableId })
}
