import type { QueryClient } from '@tanstack/react-query'

import { updateById } from '@resnet/client-common/common/utils/array/update-by-id'
import { transform } from '@resnet/client-common/common/utils/object/transform'

import {
  useCreateCompanyWidgetPresetMutation,
  useCreateMyWidgetPresetMutation,
  useDeleteCompanyWidgetPresetMutation,
  useDeleteMyWidgetPresetMutation,
  useGetMyDefaultWidgetPresetQuery,
  useListCompanyWidgetPresetsQuery,
  useListMyWidgetPresetsQuery,
  useSetMyDefaultWidgetPresetMutation,
  useUpdateCompanyWidgetPresetMutation,
  useUpdateMyWidgetPresetMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const listMyWidgetPresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListMyWidgetPresetsQueries = () => findQueries(queryClient, useListMyWidgetPresetsQuery)

  const createMyWidgetPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateMyWidgetPresetMutation, {
      onSuccess: () => {
        findListMyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateMyWidgetPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateMyWidgetPresetMutation, {
      onError: () => {
        findListMyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, items }) => {
        findListMyWidgetPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyWidgetPresets: (listMyWidgetPresets) =>
                transform(listMyWidgetPresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      items: [items].flat(),
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteMyWidgetPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteMyWidgetPresetMutation, {
      onError: () => {
        findListMyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListMyWidgetPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyWidgetPresets: (listMyWidgetPresets) =>
                transform(listMyWidgetPresets, {
                  items: (items) => items.filter((widgetPreset) => widgetPreset.id !== id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createMyWidgetPresetHandlers(), deleteMyWidgetPresetHandlers(), updateMyWidgetPresetHandlers()]
}

export const listCompanyWidgetPresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListCompanyWidgetPresetsQueries = () => findQueries(queryClient, useListCompanyWidgetPresetsQuery)

  const createCompanyWidgetPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCompanyWidgetPresetMutation, {
      onSuccess: () => {
        findListCompanyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCompanyWidgetPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCompanyWidgetPresetMutation, {
      onError: () => {
        findListCompanyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, items }) => {
        findListCompanyWidgetPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyWidgetPresets: (listCompanyWidgetPresets) =>
                transform(listCompanyWidgetPresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      items: [items].flat(),
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCompanyWidgetPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCompanyWidgetPresetMutation, {
      onError: () => {
        findListCompanyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListCompanyWidgetPresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyWidgetPresets: (listCompanyWidgetPresets) =>
                transform(listCompanyWidgetPresets, {
                  items: (items) => items.filter((widgetPreset) => widgetPreset.id !== id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyWidgetPresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createCompanyWidgetPresetHandlers(), deleteCompanyWidgetPresetHandlers(), updateCompanyWidgetPresetHandlers()]
}

export const getMyDefaultWidgetPresetMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findGetMyDefaultWidgetPreset = () => findQueries(queryClient, useGetMyDefaultWidgetPresetQuery)

  const setMyDefaultWidgetPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSetMyDefaultWidgetPresetMutation, {
      onError: () => {
        findGetMyDefaultWidgetPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultWidgetPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultWidgetPreset: () => id,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultWidgetPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteMyWidgetPresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteMyWidgetPresetMutation, {
      onError: () => {
        findGetMyDefaultWidgetPreset().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findGetMyDefaultWidgetPreset().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              getMyDefaultWidgetPreset: (getMyDefaultWidgetPreset) =>
                getMyDefaultWidgetPreset === id ? null : getMyDefaultWidgetPreset,
            })
          }),
        )
      },
      onSuccess: () => {
        findGetMyDefaultWidgetPreset().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [setMyDefaultWidgetPresetHandlers(), deleteMyWidgetPresetHandlers()]
}
