import { generatePath } from 'react-router-dom'

import type { GroupFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type GroupWithFeedPathnameT = Pick<GroupFragmentT, '__typename' | 'id'>

export const mapGroupToFeedPathname = <ValueT extends GroupWithFeedPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.groupsDetailsFeed, { groupId: value.id })
}
