export const selectDropdownSizesConfig = {
  gap: 4,
  listPadding: 8,
  maxVisibleOptions: 6,
  optionHeight: 40,
  optionIconSize: 16,
  optionPadding: 0,
} as const

export const selectDropdownMaxVisibleOptions = selectDropdownSizesConfig.maxVisibleOptions
