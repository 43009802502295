import type { MinimalEntityFragmentT } from '@resnet/client-api/api'

import { assetTypenames } from '../../assets/constants/asset-typenames'

export const entityTypenames: MinimalEntityFragmentT['__typename'][] = [
  ...assetTypenames,
  'Campaign',
  'Credential',
  'FormObject',
  'Insight',
  'Issue',
  'ResourceObject',
  'Route',
]

export const entityTypenamesSet = new Set(entityTypenames)
