import type { UseQueryOptions } from '@tanstack/react-query'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { ResourceFragmentT, GetResourceQueryT } from '@resnet/client-api/api'
import { useGetResourceQuery } from '@resnet/client-api/api'

export type UseResourceOptionPropsT = {
  id: string
  options?: UseQueryOptions<GetResourceQueryT, unknown, ResourceFragmentT>
}

export const useResourceOption = ({ id, options }: UseResourceOptionPropsT) => {
  const optionQuery = useGetResourceQuery({ id }, { ...options, select: (data) => data.getResource })

  return { optionQuery }
}

export const ResourceOptionContainer = createHookContainer(useResourceOption)
