import type { ApiErrorDescriptorT } from './api-error'

type HTTPClientErrorResponseT = { status: number; errors?: ApiErrorDescriptorT[] }

export class HTTPClientError extends Error {
  response: HTTPClientErrorResponseT

  constructor({ response }: { response: HTTPClientErrorResponseT }) {
    super('HTTPClientError')

    this.response = response
  }
}
