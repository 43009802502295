import { useCallback, useMemo } from 'react'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { assert } from '@resnet/client-common/typescript/utils/assert'
import { toPartial } from '@resnet/client-common/typescript/utils/to-partial'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { DiscriminatorCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/discriminator/custom-field-value'

import { mapCustomFieldToKey } from '../utils/map-custom-field-to-key'

export const useCheckUserFormItemIsNotHiddenByDiscriminatorCallback = ({
  fields,
  discriminatorValue,
}: {
  fields: CustomFieldFragmentT[]
  discriminatorValue: DiscriminatorCustomFieldValueT
}) => {
  const discriminatorField = fields.find((field) => field.type === CustomFieldTypeT.DiscriminatorT)

  const discriminatorFieldOptions = useMemo(() => {
    if (!discriminatorField) {
      return []
    }

    const payload = discriminatorField.payload

    assert(payload, checkNonNullable)

    const discriminatorPayload = payload[CustomFieldTypeT.DiscriminatorT]

    assert(discriminatorPayload, checkNonNullable)

    const { options } = discriminatorPayload

    const availableOptions = options.filter((option) => !option.isMarkedAsDeleted)

    return availableOptions
  }, [discriminatorField])

  const allDiscriminatorFieldOptionFieldsSet = useMemo(() => {
    return new Set(discriminatorFieldOptions.flatMap((option) => option.fields))
  }, [discriminatorFieldOptions])

  const discriminatorFieldOptionsByKey = useMemo(() => {
    return toPartial(propToKey('key', discriminatorFieldOptions))
  }, [discriminatorFieldOptions])

  const activeDiscriminatorFieldOptionFieldsSet = useMemo(() => {
    if (!discriminatorValue) {
      return new Set([])
    }

    const option = discriminatorFieldOptionsByKey[discriminatorValue]

    if (!option) {
      return new Set([])
    }

    return new Set(option.fields)
  }, [discriminatorFieldOptionsByKey, discriminatorValue])

  const checkUserFormItemIsNotHiddenByDiscriminator = useCallback(
    (field: CustomFieldFragmentT) => {
      return (
        !allDiscriminatorFieldOptionFieldsSet.has(mapCustomFieldToKey(field)) ||
        activeDiscriminatorFieldOptionFieldsSet.has(mapCustomFieldToKey(field))
      )
    },
    [allDiscriminatorFieldOptionFieldsSet, activeDiscriminatorFieldOptionFieldsSet],
  )

  return { checkUserFormItemIsNotHiddenByDiscriminator }
}
