import type { CustomFieldT } from '../../types/custom-field'

import { SectionHeaderCustomFieldUserFormPreview } from './components/section-header-custom-field-user-form-preview'

export const renderUserFormPreview: CustomFieldT['renderUserFormPreview'] = ({ index, field }) => {
  return (
    <SectionHeaderCustomFieldUserFormPreview
      field={field}
      index={index}
    />
  )
}
