import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { UserRolesT } from '@resnet/client-api/api'

export const userRoleOptions = [
  { id: UserRolesT.OwnerT as const, name: 'Owner' },
  { id: UserRolesT.AdminT as const, name: 'Admin' },
  { id: UserRolesT.ExecutiveT as const, name: 'Executive' },
  { id: UserRolesT.ManagerT as const, name: 'Manager' },
  { id: UserRolesT.AgentT as const, name: 'Agent' },
  { id: UserRolesT.FieldAgentT as const, name: 'Field Agent' },
  { id: UserRolesT.ContractorT as const, name: 'Contractor' },
  { id: UserRolesT.SystemT as const, name: 'System' },
]

export const userRoleOptionById = propToKey('id', userRoleOptions)

export const userRoleOptionsWithoutSystem = userRoleOptions.filter((option) => option.id !== UserRolesT.SystemT)
