import { Children, Fragment } from 'react'

import { insertBetween } from '@resnet/client-common/common/utils/array/insert-between'

export const ChildrenTransformer = ({
  with: transform,
  children,
}: {
  with: (children: React.ReactNode) => React.ReactNode
  children: React.ReactNode
}): React.ReactElement => {
  return <>{transform(children)}</>
}

export const divideChildren = (dividerNode: React.ReactNode, children: React.ReactNode) =>
  insertBetween(Children.toArray(children).filter(Boolean), (index) => (
    <Fragment key={`divider-${index}`}>{dividerNode}</Fragment>
  ))

export const ChildrenDivider = ({
  dividerNode,
  children,
}: {
  dividerNode: React.ReactNode
  children: React.ReactNode
}): React.ReactElement => {
  return <ChildrenTransformer with={(x) => divideChildren(dividerNode, x)}>{children}</ChildrenTransformer>
}

export const repeatChildren = (count: number, children: React.ReactNode) => {
  return Array.from({ length: count }, (item, index) => {
    return <Fragment key={index}>{children}</Fragment>
  })
}

export const ChildrenRepeater = ({
  count,
  children,
}: {
  count: number
  children: React.ReactNode
}): React.ReactElement => {
  return <ChildrenTransformer with={(x) => repeatChildren(count, x)}>{children}</ChildrenTransformer>
}

export const ChildrenRepeaterWithDivider = ({
  count,
  children,
  dividerNode,
}: React.ComponentProps<typeof ChildrenRepeater> &
  React.ComponentProps<typeof ChildrenDivider>): React.ReactElement => {
  return (
    <ChildrenTransformer with={(x) => divideChildren(dividerNode, repeatChildren(count, x))}>
      {children}
    </ChildrenTransformer>
  )
}
