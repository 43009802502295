import { ObjectId } from 'bson'

import { promiseAllProps } from '@resnet/client-common/common/utils/async/promise-all-props'

import type { ResourceTypesT } from '@resnet/client-api/api'

import type { DraftResourceT } from '@resnet/client-web/shared/files/types/draft-resource'

import { mapFileToUrl } from '../map-file-to-url'

export const mapFileToDraftResource = (file: File, type?: ResourceTypesT): Promise<DraftResourceT> =>
  promiseAllProps({
    __typename: '@DraftResource',
    file: file,
    filename: file.name,
    id: new ObjectId().toHexString(),
    mimetype: file.type,
    resourceType: type,
    url: mapFileToUrl(file),
  })
