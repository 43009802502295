import { Box } from '@mui/material'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import type { StateModelT } from '@resnet/client-common/react/types/state-model'

import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { Collapse } from '@resnet/client-web/shared/gdl/legacy-components/collapse'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export const MessageCardCollapsibleMessage = ({
  contentHeightModel,
  children,
}: {
  contentHeightModel?: StateModelT<null | number>
  children: (props: { contentRef: React.RefCallback<HTMLElement> }) => React.ReactNode
}) => {
  return (
    <Collapse
      contentHeightModel={contentHeightModel}
      height={40}
      sx={{ color: themeColors.overBackgroundDefault, display: 'flex', flexDirection: 'column', gap: toPx(8) }}
    >
      {{
        content: ({ contentRef }) => (
          <ErrorBoundary
            fallback={() => (
              <Box
                sx={{
                  color: themeColors.overBackgroundDefault,
                  fontSize: '18px',
                  lineHeight: '22px',
                }}
              >
                Something went wrong! The content is invalid.
              </Box>
            )}
          >
            {children({ contentRef })}
          </ErrorBoundary>
        ),
        controls: ({ isFitted, isCollapsed, onToggle }) =>
          isFitted ? null : (
            <Box sx={{ display: 'flex' }}>
              <Button
                color="primary"
                size="sm"
                variant="text"
                onClick={onToggle}
              >
                {isCollapsed ? 'Expand' : 'Collapse'}
              </Button>
            </Box>
          ),
      }}
    </Collapse>
  )
}
