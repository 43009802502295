import type { CustomFieldT } from '../../types/custom-field'

import { DiscriminatorCustomFieldPayload } from './components/discriminator-custom-field-payload'

export const renderPayload: CustomFieldT['renderPayload'] = ({ form, formFieldsName, name }) => {
  return (
    <DiscriminatorCustomFieldPayload
      form={form}
      formFieldsName={formFieldsName}
      name={name}
    />
  )
}
