import type { QueryKey } from '@tanstack/react-query'
import { parseQueryArgs } from '@tanstack/react-query'
import { useQuery as useQueryActual, useInfiniteQuery as useInfiniteQueryActual } from '@tanstack/react-query'

export * from '@tanstack/react-query'

export const useQueryEffectRef: { current?: (query: QueryKey) => void } = {}

export const useQuery = ((...args: Parameters<typeof parseQueryArgs>) => {
  const options = parseQueryArgs(...args)

  const query = useQueryActual(options)

  useQueryEffectRef.current?.(options.queryKey as QueryKey)

  return query
}) as typeof useQueryActual

export const useInfiniteQuery = ((...args: Parameters<typeof parseQueryArgs>) => {
  const options = parseQueryArgs(...args)

  const query = useInfiniteQueryActual(options)

  useQueryEffectRef.current?.(options.queryKey as QueryKey)

  return query
}) as typeof useInfiniteQueryActual
