import type { Mutation } from '@resnet/client-api/vendors/react-query'

import type { MutationHookT } from '../types/mutation-hook'

export const mutationHookMatches = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationHook: MutationHookT<TData, TError, TVariables, TContext>,
  mutation: Mutation,
): boolean => {
  const mutationKey = mutation.options.mutationKey

  if (!mutationKey) {
    return false
  }

  const mutationHookKey = mutationHook.getKey()

  if (mutationHookKey[0] !== mutationKey[0]) {
    return false
  }

  return true
}
