import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

import type { SkeletonPropsT } from '../skeleton'
import { Skeleton } from '../skeleton'

export type CircularSkeletonPropsT = MergeAllT<[Omit<SkeletonPropsT, 'children'>, { size: number }]>

export const CircularSkeleton = ({ size, sx = null, ...props }: CircularSkeletonPropsT) => {
  return (
    <Skeleton
      {...props}
      sx={[
        {
          aspectRatio: '1',
          backgroundColor: themeColors.overBackgroundDefault,
          borderRadius: '50%',
          maxWidth: '100%',
          width: toPx(size),
        },
        sx,
      ].flat()}
    />
  )
}
