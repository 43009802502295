import { flattenRoutes } from '../utils/flatten-routes'

import { awardDetailsScreenRoutes } from './award-details-screen-routes'
import { gameModeDetailsScreenRoutes } from './game-mode-details-screen-routes'
import { gamificationCatalogScreenRoutes } from './gamification-catalog-screen-routes'

export const gamificationScreenRoutes = flattenRoutes({
  award: ['/award', awardDetailsScreenRoutes],
  catalog: ['/', gamificationCatalogScreenRoutes],
  gameMode: ['/game-mode/:gameModeId', gameModeDetailsScreenRoutes],
})
