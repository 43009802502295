import { Box } from '@mui/material'

export const TextOverflow = ({ sx, children, ...props }: React.ComponentProps<typeof Box>): React.ReactElement => {
  return (
    <Box
      {...props}
      component="span"
      sx={[
        {
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        sx ?? null,
      ].flat()}
    >
      {children}
    </Box>
  )
}
