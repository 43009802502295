import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type {
  AttachmentSingleCustomFieldValueT,
  AttachmentMultipleCustomFieldValueT,
  AttachmentCustomFieldValueT,
} from '@resnet/client-shared/shared/custom-fields/presets/attachment/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type {
  AttachmentFormCustomFieldValueT,
  AttachmentMultipleFormCustomFieldValueT,
  AttachmentSingleFormCustomFieldValueT,
} from './form-custom-field-value'

export const mapFormCustomFieldValueToCustomFieldValue: CustomFieldT<
  AttachmentCustomFieldValueT,
  AttachmentFormCustomFieldValueT
>['mapFormCustomFieldValueToCustomFieldValue'] = ({ value, field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const attachmentPayload = payload[CustomFieldTypeT.AttachmentT]

  assert(attachmentPayload, checkNonNullable)

  const { multiple } = attachmentPayload

  if (multiple) {
    const attachmentMultipleFormFieldValue = value as AttachmentMultipleFormCustomFieldValueT

    const attachmentMultipleFieldValue: AttachmentMultipleCustomFieldValueT = attachmentMultipleFormFieldValue.map(
      (item) => item.id,
    )

    return attachmentMultipleFieldValue
  }

  const attachmentSingleFormFieldValue = value as AttachmentSingleFormCustomFieldValueT

  const singleAttachmentFieldValue: AttachmentSingleCustomFieldValueT = !attachmentSingleFormFieldValue
    ? attachmentSingleFormFieldValue
    : attachmentSingleFormFieldValue.id

  return singleAttachmentFieldValue
}
