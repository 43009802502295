import { toUpperCaseFirst } from '@resnet/client-common/common/utils/string/to-upper-case-first'

import { DEFAULT_MESSAGE } from '../constants/messages'
import { ApiError } from '../errors/api-error'
import { ValidationError } from '../errors/validation-error'

import type { ValidationErrorT } from './check-is-validation-error'
import { checkIsValidationError } from './check-is-validation-error'

const mapValidationErrorToMessage = (error: ValidationErrorT): string => {
  if (error.data[0].instancePath) {
    return `${toUpperCaseFirst(error.data[0].instancePath.slice(1))} ${error.data[0].message}`
  }

  return error.data[0].message
}

export const mapErrorToMessage = (error: unknown): string => {
  if (error instanceof ValidationError) {
    return error.message
  }

  if (error instanceof ApiError) {
    if (checkIsValidationError(error)) {
      return mapValidationErrorToMessage(error)
    }

    return error.message
  }

  return DEFAULT_MESSAGE
}
