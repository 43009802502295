import type { MutationHookT } from '@resnet/client-api/types/mutation-hook'
import { mutationHookMatches } from '@resnet/client-api/utils/mutation-hook-matches-mutation'
import type { Mutation } from '@resnet/client-api/vendors/react-query'

import type { MutationCacheHandlersT } from '../types/mutation-cache-handlers'

export const createMutationHookMutationCacheHandlers = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationHook: MutationHookT<TData, TError, TVariables, TContext>,
  mutationCacheHandlers: MutationCacheHandlersT<TData, TError, TVariables, TContext>,
): MutationCacheHandlersT => {
  const mutationMatches = (mutation: Mutation): boolean => mutationHookMatches(mutationHook, mutation)

  return {
    onError: (error, variables, context, mutation) => {
      if (!mutationMatches(mutation)) {
        return
      }

      mutationCacheHandlers.onError?.(
        error as unknown as TError,
        variables as unknown as TVariables,
        context as unknown as TContext,
        mutation as unknown as Mutation<TData, TError, TVariables, TContext>,
      )
    },
    onMutate: (variables, mutation) => {
      if (!mutationMatches(mutation)) {
        return
      }

      mutationCacheHandlers.onMutate?.(
        variables as unknown as TVariables,
        mutation as unknown as Mutation<TData, TError, TVariables, TContext>,
      )
    },
    onSuccess: (data, variables, context, mutation) => {
      if (!mutationMatches(mutation)) {
        return
      }

      mutationCacheHandlers.onSuccess?.(
        data as unknown as TData,
        variables as unknown as TVariables,
        context as unknown as TContext,
        mutation as unknown as Mutation<TData, TError, TVariables, TContext>,
      )
    },
  }
}
