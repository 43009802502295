import { Box } from '@mui/material'
import type { UseFormReturn } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { EntityTypeIdsT } from '@resnet/client-api/api'

import LabelSolidIcon from '@resnet/client-shared/assets/icons/label-solid.svg'
import PlusSolidIcon from '@resnet/client-shared/assets/icons/plus-solid.svg'

import { SimpleTextInputModalContainer } from '@resnet/client-web/shared/common/components/simple-text-input-modal'
import { EntityLabelsSelectDropdownContainer } from '@resnet/client-web/shared/entities/hooks/use-entity-labels-select-dropdown'
import { CreateNewButton } from '@resnet/client-web/shared/gdl/components/create-new-button'
import { Field } from '@resnet/client-web/shared/gdl/components/field'
import { Popover } from '@resnet/client-web/shared/gdl/components/popover'
import type { PopperPropsT } from '@resnet/client-web/shared/gdl/components/popper'
import { Popper, PopperContext } from '@resnet/client-web/shared/gdl/components/popper'
import { SelectSearch } from '@resnet/client-web/shared/gdl/components/select-dropdown/components/select-search'
import { SelectMultipleButton } from '@resnet/client-web/shared/gdl/components/select-multiple-button'
import { SelectMultipleDropdown } from '@resnet/client-web/shared/gdl/components/select-multiple-dropdown'

export type EntityLabelsSelectMultipleFieldPropsT = MergeAllT<
  [
    Omit<PopperPropsT, 'children'>,
    {
      form: UseFormReturn<{ labels?: string[] }>
      entityTypeId: EntityTypeIdsT
    },
  ]
>

export const EntityLabelsMultipleSelectField = ({
  form,
  entityTypeId,
  ...props
}: EntityLabelsSelectMultipleFieldPropsT) => {
  const renderAnchor = (): React.ReactElement => {
    return (
      <Field sx={{ flexGrow: 1, width: 0 }}>
        <Box sx={{ height: '24px' }} />
        <NonNullableContextContainer Context={PopperContext}>
          {({ setAnchorEl, open }) => (
            <Controller
              control={form.control}
              name="labels"
              render={({ field }) => (
                <SelectMultipleButton
                  buttonIcon={<PlusSolidIcon />}
                  buttonSize="md"
                  itemIcon={<LabelSolidIcon />}
                  itemName="Label"
                  items={field.value}
                  label="Add Label"
                  ref={setAnchorEl}
                  onClick={open}
                />
              )}
            />
          )}
        </NonNullableContextContainer>
      </Field>
    )
  }

  const renderContent = (): React.ReactElement => {
    return (
      <SimpleTextInputModalContainer>
        {({ onOpen, onClose }) => (
          <Popover>
            <Controller
              control={form.control}
              name="labels"
              render={({ field }) => (
                <EntityLabelsSelectDropdownContainer
                  entityTypeId={entityTypeId}
                  value={field.value}
                >
                  {({ dropdownProps, searchProps }) => (
                    <SelectMultipleDropdown
                      {...dropdownProps}
                      header={
                        <CreateNewButton
                          onClick={() => {
                            onOpen({
                              confirmActionLabel: 'Create',
                              label: 'Label Name',
                              onSubmit: ({ value }) => {
                                field.onChange([value, ...(field.value ?? [])])
                                onClose()
                              },
                              placeholder: 'Type in here',
                              title: 'Create New Label',
                            })
                          }}
                        />
                      }
                      search={<SelectSearch {...searchProps} />}
                      value={field.value ?? []}
                      onChange={field.onChange}
                    />
                  )}
                </EntityLabelsSelectDropdownContainer>
              )}
            />
          </Popover>
        )}
      </SimpleTextInputModalContainer>
    )
  }

  return (
    <Popper {...props}>
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}
