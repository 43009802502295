import type { messageTypeOptions } from '../constants/message-type-options'
import type { MessageT } from '../types/message'

export const mapMessageToMessageType = (message: MessageT): (typeof messageTypeOptions)[number]['id'] => {
  switch (message.__typename) {
    case 'Comment': {
      return 'comment'
    }
    case 'RegularPost': {
      return 'post'
    }
    case 'BotPost': {
      return 'post'
    }
  }
}
