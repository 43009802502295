import { type UseFormReturn, useController } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { type EntityAccessT } from '@resnet/client-api/api'

import { userRoleOptionById } from '@resnet/client-shared/shared/users/constants/user-role-options'
import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import {
  EntityPermitRecipientsSelectDropdownContainer,
  mapOptionsToEntityPermitRecipients,
} from '@resnet/client-web/shared/entities/hooks/use-entity-permit-recipients-select-dropdown-westfall'
import {
  DropdownInput,
  DropdownInputText,
  DropdownInputTextSkeleton,
} from '@resnet/client-web/shared/gdl/components/dropdown-input'
import { renderCount } from '@resnet/client-web/shared/gdl/components/dropdown-input/utils/render-count'
import { Field } from '@resnet/client-web/shared/gdl/components/field'
import { FieldErrorText } from '@resnet/client-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-web/shared/gdl/components/field'
import { Popover } from '@resnet/client-web/shared/gdl/components/popover'
import { Popper, PopperContext } from '@resnet/client-web/shared/gdl/components/popper'
import { SelectSearch } from '@resnet/client-web/shared/gdl/components/select-dropdown/components/select-search'
import { SelectMultipleDropdown } from '@resnet/client-web/shared/gdl/components/select-multiple-dropdown'

type FieldsT = { access?: null | Omit<EntityAccessT, '__typename'> }

type AccessControlFieldsPropsT = {
  form: UseFormReturn<FieldsT>
}

const MultipleDropdownTextHybrid = ({
  value,
}: {
  value: undefined | null | Omit<EntityAccessT, '__typename'>
}): React.ReactElement => {
  const optionsTotal = (value?.roles?.length ?? 0) + (value?.users?.length ?? 0)

  if (optionsTotal === 0) {
    return <DropdownInputText>Everyone</DropdownInputText>
  }

  if (optionsTotal > 1) {
    return <DropdownInputText>{renderCount(optionsTotal)}</DropdownInputText>
  }

  if (value?.roles?.length) {
    return <DropdownInputText>{userRoleOptionById[value.roles[0]].name}</DropdownInputText>
  }

  const userId = value?.users?.[0]

  assert(userId, checkNonNullable, 'user should be defined')

  return (
    <UserOptionContainer id={userId}>
      {({ optionQuery }) => {
        if (!optionQuery.isSuccess) {
          return <DropdownInputTextSkeleton />
        }

        const option = optionQuery.data

        return <DropdownInputText>{mapUserToTitle(option)}</DropdownInputText>
      }}
    </UserOptionContainer>
  )
}

export const EntityPermitRecipientsSelectField = ({ form }: AccessControlFieldsPropsT) => {
  const { field, fieldState } = useController({
    control: form.control,
    name: 'access',
    rules: {
      validate: (value) => {
        if (!value?.private) {
          return true
        }

        if (!value?.roles?.length && !value?.users?.length) {
          return 'This field is required if access set to private'
        }

        return true
      },
    },
  })

  const accessPrivate = field.value?.private

  const accessRoles = field.value?.roles

  const accessUsers = field.value?.users

  const renderAnchor = () => {
    return (
      <Field sx={{ flexGrow: 1, width: 0 }}>
        <FieldLabel>Users & Roles</FieldLabel>
        <NonNullableContextContainer Context={PopperContext}>
          {({ setAnchorEl, isOpened, open }) => (
            <DropdownInput
              disabled={!accessPrivate}
              hasError={fieldState.invalid}
              isOpened={isOpened}
              ref={setAnchorEl}
              onClick={open}
            >
              <MultipleDropdownTextHybrid value={field.value} />
            </DropdownInput>
          )}
        </NonNullableContextContainer>
        {!fieldState.invalid ? null : <FieldErrorText>{fieldState.error?.message}</FieldErrorText>}
      </Field>
    )
  }

  const renderContent = () => {
    return (
      <Popover>
        <EntityPermitRecipientsSelectDropdownContainer>
          {({ searchProps, dropdownProps }) => (
            <SelectMultipleDropdown
              {...dropdownProps}
              search={<SelectSearch {...searchProps} />}
              value={[...(accessRoles ?? []), ...(accessUsers ?? [])]}
              onChange={(value) => {
                field.onChange({
                  ...mapOptionsToEntityPermitRecipients(value),
                  private: field.value?.private,
                })
              }}
            />
          )}
        </EntityPermitRecipientsSelectDropdownContainer>
      </Popover>
    )
  }

  return (
    <Popper onClose={field.onBlur}>
      {{
        anchor: () => renderAnchor(),
        content: () => renderContent(),
      }}
    </Popper>
  )
}
