import { generatePath } from 'react-router-dom'

import type { ResourceObjectFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type ResourceObjectWithFeedPathnameT = Pick<ResourceObjectFragmentT, '__typename' | 'readableId'>

export const mapResourceObjectToFeedPathname = <ValueT extends ResourceObjectWithFeedPathnameT>(
  value: ValueT,
): string => {
  return generatePath(rootScreenRoutes.resourceObjectsDetailsFeed, { resourceObjectId: value.readableId })
}
