import type { DraftResourceT } from '../../types/draft-resource'

const draftResourceTypename: DraftResourceT['__typename'] = '@DraftResource'

export const checkIsDraftResource = <ResourceT extends { __typename: string }>(
  resource: ResourceT,
): resource is Extract<ResourceT, { __typename: typeof draftResourceTypename }> => {
  return resource.__typename === draftResourceTypename
}

export const checkIsNotDraftResource = <ResourceT extends { __typename: string }>(
  resource: ResourceT,
): resource is Exclude<ResourceT, { __typename: typeof draftResourceTypename }> => {
  return resource.__typename !== draftResourceTypename
}
