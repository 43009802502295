import { useLocalStorageState } from '@resnet/client-common/react/hooks/use-local-storage-state'
import { useMapStateModel } from '@resnet/client-common/react/hooks/use-map-state-model'

import { type colorSchemeOptions } from '../constants/color-scheme-options'

export const useColorSchemeModel = () => {
  const colorSchemeModelActual = useLocalStorageState<(typeof colorSchemeOptions)[number]['id']>('color-scheme')

  const colorSchemeModel = useMapStateModel(
    colorSchemeModelActual,
    (inputState) => inputState ?? 'dark',
    (outputState) => outputState,
  )

  return colorSchemeModel
}
