import { generatePath } from 'react-router-dom'

import type { CredentialFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type CredentialWithFeedPathnameT = Pick<CredentialFragmentT, '__typename' | 'readableId'>

export const mapCredentialToFeedPathname = <ValueT extends CredentialWithFeedPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.credentialsDetailsFeed, { credentialId: value.readableId })
}
