import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import type { ResourceT } from '@resnet/client-shared/shared/files/types/resource'

import { mapFileToDraftResource } from '../../utils/map-file-to-draft-resource'

export const useFileInputChangeHandler = ({
  setResources,
}: {
  setResources: React.Dispatch<React.SetStateAction<ResourceT[]>>
}) => {
  const onFileInputChange = useEventCallback(async (event: React.FormEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget

    if (!files) {
      return
    }

    const attachedResources = await pipeline(
      files,
      (x) => Array.from(x),
      (x) => x.map((file) => mapFileToDraftResource(file)),
      (x) => Promise.all(x),
    )

    setResources((resources) => resources.concat(attachedResources))
  })

  return { onFileInputChange }
}
