import { useEventCallback } from './use-event-callback'

export const useBooleanHandlers = ({ setState }: { setState: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const onEnable = useEventCallback(() => setState(true))

  const onDisable = useEventCallback(() => setState(false))

  const onToggle = useEventCallback(() => setState((state) => !state))

  return { onDisable, onEnable, onToggle }
}
