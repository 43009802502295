export const filterValues = <ObjectT extends Record<string, unknown>, ResultValueT extends ObjectT[keyof ObjectT]>(
  predicate: (value: ObjectT[keyof ObjectT], key: string, object: ObjectT) => value is ResultValueT,
  object: ObjectT,
) => {
  const result = {} as Record<string, unknown>

  for (const key of Object.keys(object)) {
    const value = object[key] as unknown as ObjectT[keyof ObjectT]

    if (predicate(value, key, object)) {
      result[key] = value
    }
  }

  return result as { [K in keyof ObjectT]: Extract<ObjectT[K], ResultValueT> }
}
