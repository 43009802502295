import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import { forwardRef } from 'react'

import { mapValues } from '@resnet/client-common/common/utils/object/map-values'
import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import { propsBySize, propsByStatus } from '@resnet/client-shared/shared/gdl/constants/tag-props'

import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

import { toPx } from '../../utils/to-px'
import { TextOverflow } from '../text-overflow'

export type TagPropsT = MergeT<
  BoxProps,
  {
    endIcon?: ForwardableT<React.SVGProps<SVGSVGElement>>
    icon?: ForwardableT<React.SVGProps<SVGSVGElement>>
    size?: keyof typeof propsBySize
    status?: keyof typeof propsByStatus
    uppercase?: boolean
    variant?: boolean
    children?: React.ReactNode
    customColors?: {
      backgroundColor?: string
      color?: string
      borderColor?: string
    }
  }
>

export const Tag = forwardRef(
  (
    {
      endIcon,
      icon,
      size = 'md',
      status = 'default',
      uppercase = false,
      variant = false,
      sx = null,
      children,
      customColors,
      ...props
    }: TagPropsT,
    ref,
  ): React.ReactElement => {
    const { fontSize, fontWeight, iconOffset, iconSize, letterSpacing, paddingHorizontal, paddingVertical } =
      propsBySize[size]

    const { backgroundColor, borderColor, color } = {
      ...mapValues((color) => themeColors[color], propsByStatus[status]),
      ...customColors,
    }

    const renderIcon = (): React.ReactNode => {
      if (!icon) {
        return null
      }

      return renderForwardable(icon, {
        fill: color,
        height: toPx(iconSize),
        width: toPx(iconSize),
      })
    }

    const renderLabel = (): React.ReactNode => {
      if (!children) {
        return null
      }

      return (
        <TextOverflow
          sx={{
            color,
            flexShrink: 1,
            fontSize: toPx(fontSize),
            fontWeight,
            letterSpacing,
            lineHeight: toPx(iconSize),
            textTransform: uppercase ? 'uppercase' : 'none',
          }}
        >
          {children}
        </TextOverflow>
      )
    }

    const renderEndIcon = (): React.ReactNode => {
      if (!endIcon) {
        return null
      }

      return renderForwardable(endIcon, {
        fill: color,
        height: toPx(iconSize),
        width: toPx(iconSize),
      })
    }

    const renderOutline = () => (
      <Box
        sx={{
          border: `1px solid ${borderColor}`,
          borderRadius: toPx(999),
          inset: 0,
          pointerEvents: 'none',
          position: 'absolute',
        }}
      />
    )

    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          {
            alignItems: 'center',
            backgroundColor: variant ? themeColors.surfaceVariantDefault : backgroundColor,
            borderRadius: toPx(999),
            display: 'inline-flex',
            gap: toPx(iconOffset),
            justifyContent: 'center',
            overflow: 'hidden',
            paddingX: toPx(paddingHorizontal),
            paddingY: toPx(paddingVertical),
            position: 'relative',
          },
          sx,
        ].flat()}
      >
        {renderOutline()}
        {renderIcon()}
        {renderLabel()}
        {renderEndIcon()}
      </Box>
    )
  },
)
