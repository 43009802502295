import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { fullDateTimeFormatter } from '@resnet/client-common/format/utils/date/full-date-time-formatter'

import type { ApprovalLevelT, ApproverT, UserQueryVariablesT } from '@resnet/client-api/api'
import { useUserQuery } from '@resnet/client-api/api'
import { ApproveStatusT } from '@resnet/client-api/api'

import MinusSolidIcon from '@resnet/client-shared/assets/icons/minus-solid.svg'
import { mapApprovalStatusToLabel } from '@resnet/client-shared/shared/issues/utils/map-approval-status-to-label'
import { mapUserToMedia } from '@resnet/client-shared/shared/users/utils/map-user-to-media'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { Avatar } from '@resnet/client-web/shared/gdl/legacy-components/avatar'
import { mapUserToPathname } from '@resnet/client-web/shared/users/utils/map-user-to-pathname'

import { IssueApprovalTag } from '../issue-approval-tag'

export const Approver = ({
  approver,
  onRemove,
  level,
  isAwaiting,
  isPreview,
}: {
  onRemove: (userId: string) => void
  approver: ApproverT
  level: ApprovalLevelT & { isDraft?: boolean }
  isAwaiting: boolean
  isPreview?: boolean
}) => {
  const userQuery = useUserQuery({ id: approver.id } as UserQueryVariablesT, {
    select: (data) => data.getUser,
  })

  if (!userQuery.data) {
    return null
  }

  const name = mapUserToTitle(userQuery.data)

  const media = mapUserToMedia(userQuery.data)

  // NOTE this mimics 4th status which does not exists on BE, but is present in UI
  const levelApprovalStatusLabel =
    isAwaiting && approver.status === ApproveStatusT.PendingT
      ? { name: 'Awaiting approval' }
      : mapApprovalStatusToLabel(approver)

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        height: '36px',
        justifyContent: 'space-between',
      }}
    >
      <Box
        component={Link}
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          textDecoration: 'none',
        }}
        to={mapUserToPathname(userQuery.data)}
      >
        <Avatar
          alt={name}
          src={media.src}
          sx={{ alignItems: 'center', height: '32px', width: '32px' }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', justifyItems: 'center' }}>
          <Box
            sx={{
              color: themeColors.overBackgroundBold,
              fontSize: '14px',
              lineHeight: '120%',
            }}
          >
            {name}
          </Box>
          {approver.status === ApproveStatusT.PendingT || !approver.updatedAt ? null : (
            <Box
              sx={{
                color: themeColors.overBackgroundMuted,
                fontSize: '12px',
                lineHeight: '120%',
              }}
            >
              {`${mapApprovalStatusToLabel(approver).name} on ${fullDateTimeFormatter.format(
                new Date(approver.updatedAt),
              )}`}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
        {level.isDraft ? null : (
          <IssueApprovalTag
            {...levelApprovalStatusLabel}
            size="sm"
          />
        )}
        {isPreview ? null : (
          <Button
            color="primary"
            icon={<MinusSolidIcon />}
            size="md"
            sx={{ alignSelf: 'flex-start' }}
            variant="text"
            onClick={() => onRemove(approver.id)}
          />
        )}
      </Box>
    </Box>
  )
}
