import type { FormulaCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/formula/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import { FormulaFormUserFormPreview } from './components/formula-custom-field-user-form-preview'

export const renderUserFormPreview: CustomFieldT['renderUserFormPreview'] = ({ field, value }) => {
  return (
    <FormulaFormUserFormPreview
      field={field}
      value={value as undefined | FormulaCustomFieldValueT}
    />
  )
}
