import { Parser } from 'expr-eval'

import { MILLISECONDS_IN_DAY } from '@resnet/client-common/common/constants/time'

export const expressionParser = new Parser()

expressionParser.functions.toTimestamp = (date: string): number => new Date(date).getTime()

expressionParser.functions.now = (): number => Date.now()

expressionParser.functions.days = (timestamp: number) => Math.floor(timestamp / MILLISECONDS_IN_DAY)

export const expressionParserReservedKeys = [
  // pre-defined functions
  'random',
  // eslint-disable-next-line spellcheck/spell-checker
  'fac',
  'min',
  'max',
  'hypot',
  // eslint-disable-next-line spellcheck/spell-checker
  'pyt',
  'pow',
  'atan2',
  'roundTo',
  'map',
  'fold',
  'filter',
  'indexOf',
  'join',
  'if',
  // custom functions
  'toTimestamp',
  'now',
  'days',
]
