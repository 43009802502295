import { GraphQLClient } from 'graphql-request'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

const getGraphqlClientBaseUrl = (tenant: string): string => {
  const baseUrl = process.env.PUBLIC_API_ENDPOINT

  if (baseUrl !== undefined) {
    return baseUrl
  }

  switch (tenant) {
    case 'dev': {
      return 'https://api.dev.resnet.ai'
    }
    case 'demo':
    case 'test': {
      return 'https://api.staging.resnet.ai'
    }
    default: {
      return 'https://api.resnet.ai'
    }
  }
}

let graphQlClient: null | GraphQLClient = null

export const getGraphQlClient = (): GraphQLClient => {
  return assertedNonNullable(graphQlClient)
}

export const setGraphQlClient = (tenant: string): void => {
  graphQlClient = new GraphQLClient(getGraphqlClientBaseUrl(tenant) + '/graphql')
}
