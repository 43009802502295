import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { assertedNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'

import { checkIsDraftResource } from '@resnet/client-shared/shared/files/utils/check-is-draft-resource'

import type { CreateIssueFormValuesT } from '../types/create-issue-form-values'

export const mapCreateIssueFormValuesToAttachments = ({ values }: { values: CreateIssueFormValuesT }) => ({
  attachments: [pipeline(values.attachments, assertedNonUndefined)].flat().filter(checkIsDraftResource),
})
