import { NETWORK_STATUS } from '../constants/network-status'
import { HTTPClientError } from '../errors/http-client-error'

export type HTTPClientT = {
  get: <DataT>(url: string, config: RequestInit) => Promise<DataT>
  post: <DataT>(url: string, body: BodyInit | null, config?: RequestInit) => Promise<DataT>
}

const request = async <DataT>(endpoint: string, config?: RequestInit): Promise<DataT> => {
  const response = await fetch(endpoint, config)

  const data = await response.json()

  if (response.status !== NETWORK_STATUS.OK || data?.errors) {
    throw new HTTPClientError({ response: { errors: data?.errors, status: response.status } })
  }

  return data
}

export const createHTTPClient = (baseURL: string): HTTPClientT => {
  return {
    get: (url, config) => request(baseURL + url, { method: 'GET', ...config }),
    post: (url, body, config) => {
      return request(baseURL + url, { body, method: 'POST', ...config })
    },
  }
}
