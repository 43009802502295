import { NETWORK_STATUS } from '@resnet/client-common/network/constants/network-status'

export type ApiErrorDescriptorT = {
  name?: string
  code?: string
  statusCode?: number
  message?: string
  data?: null | unknown
}

export class ApiError extends Error {
  code: string
  statusCode: number
  data: null | unknown

  constructor({
    code = 'AnUnknownError',
    statusCode = NETWORK_STATUS.INTERNAL_SERVER_ERROR,
    message = '',
    data,
  }: ApiErrorDescriptorT = {}) {
    super(code)

    this.name = code
    this.code = code
    this.statusCode = statusCode
    this.message = message
    this.data = !data ? null : typeof data !== 'string' ? data : JSON.parse(data)
  }
}
