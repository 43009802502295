import type { QueryClient } from '@tanstack/react-query'
import produce from 'immer'

import {
  useGetCompanyRoleQuery,
  useDeleteCompanyRoleMutation,
  useInfiniteListCompanyRolesQuery,
  useCreateCompanyRoleMutation,
  useUpdateCompanyRoleMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const companyRoleQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findCompanyRoleQueries = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetCompanyRoleQuery, (data) => data.getCompanyRole, { id })

  const updateCompanyRoleHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCompanyRoleMutation, {
      onError: (error, { id }) => {
        findCompanyRoleQueries({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findCompanyRoleQueries({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const companyRole = draft?.getCompanyRole

              if (!companyRole) {
                return
              }

              Object.assign(companyRole, data)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCompanyRoleQueries({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCompanyRoleHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCompanyRoleMutation, {
      onSuccess: (data, { id }) => {
        findCompanyRoleQueries({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateCompanyRoleHandlers(), deleteCompanyRoleHandlers()]
}

export const infiniteCompanyRolesQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteCompanyRolesQueries = () => findInfiniteQueries(queryClient, useInfiniteListCompanyRolesQuery)

  const createCompanyRoleHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCompanyRoleMutation, {
      onSuccess: () => {
        findInfiniteCompanyRolesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCompanyRoleHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCompanyRoleMutation, {
      onSuccess: () => {
        findInfiniteCompanyRolesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCompanyRoleHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCompanyRoleMutation, {
      onSuccess: () => {
        findInfiniteCompanyRolesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createCompanyRoleHandlers(), updateCompanyRoleHandlers(), deleteCompanyRoleHandlers()]
}
