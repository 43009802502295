import { feedRoutes } from '@resnet/client-shared/shared/origins/routes/feed-routes'

export const campaignDetailsScreenRoutes = {
  analytics: '/analytics',
  attachments: '/attachments',
  feed: feedRoutes,
  issues: '/issues',
  relations: '/relations',
  scheduling: '/scheduling',
}
