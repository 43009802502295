import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import AngleDownSolidIcon from '@resnet/client-shared/assets/icons/angle-down-solid.svg'
import AngleUpSolidIcon from '@resnet/client-shared/assets/icons/angle-up-solid.svg'

export const DropdownIcon = forwardFunctionalComponentRef(
  (
    { isOpened, ...props }: Omit<React.ComponentProps<SVGComponentT>, 'isOpened'> & { isOpened?: boolean },
    ref: React.Ref<SVGSVGElement>,
  ): React.ReactElement => {
    const Icon = isOpened ? AngleUpSolidIcon : AngleDownSolidIcon

    return (
      <Icon
        {...props}
        ref={ref}
      />
    )
  },
)
