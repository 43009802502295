import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { ColorPresetT } from '@resnet/client-api/api'

export const pickerColorPresets = [
  {
    id: ColorPresetT.Color_1T as const,
    name: 'Color 1',
    value: '#1C6D41',
  },
  {
    id: ColorPresetT.Color_2T as const,
    name: 'Color 2',
    value: '#2D135A',
  },
  {
    id: ColorPresetT.Color_3T as const,
    name: 'Color 3',
    value: '#052B5C',
  },
  {
    id: ColorPresetT.Color_4T as const,
    name: 'Color 4',
    value: '#6B551B',
  },
  {
    id: ColorPresetT.Color_5T as const,
    name: 'Color 5',
    value: '#8A4302',
  },
  {
    id: ColorPresetT.Color_6T as const,
    name: 'Color 6',
    value: '#682622',
  },
  {
    id: ColorPresetT.Color_7T as const,
    name: 'Color 7',
    value: '#0F446D',
  },
  {
    id: ColorPresetT.Color_8T as const,
    name: 'Color 8',
    value: '#44484C',
  },
]

export const pickerColorPresetById = propToKey('id', pickerColorPresets)
