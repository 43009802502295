import { FocusTrap as MuiFocusTrap } from '@mui/base/FocusTrap'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import { useFocusTrapRegistry } from '../../hooks/use-focus-trap-registry'

export const FocusTrap = ({
  children,
  isEnabled: isEnabledProp,
  open,
  ...props
}: React.ComponentProps<typeof MuiFocusTrap>): React.ReactElement => {
  const { registryRef, id } = useFocusTrapRegistry({ open })

  const checkIsFocusTrapEnabled = useEventCallback(() => {
    if (registryRef.current.size < 2) {
      return isEnabledProp?.() ?? true
    }

    const lastId = Array.from(registryRef.current)[registryRef.current.size - 1]

    return lastId === id
  })

  return (
    <MuiFocusTrap
      {...props}
      isEnabled={checkIsFocusTrapEnabled}
      open={open}
    >
      {children}
    </MuiFocusTrap>
  )
}
