import { checkIsEntity } from '../../entities/utils/check-is-entity'
import type { EntityWithTitleT } from '../../entities/utils/map-entity-to-title'
import { mapEntityToTitle } from '../../entities/utils/map-entity-to-title'
import { checkIsGroup } from '../../groups/utils/check-is-group'
import type { GroupWithTitleT } from '../../groups/utils/map-group-to-title'
import { mapGroupToTitle } from '../../groups/utils/map-group-to-title'

export type OriginWithTitleT = EntityWithTitleT | GroupWithTitleT

export const mapOriginToTitle = <ValueT extends OriginWithTitleT>(value: ValueT): string => {
  if (checkIsGroup(value)) {
    return mapGroupToTitle(value)
  }

  if (checkIsEntity(value)) {
    return mapEntityToTitle(value)
  }

  throw new Error(`Unknown origin type: ${value.__typename}`)
}
