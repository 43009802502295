import { useEventCallback, Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, PathValue, RegisterOptions, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { ResourceTypesT } from '@resnet/client-api/api'

import SignatureSolidIcon from '@resnet/client-shared/assets/icons/signature-2-solid.svg'

import type { ResourceT } from '@resnet/client-web/shared/files/types/resource'
import { mapDataUrlToDraftResource } from '@resnet/client-web/shared/files/utils/map-data-url-to-draft-resource'
import { Accordion } from '@resnet/client-web/shared/gdl/components/accordion'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { Field, FieldErrorText } from '@resnet/client-web/shared/gdl/components/field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { CreateSketchModalContainer } from '@resnet/client-web/shared/sketch/components/create-sketch-modal'
import { SketchGallery } from '@resnet/client-web/shared/sketch/components/sketch-gallery'

export type SketchFieldValueT = null | ResourceT

export type SketchFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, SketchFieldValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  label: string
  name: TPath
  rules?: RegisterOptions<TFieldValues, TPath>
  defaultValue?: PathValue<TFieldValues, TPath>
}

export const SketchField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, SketchFieldValueT>,
>({
  form,
  label,
  name,
  rules,
  defaultValue,
}: SketchFieldPropsT<TFieldValues, TPath>) => {
  const {
    field: { value, onChange },
    fieldState: { error, invalid },
  } = useController({ control: form.control, defaultValue, name, rules })

  const resource = value as SketchFieldValueT

  const onCreateSketch = useEventCallback(async (dataUrl: string) => {
    const resource = await mapDataUrlToDraftResource(dataUrl, ResourceTypesT.SketchT)

    if (!resource) {
      return
    }

    onChange(resource as PathValue<TFieldValues, TPath>)
  })

  const onDeleteSketch = useEventCallback(() => {
    onChange(null as PathValue<TFieldValues, TPath>)
  })

  const renderAccordion = () => {
    const renderHeader = () => {
      return label
    }

    const renderSketches = () => {
      if (!resource) {
        return null
      }

      return (
        <SketchGallery
          resources={[resource].filter(Boolean)}
          onDelete={onDeleteSketch}
        />
      )
    }

    const renderAddButton = () => {
      if (resource) {
        return null
      }

      return (
        <CreateSketchModalContainer>
          {({ onOpen }) => (
            <Button
              color="default"
              icon={<SignatureSolidIcon />}
              size="md"
              variant="contained"
              onClick={() => onOpen({ label, onCreate: onCreateSketch })}
            >
              Add Sketch
            </Button>
          )}
        </CreateSketchModalContainer>
      )
    }

    const renderContent = () => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
          {renderSketches()}
          {renderAddButton()}
        </Box>
      )
    }

    return (
      <Accordion
        defaultIsOpened
        hasError={invalid}
      >
        {{
          content: renderContent,
          header: { title: renderHeader() },
        }}
      </Accordion>
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderAccordion()}
      {renderFooter()}
    </Field>
  )
}
