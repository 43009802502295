import type { OmitDistributiveT } from '@resnet/client-common/common/types/common'

import ResetSolidIcon from '@resnet/client-shared/assets/icons/reset-solid.svg'

import { Button, type ButtonPropsT } from '@resnet/client-web/shared/gdl/components/button'

export type ResetButtonPropsT = OmitDistributiveT<ButtonPropsT, 'color' | 'variant' | 'icon' | 'endIcon' | 'children'>

export const ResetButton = (props: ResetButtonPropsT) => {
  return (
    <Button
      {...props}
      color="primary"
      icon={<ResetSolidIcon />}
      variant="text"
    >
      Reset
    </Button>
  )
}
