import { identity } from 'ramda'
import { cloneElement, isValidElement } from 'react'

const parser = new DOMParser()

const PROP_CONFIG_BY_ATTRIBUTE_NAME: {
  [key: string]: undefined | { propName: string }
} = {
  class: {
    propName: 'className',
  },
  for: {
    propName: 'htmlFor',
  },
}

const mapElementToProps = (element: HTMLElement): typeof props => {
  const props: { [key: string]: unknown } = {}

  for (const attribute of Array.from(element.attributes)) {
    const propConfig = PROP_CONFIG_BY_ATTRIBUTE_NAME[attribute.name]
    const propName = propConfig?.propName ?? attribute.name
    const propValue = attribute.value

    props[propName] = propValue
  }

  return props
}

const defaultKeyTo = (node: React.ReactNode, key: React.Key): React.ReactNode =>
  isValidElement(node) && node.key == null
    ? cloneElement(node, {
        key,
      })
    : node

export const mapHTMLToReactNodes = (
  content: string,
  mapNode: (reactNode: React.ReactNode, node: Node) => React.ReactNode = identity,
): React.ReactNode => {
  const renderNode = (node: Node, index: number): React.ReactNode => {
    const getReactNode = (): React.ReactNode => {
      if (node instanceof HTMLElement) {
        const Component = node.tagName.toLowerCase() as unknown as React.FC<Record<string, unknown>>

        const props = mapElementToProps(node)

        const children = Array.from(node.childNodes, renderNode)

        return <Component {...props}>{children}</Component>
      }

      return node.textContent
    }

    return defaultKeyTo(mapNode(getReactNode(), node), index)
  }

  return Array.from(parser.parseFromString(content, 'text/html').body.childNodes, renderNode)
}
