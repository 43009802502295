import { useAutoFocusRef } from '@resnet/client-web/shared/form/hooks/use-auto-focus-ref'

import { Search } from '../../../components/search'

export const SelectSearch = (props: React.ComponentProps<typeof Search>): React.ReactElement => {
  const autoFocusRef = useAutoFocusRef(true)

  return (
    <Search
      inputName="select-search"
      inputRef={autoFocusRef}
      {...props}
    />
  )
}
