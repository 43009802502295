import { createStore } from '@resnet/client-common/react/utils/create-store'

import type { CustomFieldsFormStoreValueT } from '@resnet/client-web/shared/custom-fields/types/custom-fields-form-store-value'

import type { CreateIssueFormValuesT } from '../types/create-issue-form-values'

export const createIssueFormValuesStore = createStore<CustomFieldsFormStoreValueT<CreateIssueFormValuesT>>({
  fields: [],
  values: {},
})
