import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { AttachmentCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/attachment/custom-field-value'

import type { ResourceT } from '@resnet/client-web/shared/files/types/resource'

import type { CustomFieldT } from '../../types/custom-field'

import type {
  AttachmentFormCustomFieldValueT,
  AttachmentMultipleFormCustomFieldValueT,
  AttachmentSingleFormCustomFieldValueT,
} from './form-custom-field-value'

export const mapFormCustomFieldValueToResources: CustomFieldT<
  AttachmentCustomFieldValueT,
  AttachmentFormCustomFieldValueT
>['mapFormCustomFieldValueToResources'] = ({ value, field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const attachmentPayload = payload[CustomFieldTypeT.AttachmentT]

  assert(attachmentPayload, checkNonNullable)

  const { multiple } = attachmentPayload

  if (multiple) {
    const attachmentMultipleFormFieldValue = value as AttachmentMultipleFormCustomFieldValueT

    const resources: ResourceT[] = attachmentMultipleFormFieldValue

    return resources
  }

  const attachmentSingleFormFieldValue = value as AttachmentSingleFormCustomFieldValueT

  const resources: ResourceT[] = !attachmentSingleFormFieldValue ? [] : [attachmentSingleFormFieldValue]

  return resources
}
