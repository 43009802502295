import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import type { CustomFieldPayloadTextT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { SwitchField } from '@resnet/client-web/shared/form-dalaran/components/common/switch-field'
import { TextField } from '@resnet/client-web/shared/form-dalaran/components/common/text-field'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export type TextCustomFieldPayloadValueT = CustomFieldFragmentT

export type TextCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, TextCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const TextCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, TextCustomFieldPayloadValueT>,
>({
  form,
  name,
}: TextCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type RequirePathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadTextT['required']>

  const requiredName = `${name}.payload.${CustomFieldTypeT.TextT}.required` as RequirePathT

  type MultilinePathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadTextT['multiline']>

  const multilineName = `${name}.payload.${CustomFieldTypeT.TextT}.multiline` as MultilinePathT

  type PlaceholderPathT = FieldPathByValue<TFieldValues, CustomFieldPayloadTextT['placeholder']>

  const placeholderName = `${name}.payload.${CustomFieldTypeT.TextT}.placeholder` as PlaceholderPathT

  type DefaultValuePathT = FieldPathByValue<TFieldValues, CustomFieldPayloadTextT['defaultValue']>

  const defaultValueName = `${name}.payload.${CustomFieldTypeT.TextT}.defaultValue` as DefaultValuePathT

  const renderRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName}
      />
    )
  }

  const renderMultilineField = () => {
    return (
      <SwitchField
        form={form}
        label="Multiline"
        name={multilineName}
      />
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderRequiredField()}
        {renderMultilineField()}
      </Box>
    )
  }

  const renderPlaceholderField = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <TextField
          form={form}
          label="Placeholder"
          name={placeholderName}
          placeholder="Enter Placeholder"
        />
      </Box>
    )
  }

  const renderDefaultValueField = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <TextField
          form={form}
          label="Default Value"
          name={defaultValueName}
          placeholder="Enter Default Value"
        />
      </Box>
    )
  }

  return (
    <>
      {renderSwitchFields()}
      {renderPlaceholderField()}
      {renderDefaultValueField()}
    </>
  )
}
