import type { ColorT } from '@resnet/client-api/api'

export const mapColorToPickerColorPreset = (color?: null | string): undefined | ColorT => {
  if (color) {
    return {
      custom: color,
    }
  }

  return undefined
}
