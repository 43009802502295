import { type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { FormulaCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/formula/custom-field-value'

import { FieldPreview } from '@resnet/client-web/shared/gdl/components/field-preview'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type FormulaFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | FormulaCustomFieldValueT
}

export const FormulaFormUserFormPreview = ({ field, value }: FormulaFormUserFormPreviewPropsT) => {
  return (
    <ObjectDetailsRow>
      <FieldPreview
        label={field.name}
        value={value}
      />
    </ObjectDetailsRow>
  )
}
