import { Box } from '@mui/material'
import type { SxProps } from '@mui/system'
import { Fragment, useMemo } from 'react'

import { toggle } from '@resnet/client-common/common/utils/array/toggle'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import { TextSkeleton } from '@resnet/client-web/shared/async/components/text-skeleton'
import type { AbstractOptionT, AbstractSimpleOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'

import { IconAvatar } from '../../components/avatar'
import type { CheckboxPropsT } from '../../components/checkbox'
import { Checkbox } from '../../components/checkbox'
import { toPx } from '../../utils/to-px'
import type { ListActionItemActionPropsT } from '../list'
import {
  List,
  ListActionItem,
  ListItem,
  ListItemAvatar,
  ListItemAvatarSkeleton,
  ListItemColor,
  ListItemColorSkeleton,
  ListItemDescription,
  ListItemIcon,
  ListItemLayout,
  ListItemName,
} from '../list'

export const CheckboxList = <OptionT extends AbstractOptionT>({
  options,
  selected: selectedProp,
  setSelected,
  renderOption,
  ...props
}: Omit<React.ComponentProps<typeof List>, 'options' | 'selected' | 'setSelected' | 'renderOption' | 'children'> & {
  options: OptionT[]
  selected: OptionT['id'][]
  setSelected: React.Dispatch<React.SetStateAction<OptionT['id'][]>>
  renderOption: (props: { option: OptionT; isSelected: boolean; onClick?: () => void }) => React.ReactNode
}): React.ReactElement => {
  const selected = useMemo(() => new Set(selectedProp), [selectedProp])

  return (
    <List {...props}>
      {options.map((option) => {
        const isSelected = selected.has(option.id)

        const onClick = () => {
          setSelected((selected) => toggle(selected, option.id))
        }

        return <Fragment key={option.id}>{renderOption({ isSelected, onClick: onClick, option })}</Fragment>
      })}
    </List>
  )
}

export const SimpleCheckboxList = <OptionT extends AbstractSimpleOptionT>({
  options,
  selected,
  setSelected,
}: {
  options: OptionT[]
  selected: OptionT['id'][]
  setSelected: React.Dispatch<React.SetStateAction<OptionT['id'][]>>
}): React.ReactElement => {
  return (
    <CheckboxList
      options={options}
      renderOption={({ option, isSelected, onClick }) => (
        <CheckboxListItem
          isSelected={isSelected}
          name={option.name}
          onClick={onClick}
        />
      )}
      selected={selected}
      setSelected={setSelected}
    />
  )
}

export const CheckboxListItem = ({
  isSelected,
  media,
  name,
  description,
  onClick,
  sx,
  actions,
  showNameTooltip,
}: {
  isSelected: boolean
  media?: MediaT
  name: React.ReactNode
  description?: React.ReactNode
  onClick?: () => void
  sx?: SxProps
  actions?: ListActionItemActionPropsT[]
  showNameTooltip?: boolean
}): React.ReactElement => {
  const renderSelection = (): React.ReactNode => {
    return (
      <Checkbox
        size="md"
        tabIndex={-1}
        value={isSelected}
        onClick={onClick}
      />
    )
  }

  const renderMedia = (): React.ReactNode => {
    if (!media || !media.type) {
      return null
    }

    switch (media.type) {
      case 'icon': {
        return <ListItemIcon>{media.icon}</ListItemIcon>
      }
      case 'iconAvatar': {
        return (
          <IconAvatar
            size="md"
            {...media}
          />
        )
      }
      case 'avatar': {
        return <ListItemAvatar {...media} />
      }
      case 'color': {
        return <ListItemColor color={media.color} />
      }
    }
  }

  const renderName = (): React.ReactNode => {
    return <ListItemName showTooltip={showNameTooltip}>{name}</ListItemName>
  }

  const renderActions = (): React.ReactNode => {
    if (!actions) {
      return null
    }

    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: toPx(8),
        }}
      >
        {actions.map((action) => (
          <ListActionItem
            key={action.id}
            {...action}
          />
        ))}
      </Box>
    )
  }

  const renderDescription = (): React.ReactNode => {
    if (!description) {
      return null
    }

    return <ListItemDescription>{description}</ListItemDescription>
  }

  return (
    <ListItem
      sx={sx}
      onClick={onClick}
    >
      <ListItemLayout>
        {{
          action: renderActions(),
          description: renderDescription(),
          media: renderMedia(),
          name: renderName(),
          selection: renderSelection(),
        }}
      </ListItemLayout>
    </ListItem>
  )
}

export const CheckboxListItemPreview = ({
  size,
  isSelected,
  media,
  name,
  description,
  sx,
}: {
  isSelected: boolean
  media?: MediaT
  name: React.ReactNode
  description?: React.ReactNode
  sx?: SxProps
  size: CheckboxPropsT['size']
}): React.ReactElement => {
  const renderSelection = (): React.ReactNode => {
    return (
      <Checkbox
        size={size}
        tabIndex={-1}
        value={isSelected}
      />
    )
  }

  const renderMedia = (): React.ReactNode => {
    if (!media || !media.type) {
      return null
    }

    switch (media.type) {
      case 'icon': {
        return <ListItemIcon>{media.icon}</ListItemIcon>
      }
      case 'iconAvatar': {
        return (
          <IconAvatar
            size="md"
            {...media}
          />
        )
      }
      case 'avatar': {
        return <ListItemAvatar {...media} />
      }
      case 'color': {
        return <ListItemColor color={media.color} />
      }
    }
  }

  const renderName = (): React.ReactNode => {
    return <ListItemName>{name}</ListItemName>
  }

  const renderDescription = (): React.ReactNode => {
    if (!description) {
      return null
    }

    return <ListItemDescription>{description}</ListItemDescription>
  }

  return (
    <ListItemLayout sx={sx}>
      {{
        description: renderDescription(),
        media: renderMedia(),
        name: renderName(),
        selection: renderSelection(),
      }}
    </ListItemLayout>
  )
}

export const CheckboxListItemSkeleton = ({ media, sx }: { sx?: SxProps; media?: MediaT }) => {
  const renderMedia = (): React.ReactNode => {
    if (!media || !media.type) {
      return null
    }

    switch (media.type) {
      case 'icon': {
        return null
      }
      case 'iconAvatar': {
        return (
          <IconAvatar
            size="md"
            {...media}
          />
        )
      }
      case 'avatar': {
        return <ListItemAvatarSkeleton />
      }
      case 'color': {
        return <ListItemColorSkeleton />
      }
    }
  }

  const renderName = () => {
    return (
      <TextSkeleton
        contentSx={{ width: '100%' }}
        typographyPreset={{ fontSize: 16, lineHeight: 24 }}
      />
    )
  }

  const renderSelection = (): React.ReactNode => {
    return (
      <Checkbox
        disabled
        size="md"
      />
    )
  }

  return (
    <ListItem sx={sx}>
      <ListItemLayout>{{ media: renderMedia(), name: renderName(), selection: renderSelection() }}</ListItemLayout>
    </ListItem>
  )
}
