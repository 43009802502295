// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".evC5eF7f_4M9YnQuUgEQ {\n  margin-bottom: 8px;\n}\n\n.CoZ4_wgNX8XQ4YKrensE {\n  color: white;\n}\n\n.iIBTNWfQAwyhpL_HejS_ {\n  color: #969492;\n}", "",{"version":3,"sources":["webpack://./../client-web/src/shared/form/components/field-title/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".title {\r\n  margin-bottom: 8px;\r\n}\r\n\r\n.white {\r\n  color: white;\r\n}\r\n\r\n.gray-400 {\r\n  color: #969492;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var title = "evC5eF7f_4M9YnQuUgEQ";
export var white = "CoZ4_wgNX8XQ4YKrensE";
var _1 = "iIBTNWfQAwyhpL_HejS_";
export { _1 as "gray-400" };
export default ___CSS_LOADER_EXPORT___;
