import { generatePath } from 'react-router-dom'

import type { WellFragmentT } from '@resnet/client-api/api'

import { rootScreenRoutes } from '../../app-routes/routes/root-screen-routes'

export type WellWithPathnameT = Pick<WellFragmentT, '__typename' | 'readableId'>

export const mapWellToPathname = <ValueT extends WellWithPathnameT>(value: ValueT): string => {
  return generatePath(rootScreenRoutes.wellsDetails, { wellId: value.readableId })
}
