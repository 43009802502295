import type { FieldPathByValue, FieldValues, PathValue, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import { validateRequired } from '@resnet/client-shared/shared/forms/validators/any-required'
import { composeValidators } from '@resnet/client-shared/shared/forms/validators/compose-validators'
import { validateListRequired } from '@resnet/client-shared/shared/forms/validators/list-required'

import { AttachmentField } from '@resnet/client-web/shared/form-dalaran/components/common/attachment-field'
import { AttachmentMultipleField } from '@resnet/client-web/shared/form-dalaran/components/common/attachment-multiple-field'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

import type { AttachmentFormCustomFieldValueT } from '../../form-custom-field-value'

export type AttachmentCustomFieldUserFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, AttachmentFormCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const AttachmentCustomFieldUserFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, AttachmentFormCustomFieldValueT>,
>({
  field,
  form,
  name,
}: AttachmentCustomFieldUserFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const attachmentPayload = payload[CustomFieldTypeT.AttachmentT]

  assert(attachmentPayload, checkNonNullable)

  const { multiple, required } = attachmentPayload

  const renderField = () => {
    if (!multiple) {
      return (
        <AttachmentField
          defaultValue={null as PathValue<TFieldValues, TPath>}
          form={form as UseFormReturn<FieldValues>}
          label={field.name}
          name={name}
          rules={{ validate: composeValidators(...(!required ? [] : [validateRequired])) }}
        />
      )
    }

    return (
      <AttachmentMultipleField
        defaultValue={[] as PathValue<TFieldValues, TPath>}
        form={form as UseFormReturn<FieldValues>}
        label={field.name}
        name={name}
        rules={{ validate: composeValidators(...(!required ? [] : [validateListRequired])) }}
      />
    )
  }

  return <ObjectDetailsRow>{renderField()}</ObjectDetailsRow>
}
