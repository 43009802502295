import { useContext, useEffect, useId, useMemo } from 'react'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { LayerRegistryContext } from '../components/layer-registry-context'

export const useLayerRegistry = ({ open }: { open?: boolean }) => {
  const drawersRegistryContextValue = useContext(LayerRegistryContext)

  const id = useId()

  assert(drawersRegistryContextValue, checkNonNullable, '"layersRegistryContextValue" is expected to not be nullable')

  const { register, registry } = drawersRegistryContextValue

  useEffect(() => {
    if (open) {
      return register(id)
    }

    return undefined
  }, [id, open, register])

  const isTopMostLayer = useMemo(() => registry.indexOf(id) === 0, [id, registry])

  return {
    isTopMostLayer,
    registry,
  }
}
