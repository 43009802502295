import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";

const rootElement: HTMLElement = document.getElementById("container") as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root.render(NextApp);
  });
}
