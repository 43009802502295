import { checkIsBattery } from '../../batteries/utils/check-is-battery'
import type { BatteryWithPathnameT } from '../../batteries/utils/map-battery-to-pathname'
import { mapBatteryToPathname } from '../../batteries/utils/map-battery-to-pathname'
import { checkIsSite } from '../../sites/utils/check-is-site'
import type { SiteWithPathnameT } from '../../sites/utils/map-site-to-pathname'
import { mapSiteToPathname } from '../../sites/utils/map-site-to-pathname'
import { checkIsWell } from '../../wells/utils/check-is-well'
import type { WellWithPathnameT } from '../../wells/utils/map-well-to-pathname'
import { mapWellToPathname } from '../../wells/utils/map-well-to-pathname'

export type AssetWithPathnameT = BatteryWithPathnameT | SiteWithPathnameT | WellWithPathnameT

export const mapAssetToPathname = <ValueT extends AssetWithPathnameT>(value: ValueT): string => {
  if (checkIsBattery(value)) {
    return mapBatteryToPathname(value)
  }

  if (checkIsSite(value)) {
    return mapSiteToPathname(value)
  }

  if (checkIsWell(value)) {
    return mapWellToPathname(value)
  }

  throw new Error(`Unknown asset type: ${value.__typename}`)
}
