import { hexRegex } from '../regexes/hex-regex'

import { hexToDecimal } from './hex-to-decimal'

export const hexToRgbaArray = (hex: string): [number, number, number, number] => {
  const matches = hexRegex.exec(hex)

  if (!matches) {
    throw new Error('"hex" parsing error')
  }

  const [, hexR, hexG, hexB] = matches

  return [hexToDecimal(hexR), hexToDecimal(hexG), hexToDecimal(hexB), 1]
}
