import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'

import DeleteSolidIcon from '@resnet/client-shared/assets/icons/delete-solid.svg'
import { useTheme } from '@resnet/client-shared/shared/gdl/contexts/theme-context'

import { RectangularSkeleton } from '@resnet/client-web/shared/async/components/rectangular-skeleton'
import { Button } from '@resnet/client-web/shared/gdl/components/button'
import { ClickableOpacity } from '@resnet/client-web/shared/gdl/components/clickable-opacity'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

import { sketchSx } from '../../styles/sketch-sx'

type SketchCardPropsT = {
  src: string
  onDelete?: () => void
  sx?: SxProps
  isDeleteButtonVisible?: boolean
  onClick?: () => void
}

const rootSx = {
  borderRadius: toPx(8),
  height: toPx(160),
}

export const SketchCard = ({ src, onDelete, onClick, sx = null, isDeleteButtonVisible = true }: SketchCardPropsT) => {
  const theme = useTheme()

  return (
    <Box
      sx={[
        {
          backgroundColor: themeColors.surfaceNeutralDefault,
          display: 'flex',
          position: 'relative',
        },
        rootSx,
        sx,
      ].flat()}
    >
      <ClickableOpacity
        sx={{ flexGrow: 1 }}
        onClick={onClick}
      >
        <Box
          component="img"
          src={src}
          sx={[{ display: 'block', height: '100%', objectFit: 'contain', width: '100%' }, sketchSx({ theme })]}
        />
      </ClickableOpacity>
      {!isDeleteButtonVisible ? null : (
        <Button
          color="default"
          icon={<DeleteSolidIcon />}
          size="sm"
          sx={{ left: toPx(4), position: 'absolute', top: toPx(4) }}
          variant="contained"
          onClick={onDelete}
        />
      )}
    </Box>
  )
}

type SketchCardSkeletonPropsT = {
  sx?: SxProps
}

export const SketchCardSkeleton = ({ sx = null }: SketchCardSkeletonPropsT) => {
  return <RectangularSkeleton sx={[rootSx, sx].flat()} />
}
