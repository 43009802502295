import type { QueryClient } from '@tanstack/react-query'

import {
  useBlockUserMutation,
  useCancelInviteMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useInfiniteSearchGroupsQuery,
  useInfiniteSearchUsersQuery,
  useSearchGroupsQuery,
  useSendInviteMutation,
  useUnblockUserMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'

export const infiniteSearchUsersQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteSearchUsersQueries = () => findInfiniteQueries(queryClient, useInfiniteSearchUsersQuery)

  const blockUserHandlers = () => {
    return createMutationHookMutationCacheHandlers(useBlockUserMutation, {
      onSuccess: () => {
        findInfiniteSearchUsersQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unblockUserHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnblockUserMutation, {
      onSuccess: () => {
        findInfiniteSearchUsersQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const sendInviteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSendInviteMutation, {
      onSuccess: () => {
        findInfiniteSearchUsersQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const cancelInviteHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCancelInviteMutation, {
      onSuccess: () => {
        findInfiniteSearchUsersQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [blockUserHandlers(), unblockUserHandlers(), sendInviteHandlers(), cancelInviteHandlers()]
}

export const searchGroupsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findSearchGroupsQueries = () => findQueries(queryClient, useSearchGroupsQuery)

  const createGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateGroupMutation, {
      onSuccess: () => {
        findSearchGroupsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteGroupMutation, {
      onSuccess: () => {
        findSearchGroupsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createGroupHandlers(), deleteGroupHandlers()]
}

export const infiniteSearchGroupsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteSearchGroupsQueries = () => findInfiniteQueries(queryClient, useInfiniteSearchGroupsQuery)

  const createGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateGroupMutation, {
      onSuccess: () => {
        findInfiniteSearchGroupsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteGroupMutation, {
      onSuccess: () => {
        findInfiniteSearchGroupsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createGroupHandlers(), deleteGroupHandlers()]
}
