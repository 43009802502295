import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { MinimalUserFragmentT } from '@resnet/client-api/api'

import type { topThreeColors } from '@resnet/client-shared/shared/gamification/constants/top-three-colors'
import { topThreeColorsById } from '@resnet/client-shared/shared/gamification/constants/top-three-colors'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import type { AvatarPropsT } from '@resnet/client-web/shared/gdl/components/avatar'
import { LoadableAvatar } from '@resnet/client-web/shared/gdl/components/avatar'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

export type UserAvatarPropsT = MergeAllT<
  [
    Omit<AvatarPropsT, 'name' | 'resource'>,
    {
      user: MinimalUserFragmentT
      outline?: (typeof topThreeColors)[number]['id']
    },
  ]
>

export const UserAvatar = ({ user, outline, sx = null, ...props }: UserAvatarPropsT): React.ReactElement => {
  const avatarId = user.avatarId

  const createOutlineSx = () => {
    if (!outline) {
      return null
    }

    return { outline: `2px solid ${themeColors[topThreeColorsById[outline].overSurface]}` }
  }

  return (
    <LoadableAvatar
      {...props}
      name={mapUserToTitle(user)}
      resourceId={avatarId}
      sx={[createOutlineSx(), sx].flat()}
    />
  )
}
