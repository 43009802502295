import type { CustomFieldFragmentT } from '@resnet/client-api/api'

import { customFieldsById } from '../constants/custom-fields'

import { mapCustomFieldToKey } from './map-custom-field-to-key'

export const mapCustomFieldsToCustomFieldValues = ({ fields }: { fields: CustomFieldFragmentT[] }) => {
  const fieldValues = Object.fromEntries(
    fields.map((field) => {
      const { mapCustomFieldToDefaultValue } = customFieldsById[field.type]

      return [mapCustomFieldToKey(field), mapCustomFieldToDefaultValue({ field })]
    }),
  )

  return { fieldValues }
}
