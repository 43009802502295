import type { CustomFieldT } from '../../types/custom-field'

import { EntityPickerCustomFieldPayload } from './components/entity-picker-custom-field-payload'

export const renderPayload: CustomFieldT['renderPayload'] = ({ form, name, isDraft }) => {
  return (
    <EntityPickerCustomFieldPayload
      form={form}
      isDraft={isDraft}
      name={name}
    />
  )
}
