import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { assertedNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { SetIssueApprovalLevelsMutationVariablesT } from '@resnet/client-api/api'

import type { CreateIssueFormValuesT } from '../types/create-issue-form-values'

export const mapCreateIssueFormValuesToSetIssueApprovalLevelsMutationVariables = ({
  values,
  id,
}: {
  values: CreateIssueFormValuesT
  id: string
}): SetIssueApprovalLevelsMutationVariablesT => ({
  id,
  levels: pipeline(values.approval, assertedNonUndefined, (x) =>
    x.map((item) => ({
      approvers: item.approvers.map((approver) => approver.id),
      requiredApprovals: item.requiredApprovals,
    })),
  ),
})
