import { Box } from '@mui/material'
import { ObjectId } from 'bson'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { EntityPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/entity-picker/custom-field-value'

import { Tag } from '@resnet/client-web/shared/gdl/components/tag'
import { EntityCell } from '@resnet/client-web/shared/tables/components/entity-cell'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'

import type { CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createColumn: CustomFieldT['createColumn'] = ({ field, fieldValuesKey }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const entityPickerPayload = payload[CustomFieldTypeT.EntityPickerT]

  assert(entityPickerPayload, checkNonNullable)

  const { multiple } = entityPickerPayload

  const getValue = (row: Record<string, unknown>) =>
    getFieldValue<EntityPickerCustomFieldValueT>(field, fieldValuesKey, row)

  return {
    field: mapCustomFieldToFullKey(field, fieldValuesKey),
    filterable: true,
    flex: 1,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      const id = typeof groupingKey !== 'string' || !ObjectId.isValid(groupingKey) ? undefined : groupingKey

      return (
        <EntityCell
          id={id}
          options={{ refetchOnWindowFocus: false }}
          sx={{ flexGrow: 1 }}
          onSuccess={rowNode.onSuccess}
        />
      )
    },
    groupable: multiple ? false : true,
    groupingValueGetter: (params) => {
      const value = getValue(params.row)

      if (value === undefined) {
        return undefined
      }

      if (Array.isArray(value)) {
        throw new Error('grouping is not available for arrays')
      }

      return value
    },
    headerName: field.name,
    minWidth: 150,
    renderCell: (params) => {
      const value = params.value as ReturnType<typeof getValue>

      if (value === undefined) {
        return <RegularCell>-</RegularCell>
      }

      const [id, ...rest] = [value].flat()

      return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
          <EntityCell id={id} />
          {rest.length < 1 ? null : <Tag>+{rest.length}</Tag>}
        </Box>
      )
    },
    sortable: true,
    valueGetter: (params) => {
      const value = getValue(params.row)

      return value
    },
  }
}
