import { checkIsAsset } from '../../assets/utils/check-is-asset'
import type { AssetWithTitleT } from '../../assets/utils/map-asset-to-title'
import { mapAssetToTitle } from '../../assets/utils/map-asset-to-title'
import { checkIsCampaign } from '../../campaigns/utils/check-is-campaign'
import { mapCampaignToTitle, type CampaignWithTitleT } from '../../campaigns/utils/map-campaign-to-title'
import { checkIsCredential } from '../../credentials/utils/check-is-credential'
import { mapCredentialToTitle, type CredentialWithTitleT } from '../../credentials/utils/map-credential-to-title'
import { checkIsFormObject } from '../../form-objects/utils/check-is-form-object'
import type { FormObjectWithTitleT } from '../../form-objects/utils/map-form-object-to-title'
import { mapFormObjectToTitle } from '../../form-objects/utils/map-form-object-to-title'
import { checkIsInsight } from '../../insights/utils/check-is-insight'
import type { InsightWithTitleT } from '../../insights/utils/map-insight-to-title'
import { mapInsightToTitle } from '../../insights/utils/map-insight-to-title'
import { checkIsIssue } from '../../issues/utils/check-is-issue'
import type { IssueWithTitleT } from '../../issues/utils/map-issue-to-title'
import { mapIssueToTitle } from '../../issues/utils/map-issue-to-title'
import { checkIsResourceObject } from '../../resource-objects/utils/check-is-resource-object'
import {
  mapResourceObjectToTitle,
  type ResourceObjectWithTitleT,
} from '../../resource-objects/utils/map-resource-object-to-title'
import { checkIsRoute } from '../../routes/utils/check-is-route'
import type { RouteWithTitleT } from '../../routes/utils/map-route-to-title'
import { mapRouteToTitle } from '../../routes/utils/map-route-to-title'

export type EntityWithTitleT =
  | AssetWithTitleT
  | CampaignWithTitleT
  | CredentialWithTitleT
  | FormObjectWithTitleT
  | IssueWithTitleT
  | InsightWithTitleT
  | ResourceObjectWithTitleT
  | RouteWithTitleT

export const mapEntityToTitle = <ValueT extends EntityWithTitleT>(value: ValueT) => {
  if (checkIsAsset(value)) {
    return mapAssetToTitle(value)
  }

  if (checkIsCampaign(value)) {
    return mapCampaignToTitle(value)
  }

  if (checkIsCredential(value)) {
    return mapCredentialToTitle(value)
  }

  if (checkIsFormObject(value)) {
    return mapFormObjectToTitle(value)
  }

  if (checkIsIssue(value)) {
    return mapIssueToTitle(value)
  }

  if (checkIsInsight(value)) {
    return mapInsightToTitle(value)
  }

  if (checkIsResourceObject(value)) {
    return mapResourceObjectToTitle(value)
  }

  if (checkIsRoute(value)) {
    return mapRouteToTitle(value)
  }

  throw new Error(`Unknown entity type: ${value.__typename}`)
}
