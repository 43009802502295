import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadT } from '@resnet/client-api/api'
import { EntityTypeIdsT, CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldT } from '../../types/custom-field'

export const entityPickerCustomField = createConstrainer<CustomFieldT>()({
  id: CustomFieldTypeT.EntityPickerT as const,
  name: 'Entity Picker',
  payload: createConstrainer<CustomFieldPayloadT>()({
    EntityPicker: {
      entityTypeId: [
        EntityTypeIdsT.WellT,
        EntityTypeIdsT.BatteryT,
        EntityTypeIdsT.SiteT,
        EntityTypeIdsT.CampaignT,
        EntityTypeIdsT.InsightT,
        EntityTypeIdsT.IssueT,
        EntityTypeIdsT.RouteT,
        EntityTypeIdsT.ResourceObjectT,
      ],
      multiple: false,
      required: false,
    },
  }) as CustomFieldPayloadT,
})
