import { hexAlphaToRgba } from '@resnet/client-common/colors/utils/hex-alpha-to-rgba'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

export const systemColors = createConstrainer<Record<string, string>>()({
  alienSkin05: '#071b10',
  alienSkin10: '#103d24',
  alienSkin15: '#1c6d41',
  alienSkin20: '#2eb16a',
  alienSkin30: '#39db83',
  alienSkin40: '#3ff391',
  alienSkin50: '#82f7b8',
  alienSkin60: '#97f9c4',
  alienSkin70: '#a9face',
  alienSkin80: '#c3fbdd',
  alienSkin90: '#e2fdef',
  alienSkin95: '#ecfef4',
  alienSkin99: '#f5fefa',
  black: '#000000',
  blackA05: hexAlphaToRgba(['#000000', 0.05]),
  blackA10: hexAlphaToRgba(['#000000', 0.1]),
  blackA20: hexAlphaToRgba(['#000000', 0.2]),
  blackA30: hexAlphaToRgba(['#000000', 0.3]),
  blackA40: hexAlphaToRgba(['#000000', 0.4]),
  blackA50: hexAlphaToRgba(['#000000', 0.5]),
  blackA60: hexAlphaToRgba(['#000000', 0.6]),
  blackA70: hexAlphaToRgba(['#000000', 0.7]),
  blackA80: hexAlphaToRgba(['#000000', 0.8]),
  blackA90: hexAlphaToRgba(['#000000', 0.9]),
  critical05: '#190908',
  critical10: '#3a1513',
  critical15: '#511e1b',
  critical20: '#682622',
  critical30: '#8b332e',
  critical40: '#ad4039',
  critical50: '#c44841',
  critical60: '#e7554c',
  critical70: '#ef918b',
  critical80: '#f2a39e',
  critical90: '#f8cac8',
  critical95: '#fdeeed',
  critical99: '#fef7f6',
  info05: '#04101b',
  info10: '#08263d',
  info15: '#0c3555',
  info20: '#0f446d',
  info30: '#145a92',
  info40: '#1971b6',
  info50: '#1a78c2',
  info60: '#2196f3',
  info70: '#6fbbf7',
  info80: '#9bd0fa',
  info90: '#badefb',
  info95: '#e9f5fe',
  info99: '#f4fafe',
  moonBase05: '#0b0516',
  moonBase10: '#190b32',
  moonBase15: '#230f46',
  moonBase20: '#2d135a',
  moonBase30: '#4a1f91',
  moonBase40: '#652ac7',
  moonBase50: '#9b75db',
  moonBase60: '#ac8ce1',
  moonBase70: '#ba9fe6',
  moonBase80: '#cfbdee',
  moonBase90: '#e8dff7',
  moonBase95: '#f0eaf9',
  moonBase99: '#f7f4fc',
  neutral05: '#151718',
  neutral10: '#313436',
  neutral15: '#44484c',
  neutral20: '#757c82',
  neutral30: '#8e969e',
  neutral40: '#9ca5ae',
  neutral50: '#b0b9c3',
  neutral60: '#c3ced9',
  neutral70: '#d8dfe6',
  neutral80: '#e4e9ee',
  neutral90: '#ecf0f3',
  neutral95: '#f9fafb',
  neutral99: '#fcfdfd',
  quantum05: '#01050a',
  quantum10: '#010b17',
  quantum15: '#020f20',
  quantum20: '#021329',
  quantum30: '#031a37',
  quantum40: '#04224a',
  quantum50: '#052b5c',
  quantum60: '#5d7595',
  quantum70: '#8fa0b6',
  quantum80: '#b2bdcc',
  quantum90: '#dadfe7',
  quantum95: '#e6eaef',
  quantum99: '#f3f4f7',
  quantumA05: hexAlphaToRgba(['#052b5c', 0.05]),
  quantumA10: hexAlphaToRgba(['#052b5c', 0.1]),
  quantumA20: hexAlphaToRgba(['#052b5c', 0.2]),
  quantumA30: hexAlphaToRgba(['#052b5c', 0.3]),
  quantumA40: hexAlphaToRgba(['#052b5c', 0.4]),
  quantumA50: hexAlphaToRgba(['#052b5c', 0.5]),
  quantumA60: hexAlphaToRgba(['#052b5c', 0.6]),
  quantumA70: hexAlphaToRgba(['#052b5c', 0.7]),
  quantumA80: hexAlphaToRgba(['#052b5c', 0.8]),
  quantumA90: hexAlphaToRgba(['#052b5c', 0.9]),
  radiation05: '#1a1507',
  radiation10: '#3b2f0f',
  radiation15: '#534215',
  radiation20: '#6b551b',
  radiation30: '#8e7125',
  radiation40: '#ad892d',
  radiation50: '#be9631',
  radiation60: '#d5a937',
  radiation70: '#edbc3d',
  radiation80: '#f5db96',
  radiation90: '#f9eac3',
  radiation95: '#fcf5e2',
  radiation99: '#fefcf5',
  warning05: '#190c00',
  warning10: '#3a1c01',
  warning15: '#512701',
  warning20: '#673202',
  warning30: '#8a4302',
  warning40: '#c45f03',
  warning50: '#e67004',
  warning60: '#efa25c',
  warning70: '#f4bf8e',
  warning80: '#f7d3b1',
  warning90: '#fbead9',
  warning95: '#fdf1e6',
  warning99: '#fef8f2',
  white: '#ffffff',
  whiteA05: hexAlphaToRgba(['#ffffff', 0.05]),
  whiteA10: hexAlphaToRgba(['#ffffff', 0.1]),
  whiteA20: hexAlphaToRgba(['#ffffff', 0.2]),
  whiteA30: hexAlphaToRgba(['#ffffff', 0.3]),
  whiteA40: hexAlphaToRgba(['#ffffff', 0.4]),
  whiteA50: hexAlphaToRgba(['#ffffff', 0.5]),
  whiteA60: hexAlphaToRgba(['#ffffff', 0.6]),
  whiteA70: hexAlphaToRgba(['#ffffff', 0.7]),
  whiteA80: hexAlphaToRgba(['#ffffff', 0.8]),
  whiteA90: hexAlphaToRgba(['#ffffff', 0.9]),
})
