import type { FieldPathByValue, FieldValues, PathValue, UseControllerProps, UseControllerReturn } from 'react-hook-form'
import { useController, type UseFormReturn } from 'react-hook-form'

import { Label } from '@resnet/client-web/shared/gdl/components/label'
import { Switch } from '@resnet/client-web/shared/gdl/components/switch'

export type SwitchFieldValueT = undefined | null | boolean

export type SwitchFieldPropsT<TFieldValues extends FieldValues> = {
  disabled?: boolean
  disabledTooltipTitle?: string
  defaultValue?: PathValue<TFieldValues, FieldPathByValue<TFieldValues, SwitchFieldValueT>>
  form: UseFormReturn<TFieldValues>
  label?: string
  name: FieldPathByValue<TFieldValues, SwitchFieldValueT>
  rules?: UseControllerProps<TFieldValues, FieldPathByValue<TFieldValues, SwitchFieldValueT>>['rules']
}

export const SwitchField = <TFieldValues extends FieldValues>({
  disabled,
  disabledTooltipTitle,
  defaultValue,
  form,
  label,
  name,
  rules,
}: SwitchFieldPropsT<TFieldValues>) => {
  const { control } = form

  const {
    field: { value: valueActual, onChange },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
  }) as unknown as UseControllerReturn<Record<string, Exclude<SwitchFieldValueT, undefined>>>

  const value = valueActual ?? false

  return (
    <Label
      description={label}
      disabled={disabled}
      size="md"
    >
      <Switch
        disabled={disabled}
        disabledTooltipTitle={disabledTooltipTitle}
        size="md"
        value={value}
        onChange={onChange}
      />
    </Label>
  )
}
