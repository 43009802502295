import { mapToKeys } from './map-to-keys'
import { update } from './update'

export const mapValues = <
  InputT extends Record<string, unknown>,
  FnT extends (value: InputT[keyof InputT], key: keyof InputT, input: InputT) => unknown,
>(
  fn: FnT,
  input: InputT,
) => {
  return mapToKeys(input).reduce((output, key) => {
    return update(output, key, () => fn(input[key], key, input) as ReturnType<FnT>)
  }, input as unknown as Record<keyof InputT, ReturnType<FnT>>)
}
