import { useState } from 'react'

import { equalByValuesStrict } from '@resnet/client-common/common/utils/object/equal-by-values'

export type ComparatorT<ValueT> = (prevValue: ValueT, nextValue: ValueT) => boolean

export const useSameRef = <ValueT>(value: ValueT, comparator: ComparatorT<ValueT> = equalByValuesStrict): ValueT => {
  const [prevValue, setPrevValue] = useState<ValueT>(() => value)

  const nextValue = !comparator(prevValue, value) ? value : prevValue

  if (prevValue !== nextValue) {
    setPrevValue(() => nextValue)
  }

  return nextValue
}
