import type { SxProps } from '@mui/material'
import { Box, Skeleton } from '@mui/material'

import type { MinimalUserFragmentT } from '@resnet/client-api/api'

import UserSolidIcon from '@resnet/client-shared/assets/icons/user-solid.svg'
import { getFirstLastName } from '@resnet/client-shared/shared/users/utils/get-first-last-name'
import { getUsername } from '@resnet/client-shared/shared/users/utils/get-username'

import { Wrap } from '@resnet/client-web/components/wrap/wrap'
import { Avatar, IconAvatar } from '@resnet/client-web/shared/gdl/components/avatar'
import { Link } from '@resnet/client-web/shared/gdl/components/link'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { mapUserToPathname } from '@resnet/client-web/shared/users/utils/map-user-to-pathname'

import { UserAvatar } from '../user-avatar'

export const USER_CARD_HEIGHT = 32

type UserCardPropsT = {
  sx?: SxProps
  user: MinimalUserFragmentT
  type?: string
  children?: React.ReactNode
  mapUsernameToDisplayNickname?: (username: string) => string
}

const defaultMapUsernameToDisplayNickname: UserCardPropsT['mapUsernameToDisplayNickname'] = (username) => `@${username}`

export const UserCard = ({
  sx,
  user,
  type,
  children,
  mapUsernameToDisplayNickname = defaultMapUsernameToDisplayNickname,
}: UserCardPropsT): React.ReactElement => {
  const userFullName = getFirstLastName(user)

  const userDisplayNickname = mapUsernameToDisplayNickname(getUsername(user))

  return (
    <Box sx={[{ alignItems: 'center', display: 'flex', minHeight: '40px' }, sx ?? null].flat()}>
      <Wrap with={!user.id ? null : <Link to={mapUserToPathname({ id: user.id })} />}>
        <UserAvatar
          size="md"
          user={user}
        />
      </Wrap>
      <Box sx={{ width: '4px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '14px', lineHeight: '20px' }}>
        <Box sx={{ display: 'flex', fontWeight: '700' }}>
          <Wrap with={!user.id ? null : <Link to={mapUserToPathname({ id: user.id })} />}>
            {userFullName ?? userDisplayNickname}
          </Wrap>
          {!type ? null : (
            <Box
              component="span"
              sx={{ color: themeColors.overBackgroundDefault, fontWeight: '500' }}
            >
              {`\u00A0·\u00A0${type}`}
            </Box>
          )}
        </Box>
        {!userFullName ? null : (
          <Box
            sx={{
              color: themeColors.overBackgroundDefault,
              fontWeight: '500',
            }}
          >
            {userDisplayNickname}
          </Box>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {children}
    </Box>
  )
}

type UserEmailCardPropsT = {
  sx?: SxProps
  user: { email: string }
  children?: React.ReactNode
  mapUsernameToDisplayNickname?: (username: string) => string
}

export const UserEmailCard = ({
  sx,
  user,
  children,
  mapUsernameToDisplayNickname = defaultMapUsernameToDisplayNickname,
}: UserEmailCardPropsT): React.ReactElement => {
  const userDisplayNickname = mapUsernameToDisplayNickname(user.email)

  return (
    <Box sx={[{ alignItems: 'center', display: 'flex', minHeight: '40px' }, sx ?? null].flat()}>
      <Avatar
        name={user.email}
        size="md"
      />
      <Box sx={{ width: '4px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '14px', lineHeight: '20px' }}>
        <Box sx={{ display: 'flex', fontWeight: '700' }}>{userDisplayNickname}</Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {children}
    </Box>
  )
}

type EmptyUserCardPropsT = {
  label: string
  type?: string
  children?: React.ReactNode
}

export const EmptyUserCard = ({ label, type, children }: EmptyUserCardPropsT): React.ReactElement => {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex', minHeight: '40px' }}>
      <IconAvatar
        icon={<UserSolidIcon />}
        size="md"
      />
      <Box sx={{ width: '4px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '14px', lineHeight: '20px' }}>
        <Box sx={{ display: 'flex', fontWeight: '700' }}>
          {label}
          {!type ? null : (
            <Box
              component="span"
              sx={{ color: themeColors.overBackgroundDefault, fontWeight: '500' }}
            >
              {`\u00A0·\u00A0${type}`}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {children}
    </Box>
  )
}

export const UserCardSkeleton = (): React.ReactElement => {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex', minHeight: '40px' }}>
      <Skeleton
        sx={{ height: '32px', width: '32px' }}
        variant="circular"
      />
      <Box sx={{ width: '4px' }} />
      <Skeleton
        sx={{ flexBasis: '50%', height: '20px' }}
        variant="rectangular"
      />
      <Box sx={{ flexGrow: 1 }} />
      <Skeleton
        sx={{ flexBasis: '20%', height: '20px' }}
        variant="rectangular"
      />
    </Box>
  )
}
