import type { UseQueryOptions } from '@tanstack/react-query'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { ResourceObjectFragmentT, GetResourceObjectQueryT } from '@resnet/client-api/api'
import { useGetResourceObjectQuery } from '@resnet/client-api/api'

export type UseResourceObjectOptionPropsT = {
  id: string
  options?: UseQueryOptions<GetResourceObjectQueryT, unknown, ResourceObjectFragmentT>
}

export const useResourceObjectOption = ({ id, options }: UseResourceObjectOptionPropsT) => {
  const optionQuery = useGetResourceObjectQuery({ id }, { ...options, select: (data) => data.getResourceObject })

  return { optionQuery }
}

export const ResourceObjectOptionContainer = createHookContainer(useResourceObjectOption)
