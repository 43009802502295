import type { FormulaCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/formula/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { FormulaFormCustomFieldValueT } from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  FormulaCustomFieldValueT,
  FormulaFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field: _field }) => {
  return null
}
