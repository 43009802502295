import { useEffect, useMemo } from 'react'
import type { DefaultValues, FieldValues, Path, UseFormReturn } from 'react-hook-form'
import { useForm } from 'react-hook-form'

import { update } from '@resnet/client-common/common/utils/object/update'
import { useDerivedState } from '@resnet/client-common/react/hooks/use-derived-state'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { usePrevious } from '@resnet/client-common/react/hooks/use-previous'
import { useRefInitializer } from '@resnet/client-common/react/hooks/use-ref-initializer'
import type { StoreT } from '@resnet/client-common/react/utils/create-store'
import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'

import { useRestoreFormDirtyState } from '../../form-dalaran/hooks/use-restored-form-dirty-state'
import type { CustomFieldsFormStoreValueT } from '../types/custom-fields-form-store-value'
import { mapCustomFieldToFullKey, mapCustomFieldToKey } from '../utils/map-custom-field-to-key'

const emptyArray: never[] = []

const useRestoredValues = <TFieldValues extends FieldValues>({
  fields,
  fieldValuesKey,
  store,
}: {
  fields: CustomFieldFragmentT[]
  fieldValuesKey: string
  store?: StoreT<CustomFieldsFormStoreValueT<TFieldValues>>
}) => {
  const { current: values } = useRefInitializer(() => {
    if (!store) {
      return {} as TFieldValues
    }

    const { values: valuesActual, fields: prevFields } = store.get()

    const deletedFields = prevFields.filter((prevField) => !fields.some((field) => field.id === prevField.id))

    const values = deletedFields.reduce(
      (values, deletedField) =>
        update(values, fieldValuesKey, (fieldValues) =>
          update(fieldValues ?? ({} as TFieldValues[string]), mapCustomFieldToKey(deletedField), () => undefined),
        ),
      valuesActual,
    )

    return values
  })

  return { values }
}

export const useDeletedCustomFields = ({ fields: fieldsActual }: { fields: CustomFieldFragmentT[] }) => {
  const prevFields = usePrevious(fieldsActual) ?? emptyArray

  const [deletedFields, setDeletedFields] = useDerivedState(
    () => prevFields.filter((prevField) => !fieldsActual.some((field) => field.id === prevField.id)),
    [fieldsActual, prevFields],
  )

  const fields = useMemo(
    () =>
      fieldsActual.filter(
        (field) =>
          !deletedFields.some((deletedField) => mapCustomFieldToKey(deletedField) === mapCustomFieldToKey(field)),
      ),
    [fieldsActual, deletedFields],
  )

  return { deletedFields, fields, setDeletedFields }
}

export const useResetDeletedCustomFields = <TFieldValues extends FieldValues>({
  deletedFields,
  fieldValuesKey,
  form,
  setDeletedFields,
}: {
  deletedFields: CustomFieldFragmentT[]
  fieldValuesKey: string
  form: UseFormReturn<TFieldValues>
  setDeletedFields: React.Dispatch<React.SetStateAction<CustomFieldFragmentT[]>>
}) => {
  useEffect(() => {
    if (deletedFields.length === 0) {
      return
    }

    deletedFields.forEach((deletedField) => {
      const fieldName = mapCustomFieldToFullKey(deletedField, fieldValuesKey) as Path<TFieldValues>

      const fieldValue = undefined as AnyTypeT

      form.setValue(fieldName, fieldValue)

      form.resetField(fieldName)
    })

    setDeletedFields([])
  }, [deletedFields, setDeletedFields, form, fieldValuesKey])
}

const usePersistCustomFieldsFormValues = <TFieldValues extends FieldValues>({
  fields,
  form,
  store,
}: {
  fields: CustomFieldFragmentT[]
  form: UseFormReturn<TFieldValues>
  store?: StoreT<CustomFieldsFormStoreValueT<TFieldValues>>
}) => {
  const onPersistFormValues = useEventCallback(() => {
    if (!store) {
      return
    }

    store.set({ fields, values: form.getValues() })
  })

  useEffect(() => {
    return () => {
      onPersistFormValues()
    }
  }, [onPersistFormValues])
}

export const useCustomFieldsForm = <TFieldValues extends FieldValues>({
  defaultValues,
  fields: fieldsActual,
  fieldValuesKey,
  store,
}: {
  defaultValues: DefaultValues<TFieldValues>
  fields: CustomFieldFragmentT[]
  fieldValuesKey: string
  store?: StoreT<CustomFieldsFormStoreValueT<TFieldValues>>
}) => {
  const { values } = useRestoredValues({ fieldValuesKey, fields: fieldsActual, store })

  const { fields, deletedFields, setDeletedFields } = useDeletedCustomFields({ fields: fieldsActual })

  const form = useForm<TFieldValues>({
    defaultValues,
    shouldFocusError: false,
    values,
  })

  useRestoreFormDirtyState(form, values)

  useResetDeletedCustomFields({ deletedFields, fieldValuesKey, form, setDeletedFields })

  usePersistCustomFieldsFormValues({ fields, form, store })

  return { fields, form }
}
