import { updateAt } from './update-at'

export const updateById = <ItemT extends { id: string }>(
  input: ItemT[],
  updateItemId: string,
  updater: (item: ItemT) => ItemT,
): ItemT[] => {
  return updateAt(
    input,
    input.findIndex((item) => item.id === updateItemId),
    updater,
  )
}
