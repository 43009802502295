import { useCallback, useState } from 'react'

import { pull } from '@resnet/client-common/common/utils/array/pull'
import { uniq } from '@resnet/client-common/common/utils/array/uniq'
import { MemoProvider } from '@resnet/client-common/react/components/memo-provider'

export type RegistryContextT<ValueT> = React.Context<null | {
  registry: ValueT[]
  register: (id: ValueT) => () => void
  unregister: (id: ValueT) => void
}>

export const RegistryProvider = <ValueT extends unknown>({
  children,
  Context,
}: {
  children: React.ReactNode
  Context: RegistryContextT<ValueT>
}) => {
  const [registry, setRegistry] = useState<ValueT[]>([])

  const register = useCallback((id: ValueT) => {
    setRegistry((currentValue) => {
      return uniq([...currentValue, id])
    })

    return () => {
      setRegistry((registry) => pull(registry, id))
    }
  }, [])

  const unregister = useCallback((id: ValueT) => {
    setRegistry((registry) => pull(registry, id))
  }, [])

  return (
    <MemoProvider
      Context={Context}
      value={{ register, registry, unregister }}
    >
      {children}
    </MemoProvider>
  )
}
