import type { EntityPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/entity-picker/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import { EntityPickerFormUserFormPreview } from './components/entity-picker-custom-field-user-form-preview'

export const renderUserFormPreview: CustomFieldT['renderUserFormPreview'] = ({ field, value }) => {
  return (
    <EntityPickerFormUserFormPreview
      field={field}
      value={value as undefined | EntityPickerCustomFieldValueT}
    />
  )
}
