import { Box } from '@mui/material'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { OptionGroupCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/option-group/custom-field-value'

import { Tag } from '@resnet/client-web/shared/gdl/components/tag'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'

import type { CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createColumn: CustomFieldT['createColumn'] = ({ field, fieldValuesKey }) => {
  assert(field, checkNonNullable)

  const payload = field.payload

  assert(payload, checkNonNullable)

  const optionGroupPayload = payload[CustomFieldTypeT.OptionGroupT]

  assert(optionGroupPayload, checkNonNullable)

  const { options, multiple } = optionGroupPayload

  const getValue = (row: Record<string, unknown>) =>
    getFieldValue<OptionGroupCustomFieldValueT>(field, fieldValuesKey, row)

  return {
    field: mapCustomFieldToFullKey(field, fieldValuesKey),
    flex: 1,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      const option = options.find((item) => item.key === groupingKey)

      if (option === undefined) {
        return <RegularCell>{groupingKey}</RegularCell>
      }

      return <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
    },
    groupable: multiple ? false : true,
    groupingValueGetter: (params) => {
      const value = getValue(params.row)

      if (value === undefined) {
        return undefined
      }

      if (Array.isArray(value)) {
        throw new Error('grouping is not available for arrays')
      }

      return value
    },
    headerName: field.name,
    minWidth: 150,
    renderCell: (params) => {
      const value = params.value as ReturnType<typeof getValue>

      if (value === undefined) {
        return <RegularCell>-</RegularCell>
      }

      const [key, ...rest] = [value].flat()

      const option = options.find((item) => item.key === key)

      if (option === undefined) {
        return <RegularCell>-</RegularCell>
      }

      return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
          <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
          {rest.length < 1 ? null : <Tag>+{rest.length}</Tag>}
        </Box>
      )
    },
    sortable: true,
    valueGetter: (params) => {
      const value = getValue(params.row)

      return value
    },
  }
}
