import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { cloneElement, isValidElement } from 'react'

import type { MaybeT } from '@resnet/client-common/common/types/common'
import { useCombineRefs } from '@resnet/client-common/react/hooks/use-combine-refs'
import { foldElement } from '@resnet/client-common/react/utils/fold-element'

import { useAutoFocusRef } from '@resnet/client-web/shared/form/hooks/use-auto-focus-ref'
import { useAutoSelectRef } from '@resnet/client-web/shared/form/hooks/use-auto-select-ref'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'

type InputPropsT =
  | {
      multiline?: false
      type?: 'text' | 'password' | 'tel' | 'email'
      onChange?: React.ChangeEventHandler<HTMLInputElement>
      onFocus?: React.FocusEventHandler<HTMLInputElement>
      onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    }
  | {
      multiline: true
      onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
      onFocus?: React.FocusEventHandler<HTMLTextAreaElement>
      onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>
    }

type SizeT = 'xlg' | 'md'

type AlignT = 'left' | 'center' | 'right'

const sizeStyles: Record<SizeT, SxProps> = {
  md: {
    '--field-font-size': '14px',
    '--field-line-height': '18px',
    '--field-padding': '8px',
  },
  xlg: {
    '--field-font-size': '18px',
    '--field-line-height': '30px',
    '--field-padding': '8px',
  },
}

export type FieldPropsT = {
  label?: string
  error?: MaybeT<{
    value: boolean
    message?: string
  }>
  placeholder?: string
  value: string
  name?: string
  size?: SizeT
  align?: AlignT
  autoFocus?: boolean
  autoSelect?: boolean
  autoComplete?: string
  readOnly?: boolean
  Icon?: React.FC<React.SVGProps<SVGSVGElement>> | React.ReactElement<React.SVGProps<SVGSVGElement>>
} & InputPropsT

export const FieldLabel = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return <Box sx={{ color: themeColors.overBackgroundDefault, fontSize: '14px', lineHeight: '18px' }}>{children}</Box>
}

const FieldInput = ({
  sx = [],
  placeholder,
  value,
  name,
  align,
  autoFocus,
  autoSelect,
  autoComplete,
  readOnly,
  ...inputProps
}: {
  sx?: SxProps
  placeholder?: string
  name?: string
  value: string
  size: SizeT
  align: AlignT
  autoFocus: boolean
  autoSelect: boolean
  autoComplete?: string
  readOnly: boolean
} & InputPropsT): React.ReactElement => {
  const commonProps = {
    name,
    placeholder,
    sx: [
      {
        borderRadius: '4px',
        color: themeColors.overBackgroundBold,
        fontSize: 'var(--field-font-size)',
        lineHeight: 'var(--field-line-height)',
        padding: 'var(--field-padding)',
        resize: 'none',
        textAlign: align,
      },
      readOnly
        ? { backgroundColor: 'transparent', border: 'none', outline: 'none', paddingX: 0 }
        : {
            backgroundColor: themeColors.surfaceNeutralDefault,
            borderColor: themeColors.borderDefault,
            borderStyle: 'solid',
            borderWidth: '1px',
          },
      sx,
    ].flat(),
    value,
  }

  const autoFocusRef = useAutoFocusRef(autoFocus)

  const autoSelectRef = useAutoSelectRef(autoSelect)

  const fieldRef = useCombineRefs(autoFocusRef, autoSelectRef)

  if (inputProps.multiline) {
    const { onChange, onFocus, onKeyDown } = inputProps

    return (
      <Box
        {...commonProps}
        autoFocus={autoFocus}
        component="textarea"
        readOnly={readOnly}
        ref={fieldRef}
        rows={3}
        sx={[commonProps.sx, { resize: 'none' }].flat()}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
    )
  }

  const { onChange, onFocus, onKeyDown } = inputProps

  return (
    <Box
      {...commonProps}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      component="input"
      readOnly={readOnly}
      ref={fieldRef}
      type={inputProps.type}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
    />
  )
}

const FieldError = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return (
    <Box sx={{ color: themeColors.feedbackCritical, display: 'flex', fontSize: '14px', lineHeight: '110%' }}>
      {children}
    </Box>
  )
}

export const Field = ({
  label,
  error,
  placeholder,
  value,
  name,
  size = 'md',
  align = 'left',
  autoFocus = false,
  autoSelect = false,
  autoComplete,
  readOnly = false,
  Icon,
  ...inputProps
}: FieldPropsT): React.ReactElement => {
  const renderLabel = (): React.ReactNode => {
    if (!label) {
      return null
    }

    return <FieldLabel>{label}</FieldLabel>
  }

  const renderIcon = (): React.ReactNode => {
    if (!Icon) {
      return null
    }

    const props = {
      style: { height: '100%', width: '100%' },
    }

    return (
      <Box
        sx={{
          color: themeColors.overBackgroundDefault,
          height: 'var(--field-line-height)',
          left: 'var(--field-padding)',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: 'var(--field-line-height)',
        }}
      >
        {isValidElement(Icon) ? cloneElement(Icon, props) : <Icon {...props} />}
      </Box>
    )
  }

  const iconElement = renderIcon()

  const renderInput = (): React.ReactNode => {
    return (
      <FieldInput
        align={align}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        autoSelect={autoSelect}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        size={size}
        sx={[
          !error?.value ? null : { borderColor: themeColors.feedbackCritical },
          !iconElement
            ? null
            : { paddingLeft: 'calc(var(--field-padding) + var(--field-line-height) + var(--field-padding) / 2)' },
        ]}
        value={value}
        {...inputProps}
      />
    )
  }

  const renderError = (): React.ReactNode => {
    if (!error?.value || !error.message) {
      return null
    }

    return <FieldError>{error.message}</FieldError>
  }

  return (
    <Box sx={[{ display: 'flex', flexDirection: 'column', gap: '8px' }, sizeStyles[size]].flat()}>
      {renderLabel()}
      {foldElement(
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          {foldElement(
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              {renderInput()}
              {iconElement}
            </Box>,
          )}
          {foldElement(<Box sx={{ display: 'flex', flexDirection: 'column' }}>{renderError()}</Box>)}
        </Box>,
      )}
    </Box>
  )
}
