import { checkSetHas } from '@resnet/client-common/common/utils/set/check-set-has'

import type { MinimalEntityFragmentT } from '@resnet/client-api/api'

import { entityTypenamesSet } from '../constants/entity-typenames'

export const checkIsEntity = <ValueT extends { __typename: string }>(
  value: ValueT,
): value is Extract<ValueT, Pick<MinimalEntityFragmentT, '__typename'>> => {
  return checkSetHas(entityTypenamesSet, value.__typename)
}
