import { useMemo } from 'react'

import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import { DropdownInput } from '@resnet/client-web/shared/gdl/components/dropdown-input'
import { Popover } from '@resnet/client-web/shared/gdl/components/popover'
import { Popper, PopperContext } from '@resnet/client-web/shared/gdl/components/popper'
import { SelectMultipleDropdown } from '@resnet/client-web/shared/gdl/components/select-multiple-dropdown'
import type { StaticOptionsMultipleDropdownTextPropsT } from '@resnet/client-web/shared/gdl/components/static-options-multiple-dropdown-text'
import { StaticOptionsMultipleDropdownText } from '@resnet/client-web/shared/gdl/components/static-options-multiple-dropdown-text'
import type { AbstractSimpleOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'
import { getSimpleOptionLabel } from '@resnet/client-web/shared/gdl/utils/get-simple-option-label'
import { getSimpleOptionMedia } from '@resnet/client-web/shared/gdl/utils/get-simple-option-media'

type SimpleStaticOptionsMultipleDropdownPropsT<OptionT extends AbstractSimpleOptionT> = {
  disablePortal?: boolean
  emptyValueText?: string
  getOptionLabel?: StaticOptionsMultipleDropdownTextPropsT<OptionT>['getOptionLabel']
  getOptionMedia?: StaticOptionsMultipleDropdownTextPropsT<OptionT>['getOptionMedia']
  onChange: (nextValue: OptionT['id'][]) => void
  options: OptionT[]
  exclude?: undefined | OptionT[]
  hasError?: boolean
  placement?: React.ComponentProps<typeof Popper>['placement']
  popperRef?: React.ComponentProps<typeof Popper>['popperRef']
  value: OptionT['id'][]
  multipleOptionSuffix?: string
}

export const SimpleStaticOptionsMultipleDropdown = <OptionT extends AbstractSimpleOptionT>({
  disablePortal,
  emptyValueText,
  getOptionLabel = getSimpleOptionLabel,
  getOptionMedia = getSimpleOptionMedia,
  onChange,
  options,
  exclude,
  hasError,
  placement,
  popperRef,
  value,
  multipleOptionSuffix = 'selected',
}: SimpleStaticOptionsMultipleDropdownPropsT<OptionT>) => {
  const optionsWithoutExcluded = useMemo(
    () => (exclude ? options.filter((option) => !exclude.includes(option)) : options),
    [options, exclude],
  )

  const renderAnchor = (): React.ReactElement => {
    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, isOpened, open }) => (
          <DropdownInput
            hasError={hasError}
            isOpened={isOpened}
            ref={setAnchorEl}
            onClick={open}
          >
            <StaticOptionsMultipleDropdownText
              emptyValueText={emptyValueText}
              getOptionLabel={getOptionLabel}
              getOptionMedia={getOptionMedia}
              multipleOptionSuffix={multipleOptionSuffix}
              options={optionsWithoutExcluded}
              value={value}
            />
          </DropdownInput>
        )}
      </NonNullableContextContainer>
    )
  }

  const renderContent = (): React.ReactElement => {
    return (
      <Popover>
        <SelectMultipleDropdown
          getOptionLabel={getOptionLabel}
          getOptionMedia={getOptionMedia}
          options={optionsWithoutExcluded}
          value={value}
          onChange={onChange}
        />
      </Popover>
    )
  }

  return (
    <Popper
      disablePortal={disablePortal}
      placement={placement}
      popperRef={popperRef}
    >
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}

export const createSimpleStaticOptionsMultipleDropdownComponent =
  <OptionT extends AbstractSimpleOptionT>({ options }: { options: OptionT[] }) =>
  (props: Omit<SimpleStaticOptionsMultipleDropdownPropsT<OptionT>, 'options'>) =>
    (
      <SimpleStaticOptionsMultipleDropdown
        {...props}
        options={options}
      />
    )
