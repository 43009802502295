import { createEmitter } from '@resnet/client-common/common/utils/emitter/create-emitter'
import { useCallbackRefEffect } from '@resnet/client-common/react/hooks/use-callback-ref-effect'

const emitter = createEmitter<ResizeObserverEntry>()

const observerCallback: ResizeObserverCallback = (entries) => {
  for (const entry of entries) {
    emitter.dispatch(entry)
  }
}

const observer = new ResizeObserver(observerCallback)

type ResizeObserverListenerT = (entry: ResizeObserverEntry, observer: ResizeObserver) => void

export const subscribeResizeObserver = (element: HTMLElement, listener: ResizeObserverListenerT) => {
  const unsubscribeEmitter = emitter.subscribe((entry) => {
    if (entry.target !== element) {
      return
    }

    listener(entry, observer)
  })

  const unsubscribeObserver = (() => {
    observer.observe(element)

    return () => {
      observer.unobserve(element)
    }
  })()

  return () => {
    unsubscribeEmitter()
    unsubscribeObserver()
  }
}

export const useResizeObserver = (listener?: ResizeObserverListenerT) => {
  const elementRef = useCallbackRefEffect<HTMLElement>(
    (element) => {
      if (!listener || !element) {
        return
      }

      return subscribeResizeObserver(element, listener)
    },
    [listener],
  )

  return elementRef
}
