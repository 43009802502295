import { zip } from 'ramda'

import type { GradientT } from '@resnet/client-shared/shared/gradients/types/common'

export const mapGradientToCSSGradient = (gradient: GradientT): string => {
  const segments = zip(
    gradient.colors,
    gradient.locations.map((location) => `${location * 100}%`),
  )

  return `linear-gradient(${gradient.angle}deg, ${segments.map((segment) => segment.join(' ')).join(', ')})`
}
