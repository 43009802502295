import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { assertedNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'
import { type CreateIssuesMutationVariablesT } from '@resnet/client-api/api'

import { mapFormCustomFieldValuesToCustomFieldValues } from '@resnet/client-web/shared/custom-fields/utils/map-form-custom-field-values-to-custom-field-values'

import type { CreateIssueFormValuesT } from '../types/create-issue-form-values'

export const mapCreateIssueFormValuesToCreateIssuesMutationVariables = ({
  fields,
  values,
}: {
  fields: CustomFieldFragmentT[]
  values: CreateIssueFormValuesT
}): CreateIssuesMutationVariablesT => {
  return {
    assetIds: pipeline(values.assetIds, assertedNonUndefined),
    cloneForWells: pipeline(values.cloneForWells, assertedNonUndefined),
    issue: {
      access: pipeline(values.access, assertedNonUndefined, (x) => (!x?.private ? null : x)),
      actualEndDate: pipeline(values.actualEndDate, assertedNonUndefined),
      actualStartDate: pipeline(values.actualStartDate, assertedNonUndefined),
      afe: pipeline(values.afe, assertedNonUndefined, (x) => x.trim()),
      assigneeId: pipeline(values.assigneeId, assertedNonUndefined),
      campaignId: pipeline(values.campaignId, assertedNonUndefined),
      customFields: pipeline(values.customFields, assertedNonUndefined, (fieldValuesActual) => {
        const { fieldValues } = mapFormCustomFieldValuesToCustomFieldValues({
          fieldValues: fieldValuesActual,
          fields,
        })

        return fieldValues
      }),
      description: pipeline(values.description, assertedNonUndefined, (x) => x.trim()),
      dispatched: pipeline(values.dispatched, assertedNonUndefined),
      estimatedEndDate: pipeline(values.estimatedEndDate, assertedNonUndefined),
      estimatedStartDate: pipeline(values.estimatedStartDate, assertedNonUndefined),
      flag: pipeline(values.flag, assertedNonUndefined),
      labels: pipeline(values.labels, assertedNonUndefined),
      name: pipeline(values.name, assertedNonUndefined, (x) => x.trim()),
      needsApproval: pipeline(values.needsApproval, assertedNonUndefined),
      priority: pipeline(values.priority, assertedNonUndefined),
      resourceObjectId: pipeline(values.resourceObjectId, assertedNonUndefined),
      status: pipeline(values.status, assertedNonUndefined),
      type: pipeline(values.type, assertedNonUndefined),
    },
  }
}
