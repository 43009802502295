import { useEventCallback } from '@mui/material'

import type { UploadResourceMutationVariablesT } from '@resnet/client-api/api'
import { ResourceTypesT, useUploadResourceMutation } from '@resnet/client-api/api'

import { checkIsDraftResource } from '@resnet/client-shared/shared/files/utils/check-is-draft-resource'

import type { ResourceT } from '@resnet/client-web/shared/files/types/resource'

export const useUploadResources = () => {
  const { mutateAsync: uploadResource } = useUploadResourceMutation()

  const uploadResources = useEventCallback(
    async ({
      resources: resourcesActual,
      originId,
      originType,
    }: {
      resources: ResourceT[]
      originId: string
      originType: UploadResourceMutationVariablesT['originType']
    }) => {
      const resources = resourcesActual.filter(checkIsDraftResource)

      if (resources.length === 0) {
        return
      }

      await Promise.all(
        resources.map(async (resource) => {
          await uploadResource({
            file: resource.file,
            id: resource.id,
            originId,
            originType,
            type: resource.resourceType ?? ResourceTypesT.AttachmentT,
          })
        }),
      )
    },
  )

  return { uploadResources }
}
