import { Box } from '@mui/material'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { ErrorBoundaryFallbackEffectContainer } from '@resnet/client-common/react/hooks/use-error-boundary-fallback-effect'

import ScrewdriverWrenchSolidIcon from '@resnet/client-shared/assets/icons/screwdriver-wrench-solid.svg'
import { useEntityOption } from '@resnet/client-shared/shared/entities/hooks/use-entity-option'
import { mapEntityToEntityTypeId } from '@resnet/client-shared/shared/entities/utils/map-entity-to-entity-type-id'
import { mapEntityToMedia } from '@resnet/client-shared/shared/entities/utils/map-entity-to-media'
import { mapEntityToTitle } from '@resnet/client-shared/shared/entities/utils/map-entity-to-title'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import { mapOriginToPathname } from '@resnet/client-shared/shared/origins/utils/map-origin-to-pathname'

import { IconAvatar } from '@resnet/client-web/shared/gdl/components/avatar'
import { Link } from '@resnet/client-web/shared/gdl/components/link'
import {
  ListItemAvatar,
  ListItemLayout,
  ListItemWithAvatarSkeleton,
} from '@resnet/client-web/shared/gdl/components/list'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { disableableSx } from '@resnet/client-web/shared/gdl/sx-presets/disableable'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'

export const EntityNotFoundListItemCard = () => {
  const renderName = () => {
    return (
      <Box sx={[mapTypographyPresetToSx(typographyPresets.titleMedium), { color: themeColors.overBackgroundBold }]}>
        Entity not found
      </Box>
    )
  }

  const renderMedia = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', p: toPx(8) }}>
        <IconAvatar
          icon={<ScrewdriverWrenchSolidIcon />}
          size="md"
        />
      </Box>
    )
  }

  return (
    <ListItemLayout sx={disableableSx({ disabled: true })}>
      {{
        media: renderMedia(),
        name: renderName(),
      }}
    </ListItemLayout>
  )
}

export const EntityListItemCardContent = ({
  id,
  renderAction,
  withLink,
}: {
  id: string
  renderAction?: () => React.ReactNode
  withLink?: boolean
}) => {
  const { optionQuery: entityQuery } = useEntityOption({ id })

  if (!entityQuery.isSuccess) {
    return <ListItemWithAvatarSkeleton />
  }

  const entity = entityQuery.data

  const renderName = () => {
    return (
      <Box sx={[mapTypographyPresetToSx(typographyPresets.titleMedium), { color: themeColors.overBackgroundBold }]}>
        {mapEntityToTitle(entity)}
      </Box>
    )
  }

  const renderMedia = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', p: toPx(8) }}>
        <ListItemAvatar {...mapEntityToMedia(entity)} />
      </Box>
    )
  }

  const renderDescription = () => {
    return (
      <Box sx={[mapTypographyPresetToSx(typographyPresets.bodySmall), { color: themeColors.overBackgroundMuted }]}>
        {mapEntityToEntityTypeId(entity)}
      </Box>
    )
  }

  const renderContent = () => {
    const renderLayout = () => (
      <ListItemLayout>
        {{
          action: renderAction?.(),
          description: renderDescription(),
          media: renderMedia(),
          name: renderName(),
        }}
      </ListItemLayout>
    )

    if (withLink) {
      return (
        <Link
          sx={{ textDecoration: 'none' }}
          to={{ pathname: mapOriginToPathname(entity) }}
        >
          {renderLayout()}
        </Link>
      )
    }

    return renderLayout()
  }

  return renderContent()
}

export const EntityListItemCard = (props: React.ComponentProps<typeof EntityListItemCardContent>) => {
  return (
    <ErrorBoundary
      fallback={({ onRecover, recoveredCount }) => (
        <ErrorBoundaryFallbackEffectContainer onRecover={recoveredCount > 0 ? undefined : onRecover}>
          {() => <EntityNotFoundListItemCard />}
        </ErrorBoundaryFallbackEffectContainer>
      )}
    >
      <EntityListItemCardContent {...props} />
    </ErrorBoundary>
  )
}
