import type { OriginWithTitleT } from './map-origin-to-title'
import { mapOriginToTitle } from './map-origin-to-title'

const ORIGIN_ICON_NAME_PATTERN = /^([0-9]?)[0-9\s]*([a-zA-Z]{1})[a-zA-Z\s]*([0-9]?)/

const ORIGIN_FIRST_LETTERS_PATTERN = /\W*(\w)\w*/g

export const mapOriginToAbbr = (origin: OriginWithTitleT): string => {
  const title = mapOriginToTitle(origin)

  const regExpMatch = title.split(ORIGIN_ICON_NAME_PATTERN)

  const [, numberFirst, char, numberLast] = regExpMatch

  if (!numberFirst && !numberLast) {
    return title.trim().replace(ORIGIN_FIRST_LETTERS_PATTERN, '$1').slice(0, 2)
  }

  return [numberFirst, char, numberLast].join('')
}
