import { Box } from '@mui/material'
import classNames from 'classnames'

import styles from './styles.module.scss'

type FormFieldTitlePropsT = {
  color?: 'white' | 'gray-400'
  required?: boolean
  children: React.ReactNode
}

export const FieldTitle = ({ color = 'gray-400', required, children }: FormFieldTitlePropsT): React.ReactElement => {
  return (
    <Box
      className={classNames(styles.title, styles[color])}
      sx={{
        fontSize: '12px',
        lineHeight: '18px',
      }}
    >
      {children}
      {!required ? null : ' (required)'}
    </Box>
  )
}
