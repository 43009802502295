import type { Node as SlateNodeT } from 'slate'
import { jsx } from 'slate-hyperscript'

const checkIsText = (node: Node): node is Text => node.nodeType === Node.TEXT_NODE

const checkIsElement = (node: Node): node is HTMLElement => node.nodeType === Node.ELEMENT_NODE

export const deserialize = (node: Node, markAttributes = {}): SlateNodeT | SlateNodeT[] => {
  if (checkIsText(node)) {
    return jsx('text', markAttributes, node.textContent)
  }

  if (checkIsElement(node)) {
    const nodeAttributes: Record<string, unknown> = { ...markAttributes }

    // define attributes for text nodes
    switch (node.nodeName) {
      case 'STRONG': {
        nodeAttributes.bold = true
        break
      }
      case 'EM': {
        nodeAttributes.italic = true
        break
      }
      case 'U': {
        nodeAttributes.underline = true
        break
      }
    }

    const children = Array.from(node.childNodes)
      .map((childNode) => deserialize(childNode, nodeAttributes))
      .flat()

    if (children.length === 0) {
      children.push(jsx('text', nodeAttributes, ''))
    }

    if (node.classList.contains('mention')) {
      return jsx(
        'element',
        {
          character: node.textContent?.slice(1),
          entityTypeId: node.dataset.entityTypeId,
          mentionId: node.dataset.mentionId,
          mentionType: node.dataset.mentionType,
          type: 'mention',
        },
        [{ text: '' }],
      )
    }

    switch (node.nodeName) {
      case 'BODY': {
        return jsx('fragment', {}, children)
      }
      case 'P': {
        return jsx('element', { type: 'paragraph' }, children)
      }
      case 'OL': {
        return jsx('element', { type: 'numbered-list' }, children)
      }
      case 'UL': {
        return jsx('element', { type: 'bulleted-list' }, children)
      }
      case 'LI': {
        return jsx('element', { type: 'list-item' }, children)
      }
      default: {
        return children
      }
    }
  }

  return []
}
