import produce from 'immer'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import {
  useApproveIssueMutation,
  useCreateCommentMutation,
  useCreatePostMutation,
  useDeleteCommentMutation,
  useDeletePostMutation,
  useInfiniteActivityPostsQuery,
  useInfinitePostsQuery,
  useLikeCommentMutation,
  useLikePostMutation,
  usePinPostMutation,
  usePostQuery,
  usePostsQuery,
  useRejectIssueMutation,
  useResetIssueApprovalMutation,
  useUnlikeCommentMutation,
  useUnlikePostMutation,
  useUnpinPostMutation,
  useUpdateIssueMutation,
  useUpdatePostMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const postQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findPostQueries = ({ postId }: { postId: string }) =>
    findGetQueriesById(queryClient, usePostQuery, (data) => data.getPost, { id: postId })

  const findAllPostQueries = () => findQueries(queryClient, usePostQuery)

  const likePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikePostMutation, {
      onError: (error, { postId }) => {
        findPostQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ postId }) => {
        findPostQueries({ postId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const item = draft?.getPost

              if (!item) {
                return
              }

              Object.assign(item, { likedByMe: true, totalLikes: (item.totalLikes ?? 0) + 1 })
            }),
          )
        })
      },
      onSuccess: (data, { postId }) => {
        findPostQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikePostMutation, {
      onError: (error, { postId }) => {
        findPostQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ postId }) => {
        findPostQueries({ postId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const item = draft?.getPost

              if (!item) {
                return
              }

              Object.assign(item, { likedByMe: false, totalLikes: (item.totalLikes ?? 1) - 1 })
            }),
          )
        })
      },
      onSuccess: (data, { postId }) => {
        findPostQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCommentMutation, {
      onSuccess: (data, { postId }) => {
        findPostQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCommentMutation, {
      onSuccess: () => {
        findAllPostQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [likePostHandlers(), unlikePostHandlers(), createCommentHandlers(), deleteCommentHandlers()]
}

export const postsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findPostsQueries = ({ postId }: { postId: string }) =>
    findQueries(queryClient, usePostsQuery, (query) => {
      return assertedNonNullable(query.state.data?.listPosts?.items).some((item) => item.id === postId)
    })

  const findPostsQueriesByOriginId = ({ originId }: { originId: string }) =>
    findQueries(queryClient, usePostsQuery, (query) => {
      return query.queryKey[1]?.filter?.originId === originId
    })

  const findAllPostsQueries = () => findQueries(queryClient, usePostsQuery)

  const approveIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useApproveIssueMutation, {
      onSuccess: (data, { id }) => {
        findPostsQueriesByOriginId({ originId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const rejectIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRejectIssueMutation, {
      onSuccess: (data, { id }) => {
        findPostsQueriesByOriginId({ originId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const resetIssueApprovalHandlers = () => {
    return createMutationHookMutationCacheHandlers(useResetIssueApprovalMutation, {
      onSuccess: (data, { id }) => {
        findPostsQueriesByOriginId({ originId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createPostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreatePostMutation, {
      onSuccess: (data, { originId }) => {
        findPostsQueriesByOriginId({ originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updatePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdatePostMutation, {
      onError: (error, { id }) => {
        findPostsQueries({ postId: id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, post }) => {
        findPostsQueries({ postId: id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listPosts?.items

              if (!items) {
                return
              }

              const item = items.find((item) => item.id === id)

              if (!item) {
                return
              }

              Object.assign(item, post, {
                updatedAt: new Date().toISOString(),
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findPostsQueries({ postId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCommentMutation, {
      onSuccess: (data, { postId }) => {
        findPostsQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateIssueMutation, {
      onSuccess: (data, { id }) => {
        findPostsQueriesByOriginId({ originId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCommentMutation, {
      onSuccess: () => {
        findAllPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const likePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikePostMutation, {
      onError: (error, { postId }) => {
        findPostsQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ postId }) => {
        findPostsQueries({ postId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listPosts?.items

              if (!items) {
                return
              }

              const item = items.find((item) => item.id === postId)

              if (!item) {
                return
              }

              Object.assign(item, { likedByMe: true, totalLikes: (item.totalLikes ?? 0) + 1 })
            }),
          )
        })
      },
      onSuccess: (data, { postId }) => {
        findPostsQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikePostMutation, {
      onError: (error, { postId }) => {
        findPostsQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ postId }) => {
        findPostsQueries({ postId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listPosts?.items

              if (!items) {
                return
              }

              const item = items.find((item) => item.id === postId)

              if (!item) {
                return
              }

              Object.assign(item, { likedByMe: false, totalLikes: (item.totalLikes ?? 1) - 1 })
            }),
          )
        })
      },
      onSuccess: (data, { postId }) => {
        findPostsQueries({ postId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deletePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeletePostMutation, {
      onError: (error, { id }) => {
        findPostsQueries({ postId: id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findPostsQueries({ postId: id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listPosts?.items

              if (!items) {
                return
              }

              const itemIndex = items.findIndex((item) => item.id === id)

              if (itemIndex < 0) {
                return
              }

              items.splice(itemIndex, 1)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findPostsQueries({ postId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const likeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikeCommentMutation, {
      onSuccess: () => {
        findAllPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikeCommentMutation, {
      onSuccess: () => {
        findAllPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const pinPostHandlers = () => {
    return createMutationHookMutationCacheHandlers(usePinPostMutation, {
      onError: (error, { id }) => {
        findPostsQueries({ postId: id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findPostsQueries({ postId: id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listPosts?.items

              if (!items) {
                return
              }

              const item = items.find((item) => item.id === id)

              if (!item || item.__typename === 'BotPost') {
                return
              }

              Object.assign(item, { pinned: { at: new Date().toISOString() } })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findPostsQueries({ postId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unpinPostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnpinPostMutation, {
      onError: (error, { id }) => {
        findPostsQueries({ postId: id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findPostsQueries({ postId: id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listPosts?.items

              if (!items) {
                return
              }

              const item = items.find((item) => item.id === id)

              if (!item || item.__typename === 'BotPost') {
                return
              }

              delete item.pinned
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findPostsQueries({ postId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    approveIssueHandlers(),
    rejectIssueHandlers(),
    resetIssueApprovalHandlers(),
    createPostHandlers(),
    updatePostHandlers(),
    createCommentHandlers(),
    updateIssueHandlers(),
    deleteCommentHandlers(),
    likePostHandlers(),
    unlikePostHandlers(),
    deletePostHandlers(),
    likeCommentHandlers(),
    unlikeCommentHandlers(),
    pinPostHandlers(),
    unpinPostHandlers(),
  ]
}

export const infinitePostsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfinitePostsQueries = () => findInfiniteQueries(queryClient, useInfinitePostsQuery)

  const createPostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreatePostMutation, {
      onSuccess: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updatePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdatePostMutation, {
      onError: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, post }) => {
        findInfinitePostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              for (const page of pages) {
                const items = page.listPosts?.items

                if (!items) {
                  continue
                }

                const item = items.find((item) => item.id === id)

                if (!item) {
                  continue
                }

                Object.assign(item, post, {
                  updatedAt: new Date().toISOString(),
                })

                return
              }
            }),
          )
        })
      },
      onSuccess: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const likePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikePostMutation, {
      onError: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ postId }) => {
        findInfinitePostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              for (const page of pages) {
                const items = page.listPosts?.items

                if (!items) {
                  continue
                }

                const item = items.find((item) => item.id === postId)

                if (!item) {
                  continue
                }

                Object.assign(item, { likedByMe: true, totalLikes: (item.totalLikes ?? 0) + 1 })

                return
              }
            }),
          )
        })
      },
      onSuccess: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikePostMutation, {
      onError: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ postId }) => {
        findInfinitePostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              for (const page of pages) {
                const items = page.listPosts?.items

                if (!items) {
                  continue
                }

                const item = items.find((item) => item.id === postId)

                if (!item) {
                  continue
                }

                Object.assign(item, { likedByMe: false, totalLikes: (item.totalLikes ?? 1) - 1 })

                return
              }
            }),
          )
        })
      },
      onSuccess: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deletePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeletePostMutation, {
      onError: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInfinitePostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              for (const page of pages) {
                const items = page.listPosts?.items

                if (!items) {
                  continue
                }

                const itemIndex = items.findIndex((item) => item.id === id)

                if (itemIndex < 0) {
                  continue
                }

                items.splice(itemIndex, 1)

                return
              }
            }),
          )
        })
      },
      onSuccess: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCommentMutation, {
      onSuccess: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCommentMutation, {
      onSuccess: () => {
        findInfinitePostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createPostHandlers(),
    updatePostHandlers(),
    likePostHandlers(),
    unlikePostHandlers(),
    deletePostHandlers(),
    createCommentHandlers(),
    deleteCommentHandlers(),
  ]
}

export const infiniteActivityPostsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteActivityPostsQueries = () => findInfiniteQueries(queryClient, useInfiniteActivityPostsQuery)

  const createPostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreatePostMutation, {
      onSuccess: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updatePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdatePostMutation, {
      onError: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, post }) => {
        findInfiniteActivityPostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              for (const page of pages) {
                const items = page.listActivityPosts?.items

                if (!items) {
                  continue
                }

                const item = items.find((item) => item.id === id)

                if (!item) {
                  continue
                }

                Object.assign(item, post, {
                  updatedAt: new Date().toISOString(),
                })

                return
              }
            }),
          )
        })
      },
      onSuccess: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const likePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikePostMutation, {
      onError: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ postId }) => {
        findInfiniteActivityPostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              for (const page of pages) {
                const items = page.listActivityPosts?.items

                if (!items) {
                  continue
                }

                const item = items.find((item) => item.id === postId)

                if (!item) {
                  continue
                }

                Object.assign(item, { likedByMe: true, totalLikes: (item.totalLikes ?? 0) + 1 })

                return
              }
            }),
          )
        })
      },
      onSuccess: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikePostMutation, {
      onError: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ postId }) => {
        findInfiniteActivityPostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              for (const page of pages) {
                const items = page.listActivityPosts?.items

                if (!items) {
                  continue
                }

                const item = items.find((item) => item.id === postId)

                if (!item) {
                  continue
                }

                Object.assign(item, { likedByMe: false, totalLikes: (item.totalLikes ?? 1) - 1 })

                return
              }
            }),
          )
        })
      },
      onSuccess: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deletePostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeletePostMutation, {
      onError: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInfiniteActivityPostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              for (const page of pages) {
                const items = page.listActivityPosts?.items

                if (!items) {
                  continue
                }

                const itemIndex = items.findIndex((item) => item.id === id)

                if (itemIndex < 0) {
                  continue
                }

                items.splice(itemIndex, 1)

                return
              }
            }),
          )
        })
      },
      onSuccess: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCommentMutation, {
      onSuccess: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCommentMutation, {
      onSuccess: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const pinPostHandlers = () => {
    return createMutationHookMutationCacheHandlers(usePinPostMutation, {
      onError: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInfiniteActivityPostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              pages.forEach((page) => {
                const items = page.listActivityPosts?.items

                if (!items) {
                  return
                }

                const item = items.find((item) => item.id === id)

                if (!item) {
                  return
                }

                Object.assign(item, { pinned: { at: new Date().toISOString() } })
              })
            }),
          )
        })
      },
      onSuccess: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unpinPostHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnpinPostMutation, {
      onError: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInfiniteActivityPostsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              pages.forEach((page) => {
                const items = page.listActivityPosts?.items

                if (!items) {
                  return
                }

                const item = items.find((item) => item.id === id)

                if (!item) {
                  return
                }

                delete item.pinned
              })
            }),
          )
        })
      },
      onSuccess: () => {
        findInfiniteActivityPostsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createPostHandlers(),
    updatePostHandlers(),
    likePostHandlers(),
    unlikePostHandlers(),
    deletePostHandlers(),
    createCommentHandlers(),
    deleteCommentHandlers(),
    pinPostHandlers(),
    unpinPostHandlers(),
  ]
}
