import type { DateCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/date/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import { DateFormUserFormPreview } from './components/date-custom-field-user-form-preview'

export const renderUserFormPreview: CustomFieldT['renderUserFormPreview'] = ({ field, value }) => {
  return (
    <DateFormUserFormPreview
      field={field}
      value={value as undefined | DateCustomFieldValueT}
    />
  )
}
