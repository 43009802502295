import { useCallback } from 'react'
import { useSlate } from 'slate-react'

import type { CustomFormatsT } from './types'
import { isMarkActive, toggleMark } from './utils/common'

type FormatButtonContainerPropsT = {
  format: CustomFormatsT
  children: (props: { isActive: boolean; onClick: React.MouseEventHandler<HTMLButtonElement> }) => React.ReactElement
}

export const FormatButtonContainer = ({ format, children }: FormatButtonContainerPropsT): React.ReactElement => {
  const editor = useSlate()
  const isActive = isMarkActive(editor, format)

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      toggleMark(editor, format)
    },
    [editor, format],
  )

  return children({ isActive, onClick })
}
