import type { UseQueryOptions } from '@tanstack/react-query'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { GetEntityQueryT, MinimalEntityFragmentT, MinimalOriginFragmentT } from '@resnet/client-api/api'
import { type GroupFragmentT, type GroupQueryT } from '@resnet/client-api/api'
import type { UseQueryResult } from '@resnet/client-api/vendors/react-query'

import type { UseEntityOptionPropsT } from '../../entities/hooks/use-entity-option'
import { useEntityOption } from '../../entities/hooks/use-entity-option'
import { useGroupOption, type UseGroupOptionPropsT } from '../../groups/hooks/use-group-option'

export type UseOriginOptionPropsT = {
  id: string
  type: string
  options?:
    | UseQueryOptions<GetEntityQueryT, unknown, MinimalEntityFragmentT>
    | UseQueryOptions<GroupQueryT, unknown, GroupFragmentT>
}

export const useOriginOption = ({ id, type, options }: UseOriginOptionPropsT) => {
  const { optionQuery } = (() => {
    switch (type) {
      case 'ENTITY': {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useEntityOption({ id, options } as unknown as UseEntityOptionPropsT<MinimalEntityFragmentT>)
      }
      case 'GROUP': {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useGroupOption({ id, options } as unknown as UseGroupOptionPropsT)
      }
      default: {
        throw new Error(`Invalid origin type: ${type}`)
      }
    }
  })() as { optionQuery: UseQueryResult<MinimalOriginFragmentT> }

  return { optionQuery }
}

export const OriginOptionContainer = createHookContainer(useOriginOption)
