import { Box } from '@mui/material'
import { useMemo } from 'react'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import { FilterModeT } from '@resnet/client-api/api'

import { checkIsSkeletonItem, useLoadableList } from '@resnet/client-web/shared/async/hooks/use-loadable-list'
import { VirtualizedList, calculateMaxHeight } from '@resnet/client-web/shared/gdl/components/virtualized-list'
import type { AbstractOptionT } from '@resnet/client-web/shared/gdl/types/abstract-option'
import type { useIncludeExcludeFilterSelectMultipleDropdown } from '@resnet/client-web/shared/presets/hooks/use-include-exclude-filter-select-multiple-dropdown'

import { Button } from '../../components/button'
import { Divider } from '../../components/divider'
import { CheckboxListItem, CheckboxListItemSkeleton } from '../checkbox-list'
import type { SelectDropdown } from '../select-dropdown'
import { SelectDropdownMessagePanel } from '../select-dropdown/components/select-dropdown-message-panel'
import { nullOptionHeight, nullOptionId } from '../select-dropdown/constants/null-option'
import { selectDropdownSizesConfig } from '../select-dropdown/constants/select-dropdown-config'
import { getSelectOptionLabel } from '../select-dropdown/utils/get-select-option-label'

type SelectMultipleDropdownPropsT<OptionT extends AbstractOptionT> = Omit<
  React.ComponentProps<typeof SelectDropdown<OptionT>>,
  'value' | 'onChange'
> &
  Partial<
    Pick<ReturnType<typeof useIncludeExcludeFilterSelectMultipleDropdown>, 'onClear' | 'onSelectAll' | 'mode'>
  > & {
    value: undefined | null | OptionT['id'][]
    onChange?: (value: OptionT['id'][]) => void
  }

export const SelectMultipleDropdown = <OptionT extends AbstractOptionT>({
  search,
  options,
  getOptionMedia,
  getOptionLabel = getSelectOptionLabel,
  getOptionDescription,
  notFoundMessage = 'No options found',
  bottomBoundary,
  isLoading = false,
  value,
  onChange,
  error,
  mode = FilterModeT.IncludeT,
  onSelectAll,
  onClear,
  optionHeight = selectDropdownSizesConfig.optionHeight,
  skeletonProps,
  header,
}: SelectMultipleDropdownPropsT<OptionT>): React.ReactElement => {
  if (!options || !value) {
    throw new Error('should be non nullable')
  }

  const { data } = useLoadableList({
    data: options,
    isFetching: isLoading,
    skeletonsCount: selectDropdownSizesConfig.maxVisibleOptions,
  })

  const selected = useMemo(() => new Set(value), [value])

  const isExcludeMode = mode === FilterModeT.ExcludeT

  const renderSelectAllControls = () => {
    if (!onSelectAll || !onClear || !value || !options?.length) {
      return null
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color="primary"
          disabled={isExcludeMode && value.length === 0}
          size="md"
          variant="outlined"
          onClick={onSelectAll}
        >
          Select All
        </Button>
        <Button
          color="primary"
          disabled={!isExcludeMode && value.length === 0}
          size="md"
          variant="outlined"
          onClick={onClear}
        >
          Clear
        </Button>
      </Box>
    )
  }

  const renderOptions = (): React.ReactNode => {
    if (error) {
      return (
        <Box sx={{ py: `${selectDropdownSizesConfig.listPadding}px` }}>
          <SelectDropdownMessagePanel>{error}</SelectDropdownMessagePanel>
        </Box>
      )
    }

    if (data.length === 0) {
      return (
        <Box sx={{ py: `${selectDropdownSizesConfig.listPadding}px` }}>
          <SelectDropdownMessagePanel>{notFoundMessage}</SelectDropdownMessagePanel>
        </Box>
      )
    }

    const unselectOption = (option: OptionT) => {
      onChange?.(value.filter((selectedOptionId) => selectedOptionId !== option.id))
    }

    const selectOption = (option: OptionT) => {
      onChange?.([...value, option.id])
    }

    return (
      <VirtualizedList
        data={data}
        gap={selectDropdownSizesConfig.gap}
        getItemSize={(item) => {
          if (item.id === nullOptionId) {
            return nullOptionHeight
          }

          return optionHeight
        }}
        maxHeight={calculateMaxHeight({ ...selectDropdownSizesConfig, optionHeight })}
        onEndReached={() => bottomBoundary?.onObserve?.()}
      >
        {({ item: option, style }) => {
          const isSelected = selected.has(option.id)
          const isSelectedVisual = isExcludeMode ? !isSelected : isSelected

          return (
            <Box
              key={option.id}
              style={style}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              {checkIsSkeletonItem(option) ? (
                <CheckboxListItemSkeleton
                  sx={{ height: optionHeight }}
                  {...skeletonProps}
                />
              ) : (
                <CheckboxListItem
                  showNameTooltip
                  description={getOptionDescription?.(option)}
                  isSelected={isSelectedVisual}
                  media={getOptionMedia?.(option)}
                  name={getOptionLabel(option)}
                  onClick={() => {
                    if (isSelected) {
                      unselectOption(option)
                    } else {
                      selectOption(option)
                    }
                  }}
                />
              )}
            </Box>
          )
        }}
      </VirtualizedList>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <ChildrenDivider dividerNode={<Divider />}>
        {search ?? null}
        {header ?? null}
        {renderOptions()}
        {renderSelectAllControls()}
      </ChildrenDivider>
    </Box>
  )
}
