import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { DiscriminatorCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/discriminator/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { DiscriminatorFormCustomFieldValueT } from './form-custom-field-value'

export const mapCustomFieldToDefaultValue: CustomFieldT<
  DiscriminatorCustomFieldValueT,
  DiscriminatorFormCustomFieldValueT
>['mapCustomFieldToDefaultValue'] = ({ field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const discriminatorPayload = payload[CustomFieldTypeT.DiscriminatorT]

  assert(discriminatorPayload, checkNonNullable)

  const { defaultValue = null } = discriminatorPayload

  return defaultValue
}
