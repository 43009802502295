import type { CheckboxCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/checkbox/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import { CheckboxFormUserFormPreview } from './components/checkbox-custom-field-user-form-preview'

export const renderUserFormPreview: CustomFieldT['renderUserFormPreview'] = ({ field, value }) => {
  return (
    <CheckboxFormUserFormPreview
      field={field}
      value={value as undefined | CheckboxCustomFieldValueT}
    />
  )
}
