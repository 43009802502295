import { ObjectId } from 'bson'

import type { ResourceTypesT } from '@resnet/client-api/api'

import { mapDataUrlToMimetype } from '@resnet/client-shared/shared/files/utils/map-data-url-to-mimetype'
import { mapMimetypeToExtension } from '@resnet/client-shared/shared/resources/utils/map-mimetype-to-extension'

import type { DraftResourceT } from '../../types/draft-resource'
import { mapDataUrlToBlob } from '../map-data-url-to-blob'

export const mapDataUrlToDraftResource = async (dataUrl: string, type?: ResourceTypesT): Promise<DraftResourceT> => {
  const mimetype = mapDataUrlToMimetype(dataUrl)

  const extension = mapMimetypeToExtension(mimetype)

  const id = new ObjectId().toHexString()

  const blob = await mapDataUrlToBlob(dataUrl)

  const filename = [id, extension].join('.')

  const file = new File([blob], filename, { lastModified: Date.now(), type: mimetype })

  return {
    __typename: '@DraftResource',
    file,
    filename: file.name,
    id,
    mimetype,
    resourceType: type,
    url: dataUrl,
  }
}
