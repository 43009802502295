import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import type { HTTPClientT } from '@resnet/client-common/network/utils/create-http-client'
import { createHTTPClient } from '@resnet/client-common/network/utils/create-http-client'

const getAuthApiHttpClientBaseUrl = (tenant: string): string => {
  const baseUrl = process.env.PUBLIC_AUTH_API_ENDPOINT

  if (baseUrl !== undefined) {
    return baseUrl
  }

  switch (tenant) {
    case 'dev': {
      return 'https://auth.dev.resnet.ai'
    }
    case 'demo':
    case 'test': {
      return 'https://auth.staging.resnet.ai'
    }
    default: {
      return 'https://auth.resnet.ai'
    }
  }
}

let authHttpClient: null | HTTPClientT = null

export const getAuthApiHttpClient = (): HTTPClientT => {
  return assertedNonNullable(authHttpClient)
}

export const setAuthApiHttpClient = (tenant: string): void => {
  authHttpClient = createHTTPClient(getAuthApiHttpClientBaseUrl(tenant))
}
