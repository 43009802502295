import type { NumberCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/number/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import { NumberFormUserFormPreview } from './components/number-custom-field-user-form-preview'

export const renderUserFormPreview: CustomFieldT['renderUserFormPreview'] = ({ field, value }) => {
  return (
    <NumberFormUserFormPreview
      field={field}
      value={value as undefined | NumberCustomFieldValueT}
    />
  )
}
