import { Box } from '@mui/material'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { ErrorBoundaryFallbackEffectContainer } from '@resnet/client-common/react/hooks/use-error-boundary-fallback-effect'

import UserSolidIcon from '@resnet/client-shared/assets/icons/user-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import { useUserOption } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToDisplayNickname } from '@resnet/client-shared/shared/users/utils/map-user-to-display-nickname'
import { mapUserToMedia } from '@resnet/client-shared/shared/users/utils/map-user-to-media-boston'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { IconAvatar } from '@resnet/client-web/shared/gdl/components/avatar'
import { Link } from '@resnet/client-web/shared/gdl/components/link'
import {
  ListItemAvatar,
  ListItemLayout,
  ListItemWithAvatarSkeleton,
} from '@resnet/client-web/shared/gdl/components/list'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { disableableSx } from '@resnet/client-web/shared/gdl/sx-presets/disableable'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-web/shared/gdl/utils/to-px'
import { mapUserToPathname } from '@resnet/client-web/shared/users/utils/map-user-to-pathname'

export const UserNotFoundListItemCard = () => {
  const renderName = () => {
    return (
      <Box sx={[mapTypographyPresetToSx(typographyPresets.titleMedium), { color: themeColors.overBackgroundBold }]}>
        User not found
      </Box>
    )
  }

  const renderMedia = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', p: toPx(8) }}>
        <IconAvatar
          icon={<UserSolidIcon />}
          size="md"
        />
      </Box>
    )
  }

  return (
    <ListItemLayout sx={disableableSx({ disabled: true })}>
      {{
        media: renderMedia(),
        name: renderName(),
      }}
    </ListItemLayout>
  )
}

export const UserListItemCardContent = ({
  id,
  renderAction,
  withLink,
}: {
  id: string
  renderAction?: () => React.ReactNode
  withLink?: boolean
}) => {
  const { optionQuery: userQuery } = useUserOption({ id })

  if (!userQuery.isSuccess) {
    return <ListItemWithAvatarSkeleton />
  }

  const user = userQuery.data

  const renderName = () => {
    return (
      <Box sx={[mapTypographyPresetToSx(typographyPresets.titleMedium), { color: themeColors.overBackgroundBold }]}>
        {mapUserToTitle(user)}
      </Box>
    )
  }

  const renderMedia = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', p: toPx(8) }}>
        <ListItemAvatar {...mapUserToMedia(user)} />
      </Box>
    )
  }

  const renderDescription = () => {
    return (
      <Box sx={[mapTypographyPresetToSx(typographyPresets.bodySmall), { color: themeColors.overBackgroundMuted }]}>
        {mapUserToDisplayNickname(user)}
      </Box>
    )
  }

  const renderContent = () => {
    const renderLayout = () => (
      <ListItemLayout>
        {{
          action: renderAction?.(),
          description: renderDescription(),
          media: renderMedia(),
          name: renderName(),
        }}
      </ListItemLayout>
    )

    if (withLink) {
      return (
        <Link
          sx={{ textDecoration: 'none' }}
          to={{ pathname: mapUserToPathname(user) }}
        >
          {renderLayout()}
        </Link>
      )
    }

    return renderLayout()
  }

  return renderContent()
}

export const UserListItemCard = (props: React.ComponentProps<typeof UserListItemCardContent>) => {
  return (
    <ErrorBoundary
      fallback={({ onRecover, recoveredCount }) => (
        <ErrorBoundaryFallbackEffectContainer onRecover={recoveredCount > 0 ? undefined : onRecover}>
          {() => <UserNotFoundListItemCard />}
        </ErrorBoundaryFallbackEffectContainer>
      )}
    >
      <UserListItemCardContent {...props} />
    </ErrorBoundary>
  )
}
