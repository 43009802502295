import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { fullDateFormatter } from '@resnet/client-common/format/utils/date/full-date-formatter'
import { fullDateTimeFormatter } from '@resnet/client-common/format/utils/date/full-date-time-formatter'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { DateCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/date/custom-field-value'

import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'

import type { CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createColumn: CustomFieldT['createColumn'] = ({ field, fieldValuesKey }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const datePayload = payload[CustomFieldTypeT.DateT]

  assert(datePayload, checkNonNullable)

  const { addTimeEnabled } = datePayload

  const formatter = addTimeEnabled ? fullDateTimeFormatter : fullDateFormatter

  const getValue = (row: Record<string, unknown>) => getFieldValue<DateCustomFieldValueT>(field, fieldValuesKey, row)

  return {
    field: mapCustomFieldToFullKey(field, fieldValuesKey),
    flex: 1,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      return <RegularCell>{groupingKey}</RegularCell>
    },
    groupable: true,
    groupingValueGetter: (params) => {
      const value = getValue(params.row)

      if (value === undefined || value === null) {
        return undefined
      }

      const text = pipeline(
        value,
        (x) => (!checkNonNullable(x) ? x : new Date(x)),
        (x) => (!checkNonNullable(x) ? x : formatter.format(x)),
      )

      return text
    },
    headerName: field.name,
    minWidth: 150,
    renderCell: (params) => {
      const value = params.value as ReturnType<typeof getValue>

      if (value === undefined || value === null) {
        return <RegularCell>-</RegularCell>
      }

      const text = pipeline(
        value,
        (x) => (!checkNonNullable(x) ? x : new Date(x)),
        (x) => (!checkNonNullable(x) ? x : formatter.format(x)),
      )

      return <RegularCell>{text}</RegularCell>
    },
    sortable: true,
    valueGetter: (params) => {
      const value = getValue(params.row)

      return value
    },
  }
}
