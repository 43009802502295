import { Box } from '@mui/material'

import type { ColorT } from '@resnet/client-api/api'

import { pickerColorPresets } from '@resnet/client-shared/shared/gdl/constants/picker-color-presets'

import { themeColors } from '../../constants/theme-colors'
import { ColorPickerButton } from '../color-picker-button'
import { ColorSelectorButton } from '../color-selector-button'
import { Outline } from '../outline'

export type PresetColorSelectorPropsT = {
  value?: ColorT
  onChange: (value: ColorT) => void
  hasError?: boolean
  renderCustomButtons?: () => React.ReactNode
  disablePickerPopoverPortal?: boolean
}

export const PresetColorSelector = ({
  value,
  onChange,
  hasError,
  renderCustomButtons,
  disablePickerPopoverPortal,
}: PresetColorSelectorPropsT): React.ReactNode => {
  const renderButtons = () => {
    return pickerColorPresets.map((item) => {
      return (
        <ColorSelectorButton
          color={item.value}
          key={item.id}
          selected={value?.preset === item.id}
          onClick={() => onChange({ preset: item.id })}
        />
      )
    })
  }

  const renderCustomColorButton = () => {
    return (
      <ColorPickerButton
        disablePortal={disablePickerPopoverPortal}
        placement="bottom-end"
        selected={Boolean(value?.custom)}
        value={value?.custom}
        onChange={(nextCustomValue) =>
          onChange({
            custom: nextCustomValue,
          })}
      />
    )
  }

  return (
    <Box
      sx={[
        {
          backgroundColor: themeColors.surfaceNeutralDefault,
          borderRadius: '8px',
          display: 'flex',
          gap: '8px',
          padding: '8px',
          position: 'relative',
        },
      ]}
    >
      {renderCustomButtons?.()}
      {renderButtons()}
      {renderCustomColorButton()}
      {!hasError ? null : (
        <Outline
          color="feedbackCritical"
          sx={{ borderRadius: '8px' }}
        />
      )}
    </Box>
  )
}
