import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { AssetEntityTypeIdT } from '@resnet/client-api/api'

export const assetTypeOptions = [
  {
    id: AssetEntityTypeIdT.WellT as const,
    name: 'Well',
    type: AssetEntityTypeIdT.WellT as const,
  },
  {
    id: AssetEntityTypeIdT.BatteryT as const,
    name: 'Battery',
    type: AssetEntityTypeIdT.BatteryT as const,
  },
  {
    id: AssetEntityTypeIdT.SiteT as const,
    name: 'Site',
    type: AssetEntityTypeIdT.SiteT as const,
  },
]

export const assetTypeOptionsById = propToKey('type', assetTypeOptions)
