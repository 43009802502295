import { hexToRgbaArray } from './hex-to-rgba-array'

export const mapColorToBrightness = (color: string): 'light' | 'dark' => {
  const [r, g, b] = hexToRgbaArray(color).map((x) => x / 255)

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)

  const luminance = (max + min) / 2

  return luminance > 0.5 ? 'light' : 'dark'
}
