import { useMemo } from 'react'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { SuccessSimpleQueryT } from '../types/query'

// is used when you don't need to fetch data

export const useSuccessOptionQuery = <OptionIdT extends string>({ id }: { id: OptionIdT }) => {
  const optionQuery = useMemo<SuccessSimpleQueryT<{ id: OptionIdT }>>(
    () => ({
      data: { id },
      isSuccess: true,
    }),
    [id],
  )

  return { optionQuery }
}

export const SuccessOptionQueryContainer = createHookContainer(useSuccessOptionQuery)
