import { useCallback } from 'react'

import { MemoProvider } from '@resnet/client-common/react/components/memo-provider'
import { useRefInitializer } from '@resnet/client-common/react/hooks/use-ref-initializer'

import { FocusTrapRegistryContext } from '../focus-trap-registry-context'

export const FocusTrapRegistryProvider = ({ children }: { children: React.ReactNode }) => {
  const registryRef = useRefInitializer<Set<string>>(() => new Set())

  const register = useCallback(
    (id: string) => {
      registryRef.current.add(id)

      return () => {
        registryRef.current.delete(id)
      }
    },
    [registryRef],
  )

  const unregister = useCallback(
    (id: string) => {
      registryRef.current.delete(id)
    },
    [registryRef],
  )

  return (
    <MemoProvider
      Context={FocusTrapRegistryContext}
      value={{ register, registryRef, unregister }}
    >
      {children}
    </MemoProvider>
  )
}
