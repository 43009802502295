import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { TextOverflow } from '@resnet/client-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-web/shared/gdl/utils/map-typography-preset-to-sx'

const messageCardNameSx = [
  mapTypographyPresetToSx(typographyPresets.labelMedium),
  { color: themeColors.overBackgroundBold, display: 'flex' },
]

export type MessageCardNamePropsT = BoxProps

export const MessageCardName = ({ sx = null, children, ...props }: MessageCardNamePropsT) => {
  return (
    <Box
      {...props}
      sx={[messageCardNameSx, sx].flat()}
    >
      <TextOverflow>{children}</TextOverflow>
    </Box>
  )
}

export type MessageCardNameLinkPropsT = MergeT<BoxProps<'a'>, LinkProps>

export const MessageCardNameLink = ({ sx = null, children, ...props }: MessageCardNameLinkPropsT) => {
  return (
    <Box
      {...props}
      component={Link}
      sx={[{ all: 'unset' }, messageCardNameSx, { cursor: 'pointer', position: 'relative' }, sx].flat()}
    >
      <TextOverflow>{children}</TextOverflow>
    </Box>
  )
}
