import { Fragment } from 'react'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'

import { customFieldsById } from '@resnet/client-web/shared/custom-fields/constants/custom-fields'
import { useCheckUserFormFieldIsNotHiddenByDiscriminatorCallback } from '@resnet/client-web/shared/custom-fields/hooks/use-check-user-form-field-is-not-hidden-by-discriminator-callback'
import type { FormCustomFieldValueT } from '@resnet/client-web/shared/custom-fields/types/form-custom-field-value'
import { ObjectDetailsGroup } from '@resnet/client-web/shared/object/components/object-details-group'
import { ObjectDetailsSectionHeader } from '@resnet/client-web/shared/object/components/object-details-section-header'

import type { FormCustomFieldValuesT } from '../../types/form-custom-field-values'
import { mapCustomFieldToKey } from '../../utils/map-custom-field-to-key'

export type CustomFieldsUserFormFieldsPropsT<TFieldValues extends FieldValues> = {
  title?: string
  fields: CustomFieldFragmentT[]
  fieldValuesName: FieldPathByValue<TFieldValues, undefined | FormCustomFieldValuesT>
  form: UseFormReturn<TFieldValues>
}

export const CustomFieldsUserFormFields = <TFieldValues extends FieldValues>({
  title = 'Custom Fields',
  fields,
  fieldValuesName,
  form,
}: CustomFieldsUserFormFieldsPropsT<TFieldValues>) => {
  const { checkUserFormFieldIsNotHiddenByDiscriminator } = useCheckUserFormFieldIsNotHiddenByDiscriminatorCallback({
    fields,
    form,
    name: fieldValuesName,
  })

  const renderHeader = () => {
    return <ObjectDetailsSectionHeader>{title}</ObjectDetailsSectionHeader>
  }

  return (
    <ObjectDetailsGroup>
      {renderHeader()}
      {fields.filter(checkUserFormFieldIsNotHiddenByDiscriminator).map((field, index) => {
        const { renderUserFormField } = customFieldsById[field.type]

        type PathT = FieldPathByValue<TFieldValues, FormCustomFieldValueT>

        const name = `${fieldValuesName}.${mapCustomFieldToKey(field)}` as PathT

        return (
          <Fragment key={field.id}>
            <ErrorBoundary>
              {renderUserFormField({
                field,
                fieldValuesName,
                form: form as unknown as UseFormReturn<FieldValues>,
                index,
                name,
              })}
            </ErrorBoundary>
          </Fragment>
        )
      })}
    </ObjectDetailsGroup>
  )
}
