import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { IssuePriorityT } from '@resnet/client-api/api'

import IssuePriorityHighIcon from '@resnet/client-shared/assets/icons/issue-priority/high.svg'
import IssuePriorityHighestIcon from '@resnet/client-shared/assets/icons/issue-priority/highest.svg'
import IssuePriorityLowIcon from '@resnet/client-shared/assets/icons/issue-priority/low.svg'
import IssuePriorityLowestIcon from '@resnet/client-shared/assets/icons/issue-priority/lowest.svg'
import IssuePriorityRegularIcon from '@resnet/client-shared/assets/icons/issue-priority/regular.svg'

export const ISSUE_PRIORITY_PROPS = {
  [IssuePriorityT.LowestT]: { Icon: IssuePriorityLowestIcon, name: 'Lowest' },
  [IssuePriorityT.LowT]: { Icon: IssuePriorityLowIcon, name: 'Low' },
  [IssuePriorityT.MediumT]: { Icon: IssuePriorityRegularIcon, name: 'Medium' },
  [IssuePriorityT.HighT]: { Icon: IssuePriorityHighIcon, name: 'High' },
  [IssuePriorityT.HighestT]: { Icon: IssuePriorityHighestIcon, name: 'Highest' },
}

export const issuePriorities = [
  { id: IssuePriorityT.LowestT as const, name: 'Lowest' },
  { id: IssuePriorityT.LowT as const, name: 'Low' },
  { id: IssuePriorityT.MediumT as const, name: 'Medium' },
  { id: IssuePriorityT.HighT as const, name: 'High' },
  { id: IssuePriorityT.HighestT as const, name: 'Highest' },
]

export const issuePrioritiesById = propToKey('id', issuePriorities)
