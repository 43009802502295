import { Box, type BoxProps } from '@mui/material'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import type { ListChildComponentProps, ListProps } from 'react-window'
import { VariableSizeList } from 'react-window'

import { sumAll } from '@resnet/client-common/common/utils/array/sum-all'
import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNull } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { MemoProvider } from '@resnet/client-common/react/components/memo-provider'
import { useCombineRefs } from '@resnet/client-common/react/hooks/use-combine-refs'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { EndBoundary } from '../bottom-boundary'

type RenderElementT = (props: BoxProps, ref: React.ForwardedRef<HTMLElement>) => React.ReactElement

type VirtualizedListT = {
  endBoundaryOffset?: number
  gap: number
  layout?: 'vertical' | 'horizontal'
  onEndReached?: () => void
  paddingLeft: number
  paddingTop: number
  renderInnerElement?: RenderElementT
  renderItem: (props: { item: unknown; style: React.CSSProperties; index: number }) => React.ReactElement
  renderOuterElement?: RenderElementT
}

export const calculateMaxHeight = (listConfig: { optionHeight: number; maxVisibleOptions: number; gap: number }) => {
  return listConfig.optionHeight * listConfig.maxVisibleOptions + listConfig.gap * (listConfig.maxVisibleOptions - 1)
}

const VirtualizedListContext = createContext<null | VirtualizedListT>(null)

const defaultRenderElement: RenderElementT = (props, ref) => {
  return (
    <Box
      ref={ref}
      {...props}
    />
  )
}

const OuterElementType = forwardFunctionalComponentRef((props: BoxProps, ref: React.ForwardedRef<HTMLElement>) => {
  const virtualizedList = useContext(VirtualizedListContext)

  assert(virtualizedList, checkNonNull)

  const { renderOuterElement = defaultRenderElement, layout } = virtualizedList

  const isHorizontalList = layout === 'horizontal'

  return renderOuterElement(
    { ...props, sx: [isHorizontalList ? { display: 'flex' } : null, props.sx ?? null].flat() },
    ref,
  )
})

const InnerElementType = forwardFunctionalComponentRef((props: BoxProps, ref: React.ForwardedRef<HTMLElement>) => {
  const virtualizedList = useContext(VirtualizedListContext)

  assert(virtualizedList, checkNonNull)

  const { renderInnerElement = defaultRenderElement, onEndReached, endBoundaryOffset, layout } = virtualizedList

  const isHorizontalList = layout === 'horizontal'

  const renderEndBoundary = () => {
    if (!onEndReached) {
      return null
    }

    return (
      <EndBoundary
        layout={layout}
        offset={endBoundaryOffset}
        onObserve={onEndReached}
      />
    )
  }

  return (
    <>
      {renderInnerElement(
        { ...props, sx: [isHorizontalList ? { flexShrink: 0 } : null, props.sx ?? null].flat() },
        ref,
      )}
      {renderEndBoundary()}
    </>
  )
})

const Item = ({ data, index, style: styleActual }: ListChildComponentProps<unknown[]>): React.ReactElement => {
  const virtualizedList = useContext(VirtualizedListContext)

  assert(virtualizedList, checkNonNull)

  const { paddingTop, renderItem, gap, layout, paddingLeft } = virtualizedList

  const item = data[index]

  const isHorizontalList = layout === 'horizontal'

  const style = useMemo<React.CSSProperties>(
    () => ({
      ...styleActual,
      height: isHorizontalList ? styleActual.height : Number(styleActual.height) - (index === 0 ? 0 : gap),
      left: isHorizontalList ? Number(styleActual.left) + paddingLeft + (index === 0 ? 0 : gap) : styleActual.left,
      overflow: 'hidden',
      top: isHorizontalList ? styleActual.top : Number(styleActual.top) + paddingTop + (index === 0 ? 0 : gap),
      width: isHorizontalList ? Number(styleActual.width) - (index === 0 ? 0 : gap) : styleActual.width,
    }),
    [styleActual, paddingTop, index, gap, isHorizontalList, paddingLeft],
  )

  return renderItem({ index, item, style })
}

export type VirtualizedListPropsT<ItemT extends unknown> = {
  children: (props: { item: ItemT; style: React.CSSProperties; index: number }) => React.ReactElement
  data: readonly ItemT[]
  endBoundaryOffset?: number
  gap?: number
  getItemKey?: (index: number, data: ItemT[]) => React.Key
  getItemSize: (item: ItemT) => number
  height?: number | string
  layout?: 'vertical' | 'horizontal'
  maxHeight?: number
  onEndReached?: () => void
  onItemsRendered?: ListProps['onItemsRendered']
  paddingBottom?: number
  paddingLeft?: number
  paddingTop?: number
  renderInnerElement?: RenderElementT
  renderOuterElement?: RenderElementT
  width?: number | string
}

export const VirtualizedList = forwardFunctionalComponentRef(
  <ItemT extends unknown>(
    {
      endBoundaryOffset,
      children,
      data,
      gap = 0,
      getItemKey,
      getItemSize,
      maxHeight = Infinity,
      onEndReached,
      onItemsRendered,
      paddingBottom = 0,
      paddingTop = 0,
      paddingLeft = 0,
      renderInnerElement,
      renderOuterElement,
      layout,
      width,
      height: heightProp = '100%',
    }: VirtualizedListPropsT<ItemT>,
    ref: React.ForwardedRef<VariableSizeList>,
  ): React.ReactElement => {
    const variableSizeListRef = useRef<VariableSizeList>(null)

    const variableSizeListCombinedRef = useCombineRefs(ref, variableSizeListRef)

    useEffect(() => {
      const variableSizeList = variableSizeListRef.current

      if (!variableSizeList) {
        return
      }

      variableSizeList.resetAfterIndex(0, true)
    }, [data.length])

    const height = useMemo(
      () =>
        pipeline(
          data,
          (x) => x.map((item, index) => getItemSize(item) + (index === 0 ? 0 : gap)),
          sumAll,
          (x) => Math.min(x, maxHeight),
          (x) => paddingTop + x + paddingBottom,
        ),
      [data, getItemSize, gap, maxHeight, paddingTop, paddingBottom],
    )

    const itemSize = useCallback(
      (index: number) => getItemSize(data[index]) + (index === 0 ? 0 : gap),
      [gap, getItemSize, data],
    )

    const isHorizontalList = layout === 'horizontal'

    return (
      <MemoProvider
        Context={VirtualizedListContext}
        value={{
          endBoundaryOffset,
          gap,
          layout,
          onEndReached,
          paddingLeft,
          paddingTop,
          renderInnerElement,
          renderItem: children,
          renderOuterElement,
        }}
      >
        <VariableSizeList
          height={isHorizontalList ? heightProp : height}
          innerElementType={InnerElementType}
          itemCount={data.length}
          itemData={data}
          itemKey={getItemKey}
          itemSize={itemSize}
          layout={layout}
          outerElementType={OuterElementType}
          ref={variableSizeListCombinedRef}
          style={isHorizontalList ? { width: '100%' } : {}}
          width={width ?? '100%'}
          onItemsRendered={onItemsRendered}
        >
          {Item}
        </VariableSizeList>
      </MemoProvider>
    )
  },
)
