import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadT } from '@resnet/client-api/api'
import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { CustomFieldT } from '../../types/custom-field'

export const dateCustomField = createConstrainer<CustomFieldT>()({
  id: CustomFieldTypeT.DateT as const,
  name: 'Date',
  payload: createConstrainer<CustomFieldPayloadT>()({
    Date: {
      addTimeEnabled: false,
      defaultValue: null,
      required: false,
    },
  }) as CustomFieldPayloadT,
})
