import { useMemo } from 'react'

import { useMapFieldModel } from '@resnet/client-common/react/hooks/use-map-field-model'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import type { EntityTypeIdsT } from '@resnet/client-api/api'
import { FilterModeT } from '@resnet/client-api/api'

import { EntityOptionContainer } from '@resnet/client-shared/shared/entities/hooks/use-entity-option'

import {
  EntitiesSelectDropdownContainer,
  getEntitySelectOptionLabel,
} from '@resnet/client-web/shared/entities/hooks/use-entities-select-dropdown-azeroth'
import { DropdownInput } from '@resnet/client-web/shared/gdl/components/dropdown-input'
import { MultipleDropdownText } from '@resnet/client-web/shared/gdl/components/multiple-dropdown-text'
import { Popover } from '@resnet/client-web/shared/gdl/components/popover'
import { Popper, PopperContext } from '@resnet/client-web/shared/gdl/components/popper'
import { SelectSearch } from '@resnet/client-web/shared/gdl/components/select-dropdown/components/select-search'
import { SelectMultipleDropdown } from '@resnet/client-web/shared/gdl/components/select-multiple-dropdown'
import { SelectMultipleDropdownWithNullOptionContainer } from '@resnet/client-web/shared/gdl/components/select-multiple-dropdown/hooks/use-select-multiple-dropdown-with-null-option'
import { useIncludeExcludeFilterSelectMultipleDropdown } from '@resnet/client-web/shared/presets/hooks/use-include-exclude-filter-select-multiple-dropdown'

import type { IncludeExcludeFilterT } from '../../../presets/types/filters'

export const EntityMultipleFilter = <ItemT extends null | string = string>({
  disablePortal,
  nullOptionEnabled,
  nullOptionLabel,
  onChange: onChangeProp,
  placement,
  popperRef,
  value: valueProp,
  entityTypeIds,
}: {
  disablePortal?: boolean
  nullOptionEnabled?: boolean
  nullOptionLabel?: React.ReactNode
  onChange: (value: IncludeExcludeFilterT<ItemT>) => void
  placement?: React.ComponentProps<typeof Popper>['placement']
  popperRef?: React.ComponentProps<typeof Popper>['popperRef']
  value: undefined | null | IncludeExcludeFilterT<ItemT>
  entityTypeIds?: EntityTypeIdsT[]
}) => {
  const [valueActual, onChangeActual] = useMapFieldModel(
    [valueProp, onChangeProp],
    (inputValue) => inputValue ?? { items: [], mode: FilterModeT.IncludeT },
    (outputValue) =>
      outputValue.mode === FilterModeT.IncludeT && outputValue.items.length === 0 ? undefined : outputValue,
  )

  const includeExcludeFilterProps = useIncludeExcludeFilterSelectMultipleDropdown({
    onChange: onChangeActual,
    value: valueActual,
  })

  const filter = useMemo(() => {
    if (!entityTypeIds) {
      return {}
    }

    return {
      entityTypeId: {
        in: entityTypeIds,
      },
    }
  }, [entityTypeIds])

  const renderAnchor = (): React.ReactElement => {
    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, isOpened, open }) => (
          <DropdownInput
            isOpened={isOpened}
            ref={setAnchorEl}
            onClick={open}
          >
            <MultipleDropdownText
              OptionContainer={EntityOptionContainer}
              getOptionLabel={getEntitySelectOptionLabel}
              mode={includeExcludeFilterProps.mode}
              nullOptionLabel={nullOptionLabel}
              value={includeExcludeFilterProps.value}
            />
          </DropdownInput>
        )}
      </NonNullableContextContainer>
    )
  }

  const renderContent = (): React.ReactElement => {
    return (
      <Popover>
        <EntitiesSelectDropdownContainer filter={filter}>
          {({ searchProps, dropdownProps }) => (
            <SelectMultipleDropdownWithNullOptionContainer
              {...includeExcludeFilterProps}
              {...dropdownProps}
              enabled={nullOptionEnabled}
              nullOptionLabel={nullOptionLabel}
            >
              {(dropdownPropsWithNullOption) => (
                <SelectMultipleDropdown
                  {...includeExcludeFilterProps}
                  {...dropdownProps}
                  {...dropdownPropsWithNullOption}
                  search={<SelectSearch {...searchProps} />}
                />
              )}
            </SelectMultipleDropdownWithNullOptionContainer>
          )}
        </EntitiesSelectDropdownContainer>
      </Popover>
    )
  }

  return (
    <Popper
      disablePortal={disablePortal}
      placement={placement}
      popperRef={popperRef}
    >
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}
