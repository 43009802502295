import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable, checkNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { assert } from '@resnet/client-common/typescript/utils/assert'
import { toPartial } from '@resnet/client-common/typescript/utils/to-partial'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type {
  SketchSingleCustomFieldValueT,
  SketchMultipleCustomFieldValueT,
  SketchCustomFieldValueT,
} from '@resnet/client-shared/shared/custom-fields/presets/sketch/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type {
  SketchFormCustomFieldValueT,
  SketchMultipleFormCustomFieldValueT,
  SketchSingleFormCustomFieldValueT,
} from './form-custom-field-value'

export const mapCustomFieldValueToFormCustomFieldValue: CustomFieldT<
  SketchCustomFieldValueT,
  SketchFormCustomFieldValueT
>['mapCustomFieldValueToFormCustomFieldValue'] = ({ value, field, resources }) => {
  const resourcesById = toPartial(propToKey('id', resources))

  const payload = field.payload

  assert(payload, checkNonNullable)

  const sketchPayload = payload[CustomFieldTypeT.SketchT]

  assert(sketchPayload, checkNonNullable)

  const { multiple } = sketchPayload

  if (multiple) {
    const sketchMultipleFieldValue = value as SketchMultipleCustomFieldValueT

    const sketchMultipleFormFieldValue: SketchMultipleFormCustomFieldValueT = pipeline(
      sketchMultipleFieldValue,
      (x) => x ?? [],
      (x) => x.map((id) => resourcesById[id]),
      (x) => x.filter(checkNonUndefined),
    )

    return sketchMultipleFormFieldValue
  }

  const sketchSingleFieldValue = value as SketchSingleCustomFieldValueT

  const sketchSingleFormFieldValue: SketchSingleFormCustomFieldValueT = pipeline(
    sketchSingleFieldValue,
    (x) => (!checkNonNullable(x) ? x : resourcesById[x]),
    (x) => x ?? null,
  )

  return sketchSingleFormFieldValue
}
